import React, {useContext} from 'react'
import RevenueView from "./revenue-view";
import {useRevenueQuery, useUpdateInitStateMutation} from "../../../generated/graphql";
import {message, Spin} from "antd";
import {handle} from "../../app/app";
import {FintoolContext} from "../../../context/fintool-context";
import {RESULT} from "../common/result-chart/queries";
import {CALCULATION_INFO} from "../queries";

interface Props {
    calculationHash: string
}

const RevenueContainer: React.FC<Props> = ({calculationHash}) => {

    const [context, setContext] = useContext(FintoolContext)

    const [updateInitState] = useUpdateInitStateMutation({
            refetchQueries: [
                {query: RESULT, variables: {calculationHash: context.calculationHash}},
                {query: CALCULATION_INFO, variables: {calculationHash: context.calculationHash}}
            ]
        }
    )

    const onUpdate = () => {
        updateInitState({
            variables: {
                calculationHash: context.calculationHash!,
                newState: false
            }
        }).then((result: any) => {
            setContext({...context, initState: result.data.updateInitState.initState})
        }).catch(error => {
            message.error(error.message)
        })
    }

    const {data, error, loading, networkStatus} = useRevenueQuery({
        variables: {calculationHash: calculationHash},
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "cache-and-network"
    });

    if (loading || networkStatus === 4) {
        return <div><Spin size="small" /></div>;
    }

    error && handle(error)

    if (!data) {
        return null;
    }

    if (!!data.revenue) {
        return (
            <RevenueView data={data.revenue} updateInitState={onUpdate} />
        )
    }
    return null
}

export default RevenueContainer
import React, {useContext} from 'react'
import {useLoanQuery} from "../../../../generated/graphql";
import {Spin} from "antd";
import {FintoolContext} from "../../../../context/fintool-context";
import LoanCalculationModuleSaveContainer from "./loan-calculation-module-save-container";
import {handle} from "../../../app/app";

interface Props {
    onClose: any
    selectedLoanId: string
}

const LoanCalculationModuleFetchContainer: React.FC<Props> = ({onClose, selectedLoanId}) => {

    const [context] = useContext(FintoolContext)

    const {data, error, loading, networkStatus} = useLoanQuery({
        variables: {
            calculationHash: context.calculationHash!,
            loanId: selectedLoanId
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "network-only"
    })

    if (loading || networkStatus === 4) {
        return <div><Spin size="small" /></div>;
    }

    error && handle(error)

    if (!data) {
        return null;
    }
    return (
        <LoanCalculationModuleSaveContainer onClose={onClose} loan={data.loan} />
    )
}

export default LoanCalculationModuleFetchContainer
// stolen from https://blog.usejournal.com/internationalization-with-react-hooks-af37bed9f195

import React, {useContext, useReducer} from 'react'
import Cookies from 'js-cookie';
import moment from 'moment'

import NL from './nl.json'
import FR from './fr.json'
import EN from './en.json'
import numeral from 'numeral'
import {Maybe, Scalars, useUpdateLanguageMutation} from "../generated/graphql";
import {getBackendPrefix} from "../components/app/app";
import queryString from "query-string";
import {FintoolContext} from "../context/fintool-context";

const translations: any = {
    nl: NL,
    fr: FR,
    en: EN,
}

fetchExtraTranslationFromTheBackend("nl");
fetchExtraTranslationFromTheBackend("fr");
fetchExtraTranslationFromTheBackend("en");

export const getTranslate = (langCode: string) => (key: string, escape?: boolean) =>
    (translations[langCode.toLowerCase()][key] === "" || !!translations[langCode.toLowerCase()][key]) ?
        (escape ?
            <span dangerouslySetInnerHTML={{__html: translations[langCode.toLowerCase()][key]}} /> : translations[langCode.toLowerCase()][key]) :
        key;


function initLanguage (){
    let queryParams = queryString.parse(window.location.search);
    if (!!queryParams['lang']) {
        Cookies.set('lang', queryParams['lang'])
        removeUrlParameter('lang')
    }
}
initLanguage();

const initialState: any = {
    langCode: Cookies.get("lang") || "nl",
    translate: getTranslate(Cookies.get("lang") || "nl"),
};

export const I18nContext = React.createContext(initialState);

export const I18nContextProvider = ({children}: any) => {

    const [context] = useContext(FintoolContext)

    const [updateLanguage] = useUpdateLanguageMutation();

    const reducer = (state: any, action: any) => {
        switch (action.type) {
            case "setLanguage":
                updateLanguage({
                    variables: {
                        calculationHash: context.calculationHash,
                        lang: action.payload
                    }
                });
                Cookies.set('lang', action.payload)
                removeUrlParameter('lang');
                moment.locale(action.payload)
                return {
                    langCode: action.payload,
                    translate: getTranslate(action.payload),
                };
            default:
                return {...initialState};
        }
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <I18nContext.Provider value={{...state, dispatch}}>
            {children}
        </I18nContext.Provider>
    );
};

const value = {
    delimiters: {
        thousands: ' ',
        decimal: ',',
    },
    currency: {
        symbol: '€',
    },
} as NumeralJSLocale;
numeral.register('locale', 'nl', value);
numeral.locale('nl');

export const currency = (value: Maybe<Scalars["Float"]> | undefined, withoutCurrencySign?: Boolean, rounded?: Boolean) => {
    if (value) {
        return numeral(rounded ? Math.round(value) : value).format((withoutCurrencySign ? '' : '$ ') + (rounded ? '0,0' : '0,0.00'));
    }
    else {
        return (withoutCurrencySign ? '' : '€ ') + (rounded ? '0' : '0,00')
    }
}

export const noCurrency = (value: number, rounded?: Boolean) => {
    if (value) {
        return numeral(rounded ? Math.round(value) : value).format(rounded ? '0' : '0,0');
    }
    else {
        return '0'
    }
}

export const avoidWrongNumericInput = (event: any) => {
    // allow backspace and so on
    if (event.keyCode === 8 || event.keyCode === 9 || event.keyCode === 37 || event.keyCode === 39) {
        return
    }
    if (["e", "E", "+", "-"].includes(event.key)) {
        event.preventDefault()
    }
    var regex = /^[0-9,]*$/;
    if (!event.key.match(regex)) {
        event.preventDefault()
    }
    if ([".", ","].includes(event.key) &&
        (!!event.target.value && (event.target.value.toString().includes('.') || event.target.value.toString().includes(',')))) {
        event.preventDefault()
    }
}

function fetchExtraTranslationFromTheBackend(lang: string) {
    fetch(getBackendPrefix() + "/backend/messagebundle/?lang=" + lang)
        .then(res => res.json())
        .then((out) => {
            translations[lang] = {...translations[lang], ...out};
        })
        .catch(err => {
            console.log("Could not load translations: " + err);
            throw err
        });
}


function removeUrlParameter(key:string) {
    let queryParams = queryString.parse(window.location.search);
    if (!!queryParams[key]) {
        delete queryParams[key];
        const newQuery = queryString.stringify(queryParams);
        if (newQuery === '') {
            window.location.href = window.location.pathname
        }
        else {
            window.location.search = newQuery;
        }
    }
}

import React, {useContext, useState} from 'react'
import {ExpenseCategory, ExpenseItem, ExpenseType} from "../../../../generated/graphql";
import ShadowBox, {BoxTitle} from "../../../common/shadow-box/shadow-box";
import GeneralExpenseSingleRowFormContainer from "./general-expense-single-row-form-container";
import {I18nContext} from "../../../../i18n";
import SocialExpenseContainer from "./social/social-expense-container";

interface Props {
    onAdd: any
    data: ExpenseItem[]
    expenseCategory: ExpenseCategory
}

const GeneralExpenseView: React.FC<Props> = ({data, onAdd, expenseCategory}) => {

    const [boxOpen, setBoxOpen] = useState(true)
    const {translate} = useContext(I18nContext);

    const onAddForType = (toAdd: any) => {
        onAdd(expenseCategory.expenseTypes.length === 1 ? expenseCategory.expenseTypes[0].code : toAdd.option, expenseCategory.code)
    }

    const isSocialSecurityCategory = () => {
        return expenseCategory.code === "SOCIAL_SECURITY";
    }

    const isFirstItem = (index:number) => {
        if(isSocialSecurityCategory()){
            return false;
        }
        return index === 0;
    }

    return (
        <ShadowBox title={
            <BoxTitle iconClass={"icon-expense-category-" + expenseCategory.code}
                      text={translate("expense.category." + expenseCategory.code)}
                      addText={translate("expense.category.add.text." + expenseCategory.code)}
                      explanation={expenseCategory.code === "MATERIAAL" ? translate("expense.category.MATERIAAL.long.subtitle") : null}
                      setBoxOpen={setBoxOpen}
                      boxOpen={boxOpen}
                      noArrow={false}
                      onAdd={onAddForType}
                      addOptions={expenseCategory.expenseTypes.length === 1 ? null : expenseCategory.expenseTypes.map((expenseType) => {
                          return {
                              disabled: (!expenseType.multipleAllowed && data.some((expenseItem: ExpenseItem) => expenseItem.expenseType === expenseType.code)),
                              value: expenseType.code,
                              text: translate("expense.type." + expenseType.code)
                          }
                      })}
                      trackOpenCloseClass={translate("expense.general.open.close.class")}
                      trackAddClass={translate("expense.category.add. " + expenseCategory.code + ".class")}
            />
        } open={boxOpen}
                   noArrow={false}>
            {isSocialSecurityCategory() &&
                <div key={'div_sociale_bijdragen'} className={'first'}>
                    <SocialExpenseContainer />
                </div>}
            {data.map((expenseItem, index) =>
                !!expenseItem ?
                    <div className={isFirstItem(index) ? 'first' : ''} key={'div_'+ expenseItem.id} >
                    <GeneralExpenseSingleRowFormContainer
                        key={expenseItem.id}
                        expenseItem={expenseItem}
                        expenseType={expenseCategory.expenseTypes!.find((expenseType: ExpenseType) => expenseType.code === expenseItem.expenseType)!}
                    /></div> : null
            )}
        </ShadowBox>)
}

export default GeneralExpenseView
import React, {useContext} from 'react'
import LoanCalculationModuleView from "./loan-calculation-module-view";
import {Loan, LoanInput, useSaveLoanMutation} from "../../../../generated/graphql";
import {FINANCING} from "../queries";
import {RESULT} from "../../common/result-chart/queries";
import {message} from "antd";
import {MESSAGE_DURATION} from "../../../../utils/constants";
import {FintoolContext} from "../../../../context/fintool-context";
import {I18nContext} from "../../../../i18n";
import {LOAN} from "./queries";

interface Props {
    onClose: any
    loan: Loan
}

const LoanCalculationModuleSaveContainer: React.FC<Props> = ({onClose, loan}) => {

    const [context] = useContext(FintoolContext)
    const {translate} = useContext(I18nContext)

    const [saveLoan] = useSaveLoanMutation({
            refetchQueries: [
                {query: FINANCING, variables: {calculationHash: context.calculationHash}},
                {query: LOAN, variables: {calculationHash: context.calculationHash, loanId: loan.id}},
                {query: RESULT, variables: {calculationHash: context.calculationHash}}
            ]
        }
    );

    const onSave = (loanInput: LoanInput) => {
        if (!!context.calculationHash) {
            saveLoan({
                variables: {
                    calculationHash: context.calculationHash,
                    loanInput: {...loanInput, id: loan ? loan.id : null}
                }
            }).then((result: any) => {
                message.success(translate("common.save.success"), MESSAGE_DURATION)
            }).catch((error: any) => {
                message.error(error.message)
            })
        }
    }
    return (
        <LoanCalculationModuleView onSave={onSave} onClose={onClose} loan={loan} />
    )
}

export default LoanCalculationModuleSaveContainer
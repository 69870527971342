import React, {useContext} from 'react'
import {FormComponentProps, ValidateCallback} from "antd/es/form";
import {Button, Col, Form, Input, Row, Slider} from "antd";
import {currency, I18nContext} from "../../../../i18n";
import {Loan, LoanInput} from "../../../../generated/graphql";
import AcertaIcon from "../../../common/iconfont";
import {useMediaQuery} from "react-responsive";
import {isMobileQuery} from "../../../../utils/media-queries";

interface FormProps extends FormComponentProps {
    onSave: any
    onClose: any
    loan: Loan
}

const LoanCalculationModuleForm: React.FC<FormProps> = ({form, onSave, onClose, loan}) => {
    const {getFieldDecorator} = form;
    const {translate} = useContext(I18nContext)
    const isMobile = useMediaQuery(isMobileQuery)

    const onChangeValue = () => {
        form.isFieldsTouched() &&
        form.validateFields((err: ValidateCallback<any>, values: LoanInput) => {
            if (!err) {
                onSave(values, form)
            }
        })
    }

    const yearsLabel = () => {
        let years = form.getFieldValue('duration') || (loan ? loan.duration : 1)
        return <>{years} {translate("common.years")}</>
    }

    const tooltipContainer = () => {
        return document.getElementById("sliders-tooltip-loan-container")!
    }

    return (
        <>
            <div id="sliders-tooltip-loan-container"></div>
            <h3 style={{marginBottom: 5}}>
                <AcertaIcon icon={"icon-calculator-24"} style={{marginRight: 8, verticalAlign: '-0.1em'}} />
                {translate("financing.loan.title")}</h3>
            <div>
                {translate("financing.loan.explanation", true)}
            </div>
            <Form id="loanCalculationModuleForm">
                <Row style={{marginBottom: 10}} type="flex" align="middle">
                    <Col xs={24} style={{marginTop: 10, marginBottom: 10}}>
                        {getFieldDecorator('name', {
                            initialValue: loan ? loan.name : null
                        })(
                            <Input placeholder={translate("financing.placeholder.naam")}
                                   onBlur={onChangeValue} onPressEnter={onChangeValue}
                            />
                        )}
                    </Col>
                    <Col xs={24}><h4>{translate("financing.loan.conditions.title")}</h4></Col>
                    {isMobile ?
                        <>
                            <Col xs={10}>
                                <div>
                                    {translate("financing.loan.amount")}
                                </div>
                                <div className="rounded-font acerta-medium-blue-1">
                                    {form.getFieldValue('amount') ?
                                        currency(form.getFieldValue('amount')) :
                                        (loan ? currency(loan.amount, false, true) : "€ 0")}
                                </div>
                            </Col>
                        </>
                        :
                        <>
                            <Col xs={6}>
                                {translate("financing.loan.amount")}
                            </Col>
                            <Col xs={4} style={{textAlign: 'right'}} className="rounded-font acerta-medium-blue-1">
                                {form.getFieldValue('amount') ?
                                    currency(form.getFieldValue('amount'), false, true) :
                                    (loan ? currency(loan.amount, false, true) : "€ 0")}
                            </Col>
                        </>
                    }
                    <Col xs={0} md={1}>
                    </Col>
                    <Col xs={14} md={13}>
                        {getFieldDecorator('amount', {
                            initialValue: loan ? loan.amount : 1000
                        })(
                            <Slider
                                step={1000}
                                min={1000} max={100000}
                                getTooltipPopupContainer={tooltipContainer}
                                onAfterChange={onChangeValue}
                                tipFormatter={(number) => {
                                    return <span>{number} {translate("common.slider.loan.amount")}</span>
                                }}
                            />
                        )}
                    </Col>
                </Row>
                <Row style={{marginBottom: 10}} type="flex" align="middle">
                    {isMobile ?
                        <>
                            <Col xs={10}>
                                <div>{translate("financing.loan.interest")}</div>
                                <div className="rounded-font">
                                    {form.getFieldValue('interest') || (loan ? loan.interest : 0)} %
                                </div>
                            </Col>
                        </>
                        :
                        <>
                    <Col xs={6}>
                        {translate("financing.loan.interest")}
                    </Col>
                    <Col xs={4} style={{textAlign: 'right'}} className="rounded-font">
                        {form.getFieldValue('interest') || (loan ? loan.interest : 0)} %
                    </Col>
                    </>
                    }
                    <Col xs={0} md={1}>
                    </Col>
                    <Col xs={14} md={13}>
                        {getFieldDecorator('interest', {
                            initialValue: loan ? loan.interest : 0.5
                        })(
                            <Slider
                                step={0.5}
                                min={0.5} max={10}
                                getTooltipPopupContainer={tooltipContainer}
                                onAfterChange={onChangeValue}
                                tipFormatter={(number) => {
                                    return <span>{number} {translate("common.slider.loan.interest")}</span>
                                }}
                            />
                        )}
                    </Col>
                </Row>
                <Row style={{marginBottom: 10}} type="flex" align="middle">
                    {isMobile ?
                        <>
                            <Col xs={10}>
                                <div>{translate("financing.loan.duration")}</div>
                                <div className="rounded-font">
                                    {yearsLabel()}
                                </div>
                            </Col>
                        </>
                        :
                        <>
                    <Col xs={6}>
                        {translate("financing.loan.duration")}
                    </Col>
                    <Col xs={4} style={{textAlign: 'right'}} className="rounded-font">
                        {yearsLabel()}
                    </Col>
                    </>}
                    <Col xs={0} md={1}>
                    </Col>
                    <Col xs={14} md={13}>
                        {getFieldDecorator('duration', {
                            initialValue: loan ? loan.duration : 1
                        })(
                            <Slider
                                step={1}
                                min={1} max={35}
                                getTooltipPopupContainer={tooltipContainer}
                                onAfterChange={onChangeValue}
                                tipFormatter={(number) => {
                                    return <span>{number} {translate("common.slider.loan.duration")}</span>
                                }}
                            />
                        )}
                    </Col>
                </Row>
            </Form>
            <div style={{textAlign: 'right', marginTop: 10}}>
                <Button
                    type={"primary"} onClick={() => {
                    onClose()
                }}>{translate("common.button.toepassen")}</Button>
            </div>

        </>
    )
}

const LoanCalculationModuleView = Form.create<FormProps>({
    name: 'loanCalculationModule',
})(LoanCalculationModuleForm);

export default LoanCalculationModuleView
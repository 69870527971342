import gql from "graphql-tag";

export const FINANCING = gql`
    query Financing($calculationHash: String!) {
        financing(calculationHash: $calculationHash) {
            loans  {
                id
                name
                amount
                financing
                includeInCalculation
            }
            ownResources {
                id
                name
                amount
                includeInCalculation
            }
            subsidies {
                id
                name
                amount
                includeInCalculation
            }
        }
    }
`;

export const ADD_FINANCING_ITEM = gql`
    mutation AddFinancingItem($calculationHash: String!, $financingItemType: FinancingItemType!) {
        addFinancingItem(calculationHash: $calculationHash, financingItemType: $financingItemType)
    }
`;

export const UPDATE_FINANCING_ITEM = gql`
    mutation UpdateFinancingItem($calculationHash: String!, $financingItemInput: FinancingItemInput!) {
        updateFinancingItem (
            calculationHash: $calculationHash,
            financingItemInput: $financingItemInput
        )
    }
`;

export const REMOVE_FINANCING_ITEM = gql`
    mutation RemoveFinancingItem($calculationHash: String!, $financingItemType: FinancingItemType!, $financingItemId: String!) {
        removeFinancingItem(calculationHash: $calculationHash, financingItemType: $financingItemType, financingItemId: $financingItemId)
    }
`;

import React, {useContext, useEffect, useState} from 'react'
import {Expense} from "../../../generated/graphql";
import VorigeVolgende from "../common/vorige-volgende";
import expenseIcon from "../../../assets/images/expense.svg";
import Header from "../common/header";
import {Button, Drawer, Icon} from "antd";
import ExpenseCategoriesContainer from "./common";
import {useMediaQuery} from "react-responsive";
import {isTabletOrMobileQuery} from "../../../utils/media-queries";
import {I18nContext} from "../../../i18n";
import ExpenseListContainer from "./expense-list-container";
import {FintoolContext} from "../../../context/fintool-context";

interface Props {
    data: Expense
    updateInitState: any
    updateExpenseSeenState: any
}

const ExpenseView: React.FC<Props> = ({data, updateInitState, updateExpenseSeenState}) => {

    const [showCategories, setShowCategories] = useState(false)
    const {translate} = useContext(I18nContext)

    const isTabletOrMobile = useMediaQuery(isTabletOrMobileQuery)

    const [context] = useContext(FintoolContext)

    useEffect(() => {
        if (context.initState) {
            updateInitState()
        }
        if (!context.expenseSeenState) {
            updateExpenseSeenState()
        }
        // eslint-disable-next-line
    }, [])

    const doShowCategories = () => {
        setShowCategories(true)
    }

    return (
        <>
            <Header stepName={"expenses"} iconSource={expenseIcon} defaultNumberOfTexts={1} />
            {false &&
            <div style={{marginTop: 10}}>
                <Button style={{marginBottom: 20}} type={"primary"} onClick={() => doShowCategories()}>
                    {translate("expenses.button.toon.categorieen")}
                </Button>
            </div>}
            <Drawer
                width={isTabletOrMobile ? '80%' : 900}
                placement="right"
                closable={false}
                onClose={() => setShowCategories(false)}
                visible={showCategories}
            >
                <div style={{textAlign: 'right'}}>
                    <Button type={"link"} onClick={() => setShowCategories(false)}>
                        <Icon style={{fontSize: 20}} type="close" />
                    </Button>
                </div>
                <ExpenseCategoriesContainer />
            </Drawer>
            <ExpenseListContainer groupedExpenses={data!.groupedExpenses} vehicles={data!.vehicles}
                                  housings={data!.housings} />
            <VorigeVolgende />
        </>
    )
}

export default ExpenseView
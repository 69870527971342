import React, {useContext, useState} from 'react'
import {Button, Col, Form, Input, InputNumber, Row, Select, Switch, Tooltip} from "antd";
import {FormComponentProps, ValidateCallback} from "antd/es/form";
import {ExpenseItem, ExpenseItemInput, ExpenseType} from "../../../../generated/graphql";
import {Default, isMobileQuery, Mobile} from "../../../../utils/media-queries";
import AcertaIcon from "../../../common/iconfont";
import {avoidWrongNumericInput, currency, I18nContext} from "../../../../i18n";
import Explanation from "../../../common/explanation";
import {useMediaQuery} from "react-responsive";

const {Option} = Select

interface FormProps extends FormComponentProps {
    expenseItem: ExpenseItem
    onChangedField: any
    onRemove: any
    expenseType: ExpenseType
}

const GeneralExpenseSingleRowForm: React.FC<FormProps> = ({form, expenseItem, onChangedField, onRemove, expenseType}) => {

    const {translate} = useContext(I18nContext);
    const {getFieldDecorator} = form;
    const isMobile = useMediaQuery(isMobileQuery)
    const [priceSelected, setPriceSelected] = useState(false)
    const [quantitySelected, setQuantitySelected] = useState(false)

    const update = () => {
        form.validateFields((err: ValidateCallback<any>, values: ExpenseItemInput) => {
            if (!err) {
                values.unitPrice = values.unitPrice || 0
                values.quantity = values.quantity || 0
                onChangedField({
                    ...values,
                    id: expenseItem.id,
                    includeInCalculation: expenseItem.includeInCalculation
                })
            }
        })
    }

    const doUpdate = () => {
        if (form.isFieldsTouched()) {
            update()
        }
    }

    const doUpdateFromSelect = () => {
        update()
    }

    const toggleExclusion = (checked: boolean) => {
        form.validateFields((err: ValidateCallback<any>, values: ExpenseItemInput) => {
            if (!err) {
                values.unitPrice = values.unitPrice || 0
                values.quantity = values.quantity || 0
                values.includeInCalculation = !expenseItem.includeInCalculation;
                onChangedField({...values, id: expenseItem.id});
            }
        })
    };

    const toggleField = getFieldDecorator('includeInCalculation', {})(
        <Switch className={translate("expense.general.toggle.class")} size="small" onChange={toggleExclusion}
                checked={expenseItem.includeInCalculation} />
    )

    const descriptionInput = getFieldDecorator('description', {
        initialValue: expenseItem.description,
    })(
        <Input
            style={{marginBottom: isMobile ? 10 : 0, width: '80%'}}
            disabled={!expenseItem.includeInCalculation} onPressEnter={doUpdate}
               onBlur={doUpdate}
               placeholder={translate("expense.type." + expenseItem.expenseType)} />,
    )

    const quantityField = getFieldDecorator('quantity', {
        initialValue: expenseItem.quantity,
    })(
        <InputNumber
            disabled={!expenseItem.includeInCalculation} onPressEnter={doUpdate}
            onBlur={() => {
                doUpdate();
                setQuantitySelected(false)
            }}
            decimalSeparator={','}
            onKeyDown={(e) => avoidWrongNumericInput(e)}
            className={
                'input-amount'
                + (!expenseItem.includeInCalculation ? '-disabled item-excluded' : '')}
            onFocus={(e) => {
                setQuantitySelected(true)
                !isMobile && e.target.select()
            }}
        />
    )

    const unitPriceField = getFieldDecorator('unitPrice', {
        initialValue: expenseItem.unitPrice,
    })(
        <InputNumber style={{width: isMobile ? 90 : 90}}
                     disabled={!expenseItem.includeInCalculation || expenseType.fixedPrice} onPressEnter={doUpdate}
                     onBlur={() => {
                         doUpdate();
                         setPriceSelected(false)
                     }}
                     decimalSeparator={','}
                     onKeyDown={(e) => avoidWrongNumericInput(e)}
                     className={
                         "input-currency" + (!expenseItem.includeInCalculation || expenseType.fixedPrice ? "-disabled" : "")
                         + (!expenseItem.includeInCalculation ? ' item-excluded' : '')}
                     onFocus={(e) => {
                         setPriceSelected(true)
                         !isMobile && e.target.select()
                     }}
        />
    )
    const expenseTypeOptionSelect = getFieldDecorator('expenseTypeOption', {
        initialValue: expenseItem.expenseTypeOption || undefined,
    })(
        <Select
            placeholder={translate("expenses.placeholder.vergunning")}
            showSearch={false}
            filterOption={false}
            style={{width: isMobile ? '85%' : '90%', marginBottom: isMobile ? 10 : 0}}
            disabled={!expenseItem.includeInCalculation}
            onSelect={doUpdateFromSelect}
            className={expenseItem.includeInCalculation ? '' : 'item-excluded'}
        >
            {expenseType.expenseTypeOptions!.map((expenseTypeOption) => <Option
                className='vergunning-dropdown-item'
                value={expenseTypeOption.code}
                key={expenseTypeOption.code}>{translate("expense.option." + expenseTypeOption.code)}</Option>)}
        </Select>
    )

    const deleteButton = <Button className={translate("expense.general.delete.class")} style={{marginRight: isMobile ? 22 : 19}} type={"link"}
                                 onClick={() => onRemove(expenseItem.id)}>
        <AcertaIcon icon={"icon-trash-24"} size="18px" />
    </Button>
    return (
        <Form id={expenseItem.id}>
            <Default>
                <Row className="single-row-form-row" type="flex" align="middle" justify="space-between" gutter={16}>
                    <Col xs={2}>
                        {toggleField}
                    </Col>
                    <Col xs={expenseItem.useQuantity ? 6 : 12}>
                        <span className={expenseItem.includeInCalculation ? '' : 'item-excluded'}>
                        {
                            expenseType.expenseTypeOptions && expenseType.expenseTypeOptions.length > 0 ?
                                expenseTypeOptionSelect :
                                (expenseType.fixedName ? translate("expense.type." + expenseItem.expenseType) : descriptionInput)
                        }
                            {translate("expense.type.help.text." + expenseItem.expenseType) && translate("expense.type.help.text." + expenseItem.expenseType).lenght !== "" &&
                            <Explanation
                                title={translate("expense.type.help.title." + expenseItem.expenseType)}
                                explanation={translate("expense.type.help.text." + expenseItem.expenseType, true)}
                                placement={"right"} />}
                        </span>
                    </Col>
                    {expenseItem.useQuantity &&
                    <Col xs={5} style={{textAlign: 'right'}}>
                        <span style={{color: '#999', marginRight: 8}}>{translate("common.aantal")}</span>
                        <Tooltip title={translate("common.by.year")}>{quantityField}</Tooltip>
                    </Col>
                    }
                    <Col xs={expenseItem.useQuantity ? 6 : 8} style={{textAlign: 'right'}}>
                        <span style={{color: '#999', marginRight: 8}}>{translate("common.prijs")}</span>
                        <Tooltip title={translate("common.price." + expenseItem.expenseType)}>{unitPriceField}</Tooltip>
                    </Col>
                    {expenseItem.useQuantity &&
                    <Col xs={3} style={{textAlign: 'right'}}>
                        <span className={expenseItem.includeInCalculation ? '' : 'item-excluded'}>
                            {currency(expenseItem.calculatedCashFlowValue!)}
                        </span>
                    </Col>
                    }
                    <Col xs={2} style={{textAlign: 'right'}}>
                        {deleteButton}
                    </Col>
                </Row>
            </Default>
            <Mobile>
                <Row style={{marginTop: 0}} gutter={0}>
                    <Col xs={3}>
                        <span>{toggleField}</span>
                    </Col>
                    <Col xs={18}>
                        <span className={expenseItem.includeInCalculation ? '' : 'item-excluded'}>
                        {
                            expenseType.expenseTypeOptions && expenseType.expenseTypeOptions.length > 0 ?
                                expenseTypeOptionSelect :
                                (expenseType.fixedName ? translate("expense.type." + expenseItem.expenseType) : descriptionInput)
                        }
                            {translate("expense.type.help.text." + expenseItem.expenseType) && translate("expense.type.help.text." + expenseItem.expenseType).lenght !== "" &&
                            <Explanation
                                title={translate("expense.type.help.title." + expenseItem.expenseType)}
                                explanation={translate("expense.type.help.text." + expenseItem.expenseType, true)}
                                placement={"right"} />}
                        </span>
                    </Col>
                    <Col xs={3} style={{textAlign: 'right'}}>
                        {deleteButton}
                    </Col>
                </Row>
                <Row type="flex" align="middle"
                     style={{paddingBottom: 15, marginTop: 0, marginBottom: 12, borderBottom: '1px solid #eee'}}>
                    <Col xs={3}></Col>
                    {expenseItem.useQuantity &&
                    <Col xs={8} style={{textAlign: 'right'}}>
                        {translate("common.aantal")}&nbsp;
                        <span style={{position: "relative"}}>
                            {quantityField}
                            {quantitySelected && isMobile &&
                            <span style={{
                                position: 'absolute',
                                top: 25, right: 3,
                                fontSize: 11,
                                fontStyle: "italic",
                                color: '#888'
                            }}>{translate("common.by.year")}</span>}</span>
                    </Col>
                    }
                    <Col xs={expenseItem.useQuantity ? 10 : 18} style={{textAlign: 'right'}}>
                        {translate("common.bedrag")}&nbsp;
                        <span style={{position: "relative"}}>
                            {unitPriceField}
                            {priceSelected && isMobile &&
                            <div style={{
                                position: 'absolute',
                                top: 25, right: 3,
                                fontSize: 11,
                                fontStyle: "italic",
                                color: '#888',
                                width: '200px'
                            }}>{translate("common.price." + expenseItem.expenseType)}</div>}
                        </span>
                    </Col>
                    <Col xs={3}>
                    </Col>
                </Row>
            </Mobile>
        </Form>
    )
}

const GeneralExpenseSingleRowFormView = Form.create<FormProps>({
    name: 'generalExpense',
})(GeneralExpenseSingleRowForm);

export default GeneralExpenseSingleRowFormView
import React, {useContext, useState} from 'react'
import {I18nContext} from "../../../../i18n";
import AcertaIcon from "../../iconfont";
import {Button, Modal, Tooltip} from "antd";
import MailCaptureContainer from "../../mail-capture/mail-capture-container";
import {FintoolContext} from "../../../../context/fintool-context";
import CurrentCodeContainer from "../../code-enter/current-code-container";
import {CalculationInfo} from "../../../../generated/graphql";
import {useMediaQuery} from "react-responsive";
import {isDesktopQuery, isMobileQuery} from "../../../../utils/media-queries";

interface Props {
    calculationInfo: CalculationInfo | null
}

const SaveSimulationView: React.FC<Props> = ({calculationInfo}) => {

    const {translate} = useContext(I18nContext)
    const [showCodePopup, setShowCodePopup] = useState(false)
    const [context] = useContext(FintoolContext)
    const isDesktop = useMediaQuery(isDesktopQuery)
    const isMobile = useMediaQuery(isMobileQuery)

    const button = <Button id={translate("common.simulatie.opslaan.id")} type={"link"}
                           style={{
                               color: "#3F7CBF",
                           }}
                         onClick={() => setShowCodePopup(true)}>

        <AcertaIcon
            icon={"icon-save-24"}
            size={'18px'}
            style={{verticalAlign: '-0.05em', color: "#3F7CBF", marginRight: 7}} /> {isMobile && translate('common.simulatie.opslaan')}
    </Button>;
    return (
        <>{isDesktop ?
            <Tooltip title={translate('common.simulatie.opslaan')} placement={"bottomLeft"}>
                {button}
            </Tooltip>:button}
            <Modal
                visible={showCodePopup}
                onCancel={() => setShowCodePopup(false)}
                okButtonProps={{disabled: true}}
                footer={null}
            >
                {(context.calculationHash !== null && (!calculationInfo || !calculationInfo.fintoolUser)) &&
                <MailCaptureContainer
                    inModal={true}
                    onModalAction={() => setShowCodePopup(false)}
                    title={translate("simulation.save.title")}
                />}
                {calculationInfo && calculationInfo.code &&
                <CurrentCodeContainer calculationInfo={calculationInfo} />
                }
            </Modal>
        </>
    )
}

export default SaveSimulationView
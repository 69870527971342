import React, {useContext} from 'react'
import ProfileView from "./profile-view";
import {message} from "antd";
import {useProfileQuery, useUpdateProfileMutation} from "../../../generated/graphql";
import {FintoolContext} from "../../../context/fintool-context";
import {RouteComponentProps} from "react-router";
import {steps, useSetCorrectSimulationStep} from "../simulation-view";
import {RESULT} from "../common/result-chart/queries";
import {I18nContext} from "../../../i18n";
import {MESSAGE_DURATION} from "../../../utils/constants";
import {CALCULATION_INFO} from "../queries";

interface Props extends RouteComponentProps {
}

const ProfileContainer: React.FC<Props> = ({match, history}) => {

    const [context, setContext] = useContext(FintoolContext)
    const {translate} = useContext(I18nContext);
    useSetCorrectSimulationStep(match, steps.PROFILE)

    const {data} = useProfileQuery({
        variables: {calculationHash: context.calculationHash as string},
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "network-only"
    });

    const [updateProfile] = useUpdateProfileMutation({
        refetchQueries: [
            {
                query: RESULT,
                variables: {
                    calculationHash: context.calculationHash
                }
            },
            {
                query: CALCULATION_INFO,
                variables: {
                    calculationHash: context.calculationHash
                }
            }]
    });

    const update = (sectors: string[], regions: string[], desiredNetMonthlyIncome: number, profession: string, dependentChildren: number, next: boolean) => {
        const calculationHash = context.calculationHash as string;
        updateProfile(
            {
                variables: {
                    calculationHash: calculationHash,
                    profileInput: {
                        sectors: sectors,
                        regions: regions,
                        desiredNetMonthlyIncome: +desiredNetMonthlyIncome,
                        profession: profession,
                        dependentChildren: dependentChildren,
                    }
                },
            }
        ).then((result: any) => {
            let calculationHash = context.calculationHash as string;
            if (!calculationHash) {
                calculationHash = result.data.updateProfile;
                setContext({
                    ...context,
                    calculationHash: calculationHash,
                    initState: false
                });
            }
            message.success(translate("common.save.success"), MESSAGE_DURATION)
            next && history.push("/simulatie/ontvangsten/" + calculationHash)
        }).catch(error => {
            console.log(error.message);
            message.error(error.message)
        })
    };

    return (
        !data ? null :
            <ProfileView valueChange={update} data={data} />
    )
};

export default ProfileContainer
import React, {useContext, useState} from 'react'
import {Affix, Button, Drawer, Icon} from "antd";
import visualsIcon from '../../../../assets/images/visual-icon-round.png'
import sofieAvatar from '../../../../assets/images/sofie_avatar.png'
import ResultChartContainer from "../result-chart";
import {FintoolContext} from "../../../../context/fintool-context";
import './tabs.css'
import Sofie from "./sofie";
import {useMediaQuery} from "react-responsive";
import {isMobileQuery} from "../../../../utils/media-queries";
import {I18nContext} from "../../../../i18n";

const TabsMobile = () => {

    const [context] = useContext(FintoolContext)
    const isMobile = useMediaQuery(isMobileQuery)
    const {translate} = useContext(I18nContext);

    const [exampleOpen, setExampleOpen] = useState(false)
    const [visualsOpen, setVisualsOpen] = useState(false)

    const pageWithExample: boolean = [0, 1, 2, 3].includes(context.currentTopStep)

    return (
        <>
            <Affix
                offsetTop={isMobile ? 70 : 90}
                className={"top-menu"}>
                <>
                    <div style={{
                        float: 'right',
                        position: 'absolute',
                        zIndex: 1000,
                        right: 0
                    }}>
                        <div className="tab-mobile" style={{marginTop: 10}} onClick={() => setVisualsOpen(true)}>
                            <img
                                alt="visuals tab"
                                style={{width: 40}}
                                src={visualsIcon} />
                        </div>
                    </div>
                    {pageWithExample &&
                    <div style={{
                        float: 'right',
                        position: 'absolute',
                        zIndex: 1000,
                        right: 0,
                        marginTop: 65,
                    }}>
                        <div className="tab-mobile" style={{marginTop: 10}} onClick={() => setExampleOpen(true)}>
                            <img
                                alt="sofie tab"
                                style={{width: 40}}
                                src={sofieAvatar} />
                            <div style={{
                                fontSize: 10,
                                textAlign: 'center',
                                marginTop: 5
                            }}>{translate("common.example.sofie")}</div>
                        </div>
                    </div>}
                </>
            </Affix>
            <Drawer
                placement="right"
                closable={false}
                width={362}
                onClose={() => setVisualsOpen(!visualsOpen)}
                visible={visualsOpen}
            >
                {!!context.calculationHash &&
                <div>
                    <div style={{textAlign: 'right'}}>
                        <Button type={"link"} onClick={() => setVisualsOpen(!visualsOpen)}>
                            <Icon style={{fontSize: 20}} type="close" />
                        </Button>
                    </div>
                    <ResultChartContainer calculationHash={context.calculationHash} />
                </div>
                }
            </Drawer>
            {pageWithExample &&
            <Drawer
                placement="right"
                closable={false}
                width={isMobile ? 362 : 700}
                onClose={() => setExampleOpen(false)}
                visible={exampleOpen}
            >
                {!!context.calculationHash &&
                <div>
                    <div style={{textAlign: 'right'}}>
                        <Button type={"link"} onClick={() => setExampleOpen(false)}>
                            <Icon style={{fontSize: 20}} type="close" />
                        </Button>
                    </div>
                    <Sofie />
                </div>
                }
            </Drawer>}
        </>
    )
}

export default TabsMobile
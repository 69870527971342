import React, {useContext} from 'react'
import {Button, Col, Icon, Row} from "antd";
import './tabs.css'
import {FintoolContext} from "../../../../context/fintool-context";
import {useMediaQuery} from "react-responsive";
import {isMobileQuery, isTabletOrMobileOrSmallDesktopQuery} from "../../../../utils/media-queries";
import {I18nContext} from "../../../../i18n";

interface Props {
    close?: any
}

const Sofie: React.FC<Props> = ({close}) => {
    const {translate} = useContext(I18nContext)
    const [context] = useContext(FintoolContext)
    const isMobile = useMediaQuery(isMobileQuery)
    const isTabletOrMobileOrSmallDesktop = useMediaQuery(isTabletOrMobileOrSmallDesktopQuery)

    return (
        <Row>
            <Col xs={isMobile ? 24 : 22} offset={isMobile ? 0 : 2}>
                {close && <div style={{textAlign: 'right', marginTop: 10, paddingRight: 20}}>
                    <Button type={"link"} onClick={close}>
                        <Icon style={{fontSize: 20}} type="close" />
                    </Button>
                </div>}
                <div style={{
                    marginTop: isTabletOrMobileOrSmallDesktop ? 0 : -20,
                    paddingRight: isMobile ? 50 : 100,
                    paddingLeft: 26
                }}
                     className="sofie-help-text">
                    {context.currentTopStep === 0 && translate("profile.header.sofie", true)}
                    {context.currentTopStep === 1 && translate("revenue.header.sofie", true)}
                    {context.currentTopStep === 2 && translate("expenses.header.sofie", true)}
                    {context.currentTopStep === 3 && translate("financing.header.sofie", true)}
                </div>
            </Col>
        </Row>
    )
}

export default Sofie
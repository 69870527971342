import React, {useContext} from 'react'
import ExpenseCategoriesView from "./expense-categories-view";
import {useExpenseCategoriesWithSelectedQuery} from "../../../../generated/graphql";
import {Spin} from "antd";
import {handle} from "../../../app/app";
import {FintoolContext} from "../../../../context/fintool-context";

const ExpenseCategoriesContainer = () => {

    const [context] = useContext(FintoolContext)

    const {data, error, loading, networkStatus} = useExpenseCategoriesWithSelectedQuery({
        variables: {
            calculationHash: context.calculationHash!
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "network-only"
    });

    if (loading || networkStatus === 4) {
        return <div><Spin size="small" /></div>;
    }

    error && handle(error)

    if (!data) {
        return null;
    }

    return (
        <ExpenseCategoriesView data={data} />
    )
}

export default ExpenseCategoriesContainer
import React from 'react'
import ResultChartView from "./result-chart-view";
import {useResultQuery} from "../../../../generated/graphql";
import {Spin} from "antd";
import {handle} from "../../../app/app";

interface Props {
    calculationHash: string
}

const ResultChartContainer: React.FC<Props> = ({calculationHash}) => {

    const {data, error, loading, networkStatus} = useResultQuery({
        variables: {
            calculationHash: calculationHash
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "network-only"
    });

    if (loading || networkStatus === 4) {
        return <div><Spin size="small" /></div>;
    }
    error && handle(error)

    if (!data) {
        return <div>no data</div>;
    }

    return (
        <ResultChartView data={data} />
    )
}

export default ResultChartContainer
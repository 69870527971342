import React, {useContext} from 'react'
import RevenueContainer from "./revenue-container";
import {FintoolContext} from "../../../context/fintool-context";
import {RouteComponentProps} from "react-router";
import {steps, useSetCorrectSimulationStep} from "../simulation-view";

interface Props extends RouteComponentProps {
}

const Revenue: React.FC<Props> = ({match}) => {

    const [context] = useContext(FintoolContext)

    useSetCorrectSimulationStep(match, steps.REVENUES)

    if (!context.calculationHash) return null

    return (
        <RevenueContainer calculationHash={context.calculationHash} />
    )
}

export default Revenue
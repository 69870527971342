import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHoc from '@apollo/react-hoc';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  /** An RFC-3339 compliant DateTime Scalar */
  DateTime: any,
};

export type CalculationInfo = {
   __typename?: 'CalculationInfo',
  fintoolUser?: Maybe<FintoolUser>,
  code?: Maybe<Scalars['String']>,
  initState: Scalars['Boolean'],
  financeSeenState: Scalars['Boolean'],
  expenseSeenState: Scalars['Boolean'],
};

export type CalculationOptions = {
   __typename?: 'CalculationOptions',
  pensionType: Scalars['String'],
};

export type CalculationOptionsInput = {
  pensionType?: Maybe<Scalars['String']>,
};


export type EmailInput = {
  email: Scalars['String'],
};

export type Expense = {
   __typename?: 'Expense',
  expenseItems: Array<ExpenseItem>,
  groupedExpenses: Array<GroupedExpense>,
  vehicles?: Maybe<Array<Vehicle>>,
  housings?: Maybe<Array<Housing>>,
  allIncludedExpenses?: Maybe<Array<PriceableItem>>,
};

export type ExpenseCategory = {
   __typename?: 'ExpenseCategory',
  id: Scalars['ID'],
  code: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  expenseTypes: Array<ExpenseType>,
};

export type ExpenseItem = {
   __typename?: 'ExpenseItem',
  id: Scalars['String'],
  expenseCategory: Scalars['String'],
  expenseType: Scalars['String'],
  expenseTypeOption?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  useQuantity: Scalars['Boolean'],
  quantity?: Maybe<Scalars['Int']>,
  unitPrice: Scalars['Float'],
  calculatedCashFlowValue?: Maybe<Scalars['Float']>,
  includeInCalculation: Scalars['Boolean'],
};

export type ExpenseItemInput = {
  id?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  expenseType?: Maybe<Scalars['String']>,
  expenseTypeOption?: Maybe<Scalars['String']>,
  quantity?: Maybe<Scalars['Int']>,
  unitPrice: Scalars['Float'],
  includeInCalculation: Scalars['Boolean'],
};

export type ExpenseType = {
   __typename?: 'ExpenseType',
  id: Scalars['ID'],
  code: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  recurrence: Recurrence,
  defaultPrice?: Maybe<Scalars['Float']>,
  fixedName: Scalars['Boolean'],
  fixedPrice: Scalars['Boolean'],
  hasOptions: Scalars['Boolean'],
  multipleAllowed: Scalars['Boolean'],
  useQuantity: Scalars['Boolean'],
  useProfessionalPart: Scalars['Boolean'],
  defaultProfessionalPart?: Maybe<Scalars['Int']>,
  minimumPrice: Scalars['Int'],
  maximumPrice?: Maybe<Scalars['Int']>,
  expenseTypeOptions?: Maybe<Array<ExpenseTypeOption>>,
  selected: Scalars['Boolean'],
};

export type ExpenseTypeOption = {
   __typename?: 'ExpenseTypeOption',
  id: Scalars['ID'],
  code: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  recurrence: Recurrence,
  defaultPrice?: Maybe<Scalars['Float']>,
  fixedName: Scalars['Boolean'],
  fixedPrice: Scalars['Boolean'],
  multipleAllowed: Scalars['Boolean'],
};

export type Financing = {
   __typename?: 'Financing',
  loans?: Maybe<Array<Loan>>,
  ownResources?: Maybe<Array<OwnResource>>,
  subsidies?: Maybe<Array<Subsidy>>,
};

export type FinancingItemInput = {
  financingItemType: FinancingItemType,
  id: Scalars['String'],
  name?: Maybe<Scalars['String']>,
  amount: Scalars['Float'],
  includeInCalculation: Scalars['Boolean'],
};

export enum FinancingItemType {
  Loan = 'LOAN',
  OwnResource = 'OWN_RESOURCE',
  Subsidy = 'SUBSIDY'
}

export type FintoolUser = {
   __typename?: 'FintoolUser',
  id: Scalars['String'],
  email: Scalars['String'],
};

export type GroupedExpense = {
   __typename?: 'GroupedExpense',
  key: Scalars['String'],
  values: Array<ExpenseItem>,
  totalPrice: Scalars['Float'],
};

export type Housing = {
   __typename?: 'Housing',
  id: Scalars['ID'],
  housingType: HousingType,
  housingFinancingType: HousingFinancingType,
  property?: Maybe<Property>,
  rental?: Maybe<Rental>,
  includeInCalculation?: Maybe<Scalars['Boolean']>,
  unitPrice?: Maybe<Scalars['Float']>,
  professionalPercentage: Scalars['Int'],
  calculatedCashFlowValue: Scalars['Float'],
  calculateNetValue: Scalars['Float'],
  total: Scalars['Float'],
};

export enum HousingFinancingType {
  Property = 'PROPERTY',
  Rental = 'RENTAL'
}

export type HousingInput = {
  id?: Maybe<Scalars['ID']>,
  housingType: HousingType,
  housingFinancingType: HousingFinancingType,
  property?: Maybe<PropertyInput>,
  rental?: Maybe<RentalInput>,
  includeInCalculation?: Maybe<Scalars['Boolean']>,
  unitPrice?: Maybe<Scalars['Float']>,
  professionalPercentage?: Maybe<Scalars['Int']>,
};

export enum HousingType {
  Beroepsruimte = 'BEROEPSRUIMTE',
  EigenWoning = 'EIGEN_WONING',
  OverigeUitgavenHuisvesting = 'OVERIGE_UITGAVEN_HUISVESTING',
  Inrichting = 'INRICHTING'
}

export enum Lang {
  Nl = 'nl',
  Fr = 'fr',
  En = 'en'
}

export type Leasing = {
   __typename?: 'Leasing',
  leaseAmount: Scalars['Int'],
  otherCosts: Scalars['Int'],
  milagePrivate: Scalars['Int'],
  milageProfessional: Scalars['Int'],
};

export type LeasingInput = {
  leaseAmount: Scalars['Int'],
  otherCosts: Scalars['Int'],
  milagePrivate: Scalars['Int'],
  milageProfessional: Scalars['Int'],
};

export type Loan = {
   __typename?: 'Loan',
  id: Scalars['String'],
  name?: Maybe<Scalars['String']>,
  amount: Scalars['Float'],
  includeInCalculation: Scalars['Boolean'],
  interest: Scalars['Float'],
  duration: Scalars['Int'],
  annuity: Scalars['Float'],
  deductible?: Maybe<Scalars['Float']>,
  toPay: Scalars['Float'],
  financing: Scalars['Float'],
};

export type LoanInput = {
  id?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  amount: Scalars['Float'],
  interest: Scalars['Float'],
  duration: Scalars['Int'],
};

export type Mutation = {
   __typename?: 'Mutation',
  createCalculation: Scalars['String'],
  updateLanguage?: Maybe<Scalars['String']>,
  enterCode: Scalars['String'],
  addServiceRevenue: ServiceRevenue,
  updateServiceRevenue: ServiceRevenue,
  removeServiceRevenue?: Maybe<Scalars['String']>,
  addProduct: Product,
  updateProduct: Product,
  removeProduct?: Maybe<Scalars['String']>,
  addOtherRevenue: OtherRevenue,
  updateOtherRevenue: OtherRevenue,
  removeOtherRevenue?: Maybe<Scalars['String']>,
  updateProfile: Scalars['String'],
  addExpenseItem: ExpenseItem,
  addVehicle: Vehicle,
  updateVehicle: Vehicle,
  removeVehicle?: Maybe<Scalars['String']>,
  toggleVehicle?: Maybe<Scalars['String']>,
  addHousing: Housing,
  updateHousing: Housing,
  removeHousing?: Maybe<Scalars['String']>,
  toggleHousing?: Maybe<Scalars['String']>,
  updateExpenseItem: ExpenseItem,
  removeExpenseItem?: Maybe<Scalars['String']>,
  addFinancingItem: Scalars['String'],
  saveLoan: Loan,
  updateFinancingItem: Scalars['String'],
  removeFinancingItem?: Maybe<Scalars['String']>,
  sendEmail?: Maybe<Scalars['String']>,
  saveEmail?: Maybe<Scalars['String']>,
  updateInitState: CalculationInfo,
  updateFinanceSeenState: CalculationInfo,
  updateExpenseSeenState: CalculationInfo,
  updateCalculationOptions?: Maybe<Scalars['String']>,
};


export type MutationCreateCalculationArgs = {
  lang?: Maybe<Lang>
};


export type MutationUpdateLanguageArgs = {
  calculationHash?: Maybe<Scalars['String']>,
  lang: Lang
};


export type MutationEnterCodeArgs = {
  calculationCode: Scalars['String']
};


export type MutationAddServiceRevenueArgs = {
  calculationHash: Scalars['String'],
  serviceRevenueInput: ServiceRevenueInput
};


export type MutationUpdateServiceRevenueArgs = {
  calculationHash: Scalars['String'],
  serviceRevenueInput: ServiceRevenueInput
};


export type MutationRemoveServiceRevenueArgs = {
  calculationHash: Scalars['String'],
  serviceRevenueId: Scalars['String']
};


export type MutationAddProductArgs = {
  calculationHash: Scalars['String'],
  productInput: ProductInput
};


export type MutationUpdateProductArgs = {
  calculationHash: Scalars['String'],
  productInput: ProductInput
};


export type MutationRemoveProductArgs = {
  calculationHash: Scalars['String'],
  productId: Scalars['String']
};


export type MutationAddOtherRevenueArgs = {
  calculationHash: Scalars['String']
};


export type MutationUpdateOtherRevenueArgs = {
  calculationHash: Scalars['String'],
  otherRevenueInput: OtherRevenueInput
};


export type MutationRemoveOtherRevenueArgs = {
  calculationHash: Scalars['String'],
  otherRevenueId: Scalars['String']
};


export type MutationUpdateProfileArgs = {
  calculationHash: Scalars['String'],
  profileInput: ProfileInput
};


export type MutationAddExpenseItemArgs = {
  calculationHash: Scalars['String'],
  expenseType: Scalars['String'],
  expenseCategory: Scalars['String']
};


export type MutationAddVehicleArgs = {
  calculationHash: Scalars['String'],
  vehicleType: VehicleType
};


export type MutationUpdateVehicleArgs = {
  calculationHash: Scalars['String'],
  vehicleInput: VehicleInput
};


export type MutationRemoveVehicleArgs = {
  calculationHash: Scalars['String'],
  vehicleId: Scalars['String']
};


export type MutationToggleVehicleArgs = {
  calculationHash: Scalars['String'],
  vehicleId: Scalars['String'],
  exclude: Scalars['Boolean']
};


export type MutationAddHousingArgs = {
  calculationHash: Scalars['String'],
  housingType: HousingType
};


export type MutationUpdateHousingArgs = {
  calculationHash: Scalars['String'],
  housingInput: HousingInput
};


export type MutationRemoveHousingArgs = {
  calculationHash: Scalars['String'],
  housingId: Scalars['String']
};


export type MutationToggleHousingArgs = {
  calculationHash: Scalars['String'],
  housingId: Scalars['String'],
  exclude: Scalars['Boolean']
};


export type MutationUpdateExpenseItemArgs = {
  calculationHash: Scalars['String'],
  expenseItemInput: ExpenseItemInput
};


export type MutationRemoveExpenseItemArgs = {
  calculationHash: Scalars['String'],
  expenseItemId: Scalars['String']
};


export type MutationAddFinancingItemArgs = {
  calculationHash: Scalars['String'],
  financingItemType: FinancingItemType
};


export type MutationSaveLoanArgs = {
  calculationHash: Scalars['String'],
  loanInput?: Maybe<LoanInput>
};


export type MutationUpdateFinancingItemArgs = {
  calculationHash: Scalars['String'],
  financingItemInput: FinancingItemInput
};


export type MutationRemoveFinancingItemArgs = {
  calculationHash: Scalars['String'],
  financingItemType: FinancingItemType,
  financingItemId: Scalars['String']
};


export type MutationSendEmailArgs = {
  calculationHash: Scalars['String'],
  emailInput: EmailInput
};


export type MutationSaveEmailArgs = {
  calculationHash: Scalars['String'],
  emailInput: EmailInput
};


export type MutationUpdateInitStateArgs = {
  calculationHash: Scalars['String'],
  newState: Scalars['Boolean']
};


export type MutationUpdateFinanceSeenStateArgs = {
  calculationHash: Scalars['String'],
  newState: Scalars['Boolean']
};


export type MutationUpdateExpenseSeenStateArgs = {
  calculationHash: Scalars['String'],
  newState: Scalars['Boolean']
};


export type MutationUpdateCalculationOptionsArgs = {
  calculationHash: Scalars['String'],
  calculationOptionsInput?: Maybe<CalculationOptionsInput>
};

export type OtherRevenue = {
   __typename?: 'OtherRevenue',
  id: Scalars['String'],
  description: Scalars['String'],
  price: Scalars['Float'],
  dateCreated: Scalars['DateTime'],
  includeInCalculation?: Maybe<Scalars['Boolean']>,
};

export type OtherRevenueInput = {
  id?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  price: Scalars['Float'],
  includeInCalculation?: Maybe<Scalars['Boolean']>,
};

export type OtherRevenuesSection = {
   __typename?: 'OtherRevenuesSection',
  revenues: Array<Maybe<OtherRevenue>>,
  totalPrice: Scalars['Float'],
};

export type OwnResource = {
   __typename?: 'OwnResource',
  id: Scalars['String'],
  name?: Maybe<Scalars['String']>,
  amount: Scalars['Float'],
  includeInCalculation: Scalars['Boolean'],
};

export type PriceableItem = {
   __typename?: 'PriceableItem',
  id: Scalars['ID'],
  description?: Maybe<Scalars['String']>,
  calculatedCashFlowValue: Scalars['Float'],
  calculateNetValue: Scalars['Float'],
};

export type Product = {
   __typename?: 'Product',
  id: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  quantity: Scalars['Int'],
  unitPrice: Scalars['Float'],
  calculatedCashFlowValue: Scalars['Float'],
  dateCreated: Scalars['DateTime'],
  includeInCalculation?: Maybe<Scalars['Boolean']>,
};

export type ProductInput = {
  id?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  quantity: Scalars['Int'],
  unitPrice: Scalars['Float'],
  includeInCalculation?: Maybe<Scalars['Boolean']>,
};

export type ProductsRevenuesSection = {
   __typename?: 'ProductsRevenuesSection',
  revenues: Array<Maybe<Product>>,
  totalPrice: Scalars['Float'],
};

export type Profile = {
   __typename?: 'Profile',
  sectors?: Maybe<Array<Scalars['String']>>,
  statute?: Maybe<Scalars['String']>,
  regions?: Maybe<Array<Scalars['String']>>,
  desiredNetMonthlyIncome?: Maybe<Scalars['Float']>,
  profession?: Maybe<Scalars['String']>,
  dependentChildren?: Maybe<Scalars['Int']>,
};

export type ProfileInput = {
  sectors?: Maybe<Array<Scalars['String']>>,
  statute?: Maybe<Scalars['String']>,
  regions?: Maybe<Array<Scalars['String']>>,
  desiredNetMonthlyIncome: Scalars['Float'],
  profession?: Maybe<Scalars['String']>,
  dependentChildren?: Maybe<Scalars['Int']>,
};

export type Property = {
   __typename?: 'Property',
  purchaseAmount: Scalars['Int'],
  interestAmount: Scalars['Int'],
  utilities: Scalars['Int'],
  insurance: Scalars['Int'],
  maintenance: Scalars['Int'],
  otherCosts: Scalars['Int'],
};

export type PropertyInput = {
  purchaseAmount: Scalars['Int'],
  interestAmount: Scalars['Int'],
  utilities: Scalars['Int'],
  insurance: Scalars['Int'],
  maintenance: Scalars['Int'],
  otherCosts: Scalars['Int'],
};

export type Purchase = {
   __typename?: 'Purchase',
  purchaseAmount: Scalars['Int'],
  maintenance: Scalars['Int'],
  tax: Scalars['Int'],
  milagePrivate: Scalars['Int'],
  milageProfessional: Scalars['Int'],
  fuelConsumption: Scalars['Int'],
  fuelPricePerLiter: Scalars['Float'],
  insurance: Scalars['Int'],
  otherCosts: Scalars['Int'],
};

export type PurchaseInput = {
  purchaseAmount: Scalars['Int'],
  maintenance: Scalars['Int'],
  tax: Scalars['Int'],
  milagePrivate: Scalars['Int'],
  milageProfessional: Scalars['Int'],
  fuelConsumption: Scalars['Int'],
  fuelPricePerLiter: Scalars['Float'],
  insurance: Scalars['Int'],
  otherCosts: Scalars['Int'],
};

export type Query = {
   __typename?: 'Query',
  calculationInfo: CalculationInfo,
  result: Result,
  revenue: Revenue,
  expense: Expense,
  profile?: Maybe<Profile>,
  calculations?: Maybe<Array<Maybe<Scalars['String']>>>,
  serviceRevenues: Array<Maybe<ServiceRevenue>>,
  products: Array<Maybe<Product>>,
  otherRevenues: Array<Maybe<OtherRevenue>>,
  financing?: Maybe<Financing>,
  expenseCategories: Array<ExpenseCategory>,
  loan: Loan,
  vehicle: Vehicle,
  housing: Housing,
};


export type QueryCalculationInfoArgs = {
  calculationHash: Scalars['String']
};


export type QueryResultArgs = {
  calculationHash: Scalars['String']
};


export type QueryRevenueArgs = {
  calculationHash: Scalars['String']
};


export type QueryExpenseArgs = {
  calculationHash: Scalars['String']
};


export type QueryProfileArgs = {
  calculationHash?: Maybe<Scalars['String']>
};


export type QueryServiceRevenuesArgs = {
  calculationHash: Scalars['String']
};


export type QueryProductsArgs = {
  calculationHash: Scalars['String']
};


export type QueryOtherRevenuesArgs = {
  calculationHash: Scalars['String']
};


export type QueryFinancingArgs = {
  calculationHash: Scalars['String']
};


export type QueryExpenseCategoriesArgs = {
  calculationHash?: Maybe<Scalars['String']>
};


export type QueryLoanArgs = {
  calculationHash: Scalars['String'],
  loanId: Scalars['String']
};


export type QueryVehicleArgs = {
  calculationHash: Scalars['String'],
  vehicleId: Scalars['String']
};


export type QueryHousingArgs = {
  calculationHash: Scalars['String'],
  housingId: Scalars['String']
};

export enum Recurrence {
  Monthly = 'MONTHLY',
  Yearly = 'YEARLY'
}

export type Rental = {
   __typename?: 'Rental',
  rentalAmount: Scalars['Int'],
  utilities: Scalars['Int'],
  insurance: Scalars['Int'],
  maintenance: Scalars['Int'],
  otherCosts: Scalars['Int'],
};

export type RentalInput = {
  rentalAmount: Scalars['Int'],
  utilities: Scalars['Int'],
  insurance: Scalars['Int'],
  maintenance: Scalars['Int'],
  otherCosts: Scalars['Int'],
};

export type Result = {
   __typename?: 'Result',
  calculationOptions: CalculationOptions,
  desiredYearlyNetIncome: Scalars['Float'],
  calculatedYearlyNetIncome: Scalars['Float'],
  yearlyRevenue: Scalars['Float'],
  yearlyExpense: Scalars['Float'],
  yearlyProceeds: Scalars['Float'],
  yearlyCost: Scalars['Float'],
  balanceBeforeSocialContributions: Scalars['Float'],
  socialContributions: Scalars['Float'],
  pensionSaving: Scalars['Float'],
  socialContributionsWithPensionSaving: Scalars['Float'],
  personalIncomeTax: Scalars['Float'],
  financed: Scalars['Float'],
  surplus: Scalars['Float'],
  shortage: Scalars['Float'],
};

export type Revenue = {
   __typename?: 'Revenue',
  serviceRevenues: ServiceRevenuesSection,
  products: ProductsRevenuesSection,
  otherRevenues: OtherRevenuesSection,
};

export type ServiceRevenue = {
   __typename?: 'ServiceRevenue',
  id: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  quantity: Scalars['Int'],
  unitPrice: Scalars['Float'],
  calculatedCashFlowValue: Scalars['Float'],
  dateCreated: Scalars['DateTime'],
  includeInCalculation?: Maybe<Scalars['Boolean']>,
};

export type ServiceRevenueInput = {
  id?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  quantity: Scalars['Int'],
  unitPrice: Scalars['Float'],
  includeInCalculation?: Maybe<Scalars['Boolean']>,
};

export type ServiceRevenuesSection = {
   __typename?: 'ServiceRevenuesSection',
  revenues: Array<Maybe<ServiceRevenue>>,
  totalPrice: Scalars['Float'],
};

export type Subsidy = {
   __typename?: 'Subsidy',
  id: Scalars['String'],
  name?: Maybe<Scalars['String']>,
  amount: Scalars['Float'],
  includeInCalculation: Scalars['Boolean'],
};

export type Vehicle = {
   __typename?: 'Vehicle',
  id: Scalars['ID'],
  vehicleType: VehicleType,
  vehicleFinancingType: VehicleFinancingType,
  purchase?: Maybe<Purchase>,
  leasing?: Maybe<Leasing>,
  includeInCalculation?: Maybe<Scalars['Boolean']>,
  calculatedCashFlowValue: Scalars['Float'],
  calculateNetValue: Scalars['Float'],
  total: Scalars['Float'],
};

export enum VehicleFinancingType {
  OwnContribution = 'OWN_CONTRIBUTION',
  Purchase = 'PURCHASE',
  Leasing = 'LEASING'
}

export type VehicleInput = {
  id?: Maybe<Scalars['ID']>,
  vehicleType: VehicleType,
  vehicleFinancingType: VehicleFinancingType,
  purchase?: Maybe<PurchaseInput>,
  leasing?: Maybe<LeasingInput>,
  includeInCalculation?: Maybe<Scalars['Boolean']>,
};

export enum VehicleType {
  Personenwagen = 'PERSONENWAGEN',
  LichteVracht = 'LICHTE_VRACHT'
}
export type EnterCodeMutationVariables = {
  calculationCode: Scalars['String']
};


export type EnterCodeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'enterCode'>
);

export type CreateCalculationMutationVariables = {
  lang: Lang
};


export type CreateCalculationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createCalculation'>
);

export type ResultQueryVariables = {
  calculationHash: Scalars['String']
};


export type ResultQuery = (
  { __typename?: 'Query' }
  & { result: (
    { __typename?: 'Result' }
    & Pick<Result, 'desiredYearlyNetIncome' | 'calculatedYearlyNetIncome' | 'yearlyRevenue' | 'yearlyExpense' | 'yearlyProceeds' | 'yearlyCost' | 'balanceBeforeSocialContributions' | 'socialContributions' | 'pensionSaving' | 'socialContributionsWithPensionSaving' | 'personalIncomeTax' | 'financed' | 'surplus' | 'shortage'>
    & { calculationOptions: (
      { __typename?: 'CalculationOptions' }
      & Pick<CalculationOptions, 'pensionType'>
    ) }
  ) }
);

export type ExpenseCategoriesWithSelectedQueryVariables = {
  calculationHash?: Maybe<Scalars['String']>
};


export type ExpenseCategoriesWithSelectedQuery = (
  { __typename?: 'Query' }
  & { expenseCategories: Array<(
    { __typename?: 'ExpenseCategory' }
    & Pick<ExpenseCategory, 'id' | 'code'>
    & { expenseTypes: Array<(
      { __typename?: 'ExpenseType' }
      & Pick<ExpenseType, 'id' | 'code' | 'selected'>
    )> }
  )> }
);

export type AddExpenseItemMutationVariables = {
  calculationHash: Scalars['String'],
  expenseType: Scalars['String'],
  expenseCategory: Scalars['String']
};


export type AddExpenseItemMutation = (
  { __typename?: 'Mutation' }
  & { addExpenseItem: (
    { __typename?: 'ExpenseItem' }
    & Pick<ExpenseItem, 'id'>
  ) }
);

export type UpdateExpenseItemMutationVariables = {
  calculationHash: Scalars['String'],
  expenseItemInput: ExpenseItemInput
};


export type UpdateExpenseItemMutation = (
  { __typename?: 'Mutation' }
  & { updateExpenseItem: (
    { __typename?: 'ExpenseItem' }
    & Pick<ExpenseItem, 'id' | 'expenseCategory' | 'expenseType' | 'expenseTypeOption' | 'description' | 'quantity' | 'unitPrice' | 'useQuantity' | 'calculatedCashFlowValue' | 'includeInCalculation'>
  ) }
);

export type RemoveExpenseItemMutationVariables = {
  calculationHash: Scalars['String'],
  expenseItemId: Scalars['String']
};


export type RemoveExpenseItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeExpenseItem'>
);

export type UpdateCalculationOptionsMutationVariables = {
  calculationHash: Scalars['String'],
  calculationOptions: CalculationOptionsInput
};


export type UpdateCalculationOptionsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateCalculationOptions'>
);

export type AddHousingMutationVariables = {
  calculationHash: Scalars['String'],
  housingType: HousingType
};


export type AddHousingMutation = (
  { __typename?: 'Mutation' }
  & { addHousing: (
    { __typename?: 'Housing' }
    & Pick<Housing, 'id'>
  ) }
);

export type RemoveHousingMutationVariables = {
  calculationHash: Scalars['String'],
  housingId: Scalars['String']
};


export type RemoveHousingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeHousing'>
);

export type ToggleHousingMutationVariables = {
  calculationHash: Scalars['String'],
  housingId: Scalars['String'],
  exclude: Scalars['Boolean']
};


export type ToggleHousingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'toggleHousing'>
);

export type HousingQueryVariables = {
  calculationHash: Scalars['String'],
  housingId: Scalars['String']
};


export type HousingQuery = (
  { __typename?: 'Query' }
  & { housing: (
    { __typename?: 'Housing' }
    & Pick<Housing, 'id' | 'housingType' | 'housingFinancingType' | 'includeInCalculation' | 'unitPrice' | 'professionalPercentage' | 'calculatedCashFlowValue' | 'calculateNetValue' | 'total'>
    & { property: Maybe<(
      { __typename?: 'Property' }
      & Pick<Property, 'purchaseAmount' | 'utilities' | 'maintenance' | 'insurance' | 'interestAmount' | 'otherCosts'>
    )>, rental: Maybe<(
      { __typename?: 'Rental' }
      & Pick<Rental, 'rentalAmount' | 'utilities' | 'maintenance' | 'insurance' | 'otherCosts'>
    )> }
  ) }
);

export type UpdateHousingMutationVariables = {
  calculationHash: Scalars['String'],
  housingInput: HousingInput
};


export type UpdateHousingMutation = (
  { __typename?: 'Mutation' }
  & { updateHousing: (
    { __typename?: 'Housing' }
    & Pick<Housing, 'id' | 'housingType' | 'housingFinancingType' | 'includeInCalculation' | 'unitPrice' | 'calculatedCashFlowValue' | 'calculateNetValue' | 'total'>
    & { property: Maybe<(
      { __typename?: 'Property' }
      & Pick<Property, 'purchaseAmount' | 'utilities' | 'maintenance' | 'insurance' | 'interestAmount' | 'otherCosts'>
    )>, rental: Maybe<(
      { __typename?: 'Rental' }
      & Pick<Rental, 'rentalAmount' | 'utilities' | 'maintenance' | 'insurance' | 'otherCosts'>
    )> }
  ) }
);

export type ExpenseQueryVariables = {
  calculationHash: Scalars['String']
};


export type ExpenseQuery = (
  { __typename?: 'Query' }
  & { expense: (
    { __typename?: 'Expense' }
    & { expenseItems: Array<(
      { __typename?: 'ExpenseItem' }
      & Pick<ExpenseItem, 'id' | 'expenseCategory' | 'expenseType' | 'expenseTypeOption' | 'description' | 'quantity' | 'unitPrice' | 'useQuantity' | 'calculatedCashFlowValue' | 'includeInCalculation'>
    )>, groupedExpenses: Array<(
      { __typename?: 'GroupedExpense' }
      & Pick<GroupedExpense, 'key' | 'totalPrice'>
      & { values: Array<(
        { __typename?: 'ExpenseItem' }
        & Pick<ExpenseItem, 'id' | 'expenseCategory' | 'expenseType' | 'expenseTypeOption' | 'description' | 'quantity' | 'unitPrice' | 'useQuantity' | 'calculatedCashFlowValue' | 'includeInCalculation'>
      )> }
    )>, vehicles: Maybe<Array<(
      { __typename?: 'Vehicle' }
      & Pick<Vehicle, 'id' | 'vehicleType' | 'vehicleFinancingType' | 'includeInCalculation' | 'calculatedCashFlowValue' | 'calculateNetValue' | 'total'>
      & { purchase: Maybe<(
        { __typename?: 'Purchase' }
        & Pick<Purchase, 'purchaseAmount' | 'maintenance' | 'tax' | 'milagePrivate' | 'milageProfessional' | 'fuelConsumption' | 'fuelPricePerLiter' | 'insurance' | 'otherCosts'>
      )>, leasing: Maybe<(
        { __typename?: 'Leasing' }
        & Pick<Leasing, 'otherCosts' | 'milagePrivate' | 'milageProfessional'>
      )> }
    )>>, housings: Maybe<Array<(
      { __typename?: 'Housing' }
      & Pick<Housing, 'id' | 'housingType' | 'housingFinancingType' | 'includeInCalculation' | 'unitPrice' | 'calculatedCashFlowValue' | 'calculateNetValue' | 'total' | 'professionalPercentage'>
    )>> }
  ) }
);

export type ExpenseCategoriesQueryVariables = {
  calculationHash?: Maybe<Scalars['String']>
};


export type ExpenseCategoriesQuery = (
  { __typename?: 'Query' }
  & { expenseCategories: Array<(
    { __typename?: 'ExpenseCategory' }
    & Pick<ExpenseCategory, 'id' | 'code'>
    & { expenseTypes: Array<(
      { __typename?: 'ExpenseType' }
      & Pick<ExpenseType, 'id' | 'code' | 'description' | 'recurrence' | 'defaultPrice' | 'fixedName' | 'fixedPrice' | 'hasOptions' | 'multipleAllowed' | 'useQuantity' | 'useProfessionalPart' | 'defaultProfessionalPart' | 'minimumPrice' | 'maximumPrice' | 'selected'>
      & { expenseTypeOptions: Maybe<Array<(
        { __typename?: 'ExpenseTypeOption' }
        & Pick<ExpenseTypeOption, 'id' | 'code' | 'description' | 'recurrence' | 'defaultPrice' | 'fixedName' | 'fixedPrice' | 'multipleAllowed'>
      )>> }
    )> }
  )> }
);

export type AddVehicleMutationVariables = {
  calculationHash: Scalars['String'],
  vehicleType: VehicleType
};


export type AddVehicleMutation = (
  { __typename?: 'Mutation' }
  & { addVehicle: (
    { __typename?: 'Vehicle' }
    & Pick<Vehicle, 'id'>
  ) }
);

export type RemoveVehicleMutationVariables = {
  calculationHash: Scalars['String'],
  vehicleId: Scalars['String']
};


export type RemoveVehicleMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeVehicle'>
);

export type ToggleVehicleMutationVariables = {
  calculationHash: Scalars['String'],
  vehicleId: Scalars['String'],
  exclude: Scalars['Boolean']
};


export type ToggleVehicleMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'toggleVehicle'>
);

export type VehicleQueryVariables = {
  calculationHash: Scalars['String'],
  vehicleId: Scalars['String']
};


export type VehicleQuery = (
  { __typename?: 'Query' }
  & { vehicle: (
    { __typename?: 'Vehicle' }
    & Pick<Vehicle, 'id' | 'vehicleType' | 'vehicleFinancingType' | 'includeInCalculation' | 'calculatedCashFlowValue' | 'calculateNetValue' | 'total'>
    & { purchase: Maybe<(
      { __typename?: 'Purchase' }
      & Pick<Purchase, 'purchaseAmount' | 'maintenance' | 'tax' | 'milagePrivate' | 'milageProfessional' | 'fuelConsumption' | 'fuelPricePerLiter' | 'insurance' | 'otherCosts'>
    )>, leasing: Maybe<(
      { __typename?: 'Leasing' }
      & Pick<Leasing, 'leaseAmount' | 'otherCosts' | 'milagePrivate' | 'milageProfessional'>
    )> }
  ) }
);

export type UpdateVehicleMutationVariables = {
  calculationHash: Scalars['String'],
  vehicleInput: VehicleInput
};


export type UpdateVehicleMutation = (
  { __typename?: 'Mutation' }
  & { updateVehicle: (
    { __typename?: 'Vehicle' }
    & Pick<Vehicle, 'id' | 'vehicleType' | 'vehicleFinancingType' | 'includeInCalculation' | 'calculatedCashFlowValue' | 'calculateNetValue' | 'total'>
    & { purchase: Maybe<(
      { __typename?: 'Purchase' }
      & Pick<Purchase, 'purchaseAmount' | 'maintenance' | 'tax' | 'milagePrivate' | 'milageProfessional' | 'fuelConsumption' | 'fuelPricePerLiter' | 'insurance' | 'otherCosts'>
    )>, leasing: Maybe<(
      { __typename?: 'Leasing' }
      & Pick<Leasing, 'leaseAmount' | 'otherCosts' | 'milagePrivate' | 'milageProfessional'>
    )> }
  ) }
);

export type LoanQueryVariables = {
  calculationHash: Scalars['String'],
  loanId: Scalars['String']
};


export type LoanQuery = (
  { __typename?: 'Query' }
  & { loan: (
    { __typename?: 'Loan' }
    & Pick<Loan, 'id' | 'name' | 'amount' | 'interest' | 'duration' | 'toPay' | 'financing' | 'includeInCalculation' | 'annuity'>
  ) }
);

export type SaveLoanMutationVariables = {
  calculationHash: Scalars['String'],
  loanInput?: Maybe<LoanInput>
};


export type SaveLoanMutation = (
  { __typename?: 'Mutation' }
  & { saveLoan: (
    { __typename?: 'Loan' }
    & Pick<Loan, 'id' | 'name' | 'amount' | 'interest' | 'duration' | 'toPay' | 'financing' | 'includeInCalculation' | 'annuity'>
  ) }
);

export type FinancingQueryVariables = {
  calculationHash: Scalars['String']
};


export type FinancingQuery = (
  { __typename?: 'Query' }
  & { financing: Maybe<(
    { __typename?: 'Financing' }
    & { loans: Maybe<Array<(
      { __typename?: 'Loan' }
      & Pick<Loan, 'id' | 'name' | 'amount' | 'financing' | 'includeInCalculation'>
    )>>, ownResources: Maybe<Array<(
      { __typename?: 'OwnResource' }
      & Pick<OwnResource, 'id' | 'name' | 'amount' | 'includeInCalculation'>
    )>>, subsidies: Maybe<Array<(
      { __typename?: 'Subsidy' }
      & Pick<Subsidy, 'id' | 'name' | 'amount' | 'includeInCalculation'>
    )>> }
  )> }
);

export type AddFinancingItemMutationVariables = {
  calculationHash: Scalars['String'],
  financingItemType: FinancingItemType
};


export type AddFinancingItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addFinancingItem'>
);

export type UpdateFinancingItemMutationVariables = {
  calculationHash: Scalars['String'],
  financingItemInput: FinancingItemInput
};


export type UpdateFinancingItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateFinancingItem'>
);

export type RemoveFinancingItemMutationVariables = {
  calculationHash: Scalars['String'],
  financingItemType: FinancingItemType,
  financingItemId: Scalars['String']
};


export type RemoveFinancingItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeFinancingItem'>
);

export type ProfileQueryVariables = {
  calculationHash: Scalars['String']
};


export type ProfileQuery = (
  { __typename?: 'Query' }
  & { profile: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'sectors' | 'regions' | 'desiredNetMonthlyIncome' | 'profession' | 'dependentChildren'>
  )> }
);

export type UpdateProfileMutationVariables = {
  calculationHash: Scalars['String'],
  profileInput: ProfileInput
};


export type UpdateProfileMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateProfile'>
);

export type CalculationInfoQueryVariables = {
  calculationHash: Scalars['String']
};


export type CalculationInfoQuery = (
  { __typename?: 'Query' }
  & { calculationInfo: (
    { __typename?: 'CalculationInfo' }
    & Pick<CalculationInfo, 'code' | 'initState' | 'financeSeenState' | 'expenseSeenState'>
    & { fintoolUser: Maybe<(
      { __typename?: 'FintoolUser' }
      & Pick<FintoolUser, 'email'>
    )> }
  ) }
);

export type UpdateInitStateMutationVariables = {
  calculationHash: Scalars['String'],
  newState: Scalars['Boolean']
};


export type UpdateInitStateMutation = (
  { __typename?: 'Mutation' }
  & { updateInitState: (
    { __typename?: 'CalculationInfo' }
    & Pick<CalculationInfo, 'initState'>
  ) }
);

export type UpdateFinanceSeenStateMutationVariables = {
  calculationHash: Scalars['String'],
  newState: Scalars['Boolean']
};


export type UpdateFinanceSeenStateMutation = (
  { __typename?: 'Mutation' }
  & { updateFinanceSeenState: (
    { __typename?: 'CalculationInfo' }
    & Pick<CalculationInfo, 'financeSeenState'>
  ) }
);

export type UpdateExpenseSeenStateMutationVariables = {
  calculationHash: Scalars['String'],
  newState: Scalars['Boolean']
};


export type UpdateExpenseSeenStateMutation = (
  { __typename?: 'Mutation' }
  & { updateExpenseSeenState: (
    { __typename?: 'CalculationInfo' }
    & Pick<CalculationInfo, 'expenseSeenState'>
  ) }
);

export type SendEmailMutationVariables = {
  calculationHash: Scalars['String'],
  email: Scalars['String']
};


export type SendEmailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendEmail'>
);

export type SaveEmailMutationVariables = {
  calculationHash: Scalars['String'],
  email: Scalars['String']
};


export type SaveEmailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'saveEmail'>
);

export type ExpensesAsPriceableItemsQueryVariables = {
  calculationHash: Scalars['String']
};


export type ExpensesAsPriceableItemsQuery = (
  { __typename?: 'Query' }
  & { expense: (
    { __typename?: 'Expense' }
    & { allIncludedExpenses: Maybe<Array<(
      { __typename?: 'PriceableItem' }
      & Pick<PriceableItem, 'id' | 'description' | 'calculatedCashFlowValue' | 'calculateNetValue'>
    )>> }
  ) }
);

export type AddOtherRevenueMutationVariables = {
  calculationHash: Scalars['String']
};


export type AddOtherRevenueMutation = (
  { __typename?: 'Mutation' }
  & { addOtherRevenue: (
    { __typename?: 'OtherRevenue' }
    & Pick<OtherRevenue, 'id'>
  ) }
);

export type UpdateOtherRevenueMutationVariables = {
  calculationHash: Scalars['String'],
  otherRevenueInput: OtherRevenueInput
};


export type UpdateOtherRevenueMutation = (
  { __typename?: 'Mutation' }
  & { updateOtherRevenue: (
    { __typename?: 'OtherRevenue' }
    & Pick<OtherRevenue, 'id'>
  ) }
);

export type RemoveOtherRevenueMutationVariables = {
  calculationHash: Scalars['String'],
  otherRevenueId: Scalars['String']
};


export type RemoveOtherRevenueMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeOtherRevenue'>
);

export type AddProductMutationVariables = {
  calculationHash: Scalars['String'],
  productInput: ProductInput
};


export type AddProductMutation = (
  { __typename?: 'Mutation' }
  & { addProduct: (
    { __typename?: 'Product' }
    & Pick<Product, 'id'>
  ) }
);

export type UpdateProductMutationVariables = {
  calculationHash: Scalars['String'],
  productInput: ProductInput
};


export type UpdateProductMutation = (
  { __typename?: 'Mutation' }
  & { updateProduct: (
    { __typename?: 'Product' }
    & Pick<Product, 'id'>
  ) }
);

export type RemoveProductMutationVariables = {
  calculationHash: Scalars['String'],
  productId: Scalars['String']
};


export type RemoveProductMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeProduct'>
);

export type RevenueQueryVariables = {
  calculationHash: Scalars['String']
};


export type RevenueQuery = (
  { __typename?: 'Query' }
  & { revenue: (
    { __typename?: 'Revenue' }
    & { serviceRevenues: (
      { __typename?: 'ServiceRevenuesSection' }
      & Pick<ServiceRevenuesSection, 'totalPrice'>
      & { revenues: Array<Maybe<(
        { __typename?: 'ServiceRevenue' }
        & Pick<ServiceRevenue, 'id' | 'description' | 'quantity' | 'unitPrice' | 'dateCreated' | 'includeInCalculation' | 'calculatedCashFlowValue'>
      )>> }
    ), products: (
      { __typename?: 'ProductsRevenuesSection' }
      & Pick<ProductsRevenuesSection, 'totalPrice'>
      & { revenues: Array<Maybe<(
        { __typename?: 'Product' }
        & Pick<Product, 'id' | 'description' | 'quantity' | 'unitPrice' | 'dateCreated' | 'includeInCalculation' | 'calculatedCashFlowValue'>
      )>> }
    ), otherRevenues: (
      { __typename?: 'OtherRevenuesSection' }
      & Pick<OtherRevenuesSection, 'totalPrice'>
      & { revenues: Array<Maybe<(
        { __typename?: 'OtherRevenue' }
        & Pick<OtherRevenue, 'id' | 'description' | 'price' | 'dateCreated' | 'includeInCalculation'>
      )>> }
    ) }
  ) }
);

export type AddServiceRevenueMutationVariables = {
  calculationHash: Scalars['String'],
  serviceRevenueInput: ServiceRevenueInput
};


export type AddServiceRevenueMutation = (
  { __typename?: 'Mutation' }
  & { addServiceRevenue: (
    { __typename?: 'ServiceRevenue' }
    & Pick<ServiceRevenue, 'id'>
  ) }
);

export type UpdateServiceRevenueMutationVariables = {
  calculationHash: Scalars['String'],
  serviceRevenueInput: ServiceRevenueInput
};


export type UpdateServiceRevenueMutation = (
  { __typename?: 'Mutation' }
  & { updateServiceRevenue: (
    { __typename?: 'ServiceRevenue' }
    & Pick<ServiceRevenue, 'id'>
  ) }
);

export type RemoveServiceRevenueMutationVariables = {
  calculationHash: Scalars['String'],
  serviceRevenueId: Scalars['String']
};


export type RemoveServiceRevenueMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeServiceRevenue'>
);

export type CalculationsQueryVariables = {};


export type CalculationsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'calculations'>
);

export type UpdateLanguageMutationVariables = {
  calculationHash?: Maybe<Scalars['String']>,
  lang: Lang
};


export type UpdateLanguageMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateLanguage'>
);

export const EnterCodeDocument = gql`
    mutation enterCode($calculationCode: String!) {
  enterCode(calculationCode: $calculationCode)
}
    `;
export type EnterCodeMutationFn = ApolloReactCommon.MutationFunction<EnterCodeMutation, EnterCodeMutationVariables>;
export type EnterCodeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EnterCodeMutation, EnterCodeMutationVariables>, 'mutation'>;

    export const EnterCodeComponent = (props: EnterCodeComponentProps) => (
      <ApolloReactComponents.Mutation<EnterCodeMutation, EnterCodeMutationVariables> mutation={EnterCodeDocument} {...props} />
    );
    
export type EnterCodeProps<TChildProps = {}> = ApolloReactHoc.MutateProps<EnterCodeMutation, EnterCodeMutationVariables> & TChildProps;
export function withEnterCode<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EnterCodeMutation,
  EnterCodeMutationVariables,
  EnterCodeProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, EnterCodeMutation, EnterCodeMutationVariables, EnterCodeProps<TChildProps>>(EnterCodeDocument, {
      alias: 'enterCode',
      ...operationOptions
    });
};

    export function useEnterCodeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EnterCodeMutation, EnterCodeMutationVariables>) {
      return ApolloReactHooks.useMutation<EnterCodeMutation, EnterCodeMutationVariables>(EnterCodeDocument, baseOptions);
    }
export type EnterCodeMutationHookResult = ReturnType<typeof useEnterCodeMutation>;
export type EnterCodeMutationResult = ApolloReactCommon.MutationResult<EnterCodeMutation>;
export type EnterCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<EnterCodeMutation, EnterCodeMutationVariables>;
export const CreateCalculationDocument = gql`
    mutation createCalculation($lang: Lang!) {
  createCalculation(lang: $lang)
}
    `;
export type CreateCalculationMutationFn = ApolloReactCommon.MutationFunction<CreateCalculationMutation, CreateCalculationMutationVariables>;
export type CreateCalculationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateCalculationMutation, CreateCalculationMutationVariables>, 'mutation'>;

    export const CreateCalculationComponent = (props: CreateCalculationComponentProps) => (
      <ApolloReactComponents.Mutation<CreateCalculationMutation, CreateCalculationMutationVariables> mutation={CreateCalculationDocument} {...props} />
    );
    
export type CreateCalculationProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CreateCalculationMutation, CreateCalculationMutationVariables> & TChildProps;
export function withCreateCalculation<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateCalculationMutation,
  CreateCalculationMutationVariables,
  CreateCalculationProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CreateCalculationMutation, CreateCalculationMutationVariables, CreateCalculationProps<TChildProps>>(CreateCalculationDocument, {
      alias: 'createCalculation',
      ...operationOptions
    });
};

    export function useCreateCalculationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCalculationMutation, CreateCalculationMutationVariables>) {
      return ApolloReactHooks.useMutation<CreateCalculationMutation, CreateCalculationMutationVariables>(CreateCalculationDocument, baseOptions);
    }
export type CreateCalculationMutationHookResult = ReturnType<typeof useCreateCalculationMutation>;
export type CreateCalculationMutationResult = ApolloReactCommon.MutationResult<CreateCalculationMutation>;
export type CreateCalculationMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCalculationMutation, CreateCalculationMutationVariables>;
export const ResultDocument = gql`
    query Result($calculationHash: String!) {
  result(calculationHash: $calculationHash) {
    calculationOptions {
      pensionType
    }
    desiredYearlyNetIncome
    calculatedYearlyNetIncome
    yearlyRevenue
    yearlyExpense
    yearlyProceeds
    yearlyCost
    balanceBeforeSocialContributions
    socialContributions
    pensionSaving
    socialContributionsWithPensionSaving
    personalIncomeTax
    financed
    surplus
    shortage
  }
}
    `;
export type ResultComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ResultQuery, ResultQueryVariables>, 'query'> & ({ variables: ResultQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ResultComponent = (props: ResultComponentProps) => (
      <ApolloReactComponents.Query<ResultQuery, ResultQueryVariables> query={ResultDocument} {...props} />
    );
    
export type ResultProps<TChildProps = {}> = ApolloReactHoc.DataProps<ResultQuery, ResultQueryVariables> & TChildProps;
export function withResult<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ResultQuery,
  ResultQueryVariables,
  ResultProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ResultQuery, ResultQueryVariables, ResultProps<TChildProps>>(ResultDocument, {
      alias: 'result',
      ...operationOptions
    });
};

    export function useResultQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ResultQuery, ResultQueryVariables>) {
      return ApolloReactHooks.useQuery<ResultQuery, ResultQueryVariables>(ResultDocument, baseOptions);
    }
      export function useResultLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ResultQuery, ResultQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<ResultQuery, ResultQueryVariables>(ResultDocument, baseOptions);
      }
      
export type ResultQueryHookResult = ReturnType<typeof useResultQuery>;
export type ResultQueryResult = ApolloReactCommon.QueryResult<ResultQuery, ResultQueryVariables>;
export const ExpenseCategoriesWithSelectedDocument = gql`
    query ExpenseCategoriesWithSelected($calculationHash: String) {
  expenseCategories(calculationHash: $calculationHash) {
    id
    code
    expenseTypes {
      id
      code
      selected
    }
  }
}
    `;
export type ExpenseCategoriesWithSelectedComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ExpenseCategoriesWithSelectedQuery, ExpenseCategoriesWithSelectedQueryVariables>, 'query'>;

    export const ExpenseCategoriesWithSelectedComponent = (props: ExpenseCategoriesWithSelectedComponentProps) => (
      <ApolloReactComponents.Query<ExpenseCategoriesWithSelectedQuery, ExpenseCategoriesWithSelectedQueryVariables> query={ExpenseCategoriesWithSelectedDocument} {...props} />
    );
    
export type ExpenseCategoriesWithSelectedProps<TChildProps = {}> = ApolloReactHoc.DataProps<ExpenseCategoriesWithSelectedQuery, ExpenseCategoriesWithSelectedQueryVariables> & TChildProps;
export function withExpenseCategoriesWithSelected<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ExpenseCategoriesWithSelectedQuery,
  ExpenseCategoriesWithSelectedQueryVariables,
  ExpenseCategoriesWithSelectedProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ExpenseCategoriesWithSelectedQuery, ExpenseCategoriesWithSelectedQueryVariables, ExpenseCategoriesWithSelectedProps<TChildProps>>(ExpenseCategoriesWithSelectedDocument, {
      alias: 'expenseCategoriesWithSelected',
      ...operationOptions
    });
};

    export function useExpenseCategoriesWithSelectedQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ExpenseCategoriesWithSelectedQuery, ExpenseCategoriesWithSelectedQueryVariables>) {
      return ApolloReactHooks.useQuery<ExpenseCategoriesWithSelectedQuery, ExpenseCategoriesWithSelectedQueryVariables>(ExpenseCategoriesWithSelectedDocument, baseOptions);
    }
      export function useExpenseCategoriesWithSelectedLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ExpenseCategoriesWithSelectedQuery, ExpenseCategoriesWithSelectedQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<ExpenseCategoriesWithSelectedQuery, ExpenseCategoriesWithSelectedQueryVariables>(ExpenseCategoriesWithSelectedDocument, baseOptions);
      }
      
export type ExpenseCategoriesWithSelectedQueryHookResult = ReturnType<typeof useExpenseCategoriesWithSelectedQuery>;
export type ExpenseCategoriesWithSelectedQueryResult = ApolloReactCommon.QueryResult<ExpenseCategoriesWithSelectedQuery, ExpenseCategoriesWithSelectedQueryVariables>;
export const AddExpenseItemDocument = gql`
    mutation AddExpenseItem($calculationHash: String!, $expenseType: String!, $expenseCategory: String!) {
  addExpenseItem(calculationHash: $calculationHash, expenseType: $expenseType, expenseCategory: $expenseCategory) {
    id
  }
}
    `;
export type AddExpenseItemMutationFn = ApolloReactCommon.MutationFunction<AddExpenseItemMutation, AddExpenseItemMutationVariables>;
export type AddExpenseItemComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddExpenseItemMutation, AddExpenseItemMutationVariables>, 'mutation'>;

    export const AddExpenseItemComponent = (props: AddExpenseItemComponentProps) => (
      <ApolloReactComponents.Mutation<AddExpenseItemMutation, AddExpenseItemMutationVariables> mutation={AddExpenseItemDocument} {...props} />
    );
    
export type AddExpenseItemProps<TChildProps = {}> = ApolloReactHoc.MutateProps<AddExpenseItemMutation, AddExpenseItemMutationVariables> & TChildProps;
export function withAddExpenseItem<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AddExpenseItemMutation,
  AddExpenseItemMutationVariables,
  AddExpenseItemProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, AddExpenseItemMutation, AddExpenseItemMutationVariables, AddExpenseItemProps<TChildProps>>(AddExpenseItemDocument, {
      alias: 'addExpenseItem',
      ...operationOptions
    });
};

    export function useAddExpenseItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddExpenseItemMutation, AddExpenseItemMutationVariables>) {
      return ApolloReactHooks.useMutation<AddExpenseItemMutation, AddExpenseItemMutationVariables>(AddExpenseItemDocument, baseOptions);
    }
export type AddExpenseItemMutationHookResult = ReturnType<typeof useAddExpenseItemMutation>;
export type AddExpenseItemMutationResult = ApolloReactCommon.MutationResult<AddExpenseItemMutation>;
export type AddExpenseItemMutationOptions = ApolloReactCommon.BaseMutationOptions<AddExpenseItemMutation, AddExpenseItemMutationVariables>;
export const UpdateExpenseItemDocument = gql`
    mutation UpdateExpenseItem($calculationHash: String!, $expenseItemInput: ExpenseItemInput!) {
  updateExpenseItem(calculationHash: $calculationHash, expenseItemInput: $expenseItemInput) {
    id
    expenseCategory
    expenseType
    expenseTypeOption
    description
    quantity
    unitPrice
    useQuantity
    calculatedCashFlowValue
    includeInCalculation
  }
}
    `;
export type UpdateExpenseItemMutationFn = ApolloReactCommon.MutationFunction<UpdateExpenseItemMutation, UpdateExpenseItemMutationVariables>;
export type UpdateExpenseItemComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateExpenseItemMutation, UpdateExpenseItemMutationVariables>, 'mutation'>;

    export const UpdateExpenseItemComponent = (props: UpdateExpenseItemComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateExpenseItemMutation, UpdateExpenseItemMutationVariables> mutation={UpdateExpenseItemDocument} {...props} />
    );
    
export type UpdateExpenseItemProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateExpenseItemMutation, UpdateExpenseItemMutationVariables> & TChildProps;
export function withUpdateExpenseItem<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateExpenseItemMutation,
  UpdateExpenseItemMutationVariables,
  UpdateExpenseItemProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateExpenseItemMutation, UpdateExpenseItemMutationVariables, UpdateExpenseItemProps<TChildProps>>(UpdateExpenseItemDocument, {
      alias: 'updateExpenseItem',
      ...operationOptions
    });
};

    export function useUpdateExpenseItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateExpenseItemMutation, UpdateExpenseItemMutationVariables>) {
      return ApolloReactHooks.useMutation<UpdateExpenseItemMutation, UpdateExpenseItemMutationVariables>(UpdateExpenseItemDocument, baseOptions);
    }
export type UpdateExpenseItemMutationHookResult = ReturnType<typeof useUpdateExpenseItemMutation>;
export type UpdateExpenseItemMutationResult = ApolloReactCommon.MutationResult<UpdateExpenseItemMutation>;
export type UpdateExpenseItemMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateExpenseItemMutation, UpdateExpenseItemMutationVariables>;
export const RemoveExpenseItemDocument = gql`
    mutation RemoveExpenseItem($calculationHash: String!, $expenseItemId: String!) {
  removeExpenseItem(calculationHash: $calculationHash, expenseItemId: $expenseItemId)
}
    `;
export type RemoveExpenseItemMutationFn = ApolloReactCommon.MutationFunction<RemoveExpenseItemMutation, RemoveExpenseItemMutationVariables>;
export type RemoveExpenseItemComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveExpenseItemMutation, RemoveExpenseItemMutationVariables>, 'mutation'>;

    export const RemoveExpenseItemComponent = (props: RemoveExpenseItemComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveExpenseItemMutation, RemoveExpenseItemMutationVariables> mutation={RemoveExpenseItemDocument} {...props} />
    );
    
export type RemoveExpenseItemProps<TChildProps = {}> = ApolloReactHoc.MutateProps<RemoveExpenseItemMutation, RemoveExpenseItemMutationVariables> & TChildProps;
export function withRemoveExpenseItem<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RemoveExpenseItemMutation,
  RemoveExpenseItemMutationVariables,
  RemoveExpenseItemProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, RemoveExpenseItemMutation, RemoveExpenseItemMutationVariables, RemoveExpenseItemProps<TChildProps>>(RemoveExpenseItemDocument, {
      alias: 'removeExpenseItem',
      ...operationOptions
    });
};

    export function useRemoveExpenseItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveExpenseItemMutation, RemoveExpenseItemMutationVariables>) {
      return ApolloReactHooks.useMutation<RemoveExpenseItemMutation, RemoveExpenseItemMutationVariables>(RemoveExpenseItemDocument, baseOptions);
    }
export type RemoveExpenseItemMutationHookResult = ReturnType<typeof useRemoveExpenseItemMutation>;
export type RemoveExpenseItemMutationResult = ApolloReactCommon.MutationResult<RemoveExpenseItemMutation>;
export type RemoveExpenseItemMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveExpenseItemMutation, RemoveExpenseItemMutationVariables>;
export const UpdateCalculationOptionsDocument = gql`
    mutation UpdateCalculationOptions($calculationHash: String!, $calculationOptions: CalculationOptionsInput!) {
  updateCalculationOptions(calculationHash: $calculationHash, calculationOptionsInput: $calculationOptions)
}
    `;
export type UpdateCalculationOptionsMutationFn = ApolloReactCommon.MutationFunction<UpdateCalculationOptionsMutation, UpdateCalculationOptionsMutationVariables>;
export type UpdateCalculationOptionsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateCalculationOptionsMutation, UpdateCalculationOptionsMutationVariables>, 'mutation'>;

    export const UpdateCalculationOptionsComponent = (props: UpdateCalculationOptionsComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateCalculationOptionsMutation, UpdateCalculationOptionsMutationVariables> mutation={UpdateCalculationOptionsDocument} {...props} />
    );
    
export type UpdateCalculationOptionsProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateCalculationOptionsMutation, UpdateCalculationOptionsMutationVariables> & TChildProps;
export function withUpdateCalculationOptions<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateCalculationOptionsMutation,
  UpdateCalculationOptionsMutationVariables,
  UpdateCalculationOptionsProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateCalculationOptionsMutation, UpdateCalculationOptionsMutationVariables, UpdateCalculationOptionsProps<TChildProps>>(UpdateCalculationOptionsDocument, {
      alias: 'updateCalculationOptions',
      ...operationOptions
    });
};

    export function useUpdateCalculationOptionsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCalculationOptionsMutation, UpdateCalculationOptionsMutationVariables>) {
      return ApolloReactHooks.useMutation<UpdateCalculationOptionsMutation, UpdateCalculationOptionsMutationVariables>(UpdateCalculationOptionsDocument, baseOptions);
    }
export type UpdateCalculationOptionsMutationHookResult = ReturnType<typeof useUpdateCalculationOptionsMutation>;
export type UpdateCalculationOptionsMutationResult = ApolloReactCommon.MutationResult<UpdateCalculationOptionsMutation>;
export type UpdateCalculationOptionsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCalculationOptionsMutation, UpdateCalculationOptionsMutationVariables>;
export const AddHousingDocument = gql`
    mutation AddHousing($calculationHash: String!, $housingType: HousingType!) {
  addHousing(calculationHash: $calculationHash, housingType: $housingType) {
    id
  }
}
    `;
export type AddHousingMutationFn = ApolloReactCommon.MutationFunction<AddHousingMutation, AddHousingMutationVariables>;
export type AddHousingComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddHousingMutation, AddHousingMutationVariables>, 'mutation'>;

    export const AddHousingComponent = (props: AddHousingComponentProps) => (
      <ApolloReactComponents.Mutation<AddHousingMutation, AddHousingMutationVariables> mutation={AddHousingDocument} {...props} />
    );
    
export type AddHousingProps<TChildProps = {}> = ApolloReactHoc.MutateProps<AddHousingMutation, AddHousingMutationVariables> & TChildProps;
export function withAddHousing<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AddHousingMutation,
  AddHousingMutationVariables,
  AddHousingProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, AddHousingMutation, AddHousingMutationVariables, AddHousingProps<TChildProps>>(AddHousingDocument, {
      alias: 'addHousing',
      ...operationOptions
    });
};

    export function useAddHousingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddHousingMutation, AddHousingMutationVariables>) {
      return ApolloReactHooks.useMutation<AddHousingMutation, AddHousingMutationVariables>(AddHousingDocument, baseOptions);
    }
export type AddHousingMutationHookResult = ReturnType<typeof useAddHousingMutation>;
export type AddHousingMutationResult = ApolloReactCommon.MutationResult<AddHousingMutation>;
export type AddHousingMutationOptions = ApolloReactCommon.BaseMutationOptions<AddHousingMutation, AddHousingMutationVariables>;
export const RemoveHousingDocument = gql`
    mutation RemoveHousing($calculationHash: String!, $housingId: String!) {
  removeHousing(calculationHash: $calculationHash, housingId: $housingId)
}
    `;
export type RemoveHousingMutationFn = ApolloReactCommon.MutationFunction<RemoveHousingMutation, RemoveHousingMutationVariables>;
export type RemoveHousingComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveHousingMutation, RemoveHousingMutationVariables>, 'mutation'>;

    export const RemoveHousingComponent = (props: RemoveHousingComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveHousingMutation, RemoveHousingMutationVariables> mutation={RemoveHousingDocument} {...props} />
    );
    
export type RemoveHousingProps<TChildProps = {}> = ApolloReactHoc.MutateProps<RemoveHousingMutation, RemoveHousingMutationVariables> & TChildProps;
export function withRemoveHousing<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RemoveHousingMutation,
  RemoveHousingMutationVariables,
  RemoveHousingProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, RemoveHousingMutation, RemoveHousingMutationVariables, RemoveHousingProps<TChildProps>>(RemoveHousingDocument, {
      alias: 'removeHousing',
      ...operationOptions
    });
};

    export function useRemoveHousingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveHousingMutation, RemoveHousingMutationVariables>) {
      return ApolloReactHooks.useMutation<RemoveHousingMutation, RemoveHousingMutationVariables>(RemoveHousingDocument, baseOptions);
    }
export type RemoveHousingMutationHookResult = ReturnType<typeof useRemoveHousingMutation>;
export type RemoveHousingMutationResult = ApolloReactCommon.MutationResult<RemoveHousingMutation>;
export type RemoveHousingMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveHousingMutation, RemoveHousingMutationVariables>;
export const ToggleHousingDocument = gql`
    mutation ToggleHousing($calculationHash: String!, $housingId: String!, $exclude: Boolean!) {
  toggleHousing(calculationHash: $calculationHash, housingId: $housingId, exclude: $exclude)
}
    `;
export type ToggleHousingMutationFn = ApolloReactCommon.MutationFunction<ToggleHousingMutation, ToggleHousingMutationVariables>;
export type ToggleHousingComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ToggleHousingMutation, ToggleHousingMutationVariables>, 'mutation'>;

    export const ToggleHousingComponent = (props: ToggleHousingComponentProps) => (
      <ApolloReactComponents.Mutation<ToggleHousingMutation, ToggleHousingMutationVariables> mutation={ToggleHousingDocument} {...props} />
    );
    
export type ToggleHousingProps<TChildProps = {}> = ApolloReactHoc.MutateProps<ToggleHousingMutation, ToggleHousingMutationVariables> & TChildProps;
export function withToggleHousing<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ToggleHousingMutation,
  ToggleHousingMutationVariables,
  ToggleHousingProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, ToggleHousingMutation, ToggleHousingMutationVariables, ToggleHousingProps<TChildProps>>(ToggleHousingDocument, {
      alias: 'toggleHousing',
      ...operationOptions
    });
};

    export function useToggleHousingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ToggleHousingMutation, ToggleHousingMutationVariables>) {
      return ApolloReactHooks.useMutation<ToggleHousingMutation, ToggleHousingMutationVariables>(ToggleHousingDocument, baseOptions);
    }
export type ToggleHousingMutationHookResult = ReturnType<typeof useToggleHousingMutation>;
export type ToggleHousingMutationResult = ApolloReactCommon.MutationResult<ToggleHousingMutation>;
export type ToggleHousingMutationOptions = ApolloReactCommon.BaseMutationOptions<ToggleHousingMutation, ToggleHousingMutationVariables>;
export const HousingDocument = gql`
    query Housing($calculationHash: String!, $housingId: String!) {
  housing(calculationHash: $calculationHash, housingId: $housingId) {
    id
    housingType
    housingFinancingType
    includeInCalculation
    unitPrice
    professionalPercentage
    calculatedCashFlowValue
    calculateNetValue
    total
    property {
      purchaseAmount
      utilities
      maintenance
      insurance
      interestAmount
      otherCosts
    }
    rental {
      rentalAmount
      utilities
      maintenance
      insurance
      otherCosts
    }
  }
}
    `;
export type HousingComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<HousingQuery, HousingQueryVariables>, 'query'> & ({ variables: HousingQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const HousingComponent = (props: HousingComponentProps) => (
      <ApolloReactComponents.Query<HousingQuery, HousingQueryVariables> query={HousingDocument} {...props} />
    );
    
export type HousingProps<TChildProps = {}> = ApolloReactHoc.DataProps<HousingQuery, HousingQueryVariables> & TChildProps;
export function withHousing<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  HousingQuery,
  HousingQueryVariables,
  HousingProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, HousingQuery, HousingQueryVariables, HousingProps<TChildProps>>(HousingDocument, {
      alias: 'housing',
      ...operationOptions
    });
};

    export function useHousingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HousingQuery, HousingQueryVariables>) {
      return ApolloReactHooks.useQuery<HousingQuery, HousingQueryVariables>(HousingDocument, baseOptions);
    }
      export function useHousingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HousingQuery, HousingQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<HousingQuery, HousingQueryVariables>(HousingDocument, baseOptions);
      }
      
export type HousingQueryHookResult = ReturnType<typeof useHousingQuery>;
export type HousingQueryResult = ApolloReactCommon.QueryResult<HousingQuery, HousingQueryVariables>;
export const UpdateHousingDocument = gql`
    mutation UpdateHousing($calculationHash: String!, $housingInput: HousingInput!) {
  updateHousing(calculationHash: $calculationHash, housingInput: $housingInput) {
    id
    housingType
    housingFinancingType
    property {
      purchaseAmount
      utilities
      maintenance
      insurance
      interestAmount
      otherCosts
    }
    rental {
      rentalAmount
      utilities
      maintenance
      insurance
      otherCosts
    }
    includeInCalculation
    unitPrice
    calculatedCashFlowValue
    calculateNetValue
    total
  }
}
    `;
export type UpdateHousingMutationFn = ApolloReactCommon.MutationFunction<UpdateHousingMutation, UpdateHousingMutationVariables>;
export type UpdateHousingComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateHousingMutation, UpdateHousingMutationVariables>, 'mutation'>;

    export const UpdateHousingComponent = (props: UpdateHousingComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateHousingMutation, UpdateHousingMutationVariables> mutation={UpdateHousingDocument} {...props} />
    );
    
export type UpdateHousingProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateHousingMutation, UpdateHousingMutationVariables> & TChildProps;
export function withUpdateHousing<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateHousingMutation,
  UpdateHousingMutationVariables,
  UpdateHousingProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateHousingMutation, UpdateHousingMutationVariables, UpdateHousingProps<TChildProps>>(UpdateHousingDocument, {
      alias: 'updateHousing',
      ...operationOptions
    });
};

    export function useUpdateHousingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateHousingMutation, UpdateHousingMutationVariables>) {
      return ApolloReactHooks.useMutation<UpdateHousingMutation, UpdateHousingMutationVariables>(UpdateHousingDocument, baseOptions);
    }
export type UpdateHousingMutationHookResult = ReturnType<typeof useUpdateHousingMutation>;
export type UpdateHousingMutationResult = ApolloReactCommon.MutationResult<UpdateHousingMutation>;
export type UpdateHousingMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateHousingMutation, UpdateHousingMutationVariables>;
export const ExpenseDocument = gql`
    query Expense($calculationHash: String!) {
  expense(calculationHash: $calculationHash) {
    expenseItems {
      id
      expenseCategory
      expenseType
      expenseTypeOption
      description
      quantity
      unitPrice
      useQuantity
      calculatedCashFlowValue
      includeInCalculation
    }
    groupedExpenses {
      key
      values {
        id
        expenseCategory
        expenseType
        expenseTypeOption
        description
        quantity
        unitPrice
        useQuantity
        calculatedCashFlowValue
        includeInCalculation
      }
      totalPrice
    }
    vehicles {
      id
      vehicleType
      vehicleFinancingType
      includeInCalculation
      calculatedCashFlowValue
      calculateNetValue
      total
      purchase {
        purchaseAmount
        maintenance
        tax
        milagePrivate
        milageProfessional
        fuelConsumption
        fuelPricePerLiter
        insurance
        otherCosts
      }
      leasing {
        otherCosts
        milagePrivate
        milageProfessional
      }
    }
    housings {
      id
      housingType
      housingFinancingType
      includeInCalculation
      unitPrice
      calculatedCashFlowValue
      calculateNetValue
      total
      professionalPercentage
    }
  }
}
    `;
export type ExpenseComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ExpenseQuery, ExpenseQueryVariables>, 'query'> & ({ variables: ExpenseQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ExpenseComponent = (props: ExpenseComponentProps) => (
      <ApolloReactComponents.Query<ExpenseQuery, ExpenseQueryVariables> query={ExpenseDocument} {...props} />
    );
    
export type ExpenseProps<TChildProps = {}> = ApolloReactHoc.DataProps<ExpenseQuery, ExpenseQueryVariables> & TChildProps;
export function withExpense<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ExpenseQuery,
  ExpenseQueryVariables,
  ExpenseProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ExpenseQuery, ExpenseQueryVariables, ExpenseProps<TChildProps>>(ExpenseDocument, {
      alias: 'expense',
      ...operationOptions
    });
};

    export function useExpenseQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ExpenseQuery, ExpenseQueryVariables>) {
      return ApolloReactHooks.useQuery<ExpenseQuery, ExpenseQueryVariables>(ExpenseDocument, baseOptions);
    }
      export function useExpenseLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ExpenseQuery, ExpenseQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<ExpenseQuery, ExpenseQueryVariables>(ExpenseDocument, baseOptions);
      }
      
export type ExpenseQueryHookResult = ReturnType<typeof useExpenseQuery>;
export type ExpenseQueryResult = ApolloReactCommon.QueryResult<ExpenseQuery, ExpenseQueryVariables>;
export const ExpenseCategoriesDocument = gql`
    query ExpenseCategories($calculationHash: String) {
  expenseCategories(calculationHash: $calculationHash) {
    id
    code
    expenseTypes {
      id
      code
      description
      recurrence
      defaultPrice
      fixedName
      fixedPrice
      hasOptions
      multipleAllowed
      useQuantity
      useProfessionalPart
      defaultProfessionalPart
      minimumPrice
      maximumPrice
      selected
      expenseTypeOptions {
        id
        code
        description
        recurrence
        defaultPrice
        fixedName
        fixedPrice
        multipleAllowed
      }
    }
  }
}
    `;
export type ExpenseCategoriesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ExpenseCategoriesQuery, ExpenseCategoriesQueryVariables>, 'query'>;

    export const ExpenseCategoriesComponent = (props: ExpenseCategoriesComponentProps) => (
      <ApolloReactComponents.Query<ExpenseCategoriesQuery, ExpenseCategoriesQueryVariables> query={ExpenseCategoriesDocument} {...props} />
    );
    
export type ExpenseCategoriesProps<TChildProps = {}> = ApolloReactHoc.DataProps<ExpenseCategoriesQuery, ExpenseCategoriesQueryVariables> & TChildProps;
export function withExpenseCategories<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ExpenseCategoriesQuery,
  ExpenseCategoriesQueryVariables,
  ExpenseCategoriesProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ExpenseCategoriesQuery, ExpenseCategoriesQueryVariables, ExpenseCategoriesProps<TChildProps>>(ExpenseCategoriesDocument, {
      alias: 'expenseCategories',
      ...operationOptions
    });
};

    export function useExpenseCategoriesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ExpenseCategoriesQuery, ExpenseCategoriesQueryVariables>) {
      return ApolloReactHooks.useQuery<ExpenseCategoriesQuery, ExpenseCategoriesQueryVariables>(ExpenseCategoriesDocument, baseOptions);
    }
      export function useExpenseCategoriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ExpenseCategoriesQuery, ExpenseCategoriesQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<ExpenseCategoriesQuery, ExpenseCategoriesQueryVariables>(ExpenseCategoriesDocument, baseOptions);
      }
      
export type ExpenseCategoriesQueryHookResult = ReturnType<typeof useExpenseCategoriesQuery>;
export type ExpenseCategoriesQueryResult = ApolloReactCommon.QueryResult<ExpenseCategoriesQuery, ExpenseCategoriesQueryVariables>;
export const AddVehicleDocument = gql`
    mutation AddVehicle($calculationHash: String!, $vehicleType: VehicleType!) {
  addVehicle(calculationHash: $calculationHash, vehicleType: $vehicleType) {
    id
  }
}
    `;
export type AddVehicleMutationFn = ApolloReactCommon.MutationFunction<AddVehicleMutation, AddVehicleMutationVariables>;
export type AddVehicleComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddVehicleMutation, AddVehicleMutationVariables>, 'mutation'>;

    export const AddVehicleComponent = (props: AddVehicleComponentProps) => (
      <ApolloReactComponents.Mutation<AddVehicleMutation, AddVehicleMutationVariables> mutation={AddVehicleDocument} {...props} />
    );
    
export type AddVehicleProps<TChildProps = {}> = ApolloReactHoc.MutateProps<AddVehicleMutation, AddVehicleMutationVariables> & TChildProps;
export function withAddVehicle<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AddVehicleMutation,
  AddVehicleMutationVariables,
  AddVehicleProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, AddVehicleMutation, AddVehicleMutationVariables, AddVehicleProps<TChildProps>>(AddVehicleDocument, {
      alias: 'addVehicle',
      ...operationOptions
    });
};

    export function useAddVehicleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddVehicleMutation, AddVehicleMutationVariables>) {
      return ApolloReactHooks.useMutation<AddVehicleMutation, AddVehicleMutationVariables>(AddVehicleDocument, baseOptions);
    }
export type AddVehicleMutationHookResult = ReturnType<typeof useAddVehicleMutation>;
export type AddVehicleMutationResult = ApolloReactCommon.MutationResult<AddVehicleMutation>;
export type AddVehicleMutationOptions = ApolloReactCommon.BaseMutationOptions<AddVehicleMutation, AddVehicleMutationVariables>;
export const RemoveVehicleDocument = gql`
    mutation RemoveVehicle($calculationHash: String!, $vehicleId: String!) {
  removeVehicle(calculationHash: $calculationHash, vehicleId: $vehicleId)
}
    `;
export type RemoveVehicleMutationFn = ApolloReactCommon.MutationFunction<RemoveVehicleMutation, RemoveVehicleMutationVariables>;
export type RemoveVehicleComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveVehicleMutation, RemoveVehicleMutationVariables>, 'mutation'>;

    export const RemoveVehicleComponent = (props: RemoveVehicleComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveVehicleMutation, RemoveVehicleMutationVariables> mutation={RemoveVehicleDocument} {...props} />
    );
    
export type RemoveVehicleProps<TChildProps = {}> = ApolloReactHoc.MutateProps<RemoveVehicleMutation, RemoveVehicleMutationVariables> & TChildProps;
export function withRemoveVehicle<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RemoveVehicleMutation,
  RemoveVehicleMutationVariables,
  RemoveVehicleProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, RemoveVehicleMutation, RemoveVehicleMutationVariables, RemoveVehicleProps<TChildProps>>(RemoveVehicleDocument, {
      alias: 'removeVehicle',
      ...operationOptions
    });
};

    export function useRemoveVehicleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveVehicleMutation, RemoveVehicleMutationVariables>) {
      return ApolloReactHooks.useMutation<RemoveVehicleMutation, RemoveVehicleMutationVariables>(RemoveVehicleDocument, baseOptions);
    }
export type RemoveVehicleMutationHookResult = ReturnType<typeof useRemoveVehicleMutation>;
export type RemoveVehicleMutationResult = ApolloReactCommon.MutationResult<RemoveVehicleMutation>;
export type RemoveVehicleMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveVehicleMutation, RemoveVehicleMutationVariables>;
export const ToggleVehicleDocument = gql`
    mutation ToggleVehicle($calculationHash: String!, $vehicleId: String!, $exclude: Boolean!) {
  toggleVehicle(calculationHash: $calculationHash, vehicleId: $vehicleId, exclude: $exclude)
}
    `;
export type ToggleVehicleMutationFn = ApolloReactCommon.MutationFunction<ToggleVehicleMutation, ToggleVehicleMutationVariables>;
export type ToggleVehicleComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ToggleVehicleMutation, ToggleVehicleMutationVariables>, 'mutation'>;

    export const ToggleVehicleComponent = (props: ToggleVehicleComponentProps) => (
      <ApolloReactComponents.Mutation<ToggleVehicleMutation, ToggleVehicleMutationVariables> mutation={ToggleVehicleDocument} {...props} />
    );
    
export type ToggleVehicleProps<TChildProps = {}> = ApolloReactHoc.MutateProps<ToggleVehicleMutation, ToggleVehicleMutationVariables> & TChildProps;
export function withToggleVehicle<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ToggleVehicleMutation,
  ToggleVehicleMutationVariables,
  ToggleVehicleProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, ToggleVehicleMutation, ToggleVehicleMutationVariables, ToggleVehicleProps<TChildProps>>(ToggleVehicleDocument, {
      alias: 'toggleVehicle',
      ...operationOptions
    });
};

    export function useToggleVehicleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ToggleVehicleMutation, ToggleVehicleMutationVariables>) {
      return ApolloReactHooks.useMutation<ToggleVehicleMutation, ToggleVehicleMutationVariables>(ToggleVehicleDocument, baseOptions);
    }
export type ToggleVehicleMutationHookResult = ReturnType<typeof useToggleVehicleMutation>;
export type ToggleVehicleMutationResult = ApolloReactCommon.MutationResult<ToggleVehicleMutation>;
export type ToggleVehicleMutationOptions = ApolloReactCommon.BaseMutationOptions<ToggleVehicleMutation, ToggleVehicleMutationVariables>;
export const VehicleDocument = gql`
    query Vehicle($calculationHash: String!, $vehicleId: String!) {
  vehicle(calculationHash: $calculationHash, vehicleId: $vehicleId) {
    id
    vehicleType
    vehicleFinancingType
    purchase {
      purchaseAmount
      maintenance
      tax
      milagePrivate
      milageProfessional
      fuelConsumption
      fuelPricePerLiter
      insurance
      otherCosts
    }
    leasing {
      leaseAmount
      otherCosts
      milagePrivate
      milageProfessional
    }
    includeInCalculation
    calculatedCashFlowValue
    calculateNetValue
    total
  }
}
    `;
export type VehicleComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<VehicleQuery, VehicleQueryVariables>, 'query'> & ({ variables: VehicleQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const VehicleComponent = (props: VehicleComponentProps) => (
      <ApolloReactComponents.Query<VehicleQuery, VehicleQueryVariables> query={VehicleDocument} {...props} />
    );
    
export type VehicleProps<TChildProps = {}> = ApolloReactHoc.DataProps<VehicleQuery, VehicleQueryVariables> & TChildProps;
export function withVehicle<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  VehicleQuery,
  VehicleQueryVariables,
  VehicleProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, VehicleQuery, VehicleQueryVariables, VehicleProps<TChildProps>>(VehicleDocument, {
      alias: 'vehicle',
      ...operationOptions
    });
};

    export function useVehicleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<VehicleQuery, VehicleQueryVariables>) {
      return ApolloReactHooks.useQuery<VehicleQuery, VehicleQueryVariables>(VehicleDocument, baseOptions);
    }
      export function useVehicleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VehicleQuery, VehicleQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<VehicleQuery, VehicleQueryVariables>(VehicleDocument, baseOptions);
      }
      
export type VehicleQueryHookResult = ReturnType<typeof useVehicleQuery>;
export type VehicleQueryResult = ApolloReactCommon.QueryResult<VehicleQuery, VehicleQueryVariables>;
export const UpdateVehicleDocument = gql`
    mutation UpdateVehicle($calculationHash: String!, $vehicleInput: VehicleInput!) {
  updateVehicle(calculationHash: $calculationHash, vehicleInput: $vehicleInput) {
    id
    vehicleType
    vehicleFinancingType
    purchase {
      purchaseAmount
      maintenance
      tax
      milagePrivate
      milageProfessional
      fuelConsumption
      fuelPricePerLiter
      insurance
      otherCosts
    }
    leasing {
      leaseAmount
      otherCosts
      milagePrivate
      milageProfessional
    }
    includeInCalculation
    calculatedCashFlowValue
    calculateNetValue
    total
  }
}
    `;
export type UpdateVehicleMutationFn = ApolloReactCommon.MutationFunction<UpdateVehicleMutation, UpdateVehicleMutationVariables>;
export type UpdateVehicleComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateVehicleMutation, UpdateVehicleMutationVariables>, 'mutation'>;

    export const UpdateVehicleComponent = (props: UpdateVehicleComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateVehicleMutation, UpdateVehicleMutationVariables> mutation={UpdateVehicleDocument} {...props} />
    );
    
export type UpdateVehicleProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateVehicleMutation, UpdateVehicleMutationVariables> & TChildProps;
export function withUpdateVehicle<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateVehicleMutation,
  UpdateVehicleMutationVariables,
  UpdateVehicleProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateVehicleMutation, UpdateVehicleMutationVariables, UpdateVehicleProps<TChildProps>>(UpdateVehicleDocument, {
      alias: 'updateVehicle',
      ...operationOptions
    });
};

    export function useUpdateVehicleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateVehicleMutation, UpdateVehicleMutationVariables>) {
      return ApolloReactHooks.useMutation<UpdateVehicleMutation, UpdateVehicleMutationVariables>(UpdateVehicleDocument, baseOptions);
    }
export type UpdateVehicleMutationHookResult = ReturnType<typeof useUpdateVehicleMutation>;
export type UpdateVehicleMutationResult = ApolloReactCommon.MutationResult<UpdateVehicleMutation>;
export type UpdateVehicleMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateVehicleMutation, UpdateVehicleMutationVariables>;
export const LoanDocument = gql`
    query Loan($calculationHash: String!, $loanId: String!) {
  loan(calculationHash: $calculationHash, loanId: $loanId) {
    id
    name
    amount
    interest
    duration
    toPay
    financing
    includeInCalculation
    annuity
  }
}
    `;
export type LoanComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<LoanQuery, LoanQueryVariables>, 'query'> & ({ variables: LoanQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const LoanComponent = (props: LoanComponentProps) => (
      <ApolloReactComponents.Query<LoanQuery, LoanQueryVariables> query={LoanDocument} {...props} />
    );
    
export type LoanProps<TChildProps = {}> = ApolloReactHoc.DataProps<LoanQuery, LoanQueryVariables> & TChildProps;
export function withLoan<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  LoanQuery,
  LoanQueryVariables,
  LoanProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, LoanQuery, LoanQueryVariables, LoanProps<TChildProps>>(LoanDocument, {
      alias: 'loan',
      ...operationOptions
    });
};

    export function useLoanQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoanQuery, LoanQueryVariables>) {
      return ApolloReactHooks.useQuery<LoanQuery, LoanQueryVariables>(LoanDocument, baseOptions);
    }
      export function useLoanLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoanQuery, LoanQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<LoanQuery, LoanQueryVariables>(LoanDocument, baseOptions);
      }
      
export type LoanQueryHookResult = ReturnType<typeof useLoanQuery>;
export type LoanQueryResult = ApolloReactCommon.QueryResult<LoanQuery, LoanQueryVariables>;
export const SaveLoanDocument = gql`
    mutation SaveLoan($calculationHash: String!, $loanInput: LoanInput) {
  saveLoan(calculationHash: $calculationHash, loanInput: $loanInput) {
    id
    name
    amount
    interest
    duration
    toPay
    financing
    includeInCalculation
    annuity
  }
}
    `;
export type SaveLoanMutationFn = ApolloReactCommon.MutationFunction<SaveLoanMutation, SaveLoanMutationVariables>;
export type SaveLoanComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SaveLoanMutation, SaveLoanMutationVariables>, 'mutation'>;

    export const SaveLoanComponent = (props: SaveLoanComponentProps) => (
      <ApolloReactComponents.Mutation<SaveLoanMutation, SaveLoanMutationVariables> mutation={SaveLoanDocument} {...props} />
    );
    
export type SaveLoanProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SaveLoanMutation, SaveLoanMutationVariables> & TChildProps;
export function withSaveLoan<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SaveLoanMutation,
  SaveLoanMutationVariables,
  SaveLoanProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SaveLoanMutation, SaveLoanMutationVariables, SaveLoanProps<TChildProps>>(SaveLoanDocument, {
      alias: 'saveLoan',
      ...operationOptions
    });
};

    export function useSaveLoanMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SaveLoanMutation, SaveLoanMutationVariables>) {
      return ApolloReactHooks.useMutation<SaveLoanMutation, SaveLoanMutationVariables>(SaveLoanDocument, baseOptions);
    }
export type SaveLoanMutationHookResult = ReturnType<typeof useSaveLoanMutation>;
export type SaveLoanMutationResult = ApolloReactCommon.MutationResult<SaveLoanMutation>;
export type SaveLoanMutationOptions = ApolloReactCommon.BaseMutationOptions<SaveLoanMutation, SaveLoanMutationVariables>;
export const FinancingDocument = gql`
    query Financing($calculationHash: String!) {
  financing(calculationHash: $calculationHash) {
    loans {
      id
      name
      amount
      financing
      includeInCalculation
    }
    ownResources {
      id
      name
      amount
      includeInCalculation
    }
    subsidies {
      id
      name
      amount
      includeInCalculation
    }
  }
}
    `;
export type FinancingComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FinancingQuery, FinancingQueryVariables>, 'query'> & ({ variables: FinancingQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const FinancingComponent = (props: FinancingComponentProps) => (
      <ApolloReactComponents.Query<FinancingQuery, FinancingQueryVariables> query={FinancingDocument} {...props} />
    );
    
export type FinancingProps<TChildProps = {}> = ApolloReactHoc.DataProps<FinancingQuery, FinancingQueryVariables> & TChildProps;
export function withFinancing<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  FinancingQuery,
  FinancingQueryVariables,
  FinancingProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, FinancingQuery, FinancingQueryVariables, FinancingProps<TChildProps>>(FinancingDocument, {
      alias: 'financing',
      ...operationOptions
    });
};

    export function useFinancingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FinancingQuery, FinancingQueryVariables>) {
      return ApolloReactHooks.useQuery<FinancingQuery, FinancingQueryVariables>(FinancingDocument, baseOptions);
    }
      export function useFinancingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FinancingQuery, FinancingQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<FinancingQuery, FinancingQueryVariables>(FinancingDocument, baseOptions);
      }
      
export type FinancingQueryHookResult = ReturnType<typeof useFinancingQuery>;
export type FinancingQueryResult = ApolloReactCommon.QueryResult<FinancingQuery, FinancingQueryVariables>;
export const AddFinancingItemDocument = gql`
    mutation AddFinancingItem($calculationHash: String!, $financingItemType: FinancingItemType!) {
  addFinancingItem(calculationHash: $calculationHash, financingItemType: $financingItemType)
}
    `;
export type AddFinancingItemMutationFn = ApolloReactCommon.MutationFunction<AddFinancingItemMutation, AddFinancingItemMutationVariables>;
export type AddFinancingItemComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddFinancingItemMutation, AddFinancingItemMutationVariables>, 'mutation'>;

    export const AddFinancingItemComponent = (props: AddFinancingItemComponentProps) => (
      <ApolloReactComponents.Mutation<AddFinancingItemMutation, AddFinancingItemMutationVariables> mutation={AddFinancingItemDocument} {...props} />
    );
    
export type AddFinancingItemProps<TChildProps = {}> = ApolloReactHoc.MutateProps<AddFinancingItemMutation, AddFinancingItemMutationVariables> & TChildProps;
export function withAddFinancingItem<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AddFinancingItemMutation,
  AddFinancingItemMutationVariables,
  AddFinancingItemProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, AddFinancingItemMutation, AddFinancingItemMutationVariables, AddFinancingItemProps<TChildProps>>(AddFinancingItemDocument, {
      alias: 'addFinancingItem',
      ...operationOptions
    });
};

    export function useAddFinancingItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddFinancingItemMutation, AddFinancingItemMutationVariables>) {
      return ApolloReactHooks.useMutation<AddFinancingItemMutation, AddFinancingItemMutationVariables>(AddFinancingItemDocument, baseOptions);
    }
export type AddFinancingItemMutationHookResult = ReturnType<typeof useAddFinancingItemMutation>;
export type AddFinancingItemMutationResult = ApolloReactCommon.MutationResult<AddFinancingItemMutation>;
export type AddFinancingItemMutationOptions = ApolloReactCommon.BaseMutationOptions<AddFinancingItemMutation, AddFinancingItemMutationVariables>;
export const UpdateFinancingItemDocument = gql`
    mutation UpdateFinancingItem($calculationHash: String!, $financingItemInput: FinancingItemInput!) {
  updateFinancingItem(calculationHash: $calculationHash, financingItemInput: $financingItemInput)
}
    `;
export type UpdateFinancingItemMutationFn = ApolloReactCommon.MutationFunction<UpdateFinancingItemMutation, UpdateFinancingItemMutationVariables>;
export type UpdateFinancingItemComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateFinancingItemMutation, UpdateFinancingItemMutationVariables>, 'mutation'>;

    export const UpdateFinancingItemComponent = (props: UpdateFinancingItemComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateFinancingItemMutation, UpdateFinancingItemMutationVariables> mutation={UpdateFinancingItemDocument} {...props} />
    );
    
export type UpdateFinancingItemProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateFinancingItemMutation, UpdateFinancingItemMutationVariables> & TChildProps;
export function withUpdateFinancingItem<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateFinancingItemMutation,
  UpdateFinancingItemMutationVariables,
  UpdateFinancingItemProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateFinancingItemMutation, UpdateFinancingItemMutationVariables, UpdateFinancingItemProps<TChildProps>>(UpdateFinancingItemDocument, {
      alias: 'updateFinancingItem',
      ...operationOptions
    });
};

    export function useUpdateFinancingItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateFinancingItemMutation, UpdateFinancingItemMutationVariables>) {
      return ApolloReactHooks.useMutation<UpdateFinancingItemMutation, UpdateFinancingItemMutationVariables>(UpdateFinancingItemDocument, baseOptions);
    }
export type UpdateFinancingItemMutationHookResult = ReturnType<typeof useUpdateFinancingItemMutation>;
export type UpdateFinancingItemMutationResult = ApolloReactCommon.MutationResult<UpdateFinancingItemMutation>;
export type UpdateFinancingItemMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateFinancingItemMutation, UpdateFinancingItemMutationVariables>;
export const RemoveFinancingItemDocument = gql`
    mutation RemoveFinancingItem($calculationHash: String!, $financingItemType: FinancingItemType!, $financingItemId: String!) {
  removeFinancingItem(calculationHash: $calculationHash, financingItemType: $financingItemType, financingItemId: $financingItemId)
}
    `;
export type RemoveFinancingItemMutationFn = ApolloReactCommon.MutationFunction<RemoveFinancingItemMutation, RemoveFinancingItemMutationVariables>;
export type RemoveFinancingItemComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveFinancingItemMutation, RemoveFinancingItemMutationVariables>, 'mutation'>;

    export const RemoveFinancingItemComponent = (props: RemoveFinancingItemComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveFinancingItemMutation, RemoveFinancingItemMutationVariables> mutation={RemoveFinancingItemDocument} {...props} />
    );
    
export type RemoveFinancingItemProps<TChildProps = {}> = ApolloReactHoc.MutateProps<RemoveFinancingItemMutation, RemoveFinancingItemMutationVariables> & TChildProps;
export function withRemoveFinancingItem<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RemoveFinancingItemMutation,
  RemoveFinancingItemMutationVariables,
  RemoveFinancingItemProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, RemoveFinancingItemMutation, RemoveFinancingItemMutationVariables, RemoveFinancingItemProps<TChildProps>>(RemoveFinancingItemDocument, {
      alias: 'removeFinancingItem',
      ...operationOptions
    });
};

    export function useRemoveFinancingItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveFinancingItemMutation, RemoveFinancingItemMutationVariables>) {
      return ApolloReactHooks.useMutation<RemoveFinancingItemMutation, RemoveFinancingItemMutationVariables>(RemoveFinancingItemDocument, baseOptions);
    }
export type RemoveFinancingItemMutationHookResult = ReturnType<typeof useRemoveFinancingItemMutation>;
export type RemoveFinancingItemMutationResult = ApolloReactCommon.MutationResult<RemoveFinancingItemMutation>;
export type RemoveFinancingItemMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveFinancingItemMutation, RemoveFinancingItemMutationVariables>;
export const ProfileDocument = gql`
    query Profile($calculationHash: String!) {
  profile(calculationHash: $calculationHash) {
    sectors
    regions
    desiredNetMonthlyIncome
    profession
    dependentChildren
  }
}
    `;
export type ProfileComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProfileQuery, ProfileQueryVariables>, 'query'> & ({ variables: ProfileQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ProfileComponent = (props: ProfileComponentProps) => (
      <ApolloReactComponents.Query<ProfileQuery, ProfileQueryVariables> query={ProfileDocument} {...props} />
    );
    
export type ProfileProps<TChildProps = {}> = ApolloReactHoc.DataProps<ProfileQuery, ProfileQueryVariables> & TChildProps;
export function withProfile<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ProfileQuery,
  ProfileQueryVariables,
  ProfileProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ProfileQuery, ProfileQueryVariables, ProfileProps<TChildProps>>(ProfileDocument, {
      alias: 'profile',
      ...operationOptions
    });
};

    export function useProfileQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProfileQuery, ProfileQueryVariables>) {
      return ApolloReactHooks.useQuery<ProfileQuery, ProfileQueryVariables>(ProfileDocument, baseOptions);
    }
      export function useProfileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProfileQuery, ProfileQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<ProfileQuery, ProfileQueryVariables>(ProfileDocument, baseOptions);
      }
      
export type ProfileQueryHookResult = ReturnType<typeof useProfileQuery>;
export type ProfileQueryResult = ApolloReactCommon.QueryResult<ProfileQuery, ProfileQueryVariables>;
export const UpdateProfileDocument = gql`
    mutation updateProfile($calculationHash: String!, $profileInput: ProfileInput!) {
  updateProfile(calculationHash: $calculationHash, profileInput: $profileInput)
}
    `;
export type UpdateProfileMutationFn = ApolloReactCommon.MutationFunction<UpdateProfileMutation, UpdateProfileMutationVariables>;
export type UpdateProfileComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateProfileMutation, UpdateProfileMutationVariables>, 'mutation'>;

    export const UpdateProfileComponent = (props: UpdateProfileComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateProfileMutation, UpdateProfileMutationVariables> mutation={UpdateProfileDocument} {...props} />
    );
    
export type UpdateProfileProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateProfileMutation, UpdateProfileMutationVariables> & TChildProps;
export function withUpdateProfile<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateProfileMutation,
  UpdateProfileMutationVariables,
  UpdateProfileProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateProfileMutation, UpdateProfileMutationVariables, UpdateProfileProps<TChildProps>>(UpdateProfileDocument, {
      alias: 'updateProfile',
      ...operationOptions
    });
};

    export function useUpdateProfileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateProfileMutation, UpdateProfileMutationVariables>) {
      return ApolloReactHooks.useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(UpdateProfileDocument, baseOptions);
    }
export type UpdateProfileMutationHookResult = ReturnType<typeof useUpdateProfileMutation>;
export type UpdateProfileMutationResult = ApolloReactCommon.MutationResult<UpdateProfileMutation>;
export type UpdateProfileMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateProfileMutation, UpdateProfileMutationVariables>;
export const CalculationInfoDocument = gql`
    query CalculationInfo($calculationHash: String!) {
  calculationInfo(calculationHash: $calculationHash) {
    fintoolUser {
      email
    }
    code
    initState
    financeSeenState
    expenseSeenState
  }
}
    `;
export type CalculationInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CalculationInfoQuery, CalculationInfoQueryVariables>, 'query'> & ({ variables: CalculationInfoQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CalculationInfoComponent = (props: CalculationInfoComponentProps) => (
      <ApolloReactComponents.Query<CalculationInfoQuery, CalculationInfoQueryVariables> query={CalculationInfoDocument} {...props} />
    );
    
export type CalculationInfoProps<TChildProps = {}> = ApolloReactHoc.DataProps<CalculationInfoQuery, CalculationInfoQueryVariables> & TChildProps;
export function withCalculationInfo<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CalculationInfoQuery,
  CalculationInfoQueryVariables,
  CalculationInfoProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, CalculationInfoQuery, CalculationInfoQueryVariables, CalculationInfoProps<TChildProps>>(CalculationInfoDocument, {
      alias: 'calculationInfo',
      ...operationOptions
    });
};

    export function useCalculationInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CalculationInfoQuery, CalculationInfoQueryVariables>) {
      return ApolloReactHooks.useQuery<CalculationInfoQuery, CalculationInfoQueryVariables>(CalculationInfoDocument, baseOptions);
    }
      export function useCalculationInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CalculationInfoQuery, CalculationInfoQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<CalculationInfoQuery, CalculationInfoQueryVariables>(CalculationInfoDocument, baseOptions);
      }
      
export type CalculationInfoQueryHookResult = ReturnType<typeof useCalculationInfoQuery>;
export type CalculationInfoQueryResult = ApolloReactCommon.QueryResult<CalculationInfoQuery, CalculationInfoQueryVariables>;
export const UpdateInitStateDocument = gql`
    mutation UpdateInitState($calculationHash: String!, $newState: Boolean!) {
  updateInitState(calculationHash: $calculationHash, newState: $newState) {
    initState
  }
}
    `;
export type UpdateInitStateMutationFn = ApolloReactCommon.MutationFunction<UpdateInitStateMutation, UpdateInitStateMutationVariables>;
export type UpdateInitStateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateInitStateMutation, UpdateInitStateMutationVariables>, 'mutation'>;

    export const UpdateInitStateComponent = (props: UpdateInitStateComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateInitStateMutation, UpdateInitStateMutationVariables> mutation={UpdateInitStateDocument} {...props} />
    );
    
export type UpdateInitStateProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateInitStateMutation, UpdateInitStateMutationVariables> & TChildProps;
export function withUpdateInitState<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateInitStateMutation,
  UpdateInitStateMutationVariables,
  UpdateInitStateProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateInitStateMutation, UpdateInitStateMutationVariables, UpdateInitStateProps<TChildProps>>(UpdateInitStateDocument, {
      alias: 'updateInitState',
      ...operationOptions
    });
};

    export function useUpdateInitStateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateInitStateMutation, UpdateInitStateMutationVariables>) {
      return ApolloReactHooks.useMutation<UpdateInitStateMutation, UpdateInitStateMutationVariables>(UpdateInitStateDocument, baseOptions);
    }
export type UpdateInitStateMutationHookResult = ReturnType<typeof useUpdateInitStateMutation>;
export type UpdateInitStateMutationResult = ApolloReactCommon.MutationResult<UpdateInitStateMutation>;
export type UpdateInitStateMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateInitStateMutation, UpdateInitStateMutationVariables>;
export const UpdateFinanceSeenStateDocument = gql`
    mutation UpdateFinanceSeenState($calculationHash: String!, $newState: Boolean!) {
  updateFinanceSeenState(calculationHash: $calculationHash, newState: $newState) {
    financeSeenState
  }
}
    `;
export type UpdateFinanceSeenStateMutationFn = ApolloReactCommon.MutationFunction<UpdateFinanceSeenStateMutation, UpdateFinanceSeenStateMutationVariables>;
export type UpdateFinanceSeenStateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateFinanceSeenStateMutation, UpdateFinanceSeenStateMutationVariables>, 'mutation'>;

    export const UpdateFinanceSeenStateComponent = (props: UpdateFinanceSeenStateComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateFinanceSeenStateMutation, UpdateFinanceSeenStateMutationVariables> mutation={UpdateFinanceSeenStateDocument} {...props} />
    );
    
export type UpdateFinanceSeenStateProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateFinanceSeenStateMutation, UpdateFinanceSeenStateMutationVariables> & TChildProps;
export function withUpdateFinanceSeenState<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateFinanceSeenStateMutation,
  UpdateFinanceSeenStateMutationVariables,
  UpdateFinanceSeenStateProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateFinanceSeenStateMutation, UpdateFinanceSeenStateMutationVariables, UpdateFinanceSeenStateProps<TChildProps>>(UpdateFinanceSeenStateDocument, {
      alias: 'updateFinanceSeenState',
      ...operationOptions
    });
};

    export function useUpdateFinanceSeenStateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateFinanceSeenStateMutation, UpdateFinanceSeenStateMutationVariables>) {
      return ApolloReactHooks.useMutation<UpdateFinanceSeenStateMutation, UpdateFinanceSeenStateMutationVariables>(UpdateFinanceSeenStateDocument, baseOptions);
    }
export type UpdateFinanceSeenStateMutationHookResult = ReturnType<typeof useUpdateFinanceSeenStateMutation>;
export type UpdateFinanceSeenStateMutationResult = ApolloReactCommon.MutationResult<UpdateFinanceSeenStateMutation>;
export type UpdateFinanceSeenStateMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateFinanceSeenStateMutation, UpdateFinanceSeenStateMutationVariables>;
export const UpdateExpenseSeenStateDocument = gql`
    mutation UpdateExpenseSeenState($calculationHash: String!, $newState: Boolean!) {
  updateExpenseSeenState(calculationHash: $calculationHash, newState: $newState) {
    expenseSeenState
  }
}
    `;
export type UpdateExpenseSeenStateMutationFn = ApolloReactCommon.MutationFunction<UpdateExpenseSeenStateMutation, UpdateExpenseSeenStateMutationVariables>;
export type UpdateExpenseSeenStateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateExpenseSeenStateMutation, UpdateExpenseSeenStateMutationVariables>, 'mutation'>;

    export const UpdateExpenseSeenStateComponent = (props: UpdateExpenseSeenStateComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateExpenseSeenStateMutation, UpdateExpenseSeenStateMutationVariables> mutation={UpdateExpenseSeenStateDocument} {...props} />
    );
    
export type UpdateExpenseSeenStateProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateExpenseSeenStateMutation, UpdateExpenseSeenStateMutationVariables> & TChildProps;
export function withUpdateExpenseSeenState<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateExpenseSeenStateMutation,
  UpdateExpenseSeenStateMutationVariables,
  UpdateExpenseSeenStateProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateExpenseSeenStateMutation, UpdateExpenseSeenStateMutationVariables, UpdateExpenseSeenStateProps<TChildProps>>(UpdateExpenseSeenStateDocument, {
      alias: 'updateExpenseSeenState',
      ...operationOptions
    });
};

    export function useUpdateExpenseSeenStateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateExpenseSeenStateMutation, UpdateExpenseSeenStateMutationVariables>) {
      return ApolloReactHooks.useMutation<UpdateExpenseSeenStateMutation, UpdateExpenseSeenStateMutationVariables>(UpdateExpenseSeenStateDocument, baseOptions);
    }
export type UpdateExpenseSeenStateMutationHookResult = ReturnType<typeof useUpdateExpenseSeenStateMutation>;
export type UpdateExpenseSeenStateMutationResult = ApolloReactCommon.MutationResult<UpdateExpenseSeenStateMutation>;
export type UpdateExpenseSeenStateMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateExpenseSeenStateMutation, UpdateExpenseSeenStateMutationVariables>;
export const SendEmailDocument = gql`
    mutation SendEmail($calculationHash: String!, $email: String!) {
  sendEmail(calculationHash: $calculationHash, emailInput: {email: $email})
}
    `;
export type SendEmailMutationFn = ApolloReactCommon.MutationFunction<SendEmailMutation, SendEmailMutationVariables>;
export type SendEmailComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SendEmailMutation, SendEmailMutationVariables>, 'mutation'>;

    export const SendEmailComponent = (props: SendEmailComponentProps) => (
      <ApolloReactComponents.Mutation<SendEmailMutation, SendEmailMutationVariables> mutation={SendEmailDocument} {...props} />
    );
    
export type SendEmailProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SendEmailMutation, SendEmailMutationVariables> & TChildProps;
export function withSendEmail<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SendEmailMutation,
  SendEmailMutationVariables,
  SendEmailProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SendEmailMutation, SendEmailMutationVariables, SendEmailProps<TChildProps>>(SendEmailDocument, {
      alias: 'sendEmail',
      ...operationOptions
    });
};

    export function useSendEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendEmailMutation, SendEmailMutationVariables>) {
      return ApolloReactHooks.useMutation<SendEmailMutation, SendEmailMutationVariables>(SendEmailDocument, baseOptions);
    }
export type SendEmailMutationHookResult = ReturnType<typeof useSendEmailMutation>;
export type SendEmailMutationResult = ApolloReactCommon.MutationResult<SendEmailMutation>;
export type SendEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<SendEmailMutation, SendEmailMutationVariables>;
export const SaveEmailDocument = gql`
    mutation SaveEmail($calculationHash: String!, $email: String!) {
  saveEmail(calculationHash: $calculationHash, emailInput: {email: $email})
}
    `;
export type SaveEmailMutationFn = ApolloReactCommon.MutationFunction<SaveEmailMutation, SaveEmailMutationVariables>;
export type SaveEmailComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SaveEmailMutation, SaveEmailMutationVariables>, 'mutation'>;

    export const SaveEmailComponent = (props: SaveEmailComponentProps) => (
      <ApolloReactComponents.Mutation<SaveEmailMutation, SaveEmailMutationVariables> mutation={SaveEmailDocument} {...props} />
    );
    
export type SaveEmailProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SaveEmailMutation, SaveEmailMutationVariables> & TChildProps;
export function withSaveEmail<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SaveEmailMutation,
  SaveEmailMutationVariables,
  SaveEmailProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SaveEmailMutation, SaveEmailMutationVariables, SaveEmailProps<TChildProps>>(SaveEmailDocument, {
      alias: 'saveEmail',
      ...operationOptions
    });
};

    export function useSaveEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SaveEmailMutation, SaveEmailMutationVariables>) {
      return ApolloReactHooks.useMutation<SaveEmailMutation, SaveEmailMutationVariables>(SaveEmailDocument, baseOptions);
    }
export type SaveEmailMutationHookResult = ReturnType<typeof useSaveEmailMutation>;
export type SaveEmailMutationResult = ApolloReactCommon.MutationResult<SaveEmailMutation>;
export type SaveEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<SaveEmailMutation, SaveEmailMutationVariables>;
export const ExpensesAsPriceableItemsDocument = gql`
    query ExpensesAsPriceableItems($calculationHash: String!) {
  expense(calculationHash: $calculationHash) {
    allIncludedExpenses {
      id
      description
      calculatedCashFlowValue
      calculateNetValue
    }
  }
}
    `;
export type ExpensesAsPriceableItemsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ExpensesAsPriceableItemsQuery, ExpensesAsPriceableItemsQueryVariables>, 'query'> & ({ variables: ExpensesAsPriceableItemsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ExpensesAsPriceableItemsComponent = (props: ExpensesAsPriceableItemsComponentProps) => (
      <ApolloReactComponents.Query<ExpensesAsPriceableItemsQuery, ExpensesAsPriceableItemsQueryVariables> query={ExpensesAsPriceableItemsDocument} {...props} />
    );
    
export type ExpensesAsPriceableItemsProps<TChildProps = {}> = ApolloReactHoc.DataProps<ExpensesAsPriceableItemsQuery, ExpensesAsPriceableItemsQueryVariables> & TChildProps;
export function withExpensesAsPriceableItems<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ExpensesAsPriceableItemsQuery,
  ExpensesAsPriceableItemsQueryVariables,
  ExpensesAsPriceableItemsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ExpensesAsPriceableItemsQuery, ExpensesAsPriceableItemsQueryVariables, ExpensesAsPriceableItemsProps<TChildProps>>(ExpensesAsPriceableItemsDocument, {
      alias: 'expensesAsPriceableItems',
      ...operationOptions
    });
};

    export function useExpensesAsPriceableItemsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ExpensesAsPriceableItemsQuery, ExpensesAsPriceableItemsQueryVariables>) {
      return ApolloReactHooks.useQuery<ExpensesAsPriceableItemsQuery, ExpensesAsPriceableItemsQueryVariables>(ExpensesAsPriceableItemsDocument, baseOptions);
    }
      export function useExpensesAsPriceableItemsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ExpensesAsPriceableItemsQuery, ExpensesAsPriceableItemsQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<ExpensesAsPriceableItemsQuery, ExpensesAsPriceableItemsQueryVariables>(ExpensesAsPriceableItemsDocument, baseOptions);
      }
      
export type ExpensesAsPriceableItemsQueryHookResult = ReturnType<typeof useExpensesAsPriceableItemsQuery>;
export type ExpensesAsPriceableItemsQueryResult = ApolloReactCommon.QueryResult<ExpensesAsPriceableItemsQuery, ExpensesAsPriceableItemsQueryVariables>;
export const AddOtherRevenueDocument = gql`
    mutation AddOtherRevenue($calculationHash: String!) {
  addOtherRevenue(calculationHash: $calculationHash) {
    id
  }
}
    `;
export type AddOtherRevenueMutationFn = ApolloReactCommon.MutationFunction<AddOtherRevenueMutation, AddOtherRevenueMutationVariables>;
export type AddOtherRevenueComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddOtherRevenueMutation, AddOtherRevenueMutationVariables>, 'mutation'>;

    export const AddOtherRevenueComponent = (props: AddOtherRevenueComponentProps) => (
      <ApolloReactComponents.Mutation<AddOtherRevenueMutation, AddOtherRevenueMutationVariables> mutation={AddOtherRevenueDocument} {...props} />
    );
    
export type AddOtherRevenueProps<TChildProps = {}> = ApolloReactHoc.MutateProps<AddOtherRevenueMutation, AddOtherRevenueMutationVariables> & TChildProps;
export function withAddOtherRevenue<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AddOtherRevenueMutation,
  AddOtherRevenueMutationVariables,
  AddOtherRevenueProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, AddOtherRevenueMutation, AddOtherRevenueMutationVariables, AddOtherRevenueProps<TChildProps>>(AddOtherRevenueDocument, {
      alias: 'addOtherRevenue',
      ...operationOptions
    });
};

    export function useAddOtherRevenueMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddOtherRevenueMutation, AddOtherRevenueMutationVariables>) {
      return ApolloReactHooks.useMutation<AddOtherRevenueMutation, AddOtherRevenueMutationVariables>(AddOtherRevenueDocument, baseOptions);
    }
export type AddOtherRevenueMutationHookResult = ReturnType<typeof useAddOtherRevenueMutation>;
export type AddOtherRevenueMutationResult = ApolloReactCommon.MutationResult<AddOtherRevenueMutation>;
export type AddOtherRevenueMutationOptions = ApolloReactCommon.BaseMutationOptions<AddOtherRevenueMutation, AddOtherRevenueMutationVariables>;
export const UpdateOtherRevenueDocument = gql`
    mutation UpdateOtherRevenue($calculationHash: String!, $otherRevenueInput: OtherRevenueInput!) {
  updateOtherRevenue(calculationHash: $calculationHash, otherRevenueInput: $otherRevenueInput) {
    id
  }
}
    `;
export type UpdateOtherRevenueMutationFn = ApolloReactCommon.MutationFunction<UpdateOtherRevenueMutation, UpdateOtherRevenueMutationVariables>;
export type UpdateOtherRevenueComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateOtherRevenueMutation, UpdateOtherRevenueMutationVariables>, 'mutation'>;

    export const UpdateOtherRevenueComponent = (props: UpdateOtherRevenueComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateOtherRevenueMutation, UpdateOtherRevenueMutationVariables> mutation={UpdateOtherRevenueDocument} {...props} />
    );
    
export type UpdateOtherRevenueProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateOtherRevenueMutation, UpdateOtherRevenueMutationVariables> & TChildProps;
export function withUpdateOtherRevenue<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateOtherRevenueMutation,
  UpdateOtherRevenueMutationVariables,
  UpdateOtherRevenueProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateOtherRevenueMutation, UpdateOtherRevenueMutationVariables, UpdateOtherRevenueProps<TChildProps>>(UpdateOtherRevenueDocument, {
      alias: 'updateOtherRevenue',
      ...operationOptions
    });
};

    export function useUpdateOtherRevenueMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOtherRevenueMutation, UpdateOtherRevenueMutationVariables>) {
      return ApolloReactHooks.useMutation<UpdateOtherRevenueMutation, UpdateOtherRevenueMutationVariables>(UpdateOtherRevenueDocument, baseOptions);
    }
export type UpdateOtherRevenueMutationHookResult = ReturnType<typeof useUpdateOtherRevenueMutation>;
export type UpdateOtherRevenueMutationResult = ApolloReactCommon.MutationResult<UpdateOtherRevenueMutation>;
export type UpdateOtherRevenueMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateOtherRevenueMutation, UpdateOtherRevenueMutationVariables>;
export const RemoveOtherRevenueDocument = gql`
    mutation RemoveOtherRevenue($calculationHash: String!, $otherRevenueId: String!) {
  removeOtherRevenue(calculationHash: $calculationHash, otherRevenueId: $otherRevenueId)
}
    `;
export type RemoveOtherRevenueMutationFn = ApolloReactCommon.MutationFunction<RemoveOtherRevenueMutation, RemoveOtherRevenueMutationVariables>;
export type RemoveOtherRevenueComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveOtherRevenueMutation, RemoveOtherRevenueMutationVariables>, 'mutation'>;

    export const RemoveOtherRevenueComponent = (props: RemoveOtherRevenueComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveOtherRevenueMutation, RemoveOtherRevenueMutationVariables> mutation={RemoveOtherRevenueDocument} {...props} />
    );
    
export type RemoveOtherRevenueProps<TChildProps = {}> = ApolloReactHoc.MutateProps<RemoveOtherRevenueMutation, RemoveOtherRevenueMutationVariables> & TChildProps;
export function withRemoveOtherRevenue<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RemoveOtherRevenueMutation,
  RemoveOtherRevenueMutationVariables,
  RemoveOtherRevenueProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, RemoveOtherRevenueMutation, RemoveOtherRevenueMutationVariables, RemoveOtherRevenueProps<TChildProps>>(RemoveOtherRevenueDocument, {
      alias: 'removeOtherRevenue',
      ...operationOptions
    });
};

    export function useRemoveOtherRevenueMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveOtherRevenueMutation, RemoveOtherRevenueMutationVariables>) {
      return ApolloReactHooks.useMutation<RemoveOtherRevenueMutation, RemoveOtherRevenueMutationVariables>(RemoveOtherRevenueDocument, baseOptions);
    }
export type RemoveOtherRevenueMutationHookResult = ReturnType<typeof useRemoveOtherRevenueMutation>;
export type RemoveOtherRevenueMutationResult = ApolloReactCommon.MutationResult<RemoveOtherRevenueMutation>;
export type RemoveOtherRevenueMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveOtherRevenueMutation, RemoveOtherRevenueMutationVariables>;
export const AddProductDocument = gql`
    mutation AddProduct($calculationHash: String!, $productInput: ProductInput!) {
  addProduct(calculationHash: $calculationHash, productInput: $productInput) {
    id
  }
}
    `;
export type AddProductMutationFn = ApolloReactCommon.MutationFunction<AddProductMutation, AddProductMutationVariables>;
export type AddProductComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddProductMutation, AddProductMutationVariables>, 'mutation'>;

    export const AddProductComponent = (props: AddProductComponentProps) => (
      <ApolloReactComponents.Mutation<AddProductMutation, AddProductMutationVariables> mutation={AddProductDocument} {...props} />
    );
    
export type AddProductProps<TChildProps = {}> = ApolloReactHoc.MutateProps<AddProductMutation, AddProductMutationVariables> & TChildProps;
export function withAddProduct<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AddProductMutation,
  AddProductMutationVariables,
  AddProductProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, AddProductMutation, AddProductMutationVariables, AddProductProps<TChildProps>>(AddProductDocument, {
      alias: 'addProduct',
      ...operationOptions
    });
};

    export function useAddProductMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddProductMutation, AddProductMutationVariables>) {
      return ApolloReactHooks.useMutation<AddProductMutation, AddProductMutationVariables>(AddProductDocument, baseOptions);
    }
export type AddProductMutationHookResult = ReturnType<typeof useAddProductMutation>;
export type AddProductMutationResult = ApolloReactCommon.MutationResult<AddProductMutation>;
export type AddProductMutationOptions = ApolloReactCommon.BaseMutationOptions<AddProductMutation, AddProductMutationVariables>;
export const UpdateProductDocument = gql`
    mutation UpdateProduct($calculationHash: String!, $productInput: ProductInput!) {
  updateProduct(calculationHash: $calculationHash, productInput: $productInput) {
    id
  }
}
    `;
export type UpdateProductMutationFn = ApolloReactCommon.MutationFunction<UpdateProductMutation, UpdateProductMutationVariables>;
export type UpdateProductComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateProductMutation, UpdateProductMutationVariables>, 'mutation'>;

    export const UpdateProductComponent = (props: UpdateProductComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateProductMutation, UpdateProductMutationVariables> mutation={UpdateProductDocument} {...props} />
    );
    
export type UpdateProductProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateProductMutation, UpdateProductMutationVariables> & TChildProps;
export function withUpdateProduct<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateProductMutation,
  UpdateProductMutationVariables,
  UpdateProductProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateProductMutation, UpdateProductMutationVariables, UpdateProductProps<TChildProps>>(UpdateProductDocument, {
      alias: 'updateProduct',
      ...operationOptions
    });
};

    export function useUpdateProductMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateProductMutation, UpdateProductMutationVariables>) {
      return ApolloReactHooks.useMutation<UpdateProductMutation, UpdateProductMutationVariables>(UpdateProductDocument, baseOptions);
    }
export type UpdateProductMutationHookResult = ReturnType<typeof useUpdateProductMutation>;
export type UpdateProductMutationResult = ApolloReactCommon.MutationResult<UpdateProductMutation>;
export type UpdateProductMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateProductMutation, UpdateProductMutationVariables>;
export const RemoveProductDocument = gql`
    mutation RemoveProduct($calculationHash: String!, $productId: String!) {
  removeProduct(calculationHash: $calculationHash, productId: $productId)
}
    `;
export type RemoveProductMutationFn = ApolloReactCommon.MutationFunction<RemoveProductMutation, RemoveProductMutationVariables>;
export type RemoveProductComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveProductMutation, RemoveProductMutationVariables>, 'mutation'>;

    export const RemoveProductComponent = (props: RemoveProductComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveProductMutation, RemoveProductMutationVariables> mutation={RemoveProductDocument} {...props} />
    );
    
export type RemoveProductProps<TChildProps = {}> = ApolloReactHoc.MutateProps<RemoveProductMutation, RemoveProductMutationVariables> & TChildProps;
export function withRemoveProduct<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RemoveProductMutation,
  RemoveProductMutationVariables,
  RemoveProductProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, RemoveProductMutation, RemoveProductMutationVariables, RemoveProductProps<TChildProps>>(RemoveProductDocument, {
      alias: 'removeProduct',
      ...operationOptions
    });
};

    export function useRemoveProductMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveProductMutation, RemoveProductMutationVariables>) {
      return ApolloReactHooks.useMutation<RemoveProductMutation, RemoveProductMutationVariables>(RemoveProductDocument, baseOptions);
    }
export type RemoveProductMutationHookResult = ReturnType<typeof useRemoveProductMutation>;
export type RemoveProductMutationResult = ApolloReactCommon.MutationResult<RemoveProductMutation>;
export type RemoveProductMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveProductMutation, RemoveProductMutationVariables>;
export const RevenueDocument = gql`
    query Revenue($calculationHash: String!) {
  revenue(calculationHash: $calculationHash) {
    serviceRevenues {
      revenues {
        id
        description
        quantity
        unitPrice
        dateCreated
        includeInCalculation
        calculatedCashFlowValue
      }
      totalPrice
    }
    products {
      revenues {
        id
        description
        quantity
        unitPrice
        dateCreated
        includeInCalculation
        calculatedCashFlowValue
      }
      totalPrice
    }
    otherRevenues {
      revenues {
        id
        description
        price
        dateCreated
        includeInCalculation
      }
      totalPrice
    }
  }
}
    `;
export type RevenueComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RevenueQuery, RevenueQueryVariables>, 'query'> & ({ variables: RevenueQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RevenueComponent = (props: RevenueComponentProps) => (
      <ApolloReactComponents.Query<RevenueQuery, RevenueQueryVariables> query={RevenueDocument} {...props} />
    );
    
export type RevenueProps<TChildProps = {}> = ApolloReactHoc.DataProps<RevenueQuery, RevenueQueryVariables> & TChildProps;
export function withRevenue<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RevenueQuery,
  RevenueQueryVariables,
  RevenueProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, RevenueQuery, RevenueQueryVariables, RevenueProps<TChildProps>>(RevenueDocument, {
      alias: 'revenue',
      ...operationOptions
    });
};

    export function useRevenueQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RevenueQuery, RevenueQueryVariables>) {
      return ApolloReactHooks.useQuery<RevenueQuery, RevenueQueryVariables>(RevenueDocument, baseOptions);
    }
      export function useRevenueLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RevenueQuery, RevenueQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<RevenueQuery, RevenueQueryVariables>(RevenueDocument, baseOptions);
      }
      
export type RevenueQueryHookResult = ReturnType<typeof useRevenueQuery>;
export type RevenueQueryResult = ApolloReactCommon.QueryResult<RevenueQuery, RevenueQueryVariables>;
export const AddServiceRevenueDocument = gql`
    mutation AddServiceRevenue($calculationHash: String!, $serviceRevenueInput: ServiceRevenueInput!) {
  addServiceRevenue(calculationHash: $calculationHash, serviceRevenueInput: $serviceRevenueInput) {
    id
  }
}
    `;
export type AddServiceRevenueMutationFn = ApolloReactCommon.MutationFunction<AddServiceRevenueMutation, AddServiceRevenueMutationVariables>;
export type AddServiceRevenueComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddServiceRevenueMutation, AddServiceRevenueMutationVariables>, 'mutation'>;

    export const AddServiceRevenueComponent = (props: AddServiceRevenueComponentProps) => (
      <ApolloReactComponents.Mutation<AddServiceRevenueMutation, AddServiceRevenueMutationVariables> mutation={AddServiceRevenueDocument} {...props} />
    );
    
export type AddServiceRevenueProps<TChildProps = {}> = ApolloReactHoc.MutateProps<AddServiceRevenueMutation, AddServiceRevenueMutationVariables> & TChildProps;
export function withAddServiceRevenue<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AddServiceRevenueMutation,
  AddServiceRevenueMutationVariables,
  AddServiceRevenueProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, AddServiceRevenueMutation, AddServiceRevenueMutationVariables, AddServiceRevenueProps<TChildProps>>(AddServiceRevenueDocument, {
      alias: 'addServiceRevenue',
      ...operationOptions
    });
};

    export function useAddServiceRevenueMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddServiceRevenueMutation, AddServiceRevenueMutationVariables>) {
      return ApolloReactHooks.useMutation<AddServiceRevenueMutation, AddServiceRevenueMutationVariables>(AddServiceRevenueDocument, baseOptions);
    }
export type AddServiceRevenueMutationHookResult = ReturnType<typeof useAddServiceRevenueMutation>;
export type AddServiceRevenueMutationResult = ApolloReactCommon.MutationResult<AddServiceRevenueMutation>;
export type AddServiceRevenueMutationOptions = ApolloReactCommon.BaseMutationOptions<AddServiceRevenueMutation, AddServiceRevenueMutationVariables>;
export const UpdateServiceRevenueDocument = gql`
    mutation UpdateServiceRevenue($calculationHash: String!, $serviceRevenueInput: ServiceRevenueInput!) {
  updateServiceRevenue(calculationHash: $calculationHash, serviceRevenueInput: $serviceRevenueInput) {
    id
  }
}
    `;
export type UpdateServiceRevenueMutationFn = ApolloReactCommon.MutationFunction<UpdateServiceRevenueMutation, UpdateServiceRevenueMutationVariables>;
export type UpdateServiceRevenueComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateServiceRevenueMutation, UpdateServiceRevenueMutationVariables>, 'mutation'>;

    export const UpdateServiceRevenueComponent = (props: UpdateServiceRevenueComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateServiceRevenueMutation, UpdateServiceRevenueMutationVariables> mutation={UpdateServiceRevenueDocument} {...props} />
    );
    
export type UpdateServiceRevenueProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateServiceRevenueMutation, UpdateServiceRevenueMutationVariables> & TChildProps;
export function withUpdateServiceRevenue<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateServiceRevenueMutation,
  UpdateServiceRevenueMutationVariables,
  UpdateServiceRevenueProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateServiceRevenueMutation, UpdateServiceRevenueMutationVariables, UpdateServiceRevenueProps<TChildProps>>(UpdateServiceRevenueDocument, {
      alias: 'updateServiceRevenue',
      ...operationOptions
    });
};

    export function useUpdateServiceRevenueMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateServiceRevenueMutation, UpdateServiceRevenueMutationVariables>) {
      return ApolloReactHooks.useMutation<UpdateServiceRevenueMutation, UpdateServiceRevenueMutationVariables>(UpdateServiceRevenueDocument, baseOptions);
    }
export type UpdateServiceRevenueMutationHookResult = ReturnType<typeof useUpdateServiceRevenueMutation>;
export type UpdateServiceRevenueMutationResult = ApolloReactCommon.MutationResult<UpdateServiceRevenueMutation>;
export type UpdateServiceRevenueMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateServiceRevenueMutation, UpdateServiceRevenueMutationVariables>;
export const RemoveServiceRevenueDocument = gql`
    mutation RemoveServiceRevenue($calculationHash: String!, $serviceRevenueId: String!) {
  removeServiceRevenue(calculationHash: $calculationHash, serviceRevenueId: $serviceRevenueId)
}
    `;
export type RemoveServiceRevenueMutationFn = ApolloReactCommon.MutationFunction<RemoveServiceRevenueMutation, RemoveServiceRevenueMutationVariables>;
export type RemoveServiceRevenueComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveServiceRevenueMutation, RemoveServiceRevenueMutationVariables>, 'mutation'>;

    export const RemoveServiceRevenueComponent = (props: RemoveServiceRevenueComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveServiceRevenueMutation, RemoveServiceRevenueMutationVariables> mutation={RemoveServiceRevenueDocument} {...props} />
    );
    
export type RemoveServiceRevenueProps<TChildProps = {}> = ApolloReactHoc.MutateProps<RemoveServiceRevenueMutation, RemoveServiceRevenueMutationVariables> & TChildProps;
export function withRemoveServiceRevenue<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RemoveServiceRevenueMutation,
  RemoveServiceRevenueMutationVariables,
  RemoveServiceRevenueProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, RemoveServiceRevenueMutation, RemoveServiceRevenueMutationVariables, RemoveServiceRevenueProps<TChildProps>>(RemoveServiceRevenueDocument, {
      alias: 'removeServiceRevenue',
      ...operationOptions
    });
};

    export function useRemoveServiceRevenueMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveServiceRevenueMutation, RemoveServiceRevenueMutationVariables>) {
      return ApolloReactHooks.useMutation<RemoveServiceRevenueMutation, RemoveServiceRevenueMutationVariables>(RemoveServiceRevenueDocument, baseOptions);
    }
export type RemoveServiceRevenueMutationHookResult = ReturnType<typeof useRemoveServiceRevenueMutation>;
export type RemoveServiceRevenueMutationResult = ApolloReactCommon.MutationResult<RemoveServiceRevenueMutation>;
export type RemoveServiceRevenueMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveServiceRevenueMutation, RemoveServiceRevenueMutationVariables>;
export const CalculationsDocument = gql`
    query Calculations {
  calculations
}
    `;
export type CalculationsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CalculationsQuery, CalculationsQueryVariables>, 'query'>;

    export const CalculationsComponent = (props: CalculationsComponentProps) => (
      <ApolloReactComponents.Query<CalculationsQuery, CalculationsQueryVariables> query={CalculationsDocument} {...props} />
    );
    
export type CalculationsProps<TChildProps = {}> = ApolloReactHoc.DataProps<CalculationsQuery, CalculationsQueryVariables> & TChildProps;
export function withCalculations<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CalculationsQuery,
  CalculationsQueryVariables,
  CalculationsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, CalculationsQuery, CalculationsQueryVariables, CalculationsProps<TChildProps>>(CalculationsDocument, {
      alias: 'calculations',
      ...operationOptions
    });
};

    export function useCalculationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CalculationsQuery, CalculationsQueryVariables>) {
      return ApolloReactHooks.useQuery<CalculationsQuery, CalculationsQueryVariables>(CalculationsDocument, baseOptions);
    }
      export function useCalculationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CalculationsQuery, CalculationsQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<CalculationsQuery, CalculationsQueryVariables>(CalculationsDocument, baseOptions);
      }
      
export type CalculationsQueryHookResult = ReturnType<typeof useCalculationsQuery>;
export type CalculationsQueryResult = ApolloReactCommon.QueryResult<CalculationsQuery, CalculationsQueryVariables>;
export const UpdateLanguageDocument = gql`
    mutation UpdateLanguage($calculationHash: String, $lang: Lang!) {
  updateLanguage(calculationHash: $calculationHash, lang: $lang)
}
    `;
export type UpdateLanguageMutationFn = ApolloReactCommon.MutationFunction<UpdateLanguageMutation, UpdateLanguageMutationVariables>;
export type UpdateLanguageComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateLanguageMutation, UpdateLanguageMutationVariables>, 'mutation'>;

    export const UpdateLanguageComponent = (props: UpdateLanguageComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateLanguageMutation, UpdateLanguageMutationVariables> mutation={UpdateLanguageDocument} {...props} />
    );
    
export type UpdateLanguageProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateLanguageMutation, UpdateLanguageMutationVariables> & TChildProps;
export function withUpdateLanguage<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateLanguageMutation,
  UpdateLanguageMutationVariables,
  UpdateLanguageProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateLanguageMutation, UpdateLanguageMutationVariables, UpdateLanguageProps<TChildProps>>(UpdateLanguageDocument, {
      alias: 'updateLanguage',
      ...operationOptions
    });
};

    export function useUpdateLanguageMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateLanguageMutation, UpdateLanguageMutationVariables>) {
      return ApolloReactHooks.useMutation<UpdateLanguageMutation, UpdateLanguageMutationVariables>(UpdateLanguageDocument, baseOptions);
    }
export type UpdateLanguageMutationHookResult = ReturnType<typeof useUpdateLanguageMutation>;
export type UpdateLanguageMutationResult = ApolloReactCommon.MutationResult<UpdateLanguageMutation>;
export type UpdateLanguageMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateLanguageMutation, UpdateLanguageMutationVariables>;
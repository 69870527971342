import React, {useState} from 'react'
import './shadow-box.css';
import {Button, Col, Collapse, Popover, Row} from "antd";
import AcertaIcon from "../iconfont";
import Explanation from "../explanation";
import {isMobileQuery} from "../../../utils/media-queries";
import {useMediaQuery} from "react-responsive";

const {Panel} = Collapse;

interface ShadowBoxProps {
    title?: any
    children: any
    open?: boolean
    noArrow?: boolean
    style?: any
}

const customPanelStyle = {
    background: 'inherit',
    margin: 0,
    padding: 0,
    border: 0,
    overflow: 'hidden',
};

interface BoxTitleProps {
    iconClass: any
    text: string
    setBoxOpen: any
    boxOpen: boolean
    onAdd?: any
    toAdd?: any
    addOptions?: any
    subtext?: string
    explanation?: string
    noArrow?: boolean
    addText?: string
    trackOpenCloseClass: string
    trackAddClass: string
}

interface OptionsType {
    disabled?: boolean
    value: string
    text: string
    trackAddClass?: string
}

export const BoxTitle: React.FC<BoxTitleProps> = (
    {
        iconClass, text, setBoxOpen, boxOpen,
        onAdd, toAdd, addOptions, subtext,
        explanation, noArrow, addText,
        trackOpenCloseClass, trackAddClass
    }) => {

    const [addPopVisible, setAddPopVisible] = useState(false)
    const isMobile = useMediaQuery(isMobileQuery)

    const selectAddOption = (e: any, option: any) => {
        e.preventDefault()
        e.stopPropagation()
        setBoxOpen(true)
        setAddPopVisible(false)
        onAdd({...toAdd, option: option})
    }

    return (
        <div>
            <Row>
                <Col xs={isMobile ? 21 : 16}>
                    {!noArrow &&
                    <span className={trackOpenCloseClass}
                          style={{marginRight: isMobile ? 15 : 30, marginLeft: -15, cursor: 'pointer'}}
                          onClick={() => setBoxOpen(!boxOpen)}>
                    <AcertaIcon
                        trackOpenCloseClass={trackOpenCloseClass}
                        style={{marginTop: 3}}
                        icon={boxOpen ? "icon-chevron-down-16" : "icon-chevron-up-16"} />
                </span>}
                    <AcertaIcon size="22px" icon={iconClass} />
                    <span style={{paddingLeft: isMobile ? 5 : 14}}>
                        {text}<span className="subtext-and-icon">
                        {subtext && <span className="subtext">{subtext}</span>}
                        {explanation &&
                        <Explanation title={text} explanation={explanation} />}
                        </span>
                </span>
                </Col>
                {onAdd ? (addOptions ?
                    <Col xs={isMobile ? 3 : 8} style={{textAlign: 'right', paddingRight: 19}}>
                        <Popover
                            placement={"right"}
                            content={
                                <div>
                                    {addOptions.map((option: OptionsType) =>
                                        <div key={option.value}
                                             className={"add-options " + (trackAddClass || "")}
                                             style={{cursor: option.disabled ? 'not-allowed' : 'pointer'}}
                                             onClick={(e) => !option.disabled && selectAddOption(e, option.value)}
                                        >
                                            <Button type={"link"}
                                                    className={option.trackAddClass}
                                                    disabled={option.disabled}
                                            >
                                                {option.text}
                                            </Button>
                                        </div>)}
                                </div>
                            }
                            trigger={isMobile ? "click" : "hover"}
                            visible={addPopVisible}
                            onVisibleChange={() => setAddPopVisible(!addPopVisible)}
                        >
                            <Button
                                type="link"
                                className={trackAddClass}
                            >
                                {!isMobile && addText && <span className="voeg-toe-link">{addText}</span>}
                                <AcertaIcon icon={"icon-plus-circle-24"}
                                            style={{color: '#E8318A', verticalAlign: '0.1em'}} />
                            </Button>
                        </Popover>
                    </Col>
                    : <Col xs={isMobile ? 3 : 8} style={{textAlign: 'right', paddingRight: 19}}>
                        <Button
                            type="link"
                            className={trackAddClass}
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                setBoxOpen(true)
                                onAdd(toAdd)
                            }}>
                            {!isMobile && addText && <span className="voeg-toe-link">{addText}</span>}
                            <AcertaIcon icon={"icon-plus-circle-24"}
                                        style={{color: '#E8318A', verticalAlign: '0.1em'}} />
                        </Button>
                    </Col>) : <></>
                }
            </Row>
        </div>
    )
}

const ShadowBox: React.FC<ShadowBoxProps> = ({title, children, open, noArrow, style}) => {
    return (
        <div className="shadow-box" style={style}>
            <Collapse
                expandIconPosition={"left"}
                bordered={false}
                activeKey={open ? ['1'] : []}
                expandIcon={({isActive}) => <AcertaIcon style={{marginTop: 3}}
                                                        icon={isActive ? "icon-chevron-down-16" : "icon-chevron-up-16"} />}
            >
                <Panel disabled={true} showArrow={false}
                       header={<h3 className={noArrow ? 'no-arrow' : ''}>{title}</h3>} key="1" style={customPanelStyle}>
                    {children}
                </Panel>
            </Collapse>
        </div>

    )
}

interface EmptyShadowBoxProps {
    children: any
    style?: any
}

export const EmptyShadowBox: React.FC<EmptyShadowBoxProps> = ({children, style}) => {
    return (
        <div style={style} className="shadow-box">{children}</div>
    )
}

export default ShadowBox
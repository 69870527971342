import { useMediaQuery } from 'react-responsive'

export const Desktop = ({ children }: any) => {
    const isIt = useMediaQuery({ minWidth: 992 })
    return isIt ? children : null
}
export const LargeDesktop = ({ children }: any) => {
    const isDesktop = useMediaQuery({ minWidth: 1201 })
    return isDesktop ? children : null
}
export const Tablet = ({ children }: any) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
    return isTablet ? children : null
}
export const Mobile = ({ children }: any) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    return isMobile ? children : null
}

export const TabletOrMobile = ({ children }: any) => {
    const isIt = useMediaQuery({ maxWidth: 991 })
    return isIt ? children : null
}

export const TabletOrMobileOrSmallDesktop = ({ children }: any) => {
    const isIt = useMediaQuery({ maxWidth: 1200 })
    return isIt ? children : null
}

export const TabletOrSmallDesktop = ({ children }: any) => {
    const isIt = useMediaQuery({minWidth: 768, maxWidth: 1200 })
    return isIt ? children : null
}

export const Default = ({ children }: any) => {
    const isNotMobile = useMediaQuery({ minWidth: 768 })
    return isNotMobile ? children : null
}

export const isDesktopQuery = { minWidth: 992 }
export const isMobileQuery = { maxWidth: 767 }
export const isTabletQuery = { minWidth: 768, maxWidth: 991 }
export const isTabletOrMobileQuery = { maxWidth: 991 }
export const isTabletOrMobileOrSmallDesktopQuery = { maxWidth: 1200 }
import React from 'react'
import './required-icon.css'

const RequiredIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="required-icon" focusable="false">
            <svg id="required" viewBox="0 0 128 128">
                <g>
                    <path
                        d="M110.1,16.4L75.8,56.8l0.3,1l50.6-10.2v32.2l-50.9-8.9l-0.3,1l34.7,39.1l-28.3,16.5L63.7,78.2L63,78.5   l-18.5,49L17.2,111l34.1-39.8v-0.6l-50,9.2V47.6l49.3,9.9l0.3-0.6L17.2,16.7L45.5,0.5l17.8,48.7H64L82.1,0.5L110.1,16.4z"></path>
                </g>
            </svg>
        </svg>
    )
}

export default RequiredIcon
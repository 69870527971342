import gql from 'graphql-tag';

export const RESULT = gql`
    query Result($calculationHash: String!) {
        result(calculationHash: $calculationHash) {
            calculationOptions{
                pensionType
            }
            desiredYearlyNetIncome
            calculatedYearlyNetIncome
            yearlyRevenue
            yearlyExpense
            yearlyProceeds
            yearlyCost
            balanceBeforeSocialContributions
            socialContributions
            pensionSaving
            socialContributionsWithPensionSaving
            personalIncomeTax
            financed
            surplus
            shortage
        }
    }
`;
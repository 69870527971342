import React, {useContext, useState} from 'react'
import {Button, Col, Form, InputNumber, Modal, Row, Switch, Tooltip} from "antd";
import {FormComponentProps, ValidateCallback} from "antd/es/form";
import {ExpenseItemInput, Housing, HousingType} from "../../../../generated/graphql";
import {Default, isMobileQuery, Mobile} from "../../../../utils/media-queries";
import AcertaIcon from "../../../common/iconfont";
import {avoidWrongNumericInput, I18nContext} from "../../../../i18n";
import HousingCalculationModuleFetchContainer from "./housing-calculation-module";
import Explanation from "../../../common/explanation";
import {useMediaQuery} from "react-responsive";

interface FormProps extends FormComponentProps {
    housing: Housing
    onChangedField: any
    onToggleExclusion: any
    onRemove: any
}

const HousingSingleRowForm: React.FC<FormProps> = ({form, housing, onChangedField, onToggleExclusion, onRemove}) => {

    const [excluded, setExcluded] = useState(!housing.includeInCalculation)
    const [housingModuleOpen, setHousingModuleOpen] = useState(false)
    const [priceSelected, setPriceSelected] = useState(false)
    const [selectedHousingId, setSelectedHousingId] = useState<string | undefined>(undefined)
    const {translate} = useContext(I18nContext);
    const {getFieldDecorator} = form;
    const isMobile = useMediaQuery(isMobileQuery)

    const update = () => {
        form.validateFields((err: ValidateCallback<any>, values: ExpenseItemInput) => {
            if (!err) {
                onChangedField({
                    ...values,
                    id: housing.id,
                    housingType: housing.housingType,
                    housingFinancingType: housing.housingFinancingType,
                    includeInCalculation: housing.includeInCalculation
                })
            }
        })
    }

    const doUpdate = () => {
        if (form.isFieldsTouched()) {
            update()
        }
    }

    const toggleExclusion = (checked: boolean) => {
        setExcluded(!checked)
        onToggleExclusion(housing.id, checked);
    };

    const toggleField = getFieldDecorator('includeInCalculation', {})(
        <Switch className={translate("expense.housing.toggle.class")} size="small" onChange={toggleExclusion}
                checked={housing!.includeInCalculation!} />
    )

    const unitPriceField = getFieldDecorator('unitPrice', {
        initialValue: housing.unitPrice,
    })(
        <InputNumber disabled={!housing.includeInCalculation} onPressEnter={doUpdate}
                     onBlur={() => {
                         doUpdate();
                         setPriceSelected(false)
                     }}
                     decimalSeparator={','}
                     onKeyDown={(e) => avoidWrongNumericInput(e)}
                     className={
                         "input-currency"
                         + (!housing.includeInCalculation ? '-disabled item-excluded' : '')}
                     onFocus={(e) => {
                         setPriceSelected(true)
                         !isMobile && e.target.select()
                     }}
        />
    )

    const deleteButton = <Button className={translate("expense.housing.delete.class")} style={{marginRight: isMobile ? 22 : 19}} type={"link"}
                                 onClick={() => onRemove(housing.id)}>
        <AcertaIcon icon={"icon-trash-24"} size="18px" />
    </Button>

    return (
        <>
            <Form id={housing.id}>
                <Default>
                    <Row type="flex" align="middle" justify="space-between" gutter={16}
                         className="single-row-form-row">
                        <Col xs={2}>
                            {toggleField}
                        </Col>
                        <Col xs={12}>
                                <span className={excluded ? 'item-excluded' : ''}>
                                    {translate("expenses.housing.type." + housing.housingType)}&nbsp;-&nbsp;
                                    <span style={{color: '#8f8f8f',
                                        fontStyle: "italic",}}
                                          className={excluded ? 'item-excluded' : ''}>{translate("expenses.housing.financing.type." + housing.housingFinancingType)}</span>
                                    {translate("expense.type.help." + housing.housingType) && translate("expense.type.help." + housing.housingType).lenght !== "" &&
                                    <Explanation
                                        title={translate("expense.type." + housing.housingType)}
                                        explanation={translate("expense.type.help." + housing.housingType, true)} />}
                            </span>
                        </Col>
                        <Col xs={8} style={{textAlign: 'right'}}>
                            {(housing.housingType === HousingType.EigenWoning ||
                                housing.housingType === HousingType.Beroepsruimte) ?
                                <>
                                    <Button type={"default"} disabled={excluded}
                                            className={translate("expense.housing.calculator.button.class") + " calculator-button" + (excluded ? "-excluded" : "")}
                                            onClick={() => {
                                                setHousingModuleOpen(true)
                                                setSelectedHousingId(housing.id)
                                            }}>
                                        <AcertaIcon
                                            style={{
                                                verticalAlign: '-0.15em',
                                                color: !excluded ? '#fff' : '#a8a8a8'
                                            }}
                                            icon={"icon-calculator-24"}
                                            size="19px" />
                                    </Button>
                                    <Tooltip title={translate("common.totaal.rekenmodule") + " / " + translate("common.ex.btw")}>
                                        <InputNumber
                                            disabled={true}
                                            decimalSeparator={','}
                                            className={"input-currency-disabled" + (excluded ? ' item-excluded' : '')}
                                            value={housing.total}
                                        />
                                    </Tooltip>
                                </>
                                :
                                <>
                                    <span style={{color: '#999', marginRight: 8}}>{translate("common.prijs")}</span>
                                    <Tooltip title={translate("common.by.year") + " / " + translate("common.ex.btw")}>
                                        {unitPriceField}
                                    </Tooltip>
                                </>}
                        </Col>
                        <Col xs={2} style={{textAlign: 'right'}}>
                            {deleteButton}
                        </Col>
                    </Row>
                </Default>
                <Mobile>
                    <Row type="flex" align="middle">
                        <Col xs={3}>
                            {toggleField}
                        </Col>
                        <Col xs={18}>
                            <span className={excluded ? 'item-excluded' : ''}>
                                    {translate("expenses.housing.type." + housing.housingType)}&nbsp;-&nbsp;
                                <span style={{color: '#8f8f8f',
                                    fontStyle: "italic",}}
                                      className={excluded ? 'item-excluded' : ''}>{translate("expenses.housing.financing.type." + housing.housingFinancingType)}</span>
                                {translate("expense.type.help." + housing.housingType) && translate("expense.type.help." + housing.housingType).lenght !== "" &&
                                <Explanation
                                    title={translate("expense.type." + housing.housingType)}
                                    explanation={translate("expense.type.help." + housing.housingType, true)} />}
                            </span>

                        </Col>
                        <Col xs={3} style={{textAlign: 'right'}}>
                            {deleteButton}
                        </Col>
                    </Row>
                    <Row type="flex" align="middle"
                         style={{paddingBottom: 18, marginTop: 0, marginBottom: 4, borderBottom: '1px solid #eee'}}>
                        <Col xs={3}>
                        </Col>
                        <Col xs={18} style={{textAlign: 'right'}}>
                            {(housing.housingType === HousingType.EigenWoning ||
                                housing.housingType === HousingType.Beroepsruimte) ?
                                <>
                                    <Button type={"default"} disabled={excluded}
                                            className={"calculator-button" + (excluded ? "-excluded" : "")}
                                            onClick={() => {
                                                setHousingModuleOpen(true)
                                                setSelectedHousingId(housing.id)
                                            }}>
                                        <AcertaIcon
                                            style={{
                                                verticalAlign: '-0.15em',
                                                color: !excluded ? '#fff' : '#a8a8a8'
                                            }}
                                            icon={"icon-calculator-24"}
                                            size="19px" />
                                    </Button>
                                    <InputNumber
                                        disabled={true}
                                        decimalSeparator={','}
                                        className={"input-currency-disabled" + (excluded ? ' item-excluded' : '')}
                                        value={housing.total}
                                    />
                                    <div style={{
                                        position: 'absolute',
                                        top: 34, right: 3,
                                        fontSize: 11,
                                        fontStyle: "italic",
                                        color: '#888',
                                        width: '200px'
                                    }}>{translate("common.totaal.rekenmodule") + " / " + translate("common.ex.btw")}</div>
                                </>
                                :
                                <>
                                    <span style={{color: '#999', marginRight: 8}}>{translate("common.prijs")}</span>
                                    {unitPriceField}
                                    {priceSelected &&
                                    <div style={{
                                        position: 'absolute',
                                        top: 34, right: 3,
                                        fontSize: 11,
                                        fontStyle: "italic",
                                        color: '#888',
                                        width: '200px'
                                    }}>{translate("common.by.year") + " / " + translate("common.ex.btw")}</div>}
                                </>}
                        </Col>
                        <Col xs={3} style={{textAlign: 'right'}}>
                        </Col>
                    </Row>
                </Mobile>
            </Form>
            <Modal
                visible={housingModuleOpen}
                onOk={() => {
                    setHousingModuleOpen(false)
                }}
                onCancel={() => setHousingModuleOpen(false)}
                okText={"Lening opslaan"}
                cancelText={"Annuleren"}
                okButtonProps={{disabled: true}}
                footer={null}
            >
                <HousingCalculationModuleFetchContainer selectedHousingId={selectedHousingId!}
                                                        onClose={() => setHousingModuleOpen(false)} />
            </Modal>
        </>
    )
}

const HousingSingleRowFormView = Form.create<FormProps>({
    name: 'housing',
})(HousingSingleRowForm);

export default HousingSingleRowFormView
import React from 'react'
import {Link} from "react-router-dom";

interface Props {
    data: any
    setShow: any
}

const CalculationsView:React.FC<Props> = ({data, setShow}) => {
    if (!data || !data.calculations) return null
    return (
        <>{data.calculations.map((calculation: string) =>
            <ul>
                <li><Link onClick={() => setShow(false)} to={'/simulatie/profiel/' + calculation}>{calculation}</Link></li>
            </ul>)}</>
    )
}

export default CalculationsView
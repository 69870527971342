import React, {useContext} from 'react'
import {ServiceRevenueInput, ServiceRevenuesSection, useAddServiceRevenueMutation} from "../../../../generated/graphql";
import {REVENUE} from "../queries";
import {RESULT} from "../../common/result-chart/queries";
import {message} from "antd";
import {FintoolContext} from "../../../../context/fintool-context";
import ServiceRevenuesView from "./service-revenues-view";
import {MESSAGE_DURATION} from "../../../../utils/constants";
import {I18nContext} from "../../../../i18n";

interface Props {
    data: ServiceRevenuesSection
}

const ServiceRevenuesContainer: React.FC<Props> = ({data}) => {

    const [context] = useContext(FintoolContext)
    const {translate} = useContext(I18nContext);

    const [addService] = useAddServiceRevenueMutation({
            refetchQueries: [
                {query: REVENUE, variables: {calculationHash: context.calculationHash}},
                {query: RESULT, variables: {calculationHash: context.calculationHash}}
            ]
        }
    )

    const onAdd = (values: ServiceRevenueInput) => {
        if (!!context.calculationHash) {
            addService({
                variables: {
                    calculationHash: context.calculationHash,
                    serviceRevenueInput: values
                }
            }).then((result: any) => {
                message.success(translate("common.add.succes"), MESSAGE_DURATION)
            }).catch(error => {
                message.error(error.message)
            })
        }
    }

    return (
        <ServiceRevenuesView onAdd={onAdd} data={data} />
    )
}

export default ServiceRevenuesContainer
import React from 'react'
import {useCalculationInfoQuery} from "../../../../generated/graphql";
import {Spin} from "antd";
import SaveSimulationView from "./save-simulation-view";
import {handle} from "../../../app/app";

interface Props {
    calculationHash: string
}

const SaveSimulationContainer:React.FC<Props> = ({calculationHash}) => {

    const {data, error, loading, networkStatus} = useCalculationInfoQuery({
        variables: {calculationHash: calculationHash},
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "cache-and-network"
    });

    if (loading || networkStatus === 4) {
        return <div><Spin size="small" /></div>;
    }

    error && handle(error)

    if (!data) {
        return null;
    }
    const info = (data && data.calculationInfo) ? data.calculationInfo : null

    return (
        <SaveSimulationView calculationInfo={info} />
    )
}

export default SaveSimulationContainer
import React, {useContext, useState} from 'react'
import {Col, Form, Icon, InputNumber, Row, Switch, Tooltip} from "antd";
import {FormComponentProps} from "antd/es/form";
import {Default, isMobileQuery, Mobile} from "../../../../../utils/media-queries";
import {I18nContext} from "../../../../../i18n";
import Explanation from "../../../../common/explanation";
import {useMediaQuery} from "react-responsive";
import {Result} from "../../../../../generated/graphql";


interface FormProps extends FormComponentProps {
    result: Result
}

const SocialExpenseSingleRowForm: React.FC<FormProps> = ({form, result}) => {

    const {translate} = useContext(I18nContext);
    const {getFieldDecorator} = form;
    const isMobile = useMediaQuery(isMobileQuery)
    const [priceSelected, setPriceSelected] = useState(false)

    const toggleField = getFieldDecorator('includeInCalculation', {})(
        <Switch size="small" checked={true} disabled={true} checkedChildren={<Icon type="lock"/>}/>
    );

    const unitPriceField = getFieldDecorator('unitPrice', {
        initialValue: result.socialContributions,
    })(
        <InputNumber style={{width: isMobile ? 90 : 90}}
                     disabled={true}
                     decimalSeparator={','}
                     precision={2}
                     className={
                         "input-currency-disabled"}
                     onFocus={() => setPriceSelected(true)}
        />
    );

    return (
        <Form id={"form_social_bijdragen"}>
            <Default>
                <Row className="single-row-form-row" type="flex" align="middle" justify="space-between" gutter={16}>
                    <Col xs={2}>
                        {toggleField}
                    </Col>
                    <Col xs={12}>
                        <span>
                        {translate("expense.type.SOCIAL_CONTRIBUTIONS")}
                            <Explanation
                                title={translate("expense.type.help.title.SOCIAL_CONTRIBUTIONS")}
                                explanation={translate("expense.type.help.text.SOCIAL_CONTRIBUTIONS", true)}
                                placement={"right"}/>
                        </span>
                    </Col>
                    <Col xs={8} style={{textAlign: 'right'}}>
                        <span style={{color: '#999', marginRight: 8}}>Prijs</span>
                        <Tooltip title={translate("common.price.SOCIAL_CONTRIBUTIONS")}>{unitPriceField}</Tooltip>
                    </Col>
                    <Col xs={2} style={{textAlign: 'center'}}> </Col>
                </Row>
            </Default>
            <Mobile>
                <Row style={{marginTop: 0}} gutter={0}>
                    <Col xs={3}>
                        <span>{toggleField}</span>
                    </Col>
                    <Col xs={18}>
                        <span>
                            {translate("expense.type.SOCIAL_CONTRIBUTIONS")}
                            <Explanation
                                title={translate("expense.type.help.title.SOCIAL_CONTRIBUTIONS")}
                                explanation={translate("expense.type.help.text.SOCIAL_CONTRIBUTIONS", true)}
                                placement={"right"}/>
                        </span>
                    </Col>
                    <Col xs={3} style={{textAlign: 'right'}}>
                    </Col>
                </Row>
                <Row type="flex" align="middle"
                     style={{paddingBottom: 15, marginTop: 0, marginBottom: 12, borderBottom: '1px solid #eee'}}>
                    <Col xs={3}></Col>
                    <Col xs={18} style={{textAlign: 'right'}}>
                        {translate("common.bedrag")}&nbsp;
                        <span style={{position: "relative"}}>
                            {unitPriceField}
                            {priceSelected && isMobile &&
                            <span style={{
                                position: 'absolute',
                                top: 25, right: 3,
                                fontSize: 11,
                                fontStyle: "italic",
                                color: '#888'
                            }}>{translate("common.ex.btw")}</span>}
                        </span>
                    </Col>
                    <Col xs={3}>
                    </Col>
                </Row>
            </Mobile>
        </Form>
    )
}

const SocialContributionSingleRowFormView = Form.create<FormProps>({
    name: 'generalExpense',
})(SocialExpenseSingleRowForm);

export default SocialContributionSingleRowFormView
import React, {useContext, useEffect} from 'react'
import {Revenue} from "../../../generated/graphql";
import ServiceRevenuesContainer from "./service-revenues";
import ProductsContainer from "./products";
import VorigeVolgende from "../common/vorige-volgende";
import revenueIcon from "../../../assets/images/income.svg";
import Header from "../common/header";
import {FintoolContext} from "../../../context/fintool-context";

interface Props {
    data: Revenue
    updateInitState: any
}

const RevenueView: React.FC<Props> = ({data, updateInitState}) => {

    const [context] = useContext(FintoolContext);

    useEffect(() => {
            if (context.initState) {
                updateInitState()
            }
            // eslint-disable-next-line
        }, []
    )

    return (
        <>
            <Header stepName={"revenue"} iconSource={revenueIcon} defaultNumberOfTexts={1} />
            <ServiceRevenuesContainer data={data.serviceRevenues} />
            <ProductsContainer data={data.products} />
            <VorigeVolgende />
        </>
    )
}

export default RevenueView
import React, {useContext} from 'react'
import {FormComponentProps, ValidateCallback} from "antd/es/form";
import {Housing, HousingFinancingType, HousingInput, HousingType} from "../../../../../generated/graphql";
import {Button, Col, Form, Radio, Row, Slider} from "antd";
import {currency, I18nContext, noCurrency} from "../../../../../i18n";
import _ from 'lodash'
import AcertaIcon from "../../../../common/iconfont";
import Explanation from "../../../../common/explanation";
import {useMediaQuery} from "react-responsive";
import {isMobileQuery} from "../../../../../utils/media-queries";

interface FormProps extends FormComponentProps {
    onSave: any
    onClose: any
    housing: Housing
}

interface Item {
    name: string
    min: number
    max: number
    step: number
    isCurrency: Boolean
}

const HousingCalculationModuleForm: React.FC<FormProps> = ({form, onSave, onClose, housing}) => {
    const {getFieldDecorator} = form;
    const {translate} = useContext(I18nContext)
    const isMobile = useMediaQuery(isMobileQuery)

    const defaultProperty = {
        purchaseAmount: 230000,
        utilities: 3000,
        maintenance: 1500,
        insurance: 600,
        interestAmount: 0,
        otherCosts: 900
    }
    const defaultRental = {
        rentalAmount: 9600,
        utilities: 3000,
        maintenance: 750,
        insurance: 600,
        otherCosts: 0
    }

    const onChangeValue = (housingFinancingType: HousingFinancingType | undefined) => {
        form.validateFields((err: ValidateCallback<any>, values: HousingInput) => {
            let type = !!housingFinancingType ? housingFinancingType
                : (values.housingFinancingType ? values.housingFinancingType : HousingFinancingType.Rental);
            onSave({
                ...values,
                property: type === HousingFinancingType.Property ? (values.property ? values.property : defaultProperty) : null,
                rental: type === HousingFinancingType.Rental ? (values.rental ? values.rental : defaultRental) : null,
                housingType: housing.housingType,
                housingFinancingType: housing.housingType === HousingType.Beroepsruimte ? HousingFinancingType.Rental : type,
                includeInCalculation: housing.includeInCalculation
            }, form)
        })
    }

    const propertyItems: Item[] = [
        {name: 'property.purchaseAmount', min: 50000, max: 750000, step: 1000, isCurrency: true},
        {name: 'property.utilities', min: 0, max: 6000, step: 100, isCurrency: true},
        {name: 'property.maintenance', min: 0, max: 10000, step: 100, isCurrency: true},
        {name: 'property.insurance', min: 0, max: 3000, step: 100, isCurrency: true},
        {name: 'property.interestAmount', min: 0, max: 10000, step: 100, isCurrency: true},
        {name: 'property.otherCosts', min: 0, max: 20000, step: 100, isCurrency: true},
        {name: 'professionalPercentage', min: 0, max: 100, step: 1, isCurrency: false},
    ]

    const rentalItems: Item[] = [
        {name: 'rental.rentalAmount', min: 2500, max: 15000, step: 100, isCurrency: true},
        {name: 'rental.utilities', min: 0, max: 6000, step: 100, isCurrency: true},
        {name: 'rental.maintenance', min: 0, max: 10000, step: 100, isCurrency: true},
        {name: 'rental.insurance', min: 0, max: 3000, step: 100, isCurrency: true},
        {name: 'rental.otherCosts', min: 0, max: 20000, step: 100, isCurrency: true},
        {name: 'professionalPercentage', min: 0, max: 100, step: 1, isCurrency: false},
    ]

    const tooltipContainer = () => {
        return document.getElementById("sliders-tooltip-housing-container")!
    }

    return (
        <>
            <h3 style={{marginBottom: 5}}>
                <AcertaIcon icon={"icon-calculator-24"} style={{marginRight: 8, verticalAlign: '-0.1em'}} />
                {translate("expenses.housing.type." + housing.housingType)}</h3>
            <p>{translate("expenses.housing.subtitle." + housing.housingType + housing.housingFinancingType)}</p>
            <Form id="housingCalculationModuleForm">
                {housing.housingType === HousingType.EigenWoning &&
                <Row style={{marginBottom: 10}} type="flex" align="middle">
                    <Col xs={24} style={{marginTop: 10, marginBottom: 10}}>
                        {getFieldDecorator('housingFinancingType', {
                            initialValue: housing.housingFinancingType
                        })(
                            <Radio.Group
                                onChange={(e) => {
                                    onChangeValue(e.target.value)
                                }}>
                                <Radio
                                    value={HousingFinancingType.Property}>{translate("expenses.housing.financing.type." + HousingFinancingType.Property)}</Radio>
                                <Radio
                                    value={HousingFinancingType.Rental}>{translate("expenses.housing.financing.type." + HousingFinancingType.Rental)}</Radio>
                            </Radio.Group>
                        )}
                    </Col>
                </Row>}
                {
                    (housing.housingFinancingType === HousingFinancingType.Rental ?
                        rentalItems :
                        propertyItems).map((item: Item) =>
                        <Row style={{marginBottom: 0}} type="flex" align="middle" key={item.name}>
                            {isMobile ?
                                <Col xs={11} md={13} style={{marginBottom: 10}}>
                                    <div>
                                        {translate("expenses.housing.item.name." + item.name)}
                                        {translate("expenses.housing.item.name." + item.name + ".help") !== '' &&
                                        <Explanation title={translate("expenses.housing.item.name." + item.name)}
                                                     explanation={translate("expenses.housing.item.name." + item.name + ".help")} />}
                                    </div>
                                    <div className={"rounded-font" + (item.isCurrency ? " acerta-medium-blue-1" : "")}>
                                        {form.getFieldValue(item.name) ?
                                            (item.isCurrency ? currency(form.getFieldValue(item.name), false, true) : noCurrency(form.getFieldValue(item.name))) :
                                            (item.isCurrency ? currency(_.get(housing, item.name), false, true) : noCurrency(_.get(housing, item.name)))}
                                    </div>
                                </Col>
                                : <>
                                    <Col xs={7}>
                                        {translate("expenses.housing.item.name." + item.name)}
                                        {translate("expenses.housing.item.name." + item.name + ".help") !== '' &&
                                        <Explanation title={translate("expenses.housing.item.name." + item.name)}
                                                     explanation={translate("expenses.housing.item.name." + item.name + ".help")} />}
                                    </Col>
                                    <Col xs={5} style={{textAlign: 'right'}}
                                         className={"rounded-font" + (item.isCurrency ? " acerta-medium-blue-1" : "")}>
                                        {form.getFieldValue(item.name) ?
                                            (item.isCurrency ? currency(form.getFieldValue(item.name), false, true) : noCurrency(form.getFieldValue(item.name))) :
                                            (item.isCurrency ? currency(_.get(housing, item.name), false, true) : noCurrency(_.get(housing, item.name)))}
                                    </Col>
                                </>}
                            <Col xs={0} md={1}>
                            </Col>
                            <Col xs={13} md={11}>
                                {getFieldDecorator(item.name, {
                                    initialValue: _.get(housing, item.name)
                                })(
                                    <Slider
                                        step={item.step}
                                        min={item.min} max={item.max}
                                        getTooltipPopupContainer={tooltipContainer}
                                        onAfterChange={() => onChangeValue(undefined)}
                                        tipFormatter={(number) => {
                                            return <span>{number} {translate("common.slider." + item.name, true)}</span>
                                        }}
                                    />
                                )}
                            </Col>
                        </Row>)
                }
            </Form>
            <div style={{textAlign: 'right', marginTop: 10}}>
                <Button
                    type={"primary"} onClick={() => {
                    onClose()
                }}>{translate("common.button.toepassen")}</Button>
            </div>
        </>
    )
}

const HousingCalculationModuleView = Form.create<FormProps>({
    name: 'housingCalculationModule',
})(HousingCalculationModuleForm);

export default HousingCalculationModuleView
import React, {useContext} from 'react'
import Header from "../header";
import {FintoolProvider} from "../../../context/fintool-context";
import {Button, Col, Layout, Result, Row} from "antd";
import Footer from "../footer";
import {I18nContext} from "../../../i18n";

interface Props {
    status: any
    message?: string
    calculationHash?: string
}

const CustomError:React.FC<Props> = ({status, message, calculationHash}) => {

    const {translate} = useContext(I18nContext)

    return (
        <Layout>
            <FintoolProvider>
                <Layout.Header>
                    <Header networkError={true} />
                </Layout.Header>
                <Layout.Content>
                    <Row>
                        <Col>
                            <Result
                                status={status}
                                title={<h3>{status === "500" ? translate("common.error.netwerk.error.title") : status}</h3>}
                                subTitle={message ? message : translate("common.error.netwerk.error.subtitle")}
                                extra={<a href={!!calculationHash ? "/simulatie/profiel/" + calculationHash : "/"}><Button
                                    type="primary">{translate("common.button.back")}</Button></a>}
                            />
                        </Col>
                    </Row>
                </Layout.Content>
                <Layout.Footer>
                    <Footer />
                </Layout.Footer>
            </FintoolProvider>
        </Layout>
    )
}

export default CustomError
import React, {useContext, useEffect} from 'react'
import VorigeVolgende from "../common/vorige-volgende/vorige-volgende";
import {CalculationInfo} from "../../../generated/graphql";
import Header from "../common/header";
import ResultDetailsContainer from "./result-details-container";
import MailCaptureContainer from "../../common/mail-capture/mail-capture-container";
import {FintoolContext} from "../../../context/fintool-context";
import resultIcon from "../../../assets/images/result.svg";
import {Button, Icon} from "antd";
import {I18nContext} from "../../../i18n";
import {useMediaQuery} from "react-responsive";
import {isMobileQuery} from "../../../utils/media-queries";
import DownloadContainer from "../../common/download/download-container";

interface Props {
    calculationInfo: CalculationInfo
    updateInitState: any
}

const ResultView: React.FC<Props> = ({calculationInfo, updateInitState}) => {

    const [context] = useContext(FintoolContext)
    const {translate} = useContext(I18nContext);
    const isMobile = useMediaQuery(isMobileQuery)

    useEffect(() => {
        if (context.initState) {
            updateInitState()
        }
        // eslint-disable-next-line
    }, [])

    const downloadButton = <Button
        id={translate("result.download.button.id")}
        style={isMobile ? {width: '100%', marginBottom: 20, marginTop: 10} : {}}
        type={"primary"}>
        <Icon type="download" /> {translate("common.pdf.download")}
    </Button>

    return (
        <>
            <Header stepName={"resultaat"} iconSource={resultIcon} defaultNumberOfTexts={0}
                    rightButton={!isMobile && <DownloadContainer component={downloadButton} noTooltip={true} />}
            />
            {calculationInfo && calculationInfo.fintoolUser ?
                <div>
                    {isMobile &&
                    <DownloadContainer component={downloadButton} />
                    }
                    <ResultDetailsContainer calculationInfo={calculationInfo} />
                </div>
                :
                <MailCaptureContainer title={translate("result.download.title")} />
            }
            <VorigeVolgende />
        </>
    )
}

export default ResultView
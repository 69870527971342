import React from 'react'
import LoadSimulationView from "./load-simulation-view";

const LoadSimulationContainer = () => {

    return (
        <LoadSimulationView />
    )
}

export default LoadSimulationContainer
import React, {useContext} from 'react'
import {
    ExpenseItem,
    ExpenseItemInput,
    ExpenseType,
    useRemoveExpenseItemMutation,
    useUpdateExpenseItemMutation
} from "../../../../generated/graphql";
import GeneralExpenseSingleRowFormView from "./general-expense-single-row-form-view";
import {EXPENSE} from "../queries";
import {RESULT} from "../../common/result-chart/queries";
import {message} from "antd";
import {FintoolContext} from "../../../../context/fintool-context";
import {I18nContext} from "../../../../i18n";
import {MESSAGE_DURATION} from "../../../../utils/constants";
import {EXPENSE_CATEGORIES_WITH_SELECTED} from "../common/queries";

interface Props {
    expenseItem: ExpenseItem
    expenseType: ExpenseType
}

const GeneralExpenseSingleRowFormContainer: React.FC<Props> = ({expenseItem, expenseType}) => {

    const [context] = useContext(FintoolContext)
    const {translate} = useContext(I18nContext);

    const [updateExpenseItem] = useUpdateExpenseItemMutation({
            refetchQueries: [
                {query: EXPENSE_CATEGORIES_WITH_SELECTED, variables: {calculationHash: context.calculationHash}},
                {query: RESULT, variables: {calculationHash: context.calculationHash}}
            ]
        }
    )
    const [removeExpenseItem] = useRemoveExpenseItemMutation({
            refetchQueries: [
                {query: EXPENSE, variables: {calculationHash: context.calculationHash}},
                {query: EXPENSE_CATEGORIES_WITH_SELECTED, variables: {calculationHash: context.calculationHash}},
                {query: RESULT, variables: {calculationHash: context.calculationHash}}
            ]
        }
    )

    const onChange = (values: ExpenseItemInput) => {
        if (!!context.calculationHash) {
            updateExpenseItem({
                variables: {
                    calculationHash: context.calculationHash,
                    expenseItemInput: values
                }
            }).then((result: any) => {
                message.success(translate("common.save.success"), MESSAGE_DURATION)
            }).catch(error => {
                message.error(error.message)
            })
        }
    }

    const onRemove = (id: string) => {
        if (!!context.calculationHash) {
            removeExpenseItem({
                variables: {
                    calculationHash: context.calculationHash,
                    expenseItemId: id
                }
            }).then((result: any) => {
                message.success(translate("common.delete.succes"), MESSAGE_DURATION)
            }).catch(error => {
                message.error(error.message)
            })
        }
    }

    return (
        <GeneralExpenseSingleRowFormView
            expenseItem={expenseItem}
            onChangedField={onChange}
            onRemove={onRemove}
            expenseType={expenseType}
        />
    )
}

export default GeneralExpenseSingleRowFormContainer
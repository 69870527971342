import React, {useContext} from 'react'
import {
    Housing,
    useRemoveHousingMutation,
    useToggleHousingMutation,
    useUpdateHousingMutation,
    HousingInput
} from "../../../../generated/graphql";
import {EXPENSE} from "../queries";
import {RESULT} from "../../common/result-chart/queries";
import {message} from "antd";
import {FintoolContext} from "../../../../context/fintool-context";
import {I18nContext} from "../../../../i18n";
import {MESSAGE_DURATION} from "../../../../utils/constants";
import {handle} from "../../../app/app";
import HousingSingleRowFormView from "./housing-single-row-form-view";

interface Props {
    housing: Housing
}

const HousingSingleRowFormContainer: React.FC<Props> = ({housing}) => {

    const [context] = useContext(FintoolContext)
    const {translate} = useContext(I18nContext);

    const [updateHousing] = useUpdateHousingMutation({
            refetchQueries: [
                {query: EXPENSE, variables: {calculationHash: context.calculationHash}},
                {query: RESULT, variables: {calculationHash: context.calculationHash}}
            ]
        }
    )
    const [toggleHousing] = useToggleHousingMutation({
            refetchQueries: [
                {query: EXPENSE, variables: {calculationHash: context.calculationHash}},
                {query: RESULT, variables: {calculationHash: context.calculationHash}}
            ]
        }
    )
    const [removeHousing] = useRemoveHousingMutation({
            refetchQueries: [
                {query: EXPENSE, variables: {calculationHash: context.calculationHash}},
                {query: RESULT, variables: {calculationHash: context.calculationHash}}
            ]
        }
    )

    const onChange = (values: HousingInput) => {
        if (!!context.calculationHash) {
            updateHousing({
                variables: {
                    calculationHash: context.calculationHash,
                    housingInput: values
                }
            }).then((result: any) => {
                message.success(translate("common.save.success"), MESSAGE_DURATION)
            }).catch((error: any) => {
                handle(error)
            })
        }
    }

    const onToggle = (housingId: string, includeInCalculation: Boolean) => {
        if (!!context.calculationHash) {
            toggleHousing({
                variables: {
                    calculationHash: context.calculationHash,
                    housingId: housingId,
                    exclude: !includeInCalculation
                }
            }).then((result: any) => {
                message.success(translate("common.save.success"), MESSAGE_DURATION)
            }).catch((error: any) => {
                handle(error)
            })
        }
    }

    const onRemove = (id: string) => {
        if (!!context.calculationHash) {
            removeHousing({
                variables: {
                    calculationHash: context.calculationHash,
                    housingId: id
                }
            }).then((result: any) => {
                message.success(translate("common.delete.succes"), MESSAGE_DURATION)
            }).catch((error: any) => {
                handle(error)
            })
        }
    }

    return (
        <HousingSingleRowFormView
            housing={housing}
            onChangedField={onChange}
            onToggleExclusion={onToggle}
            onRemove={onRemove}
        />
    )
}

export default HousingSingleRowFormContainer
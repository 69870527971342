import React, {useContext, useState} from 'react'
import VorigeVolgende from "../common/vorige-volgende/vorige-volgende";
import {
    FinancingItemType,
    useAddFinancingItemMutation,
    useFinancingQuery, useUpdateFinanceSeenStateMutation,
    useUpdateInitStateMutation
} from "../../../generated/graphql";
import FinancingView from "./financing-view";
import {message, Spin} from "antd";
import {MESSAGE_DURATION} from "../../../utils/constants";
import {I18nContext} from "../../../i18n";
import {FINANCING} from "./queries";
import {FinancingItemProps} from "./financing-item-single-row-form-container";
import {handle} from "../../app/app";
import {FintoolContext} from "../../../context/fintool-context";
import {RESULT} from "../common/result-chart/queries";
import {CALCULATION_INFO} from "../queries";

interface Props {
    calculationHash: string
}

const FinancingContainer: React.FC<Props> = ({calculationHash}) => {

    const {translate} = useContext(I18nContext)
    const [newLoanId, setNewLoadId] = useState<string | undefined>(undefined)

    const [context, setContext] = useContext(FintoolContext)

    const [updateInitState] = useUpdateInitStateMutation({
            refetchQueries: [
                {query: RESULT, variables: {calculationHash: context.calculationHash}},
                {query: CALCULATION_INFO, variables: {calculationHash: context.calculationHash}}
            ]
        }
    )

    const onUpdate = () => {
        updateInitState({
            variables: {
                calculationHash: context.calculationHash!,
                newState: false
            }
        }).then((result: any) => {
            setContext({...context, initState: result.data.updateInitState.initState})
        }).catch(error => {
            message.error(error.message)
        })
    }

    const [updateFinanceSeenState] = useUpdateFinanceSeenStateMutation({
            refetchQueries: [
                {query: RESULT, variables: {calculationHash: context.calculationHash}},
                {query: CALCULATION_INFO, variables: {calculationHash: context.calculationHash}}
            ]
        }
    )

    const onUpdateFinanceSeen = () => {
        updateFinanceSeenState({
            variables: {
                calculationHash: context.calculationHash!,
                newState: true
            }
        }).then((result: any) => {
            setContext({...context, financeSeenState: result.data.updateFinanceSeenState.financeSeenState})
        }).catch((error: any) => {
            message.error(error.message)
        })
    }

    const {data, error, loading, networkStatus} = useFinancingQuery({
        variables: {calculationHash: calculationHash},
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "network-only"
    });

    const [addFinancingItem] = useAddFinancingItemMutation({
            refetchQueries: [
                {query: FINANCING, variables: {calculationHash: calculationHash}},
            ]
        }
    );

    const onAdd = (financingItemProps: FinancingItemProps) => {
        if (!!calculationHash) {
            addFinancingItem({
                variables: {
                    calculationHash: calculationHash,
                    financingItemType: (financingItemProps && financingItemProps.option) || FinancingItemType.OwnResource
                }
            }).then((result: any) => {
                if (financingItemProps.option === FinancingItemType.Loan) {
                    setNewLoadId(result.data.addFinancingItem)
                }
                message.success(translate("common.add.succes"), MESSAGE_DURATION)
            }).catch((error: any) => {
                message.error(error.message)
            })
        }
    }

    if (loading || networkStatus === 4) {
        return <div><Spin size="small" /></div>;
    }

    error && handle(error)

    if (!data) {
        return null;
    }
    return (
        <>
            <FinancingView
                newLoanId={newLoanId} onAdd={onAdd} data={data}
                updateInitState={onUpdate}
                updateFinanceSeenState={onUpdateFinanceSeen}
            />
            <VorigeVolgende />
        </>
    )
};

export default FinancingContainer
import React, {useContext} from 'react'
import DownloadView from "./download-view";
import {useCalculationInfoQuery} from "../../../generated/graphql";
import {Spin} from "antd";
import {handle} from "../../app/app";
import {FintoolContext} from "../../../context/fintool-context";

interface Props {
    component: any
    noTooltip?: boolean
}

const DownloadContainer:React.FC<Props> = ({component, noTooltip}) => {

    const [context] = useContext(FintoolContext)

    const {data, error, loading, networkStatus} = useCalculationInfoQuery({
        variables: {calculationHash: context.calculationHash!},
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "cache-and-network"
    });

    if (loading || networkStatus === 4) {
        return <div><Spin size="small" /></div>;
    }

    error && handle(error)

    if (!data) {
        return null;
    }

    return (
        <DownloadView askEmail={!data.calculationInfo.fintoolUser} component={component} noTooltip={noTooltip} />
    )
}

export default DownloadContainer
import React, {useContext} from 'react'
import {Product, ProductInput, useRemoveProductMutation, useUpdateProductMutation} from "../../../../generated/graphql";
import ProductSingleRowFormView from "./product-single-row-form-view";
import {REVENUE} from "../queries";
import {RESULT} from "../../common/result-chart/queries";
import {message} from "antd";
import {FintoolContext} from "../../../../context/fintool-context";
import {I18nContext} from "../../../../i18n";
import {MESSAGE_DURATION} from "../../../../utils/constants";

interface Props {
    product: Product
}

const ProductSingleRowFormContainer: React.FC<Props> = ({product}) => {

    const [context] = useContext(FintoolContext)
    const {translate} = useContext(I18nContext);

    const [updateProduct] = useUpdateProductMutation({
            refetchQueries: [
                {query: REVENUE, variables: {calculationHash: context.calculationHash}},
                {query: RESULT, variables: {calculationHash: context.calculationHash}}
            ]
        }
    )
    const [removeProduct] = useRemoveProductMutation({
            refetchQueries: [
                {query: REVENUE, variables: {calculationHash: context.calculationHash}},
                {query: RESULT, variables: {calculationHash: context.calculationHash}}
            ]
        }
    )

    const onChange = (values: ProductInput) => {
        if (!!context.calculationHash) {
            updateProduct({
                variables: {
                    calculationHash: context.calculationHash,
                    productInput: values
                }
            }).then((result: any) => {
                message.success(translate("common.save.success"), MESSAGE_DURATION)
            }).catch(error => {
                message.error(error.message)
            })
        }
    }

    const onRemove = (id: string) => {
        if (!!context.calculationHash) {
            removeProduct({
                variables: {
                    calculationHash: context.calculationHash,
                    productId: id
                }
            }).then((result: any) => {
                message.success(translate("common.delete.succes"), MESSAGE_DURATION)
            }).catch(error => {
                message.error(error.message)
            })
        }
    }

    return (
        <ProductSingleRowFormView
            product={product}
            onChangedField={onChange}
            removeProduct={onRemove}
        />
    )
}

export default ProductSingleRowFormContainer
import React, {useContext, useState} from 'react'
import {ProductsRevenuesSection} from "../../../../generated/graphql";
import ProductSingleRowFormContainer from "./product-single-row-form-container";
import ShadowBox, {BoxTitle} from "../../../common/shadow-box/shadow-box";
import {I18nContext} from "../../../../i18n";

interface Props {
    data: ProductsRevenuesSection
    onAdd: any
}

const ProductsView: React.FC<Props> = ({data, onAdd}) => {

    const [boxOpen, setBoxOpen] = useState(true)
    const {translate} = useContext(I18nContext)

    return (
        <>
            <ShadowBox title={
                <BoxTitle iconClass="icon-package-24"
                          text={translate("revenue.producten.title")}
                          addText={translate("revenue.producten.add.text")}
                          explanation={translate("revenue.producten.long.subtitle")}
                          setBoxOpen={setBoxOpen} boxOpen={boxOpen}
                          noArrow={false}
                          onAdd={() => onAdd({
                              description: "",
                              unitPrice: 0,
                              quantity: 0,
                          })}
                          trackOpenCloseClass={translate("revenue.product.open.close.class")}
                          trackAddClass={translate("revenue.product.add.class")}
                />
            } open={boxOpen}
                       noArrow={false}
            >
                {data.revenues.map((product, index) =>
                    !!product ?
                        <div className={index === 0 ? 'first' : ''} key={'div_' + product.id}>
                        <ProductSingleRowFormContainer key={product.id} product={product} />
                        </div>: null
                )}
            </ShadowBox>
        </>
    )
}

export default ProductsView
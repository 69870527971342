import React, {useContext, useState} from 'react'
import {Button, Col, Form, InputNumber, Modal, Row, Switch, Tooltip} from "antd";
import {FormComponentProps} from "antd/es/form";
import {Vehicle} from "../../../../generated/graphql";
import {Default, isMobileQuery, Mobile} from "../../../../utils/media-queries";
import AcertaIcon from "../../../common/iconfont";
import {I18nContext} from "../../../../i18n";
import VehicleCalculationModuleFetchContainer from "./vehicle-calculation-module";
import Explanation from "../../../common/explanation";
import {useMediaQuery} from "react-responsive";

interface FormProps extends FormComponentProps {
    vehicle: Vehicle
    onChangedField: any
    onToggleExclusion: any
    onRemove: any
}

const VehicleSingleRowForm: React.FC<FormProps> = ({form, vehicle, onToggleExclusion, onRemove}) => {

    const [excluded, setExcluded] = useState(!vehicle.includeInCalculation)
    const [vehicleModuleOpen, setVehicleModuleOpen] = useState(false)
    const [selectedVehicleId, setSelectedVehicleId] = useState<string | undefined>(undefined)
    const {translate} = useContext(I18nContext);
    const {getFieldDecorator} = form;
    const isMobile = useMediaQuery(isMobileQuery)

    const toggleExclusion = (checked: boolean) => {
        setExcluded(!checked)
        onToggleExclusion(vehicle.id, checked);
    };

    const toggleField = getFieldDecorator('includeInCalculation', {})(
        <Switch className={translate("expense.vehicle.toggle.class")} size="small" onChange={toggleExclusion}
                checked={vehicle!.includeInCalculation!} />
    )

    const deleteButton = <Button className={translate("expense.vehicle.delete.class")} style={{marginRight: isMobile ? 22 : 19}}
                                 type={"link"}
                                 onClick={() => onRemove(vehicle.id)}>
        <AcertaIcon icon={"icon-trash-24"} size="18px" />
    </Button>

    return (
        <>
            <Form id={vehicle.id}>
                <Default>
                    <Row type="flex" align="middle" justify="space-between" gutter={16}
                         className="single-row-form-row">
                        <Col xs={2}>
                            {toggleField}
                        </Col>
                        <Col xs={12}>
                                <span className={excluded ? 'item-excluded' : ''}>
                                    {translate("expenses.vehicle.type." + vehicle.vehicleType)}&nbsp;-&nbsp;
                                    <span style={{
                                        color: '#8f8f8f',
                                        fontStyle: "italic",
                                    }}
                                          className={excluded ? 'item-excluded' : ''}>{translate("expenses.vehicle.financing.type." + vehicle.vehicleFinancingType)}</span>
                                    {translate("expense.type.help." + vehicle.vehicleType) && translate("expense.type.help." + vehicle.vehicleType).lenght !== "" &&
                                    <Explanation
                                        title={translate("expense.type." + vehicle.vehicleType + "." + vehicle.vehicleFinancingType)}
                                        explanation={translate("expense.type.help." + vehicle.vehicleType + "." + vehicle.vehicleFinancingType, true)} />}
                            </span>
                        </Col>
                        <Col xs={8} style={{textAlign: 'right'}}>
                            <Button type={"default"} disabled={excluded}
                                    className={translate("expense.vehicle.calculator.button.class") + " calculator-button" + (excluded ? "-excluded" : "")}
                                    onClick={() => {
                                        setVehicleModuleOpen(true)
                                        setSelectedVehicleId(vehicle.id)
                                    }}>
                                <AcertaIcon
                                    style={{
                                        verticalAlign: '-0.15em',
                                        color: !excluded ? '#fff' : '#a8a8a8'
                                    }}
                                    icon={"icon-calculator-24"} size="19px" />
                            </Button>
                            <Tooltip
                                title={translate("common.totaal.rekenmodule") + " / " + translate("common.ex.btw")}>
                                <InputNumber
                                    disabled={true}
                                    decimalSeparator={','}
                                    className={"input-currency-disabled" + (excluded ? ' item-excluded' : '')}
                                    value={vehicle.total}
                                />
                            </Tooltip>
                        </Col>
                        <Col xs={2} style={{textAlign: 'right'}}>
                            {deleteButton}
                        </Col>
                    </Row>
                </Default>
                <Mobile>
                    <Row type="flex" align="middle">
                        <Col xs={3}>
                            {toggleField}
                        </Col>
                        <Col xs={18}>
                            <span className={excluded ? 'item-excluded' : ''}>
                                    {translate("expenses.vehicle.type." + vehicle.vehicleType)}&nbsp;-&nbsp;
                                <span style={{
                                    color: '#8f8f8f',
                                    fontStyle: "italic",
                                }}
                                      className={excluded ? 'item-excluded' : ''}>{translate("expenses.vehicle.financing.type." + vehicle.vehicleFinancingType)}</span>
                                {translate("expense.type.help." + vehicle.vehicleType) && translate("expense.type.help." + vehicle.vehicleType).lenght !== "" &&
                                <Explanation
                                    title={translate("expense.type." + vehicle.vehicleType + "." + vehicle.vehicleFinancingType)}
                                    explanation={translate("expense.type.help." + vehicle.vehicleType + "." + vehicle.vehicleFinancingType, true)} />}
                            </span>
                        </Col>
                        <Col xs={3} style={{textAlign: 'right'}}>
                            {deleteButton}
                        </Col>
                    </Row>
                    <Row type="flex" align="middle"
                         style={{paddingBottom: 18, marginTop: 0, marginBottom: 4, borderBottom: '1px solid #eee'}}>
                        <Col xs={3}>
                        </Col>
                        <Col xs={18} style={{textAlign: 'right'}}>
                            <Button type={"default"} disabled={excluded}
                                    className={"calculator-button" + (excluded ? "-excluded" : "")}
                                    onClick={() => {
                                        setVehicleModuleOpen(true)
                                        setSelectedVehicleId(vehicle.id)
                                    }}>
                                <AcertaIcon
                                    style={{
                                        verticalAlign: '-0.15em',
                                        color: !excluded ? '#fff' : '#a8a8a8'
                                    }}
                                    icon={"icon-calculator-24"} size="19px" />
                            </Button>
                            <InputNumber
                                disabled={true}
                                decimalSeparator={','}
                                className={"input-currency-disabled" + (excluded ? ' item-excluded' : '')}
                                value={vehicle.total}
                            />
                            <div style={{
                                position: 'absolute',
                                top: 34, right: 3,
                                fontSize: 11,
                                fontStyle: "italic",
                                color: '#888',
                                width: '200px'
                            }}>{translate("common.totaal.rekenmodule") + " / " + translate("common.ex.btw")}</div>
                        </Col>
                        <Col xs={3} style={{textAlign: 'right'}}>
                        </Col>
                    </Row>
                </Mobile>
            </Form>
            <Modal
                visible={vehicleModuleOpen}
                onOk={() => {
                    setVehicleModuleOpen(false)
                }}
                onCancel={() => setVehicleModuleOpen(false)}
                okText={"Lening opslaan"}
                cancelText={"Annuleren"}
                okButtonProps={{disabled: true}}
                footer={null}
            >
                <VehicleCalculationModuleFetchContainer selectedVehicleId={selectedVehicleId!}
                                                        onClose={() => setVehicleModuleOpen(false)} />
            </Modal>
        </>
    )
}

const VehicleSingleRowFormView = Form.create<FormProps>({
    name: 'vehicle',
})(VehicleSingleRowForm);

export default VehicleSingleRowFormView
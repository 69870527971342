import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import "antd/dist/antd.css";
import './assets/css/acerta-fintool.css';
import './assets/css/media-general.css';
import './assets/css/media-header.css';
import './assets/css/media-steps.css';
import './assets/css/acerta-fintool-iconfont.css';
import * as serviceWorker from './serviceWorker';
import ApolloApp from "./components/app/apollo-app";
import {Router} from "react-router";
import {createBrowserHistory} from "history";

const history = createBrowserHistory();

history.listen(location => {
    const {pathname} = location;
    (window as any).dataLayer = (window as any).dataLayer || [];
    (window as any).dataLayer.push({
        event: "virtualPageview",
        url: pathname
    });
});

ReactDOM.render(
    <Router history={history}>
        <ApolloApp />
    </Router>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React from 'react'
import AcertaIcon from "../iconfont";

interface ListItemProps {
    text: any
    style?: any
}

const ListItem: React.FC<ListItemProps> = ({text, style}) => {
    return <div style={style}>
        <div style={{display: 'flex'}}>
            <div><AcertaIcon icon={"icon-check-16"} style={{marginRight: 10, verticalAlign: '-0.1em'}} /></div>
            <div><span className="acerta-dark-blue">{text}</span></div>
        </div>
    </div>;
}

export default ListItem
import React, {useContext} from 'react'
import {message} from "antd";
import {FintoolContext} from "../../../../../context/fintool-context";
import {Housing, HousingInput, useUpdateHousingMutation} from "../../../../../generated/graphql";
import {I18nContext} from "../../../../../i18n";
import {FINANCING} from "../../../financing/queries";
import {RESULT} from "../../../common/result-chart/queries";
import {MESSAGE_DURATION} from "../../../../../utils/constants";
import HousingCalculationModuleView from "./housing-calculation-module-view";
import {HOUSING} from "../queries";

interface Props {
    onClose: any
    housing: Housing
}

const HousingCalculationModuleSaveContainer: React.FC<Props> = ({onClose, housing}) => {

    const [context] = useContext(FintoolContext)
    const {translate} = useContext(I18nContext)

    const [saveHousing] = useUpdateHousingMutation({
            refetchQueries: [
                {query: FINANCING, variables: {calculationHash: context.calculationHash}},
                {query: HOUSING, variables: {calculationHash: context.calculationHash, housingId: housing.id}},
                {query: RESULT, variables: {calculationHash: context.calculationHash}}
            ]
        }
    );

    const onSave = (housingInput: HousingInput) => {
        if (!!context.calculationHash) {
            saveHousing({
                variables: {
                    calculationHash: context.calculationHash,
                    housingInput: {...housingInput, id: housing ? housing.id : null}
                }
            }).then((result: any) => {
                message.success(translate("common.save.success"), MESSAGE_DURATION)
            }).catch((error: any) => {
                message.error(error.message)
                console.log(error.message);
            })
        }
    }
    return (
        <HousingCalculationModuleView onSave={onSave} onClose={onClose} housing={housing} />
    )
}

export default HousingCalculationModuleSaveContainer
import React, {useContext, useEffect} from 'react'
import {FintoolContext} from "../context/fintool-context";
import {Redirect} from 'react-router'

const Admin = () => {

    const [context, setContext] = useContext(FintoolContext)

    useEffect(() => {
        setContext({...context, admin: true})
        // eslint-disable-next-line
    }, [])


    return <Redirect to={'/'} />
}

export default Admin
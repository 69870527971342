import React, {useContext, useState} from 'react'
import {Vehicle, VehicleType} from "../../../../generated/graphql";
import ShadowBox, {BoxTitle} from "../../../common/shadow-box/shadow-box";
import {I18nContext} from "../../../../i18n";
import VehicleSingleRowFormContainer from "./vehicle-single-row-form-container";
import {Modal} from "antd";
import VehicleCalculationModuleFetchContainer from "./vehicle-calculation-module";

interface Props {
    onAdd: any
    data?: Vehicle[] | null
    newVehicleId?: string
}

const VehicleView: React.FC<Props> = ({data, onAdd, newVehicleId}) => {

    const [boxOpen, setBoxOpen] = useState(true)
    const [vehicleModuleOpen, setVehicleModuleOpen] = useState(false)
    const {translate} = useContext(I18nContext)

    const onBeforeAdd = (values: any) => {
        onAdd(values.option)
        setVehicleModuleOpen(true)
    }

    return (
        <>
            <ShadowBox title={
                <BoxTitle iconClass="icon-truck-24"
                          text={translate("expense.category.VOERTUIGEN")}
                          addText={translate("expense.category.add.text.VOERTUIGEN")}
                          setBoxOpen={setBoxOpen} boxOpen={boxOpen}
                          noArrow={false}
                          onAdd={onBeforeAdd}
                          addOptions={[{
                              value: VehicleType.Personenwagen,
                              text: translate("expenses.vehicle.type." + VehicleType.Personenwagen)
                          },
                              {
                                  value: VehicleType.LichteVracht,
                                  text: translate("expenses.vehicle.type." + VehicleType.LichteVracht)
                              }]}
                          toAdd={{
                              purchaseAmount: 0,
                              maintenance: 0,
                          }}
                          trackOpenCloseClass={translate("expense.vehicle.open.close.class")}
                          trackAddClass={translate("expense.category.add.VOERTUIGEN.class")}
                />
            } open={boxOpen}
                       noArrow={false}>
                {data ? data.map((vehicle: Vehicle, index: number) =>
                        <div className={index === 0 ? 'first' : ''} key={'div_' + vehicle.id}>
                    <VehicleSingleRowFormContainer key={vehicle.id}
                        vehicle={vehicle}
                    /></div>
                ) : null}
            </ShadowBox>
            {newVehicleId &&
            <Modal
                visible={vehicleModuleOpen}
                onOk={() => {
                    setVehicleModuleOpen(false)
                }}
                onCancel={() => setVehicleModuleOpen(false)}
                okText={"Lening opslaan"}
                cancelText={"Annuleren"}
                okButtonProps={{disabled: true}}
                footer={null}
                style={{ top: 20 }}
            >
                <VehicleCalculationModuleFetchContainer selectedVehicleId={newVehicleId}
                                                        onClose={() => setVehicleModuleOpen(false)} />
            </Modal>
            }
            <div id="sliders-tooltip-vehicle-container"></div>
        </>
    )
}

export default VehicleView
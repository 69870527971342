import gql from 'graphql-tag';

export const ADD_HOUSING = gql`
    mutation AddHousing($calculationHash: String!, $housingType: HousingType!) {
        addHousing(calculationHash: $calculationHash, housingType: $housingType) {
            id
        }
    }
`;

export const REMOVE_HOUSING = gql`
    mutation RemoveHousing($calculationHash: String!, $housingId: String!) {
        removeHousing(calculationHash: $calculationHash, housingId: $housingId)
    }
`;

export const TOGGLE_HOUSING = gql`
    mutation ToggleHousing($calculationHash: String!, $housingId: String!, $exclude: Boolean!) {
        toggleHousing(calculationHash: $calculationHash, housingId: $housingId, exclude: $exclude)
    }
`;

export const HOUSING = gql`
    query Housing($calculationHash: String!, $housingId: String!) {
        housing(calculationHash: $calculationHash, housingId: $housingId) {
            id
            housingType
            housingFinancingType
            includeInCalculation
            unitPrice
            professionalPercentage
            calculatedCashFlowValue
            calculateNetValue
            total
            property {
                purchaseAmount
                utilities
                maintenance
                insurance
                interestAmount
                otherCosts
            }
            rental {
                rentalAmount
                utilities
                maintenance
                insurance
                otherCosts
            }
        }
    }
`;

export const UPDATE_HOUSING = gql`
    mutation UpdateHousing($calculationHash: String!, $housingInput: HousingInput!) {
        updateHousing(calculationHash: $calculationHash, housingInput: $housingInput) {
            id
            housingType
            housingFinancingType
            property {
                purchaseAmount
                utilities
                maintenance
                insurance
                interestAmount
                otherCosts
            }
            rental {
                rentalAmount
                utilities
                maintenance
                insurance
                otherCosts
            }
            includeInCalculation
            unitPrice
            calculatedCashFlowValue
            calculateNetValue
            total
        }
    }
`;
import React, {useContext} from 'react'
import {CalculationInfo} from "../../../generated/graphql";
import CopyToClipboard from "react-copy-to-clipboard";
import {Icon, message} from "antd";
import {I18nContext} from "../../../i18n";

interface Props {
    calculationInfo: CalculationInfo
}

const CurrentCodeView: React.FC<Props> = ({calculationInfo}) => {

    const {translate} = useContext(I18nContext)

    return (
        <div style={{textAlign: "center"}}>
            <h2>{translate("common.your.simulation.code")}</h2>
            <div style={{textAlign: 'right', fontStyle: "italic"}}>
                <CopyToClipboard
                    text={calculationInfo.code!}
                    onCopy={() => message.success(translate("common.code.copied"))}
                >
                    <sub style={{cursor: "pointer"}}><Icon
                        type="copy" /> {translate("common.clipboard.click")}</sub>
                </CopyToClipboard>
            </div>
            <div style={{backgroundColor: '#f9f9f9', borderRadius: 4}}>
                <h1>{calculationInfo.code}</h1>
            </div>
        </div>
    )
}

export default CurrentCodeView
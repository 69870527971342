import React, {useContext} from 'react'
import {currency, I18nContext} from "../../../../i18n";
import {Popover} from "antd";

interface NettoInkomenChartPartProps {
    hoverState: any
    setHoverState: any
    first?: boolean
    last?: boolean
    valueName: string
    height: number
}

export const NettoInkomenChartPart: React.FC<NettoInkomenChartPartProps> =
    ({
         hoverState,
         setHoverState,
         first,
         last,
         valueName,
         height
     }) => {
        return (
            <div className={"bar chart-color-" + valueName + (hoverState ? " hover" : "")}
                 onMouseEnter={() => setHoverState(true)}
                 onMouseLeave={() => setHoverState(false)}
                 style={{
                     borderTopLeftRadius: first ? 4 : 0,
                     borderTopRightRadius: first ? 4 : 0,
                     borderBottomLeftRadius: last ? 4 : 0,
                     borderBottomRightRadius: last ? 4 : 0,
                     height: height,
                     borderBottom: last ? 'none' : '1px solid #fff'
                 }}
            />
        )
    }

interface BankSaldoChartPartProps {
    hoverState: any
    setHoverState: any
    colorClass: string
    percentage: number
    firstTop?: boolean
    lastTop?: boolean
    firstBottom?: boolean
    lastBottom?: boolean
    popoverText?: string
}

export const BankSaldoChartPart: React.FC<BankSaldoChartPartProps> =
    ({
         children,
         hoverState,
         setHoverState,
         colorClass,
         percentage,
         firstTop,
         lastTop,
         firstBottom,
         lastBottom,
         popoverText
     }) => {
        if (!!popoverText) {
            return (<Popover
                title={popoverText}
                placement={"left"}
            >
                <div className={colorClass + " bar" + (hoverState ? " hover" : "")}
                                    onMouseEnter={() => setHoverState(true)}
                                    onMouseLeave={() => setHoverState(false)}
                                    style={{
                                        display: 'inline-block',
                                        height: 34,
                                        borderTopLeftRadius: firstTop ? 4 : "inherit",
                                        borderTopRightRadius: lastTop ? 4 : "inherit",
                                        borderBottomLeftRadius: firstBottom ? 4 : "inherit",
                                        borderBottomRightRadius: lastBottom ? 4 : "inherit",
                                        borderRight: !(lastTop || lastBottom) && (percentage >= 1) ? '1px solid #fff' : 'none',
                                        borderBottom: (firstTop || lastTop) ? '1px solid #fff' : '',
                                        width: 0.995 * percentage + '%'
                                    }}
                >{children}</div>
            </Popover>)
        }
        return (
            <div className={colorClass + " bar" + (hoverState ? " hover" : "")}
                 onMouseEnter={() => setHoverState(true)}
                 onMouseLeave={() => setHoverState(false)}
                 style={{
                     display: 'inline-block',
                     height: 34,
                     borderTopLeftRadius: firstTop ? 4 : "inherit",
                     borderTopRightRadius: lastTop ? 4 : "inherit",
                     borderBottomLeftRadius: firstBottom ? 4 : "inherit",
                     borderBottomRightRadius: lastBottom ? 4 : "inherit",
                     borderRight: !(lastTop || lastBottom) && (percentage >= 1) ? '1px solid #fff' : 'none',
                     borderBottom: (firstTop || lastTop) ? '1px solid #fff' : '',
                     width: 0.995 * percentage + '%'
                 }}
            >{children}</div>
        )
    }

interface LegendProps {
    hoverState: any
    setHoverState: any
    valueName: string
    value: number
    colorClass: string
    striped?: boolean
    bolder?: boolean
}

export const Legend: React.FC<LegendProps> = ({hoverState, setHoverState, valueName, value, colorClass, striped, bolder}) => {
    const {translate} = useContext(I18nContext)
    return (
        <div style={{height: 45}}>
            <div
                onMouseEnter={() => setHoverState(true)}
                onMouseLeave={() => setHoverState(false)}
                style={{display: 'flex'}} className={hoverState ? 'legend legend-hover' : 'legend'}>
                <div>
                        <span
                            className={"chart-color-" + valueName}
                            style={{
                                height: striped ? 12 : 10,
                                width: striped ? 12 : 10,
                                border: striped ? '1px solid #ddd' : 'inherit',
                                borderRadius: '50%',
                                display: 'inline-block'
                            }}>
                        </span>
                </div>
                <div style={{
                    paddingLeft: 5,
                    fontSize: bolder ? 13 : 'inherit',
                    fontWeight: bolder ? 'bold' : 'inherit'
                }}>
                        <span style={{verticalAlign: '0.05em'}}>
                            <div style={{display: 'inline-block'}}>
                                {translate("chart." + valueName)}
                            </div>
                        </span>
                    <div className={"rounded-font " + colorClass}>
                        {bolder && <span className="acerta-light-blue-1">€ </span>}{<span style={{whiteSpace: 'nowrap'}}>{currency(value, bolder, true)}</span>}
                    </div>
                </div>
            </div>
        </div>
    )
}
import React, {useContext, useState} from 'react'
import {Button, Col, Form, Input, InputNumber, Row, Switch, Tooltip} from "antd";
import {FormComponentProps, ValidateCallback} from "antd/es/form";
import {ServiceRevenue, ServiceRevenueInput} from "../../../../generated/graphql";
import AcertaIcon from "../../../common/iconfont";
import {Default, isMobileQuery, Mobile} from "../../../../utils/media-queries";
import {avoidWrongNumericInput, currency, I18nContext} from "../../../../i18n";
import {useMediaQuery} from "react-responsive";

interface FormProps extends FormComponentProps {
    onChangedField: any
    serviceRevenue: ServiceRevenue
    removeServiceRevenue: any
}

const ServiceRevenueSingleRowForm: React.FC<FormProps> = ({form, onChangedField, serviceRevenue, removeServiceRevenue}) => {

    const [excluded, setExcluded] = useState(!serviceRevenue.includeInCalculation)
    const {translate} = useContext(I18nContext)
    const isMobile = useMediaQuery(isMobileQuery)
    const [priceSelected, setPriceSelected] = useState(false)
    const [quantitySelected, setQuantitySelected] = useState(false)

    const {getFieldDecorator} = form;

    const doUpdate = () => {
        form.validateFields((err: ValidateCallback<any>, values: ServiceRevenueInput) => {
            if (!err) {
                values.unitPrice = values.unitPrice || 0
                values.quantity = values.quantity || 0
                onChangedField({...values, id: serviceRevenue.id})
            }
        })
    }

    const toggleExclusion = (checked: boolean) => {
        form.validateFields((err: ValidateCallback<any>, values: ServiceRevenueInput) => {
            if (!err) {
                values.unitPrice = values.unitPrice || 0
                values.quantity = values.quantity || 0
                values.includeInCalculation = checked;
                setExcluded(!checked)
                onChangedField({...values, id: serviceRevenue.id});
            }
        })
    };

    const toggleField = getFieldDecorator('includeInCalculation', {})(
        <Switch className={translate("revenue.service.toggle.class")} size="small" onChange={toggleExclusion}
                checked={serviceRevenue!.includeInCalculation!} />
    )

    const descriptionField = getFieldDecorator('description', {
        initialValue: serviceRevenue.description,
    })(
        <Input disabled={excluded} onPressEnter={doUpdate} onBlur={doUpdate}
               placeholder={translate("revenue.placenholder.nieuwe.dienst")} />,
    )

    const quantityField = getFieldDecorator('quantity', {
        initialValue: serviceRevenue.quantity,
    })(
        <InputNumber style={{width: isMobile ? '100%' : 90}}
                     type="number"
                     disabled={excluded} onPressEnter={doUpdate}
                     onBlur={() => {
                         doUpdate();
                         setQuantitySelected(false)
                     }}
                     decimalSeparator={','} onKeyDown={(e) => avoidWrongNumericInput(e)}
                     className={"input-amount" + (excluded ? '-disabled' : '') + (!excluded ? '' : ' item-excluded')}
                     onFocus={(e) => {
                         setQuantitySelected(true)
                         !isMobile && e.target.select()
                     }}

        />
    )
    const unitPriceField = getFieldDecorator('unitPrice', {
        initialValue: serviceRevenue.unitPrice,
    })(
        <InputNumber style={{width: isMobile ? '100%' : 90}} disabled={excluded} onPressEnter={doUpdate}
                     onBlur={() => {
                         doUpdate();
                         setPriceSelected(false)
                     }}
                     type="number"
                     decimalSeparator={','} onKeyDown={(e) => avoidWrongNumericInput(e)}
                     className={"input-currency" + (excluded ? '-disabled' : '') + (!excluded ? '' : ' item-excluded')}
                     onFocus={(e) => {
                         setPriceSelected(true)
                         !isMobile && e.target.select()
                     }}

        />
    )

    const deleteButton = <Button className={translate("revenue.service.delete.class")} style={{marginRight: isMobile ? 22 : 19}} type={"link"}
                                 onClick={() => removeServiceRevenue(serviceRevenue.id)}>
        <AcertaIcon icon={"icon-trash-24"} size="18px" />
    </Button>

    return (
        <Form id={serviceRevenue.id}>
            <Default>
                <Row type="flex" align="middle" justify="space-between" gutter={16}
                     className="single-row-form-row">
                    <Col xs={2}>
                        {toggleField}
                    </Col>
                    <Col xs={6} className={excluded ? 'item-excluded' : ''}>
                        {descriptionField}
                    </Col>
                    <Col xs={5} style={{textAlign: 'right', whiteSpace: 'nowrap'}}>
                        <span style={{color: '#999', marginRight: 8}}>{translate("common.aantal")}</span>
                        <Tooltip title={translate("common.by.year")}>
                            {quantityField}
                        </Tooltip>
                    </Col>
                    <Col xs={6} style={{textAlign: 'right', whiteSpace: 'nowrap'}}>
                        <span style={{color: '#999', marginRight: 8}}>{translate("common.prijs")}</span>
                        <Tooltip title={translate("common.ex.btw")}>{unitPriceField}</Tooltip>
                    </Col>
                    <Col xs={3} style={{textAlign: 'right', whiteSpace: 'nowrap'}}>
                        <span className={!excluded ? '' : 'item-excluded'}>
                            {currency(serviceRevenue.calculatedCashFlowValue)}
                        </span>
                    </Col>
                    <Col xs={2} style={{textAlign: 'right'}}>
                        {deleteButton}
                    </Col>
                </Row>
            </Default>
            <Mobile>
                <Row style={{marginTop: 0}} gutter={0}>
                    <Col xs={3}>
                        <span style={{verticalAlign: '-0.3em'}}>{toggleField}</span>
                    </Col>
                    <Col xs={18} className={excluded ? 'item-excluded' : ''}>
                        {descriptionField}
                    </Col>
                    <Col xs={3} style={{textAlign: 'right'}}>
                        {deleteButton}
                    </Col>
                </Row>
                <Row style={{marginTop: 5}}>
                    <Col xs={3}></Col>
                    <Col xs={10}>
                        {translate("common.aantal")}
                    </Col>
                    <Col xs={9}>
                        {translate("common.prijs")}
                    </Col>
                    <Col xs={3}>
                    </Col>
                </Row>
                <Row type="flex" align="middle">
                    <Col xs={3}></Col>
                    <Col xs={8}>
                        <span style={{position: "relative"}}>
                            {quantityField}
                            {quantitySelected && isMobile &&
                            <span style={{
                                position: 'absolute',
                                top: 25, right: 3,
                                fontSize: 11,
                                fontStyle: "italic",
                                color: '#888'
                            }}>{translate("common.by.year")}</span>}</span>
                    </Col>
                    <Col xs={2}>
                    </Col>
                    <Col xs={8}>
                        <span style={{position: "relative"}}>
                        {unitPriceField}
                            {priceSelected && isMobile &&
                            <span style={{
                                position: 'absolute',
                                top: 25, right: 3,
                                fontSize: 11,
                                fontStyle: "italic",
                                color: '#888'
                            }}>{translate("common.ex.btw")}</span>}</span>
                    </Col>
                    <Col xs={3}>
                    </Col>
                </Row>
                <Row type="flex" align="middle"
                     style={{paddingBottom: 8, marginTop: 15, marginBottom: 12, borderBottom: '1px solid #eee'}}>
                    <Col xs={3}></Col>
                    <Col xs={18} style={{textAlign: 'right'}}>
                        {translate("common.totaal")}&nbsp;
                        <span className={!excluded ? '' : 'item-excluded'}>
                            {currency(serviceRevenue.calculatedCashFlowValue)}
                        </span>
                    </Col>
                    <Col xs={3} style={{textAlign: 'right'}}>

                    </Col>
                </Row>
            </Mobile>
        </Form>
    )
}

const ServiceRevenueSingleRowFormView = Form.create<FormProps>({
    name: 'serviceRevenue',
})(ServiceRevenueSingleRowForm);

export default ServiceRevenueSingleRowFormView
import React from 'react'
import AcertaIcon from "../iconfont";
import {Popover} from "antd";
import './explanation.css'
import {TooltipPlacement} from "antd/lib/tooltip";
import {useMediaQuery} from "react-responsive";
import {isMobileQuery} from "../../../utils/media-queries";

interface Props {
    title: string
    explanation: string
    placement?: TooltipPlacement
    onClick?: any
}

const Explanation: React.FC<Props> = ({title, explanation, placement, onClick}) => {

    const isMobile = useMediaQuery(isMobileQuery)

    const explanationContent = () => {
        return (
            <div className="explanation">
                <div><AcertaIcon icon={"icon-info-circle-16"} style={{marginRight: 5}} /><span
                    className="explanation-title">{title}</span></div>
                <div>{explanation}</div>
            </div>
        )
    }

    return (
        <Popover trigger={"hover"} content={explanationContent()} placement={placement ? placement : "top"}>
            <span className="link-icon" onClick={() => onClick && onClick()}>
                <AcertaIcon icon={"icon-info-circle-16"} size={isMobile ? "18px" : "16px"} />
            </span>
        </Popover>)
}

export default Explanation
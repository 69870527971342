import React from 'react'
import CurrentCodeView from "./current-code-view";
import {CalculationInfo} from "../../../generated/graphql";

interface Props {
    calculationInfo: CalculationInfo
}

const CurrentCodeContainer: React.FC<Props> = ({calculationInfo}) => {

    return (
        <CurrentCodeView calculationInfo={calculationInfo} />
    )
}

export default CurrentCodeContainer
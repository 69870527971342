import React from 'react'
import CalculationsView from "./CalculationsView";
import {useCalculationsQuery} from "../generated/graphql";
import {Spin} from "antd";
import {handle} from "../components/app/app";

interface Props {
    setShow: any
}

const CalculationsContainer: React.FC<Props> = ({setShow}) => {

    const {data, error, loading, networkStatus} = useCalculationsQuery({
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "network-only"
    });

    if (loading || networkStatus === 4) {
        return <div><Spin size="small" /></div>;
    }

    error && handle(error)

    if (!data) {
        return <div>no data</div>;
    }

    return (
        <CalculationsView setShow={setShow} data={data} />
    )
}

export default CalculationsContainer
import gql from 'graphql-tag';

export const EXPENSE = gql`
    query Expense($calculationHash: String!) {
        expense(calculationHash: $calculationHash) {
            expenseItems {
                id
                expenseCategory
                expenseType
                expenseTypeOption
                description
                quantity
                unitPrice
                useQuantity
                calculatedCashFlowValue
                includeInCalculation
            }
            groupedExpenses {
                key
                values {
                    id
                    expenseCategory
                    expenseType
                    expenseTypeOption
                    description
                    quantity
                    unitPrice
                    useQuantity
                    calculatedCashFlowValue
                    includeInCalculation
                }
                totalPrice
            }
            vehicles {
                id
                vehicleType
                vehicleFinancingType
                includeInCalculation
                calculatedCashFlowValue
                calculateNetValue
                total
                purchase {
                    purchaseAmount
                    maintenance
                    tax
                    milagePrivate
                    milageProfessional
                    fuelConsumption
                    fuelPricePerLiter
                    insurance
                    otherCosts
                }
                leasing {
                    otherCosts
                    milagePrivate
                    milageProfessional
                }
            }
            housings {
                id
                housingType
                housingFinancingType
                includeInCalculation
                unitPrice
                calculatedCashFlowValue
                calculateNetValue
                total
                professionalPercentage
            }
        }
    }
`;

export const EXPENSE_CATEGORIES = gql`
    query ExpenseCategories($calculationHash: String) {
        expenseCategories(calculationHash: $calculationHash){
            id
            code
            expenseTypes{
                id
                code
                description
                recurrence
                defaultPrice
                fixedName
                fixedPrice
                hasOptions
                multipleAllowed
                useQuantity
                useProfessionalPart
                defaultProfessionalPart
                minimumPrice
                maximumPrice
                selected
                expenseTypeOptions{
                    id
                    code
                    description
                    recurrence
                    defaultPrice
                    fixedName
                    fixedPrice
                    multipleAllowed
                }
            }
        }
    }
`;
import React, {useContext} from 'react'
import {FintoolContext} from "../../../context/fintool-context";
import {RouteComponentProps} from "react-router";
import {steps, useSetCorrectSimulationStep} from "../simulation-view";
import FinancingContainer from "./financing-container";

interface Props extends RouteComponentProps {
}

const Financing: React.FC<Props> = ({match}) => {

    const [context] = useContext(FintoolContext)

    useSetCorrectSimulationStep(match, steps.FINANCING)

    if (!context.calculationHash) return null
    return (
        <FinancingContainer calculationHash={context.calculationHash} />
    )
}

export default Financing
import React, {useContext} from 'react'
import {I18nContext} from "../../../i18n";
import { Col, Row} from "antd";
import {useMediaQuery} from "react-responsive";
import {isMobileQuery, Mobile} from "../../../utils/media-queries";

interface Props {
    stepName: string
    iconSource?: any
    defaultNumberOfTexts: number
    rightButton?: any
    defaultShowMore?: boolean
}

const Header: React.FC<Props> = ({stepName, iconSource, defaultNumberOfTexts, rightButton, defaultShowMore}) => {
    const {translate} = useContext(I18nContext);
    const isMobile = useMediaQuery(isMobileQuery)

    const defaults = () => {
        let defaults = []
        let i;
        for (i = 0; i < defaultNumberOfTexts; i++) {
            defaults.push(<p style={{marginBottom: '0.5em'}} key={"step" + i}>
                {translate(stepName + ".header.tekst" + (i + 1), true)}
            </p>)
        }
        return defaults;
    }

    return (
        <>
            <Row>
                <Col xs={20} md={22} xl={24}>
                    <Row>
                        <Col xs={24}>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: "space-between"}}>
                                <div style={{display: 'flex', alignItems: 'center', flexGrow: 1}}>
                                    {iconSource &&
                                    <div>
                                        <img
                                            style={{verticalAlign: '0.3em', width: isMobile ? 40 : 50, marginRight: 10}}
                                            src={iconSource}
                                            alt={stepName + " icon"} />
                                    </div>}
                                    <div>
                                        <h1 className="title">
                                            {translate(stepName + ".header.subtitle" + (isMobile ? '.mobile' : ''))}
                                        </h1>
                                        <Mobile>
                                            <h3 style={{color: '#4CB4E7', marginBottom: 0, marginTop: -4}}>
                                                {translate(stepName + ".header.subtitle.mobile.more")}
                                            </h3>
                                        </Mobile>
                                    </div>
                                    {rightButton && <div style={{flexGrow: 1, textAlign: "right"}}>{rightButton}</div>}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <div style={{marginBottom: 20, paddingRight: isMobile ? 50 : 34}}>
                {defaults()}
            </div>
        </>
    )
}

export default Header
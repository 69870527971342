import React, {useContext, useState} from 'react'
import {Button, Icon, Modal, Tooltip} from "antd";
import EnterCodeContainer from "../../code-enter/enter-code-container";
import {useMediaQuery} from "react-responsive";
import {isDesktopQuery, isMobileQuery} from "../../../../utils/media-queries";
import {I18nContext} from "../../../../i18n";


const LoadSimulationView = () => {

    const {translate} = useContext(I18nContext)
    const [showCodePopup, setShowCodePopup] = useState(false)
    const isDesktop = useMediaQuery(isDesktopQuery)
    const isMobile = useMediaQuery(isMobileQuery)

    let button = <Button id={translate("common.simulatie.ophalen.id")} type={"link"}
                         onClick={() => setShowCodePopup(true)}
                         style={{
                             color: "#3F7CBF",
                         }}>
        <Icon type="folder-open" style={{fontSize: 18}} />  {isMobile && translate('common.simulatie.ophalen')}
    </Button>;

    return (
        <>
            {isDesktop ?
                <Tooltip title={translate('common.simulatie.ophalen.tooltip')} placement={"bottomLeft"}>
                    {button}
                </Tooltip> :
                button}
            <Modal
                visible={showCodePopup}
                onCancel={() => setShowCodePopup(false)}
                okButtonProps={{disabled: true}}
                footer={null}
            >
                <EnterCodeContainer inModal={true} onModalAction={() => setShowCodePopup(false)} />
            </Modal>
        </>
    )
}

export default LoadSimulationView
import React, {useContext, useState} from 'react'
import {Button, Col, Form, InputNumber, Modal, Row, Switch, Tooltip} from "antd";
import {FormComponentProps, ValidateCallback} from "antd/es/form";
import {avoidWrongNumericInput, I18nContext} from "../../../i18n";
import {FinancingItemProps} from "./financing-item-single-row-form-container";
import {FinancingItemInput, FinancingItemType} from "../../../generated/graphql";
import {Default, isMobileQuery, Mobile} from "../../../utils/media-queries";
import AcertaIcon from "../../common/iconfont";
import LoanCalculationModuleFetchContainer from "./loan-calculation-module/loan-calculation-module-fetch-container";
import {useMediaQuery} from "react-responsive";

interface FormProps extends FormComponentProps {
    onChange: any
    financingItem: FinancingItemProps
    onRemove: any
}

const FinancingItemSingleRowForm: React.FC<FormProps> = ({form, financingItem, onRemove, onChange}) => {
    const {getFieldDecorator} = form;
    const {translate} = useContext(I18nContext)
    const isMobile = useMediaQuery(isMobileQuery)
    const [excluded, setExcluded] = useState(!financingItem.includeInCalculation)
    const [loanModuleOpen, setLoanModuleOpen] = useState(false)
    const [selectedLoanId, setSelectedLoanId] = useState<string | undefined>(undefined)

    const doUpdate = () => {
        form.validateFields((err: ValidateCallback<any>, values: any) => {
            if (!err) {
                values.amount = values.amount || 0
                let financingItemInput: FinancingItemInput = {
                    id: financingItem.id,
                    financingItemType: financingItem.financingItemType,
                    includeInCalculation: financingItem.includeInCalculation,
                    name: values.name,
                    amount: values.amount
                }
                onChange(financingItemInput)
            }
        })
    };

    const toggleExclusion = (checked: boolean) => {
        form.validateFields((err: ValidateCallback<any>, values: FinancingItemInput) => {
            if (!err) {
                values.amount = values.amount || 0
                setExcluded(!checked)
                onChange({
                    ...values,
                    financingItemType: financingItem.financingItemType,
                    includeInCalculation: checked,
                    name: financingItem.name,
                    id: financingItem.id
                });
            }
        })
    };

    const toggleField = getFieldDecorator('includeInCalculation', {})(
        <Switch className={translate("financing.toggle.class")} size="small" onChange={toggleExclusion}
                checked={financingItem!.includeInCalculation!} />
    )

    const amountField = getFieldDecorator('amount', {
        initialValue: financingItem.amount,
    })(
        <InputNumber disabled={excluded} onPressEnter={doUpdate} onBlur={doUpdate}
                     decimalSeparator={','}
                     onKeyDown={(e) => avoidWrongNumericInput(e)}
                     className={"input-currency" + (excluded ? '-disabled item-excluded' : '')}
                     onFocus={(e) => e.target.select()}
        />
    )

    const deleteButton = <Button className={translate("financing.delete.class")} style={{marginRight: isMobile ? 22 : 19}} type={"link"}
                                 onClick={() => onRemove(financingItem.id)}>
        <AcertaIcon icon={"icon-trash-24"} size="18px" />
    </Button>

    return (
        <>
            <Form id={financingItem.id}>
                <Default>
                    <Row type="flex" align="middle" justify="space-between" gutter={16}
                         className="single-row-form-row">
                        <Col xs={2}>
                            {toggleField}
                        </Col>
                        <Col xs={11} xl={12} className={excluded ? 'item-excluded' : ''}>
                            {translate("financing." + financingItem.financingItemType)}
                            <span style={{color: '#8f8f8f', fontStyle: "italic"}} className={excluded ? 'item-excluded' : ''}>
                                {financingItem.name && ' - ' + financingItem.name}
                            </span>
                        </Col>
                        <Col xs={9} xl={8} style={{textAlign: 'right'}}>
                            {financingItem.financingItemType === FinancingItemType.Loan ?
                                <span className={excluded ? 'item-excluded' : ''}>
                                <Button type={"default"} disabled={excluded}
                                        className={translate("financing.leningen.calculator.button.class") + " calculator-button" + (excluded ? "-excluded" : "")}
                                        onClick={() => {
                                            setLoanModuleOpen(true)
                                            setSelectedLoanId(financingItem.id)
                                        }}>
                                    <AcertaIcon
                                        style={{
                                            verticalAlign: '-0.15em',
                                            color: !excluded ? '#fff' : '#a8a8a8'
                                        }}
                                        icon={"icon-calculator-24"} size="19px" />
                                </Button>
                                    <Tooltip title={translate("common.loan.amount")}>
                                    <InputNumber
                                        disabled={true}
                                        decimalSeparator={','}
                                        className={"input-currency-disabled" + (excluded ? ' item-excluded' : '')}
                                        value={financingItem.amount}
                                    />
                                    </Tooltip>
                                </span>
                                :
                                <><span style={{
                                    color: '#999',
                                    marginRight: 8
                                }}>{translate("common.bedrag")}</span>{amountField}</>}
                        </Col>
                        <Col xs={2} style={{textAlign: 'center'}}>
                            {deleteButton}
                        </Col>
                    </Row>
                </Default>
                <Mobile>
                    <Row  type="flex" align="middle"
                          style={{paddingBottom: 12, marginTop: 0, marginBottom: 12, borderBottom: '1px solid #eee'}}
                    >
                        <Col xs={3}>
                            {toggleField}
                        </Col>
                        <Col xs={financingItem.financingItemType === FinancingItemType.Loan ? 7 : 11} className={excluded ? 'item-excluded' : ''}>
                            {translate("financing." + financingItem.financingItemType)}
                            <span style={{
                                color: '#8f8f8f',
                                fontStyle: "italic",
                            }} className={excluded ? 'item-excluded' : ''}>
                                {financingItem.name && ' - ' + financingItem.name}
                            </span>
                        </Col>
                        <Col style={{textAlign: 'right', paddingRight: 5}}
                             xs={financingItem.financingItemType === FinancingItemType.Loan ? 12 : 8}>
                            {financingItem.financingItemType === FinancingItemType.Loan ?
                                <span className={excluded ? 'item-excluded' : ''}>
                                <Button type={"default"} disabled={excluded}
                                        className={"calculator-button" + (excluded ? "-excluded" : "")}
                                        onClick={() => {
                                            setLoanModuleOpen(true)
                                            setSelectedLoanId(financingItem.id)
                                        }}>
                                    <AcertaIcon
                                        style={{
                                            verticalAlign: '-0.15em',
                                            color: !excluded ? '#fff' : '#a8a8a8'
                                        }}
                                        icon={"icon-calculator-24"} size="19px" />
                                </Button>
                                    <Tooltip title={translate("common.loan.amount")}>
                                    <InputNumber
                                        disabled={true}
                                        decimalSeparator={','}
                                        className={"input-currency-disabled" + (excluded ? ' item-excluded' : '')}
                                        value={financingItem.amount}
                                    />
                                    </Tooltip>
                                </span>
                                :
                                <>{amountField}</>}
                        </Col>
                        <Col xs={2} style={{textAlign: 'right'}}>
                            {deleteButton}
                        </Col>
                    </Row>
                </Mobile>
            </Form>
            <Modal
                visible={loanModuleOpen}
                onOk={() => {
                    setLoanModuleOpen(false)
                }}
                onCancel={() => setLoanModuleOpen(false)}
                okText={"Lening toevoegen"}
                cancelText={"Annuleren"}
                okButtonProps={{disabled: true}}
                footer={null}
            >
                <LoanCalculationModuleFetchContainer onClose={() => setLoanModuleOpen(false)}
                                                     selectedLoanId={selectedLoanId!} />
            </Modal>
        </>
    )
};

const FinancingItemSingleRowFormView = Form.create<FormProps>({
    name: 'financingItem',
})(FinancingItemSingleRowForm);

export default FinancingItemSingleRowFormView
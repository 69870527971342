import React, {useContext, useEffect, useState} from 'react'
import {Button, Col, Form, Input, Row, Select, Slider, Tooltip} from 'antd'
import {I18nContext} from "../../../i18n";
import VorigeVolgende from "../common/vorige-volgende";
import {FormComponentProps, ValidateCallback} from "antd/es/form";
import ShadowBox from "../../common/shadow-box/shadow-box";
import {Profile} from "../../../generated/graphql";
import {belgium, belgiumIds, europe} from "./maps"
import AcertaIcon from "../../common/iconfont";
import {useMediaQuery} from "react-responsive";
import {isMobileQuery} from "../../../utils/media-queries";
import profileIcon from "../../../assets/images/plant01.svg";
import Header from "../common/header";
import {FintoolContext} from "../../../context/fintool-context";
import _ from 'lodash'
import RequiredIcon from "../../common/forms/required-icon";

const {Option} = Select;

interface FormProps extends FormComponentProps {
    data: Profile,
    valueChange: any
}

interface RegionProps {
    d: string
    id: string
    name: string
    type: string
}

interface RegionDisplayProps {
    id: string
    translation: string
    isBelgium: boolean
}

const ProfileViewForm: React.FC<FormProps> = ({form, data, next, valueChange}: any) => {
    const {translate} = useContext(I18nContext);
    const {getFieldDecorator} = form;
    const isMobile = useMediaQuery(isMobileQuery)
    const [context, setContext] = useContext(FintoolContext)

    const [hoverRegion, setHoverRegion] = useState('')
    const [clickedCountry, setClickedCountry] = useState('BE')

    const [selectedRegions, setSelectedRegions] = useState<string[]>([])
    const [selectedSectors, setSelectedSectors] = useState<string[]>([])

    useEffect(() => {
        if (data.profile) {
            if (data.profile.regions) {
                setSelectedRegions(data.profile.regions)
            }
            if (data.profile.sectors) {
                setSelectedSectors(data.profile.sectors)
            }
        }
        let errors = []
        if (!data.profile.sectors || data.profile.sectors.length === 0) {
            errors.push("profile.validation.sectors.required")
        }
        if (!data.profile.regions || data.profile.regions.length === 0) {
            errors.push("profile.validation.region.required")
        }
        if (!data.profile.desiredNetMonthlyIncome || data.profile.desiredNetMonthlyIncome === 0) {
            errors.push("profile.validation.netto.required")
        }
        setContext({...context, validationErrors: errors})

        // eslint-disable-next-line
    }, [data.profile])

    const formatter = (value: number) => {
        return (
            <>
                <div style={{
                    fontSize: 30,
                    backgroundColor: '#FFF',
                    fontFamily: 'Arial Rounded MT Bold Regular'
                }}>
                    <span className="acerta-light-blue-1">€</span>
                    <span className="acerta-dark-blue">&nbsp;{value}</span>
                </div>
                <div style={{textAlign: 'right', marginTop: -5}}>
                    <sup
                        className="acerta-grey-dark center"
                        style={{color: '#a8a8a8', marginTop: -3}}>
                        <em>{translate("profile.netto.per.maand")}</em>
                    </sup>
                </div>
            </>
        )
    }

    const regionClick = (clickedRegionId: string) => {
        let newSelectedRegions: string[] = []

        if (selectedRegions.find((id: string) => id === clickedRegionId)) {
            newSelectedRegions = selectedRegions.filter(id => id !== clickedRegionId)
        } else
            // BE clicked and province in selection? => remove all provinces
        if (clickedRegionId === "BE" && belgiumIds.some(r => selectedRegions.includes(r))) {
            newSelectedRegions = selectedRegions.filter((id: string) => !belgiumIds.includes(id))
            newSelectedRegions = [...newSelectedRegions, "BE"]
        } else
            // BE selected and now a province clicked? => remove BE and add all provinces except the one clicked
        if (selectedRegions.includes("BE") && belgiumIds.includes(clickedRegionId)) {
            newSelectedRegions = selectedRegions.filter(id => id !== "BE")
            newSelectedRegions = [...newSelectedRegions, ...belgiumIds.filter((id: string) => id !== clickedRegionId)]
        } else {
            newSelectedRegions = [...selectedRegions, clickedRegionId]
        }
        // all belgian provinces selected?
        const allSelected = belgiumIds.every(function (val) {
            return newSelectedRegions.indexOf(val) >= 0;
        });
        if (allSelected) {
            // remove provinces and add BE
            newSelectedRegions = newSelectedRegions.filter((id: string) => !belgiumIds.includes(id))
            newSelectedRegions = [...newSelectedRegions, "BE"]
        }

        newSelectedRegions = newSelectedRegions.sort(function (a: string, b: string) {
            return a > b ? 1 : -1;
        })

        // always avoid doubles
        newSelectedRegions = [...newSelectedRegions.filter((v, i, a) => a.indexOf(v) === i)]

        setSelectedRegions(newSelectedRegions);
        onValueChange(false, newSelectedRegions)
    }

    const onValueChange = (next: boolean, newSelectedRegions?: string[], newSelectedSectors?: string[], newSelectedDependents?: number) => {
        form.validateFields((err: ValidateCallback<any>, values: any) => {
            if (!err) {
                let newSectors = newSelectedSectors ? newSelectedSectors : values.sectors
                let errors = []
                if (!newSectors || newSectors.length === 0) {
                    errors.push(translate("profile.validation.sectors.required", true))
                }
                let newRegions = newSelectedRegions ? newSelectedRegions : selectedRegions
                if (!newRegions || newRegions.length === 0) {
                    errors.push(translate("profile.validation.region.required", true))
                }
                let desiredNet = values.desiredNetMonthlyIncome
                if (!desiredNet || desiredNet === 0) {
                    errors.push(translate("profile.validation.netto.required", true))
                }
                setContext({...context, validationErrors: errors})
                valueChange(
                    newSelectedSectors ? newSelectedSectors : values.sectors,
                    newSelectedRegions ? newSelectedRegions : selectedRegions,
                    values.desiredNetMonthlyIncome,
                    values.profession,
                    newSelectedDependents! >= 0 ? newSelectedDependents : values.dependentChildren,
                    next)
                if (errors.length > 0) {
                    return
                }
                setContext({
                    ...context,
                    validationErrors: []
                })
            }
        });
    };

    const sectorsRemove = (value: any) => {
        let newSelectedSectors = selectedSectors.filter((id: string) => id !== value)
        setSelectedSectors(newSelectedSectors)
        onValueChange(false, undefined, newSelectedSectors)
    }

    const sectorsAdd = (value: any) => {
        let newSelectedSectors = [...selectedSectors, value]
        setSelectedSectors(newSelectedSectors)
        onValueChange(false, undefined, newSelectedSectors)
    }

    const selectDependentChildren = (value: number) => {
        onValueChange(false, undefined, undefined, value)
    }

    const fillColor = (regionId: string) => {
        let theColor;
        if (clickedCountry === "BE" && selectedRegions.includes("BE")) {
            theColor = "#E8318A"
        } else {
            if (regionId === "BE") {
                if (selectedRegions.includes("BE")) {
                    theColor = "#E8318A"
                } else if (belgiumIds.some(r => selectedRegions.includes(r))) {
                    theColor = "url(#diagonalHatch)"
                }
            } else {
                theColor = selectedRegions.find((id: string) => id === regionId) ? "#E8318A" : "#c8c8c8"
            }
        }
        return (hoverRegion === regionId && !isMobile) ? 'rgba(232, 49, 138, 0.7)' : theColor
    }

    const strokeWidth = (regionId: string) => {
        return hoverRegion === regionId ? "1" : "0.8"
    }

    const belgiumStripedOrNot = (regionId: string) => {
        if (regionId === "BE") {
            return "striped"
        } else {
            return ""
        }
    }

    let regionsForDisplay: RegionDisplayProps[] = selectedRegions.map((regionId) => {
        const forDisplay: RegionDisplayProps = {
            id: regionId,
            translation: translate(regionId),
            isBelgium: regionId.indexOf("BE-") === 0
        }
        return forDisplay;
    })
    regionsForDisplay = _.orderBy(regionsForDisplay, ['isBelgium', 'translation'], ['desc', 'asc'])

    return (
        <>
            <Header stepName={"profile"} iconSource={profileIcon}
                    defaultNumberOfTexts={1}
            />
            <Form onSubmit={next}>
                <Row gutter={30}>
                    <Col md={9}>
                        <ShadowBox title={<span>{translate('profile.domain.title')}<RequiredIcon /></span>} open={true}
                                   noArrow={true}>
                            <Form.Item style={{marginBottom: 0}}>
                                {getFieldDecorator('sectors', {
                                    initialValue: data.profile.sectors || [],
                                })(
                                    <Select
                                        getInputElement={() => <input readOnly />}
                                        showSearch={false}
                                        filterOption={false}
                                        mode="multiple"
                                        style={{width: '100%'}}
                                        placeholder={translate("profile.placeholder.domain")}
                                        onSelect={sectorsAdd}
                                        onDeselect={sectorsRemove}
                                    >
                                        <Option key="BANK_STOCKMARKET_INSURANCE"
                                                value="BANK_STOCKMARKET_INSURANCE">{translate("profile.bank_stockmarket_insurance")}</Option>
                                        <Option key="CONSTRUCTION_PRODUCTION_TECHNOLOGY"
                                                value="CONSTRUCTION_PRODUCTION_TECHNOLOGY">{translate("profile.construction_production_technology")}</Option>
                                        <Option key="COMMUNICATION_MEDIA"
                                                value="COMMUNICATION_MEDIA">{translate("profile.communication_media")}</Option>
                                        <Option key="HEALTH_WELFARE_SPORT"
                                                value="HEALTH_WELFARE_SPORT">{translate("profile.health_welfare_sport")}</Option>
                                        <Option key="HOSPITALITY_RECREATION_AND_TOURISM"
                                                value="HOSPITALITY_RECREATION_AND_TOURISM">{translate("profile.hospitality_recreation_and_tourism")}</Option>
                                        <Option key="ICT" value="ICT">{translate("profile.ict")}</Option>
                                        <Option key="JUSTICE_PUBLIC_ADMINISTRATION_OR_SECURITY"
                                                value="JUSTICE_PUBLIC_ADMINISTRATION_OR_SECURITY">{translate("profile.justice_public_administration_or_security")}</Option>
                                        <Option key="AGRICULTURE_NATURE_OR_FISHERIES"
                                                value="AGRICULTURE_NATURE_OR_FISHERIES">{translate("profile.agriculture_nature_or_fisheries")}</Option>
                                        <Option key="LOGISTICS_OR_TRANSPORT"
                                                value="LOGISTICS_OR_TRANSPORT">{translate("profile.logistics_or_transport")}</Option>
                                        <Option key="EDUCATION_CULTURE_AND_SCIENCE"
                                                value="EDUCATION_CULTURE_AND_SCIENCE">{translate("profile.education_culture_and_science")}</Option>
                                        <Option key="OTHER"
                                                value="OTHER">{translate("profile.other")}</Option>
                                    </Select>
                                )}
                            </Form.Item>
                        </ShadowBox>
                    </Col>
                    <Col md={9}>
                        <ShadowBox title={translate("profile.profession.title")} open={true} noArrow={true}>
                            <Form.Item style={{marginBottom: 0}}>
                                {getFieldDecorator('profession', {
                                    initialValue: data.profile.profession,
                                })(
                                    <Input onPressEnter={() => onValueChange(false)} onBlur={() => onValueChange(false)}
                                           placeholder={translate("profile.placeholder.profession")} />,
                                )}
                            </Form.Item>
                        </ShadowBox>
                    </Col>
                    <Col md={6}>
                        <ShadowBox title={translate("profile.dependents.title")} open={true} noArrow={true}>
                            <Form.Item style={{marginBottom: 0}}>
                                {getFieldDecorator('dependentChildren', {
                                    initialValue: data.profile.dependentChildren,
                                })(
                                    <Select onChange={selectDependentChildren}>
                                        {[...Array(10)].map((_, i) => {
                                            return <Option value={i}>{i}</Option>
                                        })}
                                    </Select>,
                                )}
                            </Form.Item>
                        </ShadowBox>
                    </Col>
                </Row>
                <ShadowBox title={<span>{translate('profile.income.title')}<RequiredIcon /></span>} open={true}
                           noArrow={true}>
                    <div id="income-tooltopcontainer"></div>
                    <div style={{marginTop: 90}} id="profile-income">
                        <Form.Item style={{marginBottom: 0, paddingLeft: 10, paddingRight: 20}}>
                            {getFieldDecorator('desiredNetMonthlyIncome', {
                                initialValue: data.profile.desiredNetMonthlyIncome,
                            })(
                                <Slider
                                    onAfterChange={() => onValueChange(false)}
                                    step={100}
                                    max={5000}
                                    tooltipVisible
                                    tipFormatter={formatter}
                                    getTooltipPopupContainer={() => {
                                        return document.getElementById('income-tooltopcontainer') || document.body
                                    }}
                                />
                            )}
                        </Form.Item>
                    </div>
                </ShadowBox>
                <ShadowBox title={
                    <div style={{marginBottom: 10}}>
                        <Row>
                            <Col xs={24} md={16} style={{marginBottom: isMobile ? 10 : 0}}>
                                {translate('profile.region.title')}<RequiredIcon />
                            </Col>
                            <Col xs={24} md={8}
                                 style={{textAlign: isMobile ? 'left' : 'right', marginBottom: 20}}
                            >
                                <div className="rounded-font">
                                <span
                                    onClick={() => {
                                        setHoverRegion('')
                                        setClickedCountry('BE')
                                    }}
                                    style={{
                                        cursor: 'pointer',
                                        paddingBottom: 8,
                                        marginRight: 16,
                                        color: clickedCountry === 'BE' ? '#4CB4E7' : '#a8a8a8',
                                        borderBottom: clickedCountry === 'BE' ? '3px solid #4CB4E7' : 'none'
                                    }}>
                                    {translate("profile.region.by.belgie")}
                                </span>
                                    <span
                                        onClick={() => {
                                            setHoverRegion('')
                                            setClickedCountry('EU')
                                        }}
                                        style={{
                                            cursor: 'pointer',
                                            paddingBottom: 8,
                                            color: clickedCountry === 'EU' ? '#4CB4E7' : '#a8a8a8',
                                            borderBottom: clickedCountry === 'EU' ? '3px solid #4CB4E7' : 'none'
                                        }}
                                    >
                                    {translate("profile.region.by.europa")}
                                </span>
                                </div>
                            </Col>
                        </Row>
                    </div>
                } open={true} noArrow={true}>
                    <Row>
                        <Col xs={24} md={18}>
                            {clickedCountry !== 'BE' ?
                                <div
                                    style={{
                                        position: 'relative',
                                        height: 0,
                                        width: '100%',
                                        padding: 0,
                                        paddingBottom: '100%'
                                    }}
                                >
                                    <svg
                                        style={{
                                            position: 'absolute',
                                            height: '100%',
                                            width: '100%',
                                            left: 0,
                                            top: 0,
                                        }}
                                        viewBox="120 0 900 684" fill="#ececec" stroke="#fff" strokeLinecap="round"
                                        strokeLinejoin="round" strokeWidth=".5" xmlns="http://www.w3.org/2000/svg">
                                        <pattern
                                            id="diagonalHatch"
                                            patternUnits="userSpaceOnUse"
                                            width="4"
                                            height="8"
                                            patternTransform="rotate(-45 2 2)"
                                        >
                                            <path d="M -1,2 l 6,0" stroke="#E8318A" stroke-width="4" />
                                            <path d="M -1,6 l 6,0" stroke="#ececec" stroke-width="4" />
                                        </pattern>
                                        {europe.map((region: RegionProps) => {
                                            return isMobile ?
                                                <path
                                                    className={belgiumStripedOrNot(region.id)}
                                                    key={region.d}
                                                    d={region.d}
                                                    id={region.id}
                                                    name={region.name}
                                                    fill={fillColor(region.id)}
                                                    strokeWidth={strokeWidth(region.id)}
                                                    onMouseEnter={() => setHoverRegion(region.id)}
                                                    onMouseLeave={() => setHoverRegion('')}
                                                    onClick={() => {
                                                        regionClick(region.id)
                                                    }}
                                                /> :
                                                <Tooltip title={translate(region.id)}>
                                                    <path
                                                        className={belgiumStripedOrNot(region.id)}
                                                        key={region.d}
                                                        d={region.d}
                                                        id={region.id}
                                                        name={region.name}
                                                        fill={fillColor(region.id)}
                                                        strokeWidth={strokeWidth(region.id)}
                                                        onMouseEnter={() => setHoverRegion(region.id)}
                                                        onMouseLeave={() => setHoverRegion('')}
                                                        onClick={() => {
                                                            regionClick(region.id)
                                                        }}
                                                    />
                                                </Tooltip>
                                        })}
                                    </svg>
                                </div>
                                :
                                <div
                                    style={{
                                        position: 'relative',
                                        height: 0,
                                        width: '100%',
                                        padding: 0,
                                        paddingBottom: '100%'
                                    }}
                                >
                                    <svg
                                        style={{
                                            position: 'absolute',
                                            height: '100%',
                                            width: '100%',
                                            left: 0,
                                            top: 0,
                                        }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 752 611"
                                        fill="#ececec" stroke="#fff" strokeLinecap="round"
                                        strokeLinejoin="round" strokeWidth="2"
                                    >
                                        {belgium.map((region: RegionProps) => {
                                                return isMobile ? <path
                                                        key={region.d}
                                                        d={region.d}
                                                        id={region.id}
                                                        name={region.name}
                                                        fill={fillColor(region.id)}
                                                        strokeWidth={strokeWidth(region.id)}
                                                        onMouseEnter={() => setHoverRegion(region.id)}
                                                        onMouseLeave={() => setHoverRegion('')}
                                                        onClick={() => {
                                                            regionClick(region.id)
                                                        }
                                                        }
                                                    /> :
                                                    <Tooltip key={region.id} title={translate(region.id)}>
                                                        <path
                                                            key={region.d}
                                                            d={region.d}
                                                            id={region.id}
                                                            name={region.name}
                                                            fill={fillColor(region.id)}
                                                            strokeWidth={strokeWidth(region.id)}
                                                            onMouseEnter={() => setHoverRegion(region.id)}
                                                            onMouseLeave={() => setHoverRegion('')}
                                                            onClick={() => {
                                                                regionClick(region.id)
                                                            }
                                                            }
                                                        />
                                                    </Tooltip>
                                            }
                                        )}
                                    </svg>
                                </div>
                            }
                        </Col>
                        <Col xs={24} md={6} style={{textAlign: isMobile ? 'left' : 'right', paddingRight: 10}}>
                            <div>
                                {regionsForDisplay.map((regionForDisplay: RegionDisplayProps) =>
                                    <div key={regionForDisplay.id}>
                                        {!isMobile &&
                                        <span
                                            className={regionForDisplay.isBelgium || regionForDisplay.id === 'BE' ? 'acerta-medium-blue-1 bold' : ''}
                                            style={{paddingRight: 5,}}>
                                            {regionForDisplay.translation}
                                        </span>}
                                        <Button
                                            style={{
                                                verticalAlign: '-0.1em',
                                                height: 'inherit',
                                                paddingLeft: 0,
                                                paddingRight: 0,
                                            }}
                                            type={"link"}
                                            onClick={() => regionClick(regionForDisplay.id)}>
                                            <AcertaIcon size={"16"} icon={"icon-trash-24"} />
                                        </Button>
                                        {isMobile && <span
                                            className={regionForDisplay.isBelgium || regionForDisplay.id === 'BE' ? 'acerta-medium-blue-1 bold' : ''}
                                            style={{paddingLeft: 5,}}>
                                            {regionForDisplay.translation}
                                        </span>}
                                    </div>
                                )}
                            </div>
                        </Col>
                    </Row>
                </ShadowBox>
                <VorigeVolgende />
            </Form>
        </>
    )
};

const ProfileView = Form.create<FormProps>({name: 'ProfileView',})(ProfileViewForm);

export default ProfileView
import React, {useContext} from 'react'
import SimulationView from "./simulation-view";
import {useParams} from "react-router-dom"
import {useCalculationInfoQuery} from "../../generated/graphql";
import {Spin} from "antd";
import {handle} from "../app/app";
import {FintoolContext} from "../../context/fintool-context";

interface Props {
    calculationHash: string
}

const SimulationContainerQuery: React.FC<Props> = ({calculationHash}) => {
    const[context, setContext] = useContext(FintoolContext)
    const {data, error, loading, networkStatus} = useCalculationInfoQuery({
        variables: {calculationHash: calculationHash},
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "cache-and-network"
    });

    if (loading || networkStatus === 4) {
        return <div><Spin size="small" /></div>;
    }

    error && handle(error)

    if (!data) {
        return null;
    }
    const info = (data && data.calculationInfo) ? data.calculationInfo : null
    if (!!info) {
        if (context.financeSeenState !== info.financeSeenState) {
            setContext({...context, financeSeenState: info.financeSeenState})
        }
        if (context.initState !== info.initState) {
            setContext({...context, initState: info.initState})
        }
        if (context.expenseSeenState !== info.expenseSeenState) {
            setContext({...context, expenseSeenState: info.expenseSeenState})
        }
    }
    return (
        <SimulationView calculationInfo={info} />
    )
}

const SimulationContainer = () => {
    let {calculationHash} = useParams();
    if (!!calculationHash) {
        return <SimulationContainerQuery calculationHash={calculationHash} />
    }
    return null
}

export default SimulationContainer
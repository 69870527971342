import gql from 'graphql-tag';

export const CALCULATION_INFO = gql`
    query CalculationInfo($calculationHash: String!) {
        calculationInfo(calculationHash: $calculationHash) {
            fintoolUser {
                email
            }
            code
            initState
            financeSeenState
            expenseSeenState
        }
    }
`;

export const UPDATE_INIT_STATE = gql`
    mutation UpdateInitState($calculationHash: String!, $newState: Boolean!) {
        updateInitState(calculationHash: $calculationHash, newState: $newState) {
            initState
        }
    }
`;

export const UPDATE_FINANCE_SEEN_STATE = gql`
    mutation UpdateFinanceSeenState($calculationHash: String!, $newState: Boolean!) {
        updateFinanceSeenState(calculationHash: $calculationHash, newState: $newState) {
            financeSeenState
        }
    }
`;

export const UPDATE_EXPENSE_SEEN_STATE = gql`
    mutation UpdateExpenseSeenState($calculationHash: String!, $newState: Boolean!) {
        updateExpenseSeenState(calculationHash: $calculationHash, newState: $newState) {
            expenseSeenState
        }
    }
`;


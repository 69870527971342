import React, {useContext} from 'react'
import {FINANCING} from "./queries";
import {RESULT} from "../common/result-chart/queries";
import {message} from "antd";
import {FintoolContext} from "../../../context/fintool-context";
import {I18nContext} from "../../../i18n";
import {MESSAGE_DURATION} from "../../../utils/constants";
import FinancingItemSingleRowFormView from "./financing-item-single-row-form-view";
import {
    FinancingItemInput,
    FinancingItemType,
    useRemoveFinancingItemMutation,
    useUpdateFinancingItemMutation
} from "../../../generated/graphql";

export interface FinancingItemProps {
    id: string
    name: string
    amount: number
    financingItemType: FinancingItemType
    includeInCalculation: boolean
    financing?: number
    option?: FinancingItemType
}

interface Props {
    financingItem: FinancingItemProps
}

const FinancingItemSingleRowFormContainer: React.FC<Props> = ({financingItem}) => {

    const [context] = useContext(FintoolContext)
    const {translate} = useContext(I18nContext);

    const [updateFinancingItem] = useUpdateFinancingItemMutation({
            refetchQueries: [
                {query: FINANCING, variables: {calculationHash: context.calculationHash}},
                {query: RESULT, variables: {calculationHash: context.calculationHash}}
            ]
        }
    )
    const [removeFinancingItem] = useRemoveFinancingItemMutation({
            refetchQueries: [
                {query: FINANCING, variables: {calculationHash: context.calculationHash}},
                {query: RESULT, variables: {calculationHash: context.calculationHash}}
            ]
        }
    )

    const onChange = (financingItemInput: FinancingItemInput) => {
        updateFinancingItem({
                variables: {
                    calculationHash: context.calculationHash!,
                    financingItemInput: financingItemInput
                }
            }
        ).then((result: any) => {
            message.success(translate("common.save.success"), MESSAGE_DURATION)
        }).catch((error: any) => {
            console.log(error.message);
            message.error(error.message)
        })
    };

    const onRemove = (id: string) => {
        removeFinancingItem({
            variables: {
                calculationHash: context.calculationHash!,
                financingItemId: id,
                financingItemType: financingItem.financingItemType
            }
        }).then((result: any) => {
            message.success(translate("common.delete.succes"), MESSAGE_DURATION)
        }).catch((error: any) => {
            message.error(error.message)
        })
    }
    return (
        <FinancingItemSingleRowFormView
            financingItem={financingItem}
            onChange={onChange}
            onRemove={onRemove}
        />
    )
}

export default FinancingItemSingleRowFormContainer
import React from 'react'
import ApolloClient from 'apollo-client';
import {HOST_URL} from "../../utils/constants";
import {ApolloLink} from "apollo-link";
import {createHttpLink} from "apollo-link-http";
import {onError} from "apollo-link-error";
import {InMemoryCache} from 'apollo-cache-inmemory';

import Cookies from "js-cookie";
import {ApolloProvider} from 'react-apollo';
import {ApolloProvider as ApolloHooksProvider} from '@apollo/react-hooks';
import {I18nContextProvider} from "../../i18n";
import App from "./app";
import {BrowserRouter as Router} from "react-router-dom";
import CustomError from "../common/error-pages/custom-error";
import {render} from "react-dom";
import {FintoolProvider} from "../../context/fintool-context";

const ApolloApp = () => {

    const httpLink = createHttpLink({uri: `${HOST_URL}/graphql`});
    const errorLink = onError(({networkError, graphQLErrors, forward, operation}) => {
        if (networkError) {
            console.log(JSON.stringify(networkError))
            const calculationHash = Cookies.get("calculationHash")
            render(
                <Router basename="/">
                    <CustomError status="500" calculationHash={calculationHash} />
                </Router>,
                document.getElementById('root')
            )
        }
        if (graphQLErrors && graphQLErrors.length > 0) {
            if (graphQLErrors[0]!.extensions && graphQLErrors[0]!.extensions.errorCode === 'DATA_NOT_FOUND') {
                render(
                    <Router basename="/">
                        <CustomError status="404" message={graphQLErrors[0]!.extensions.errorMessage}/>
                    </Router>,
                    document.getElementById('root')
                )
            }
        }
        return forward(operation)
    });
    const middlewareLink = new ApolloLink((operation, forward) => {
        operation.setContext({
            headers: {
                "Accept-Language": Cookies.get("lang") || "nl"
            },
        });
        return forward(operation);
    });

    const link = errorLink.concat(middlewareLink).concat(httpLink);

    const client = new ApolloClient({
        link: link,
        cache: new InMemoryCache()
    });

    return (
        <ApolloProvider client={client}>
            <ApolloHooksProvider client={client}>
                <FintoolProvider>
                    <I18nContextProvider>
                        <App />
                    </I18nContextProvider>
                </FintoolProvider>
            </ApolloHooksProvider>
        </ApolloProvider>
    )
}

export default ApolloApp
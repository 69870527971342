import React, {useContext} from 'react'
import {Redirect, Route, Switch} from "react-router-dom"
import {Col, Layout, message, notification, Row, Skeleton} from 'antd'
import Header from "../common/header";
import Footer from "../common/footer";
import FourOFour from "../common/404";
import SimulationContainer from "../simulatie";
import Admin from "../../devonly/Admin";
import LandingContainer from "../landing";
import {getTranslate, I18nContext} from "../../i18n";
import Cookies from 'js-cookie';
import {IconType} from "antd/es/notification";
import CookiePolicy from "../common/cookie-policy";
import Helmet from "react-helmet";

message.config({
    maxCount: 1,
});

export const handle = (error: any) => {
    message.error(getTranslate(Cookies.get("language") || 'nl')("common.error.fetching.data"))
    return <div style={{padding: 20}}><Skeleton /></div>;
}

export const openNotificationWithIcon = (type: IconType, message: any, description: any) => {
    notification[type]({
        key: type,
        message: message,
        description: description,
    });
};

export const getBackendPrefix = () => {
    if (window.location.href.indexOf(':3000') !== -1) {
        // om testen op mobile via ip ook mogelijk te maken
        return window.location.href.substr(0, window.location.href.indexOf(':3000')) + ':8080';
    }
    return ''
}

const App = () => {
    const {langCode, translate} = useContext(I18nContext);
    return (
        <>
            <Helmet>
                <meta name="description" content={translate("landing.banner.subtitle")} />
                <title>{translate("landing.banner.subtitle")}</title>
            </Helmet>
            <Layout>
                <Layout.Header>
                    <Header />
                </Layout.Header>
                <Layout.Content>
                    <Row>
                        <Col>
                            <Switch>
                                <Route exact path="/simulatie/:calculationHash"
                                       render={({match}) => (
                                           <Redirect
                                               to={`/simulatie/profiel/${match!.params.calculationHash}`} />
                                       )} />
                                <Route exact path="/simulatie/profiel/:calculationHash"
                                       children={<SimulationContainer />} />
                                <Route exact path="/simulatie/ontvangsten/:calculationHash"
                                       component={SimulationContainer} />
                                <Route exact path="/simulatie/uitgaven/:calculationHash"
                                       component={SimulationContainer} />
                                <Route exact path="/simulatie/financiering/:calculationHash"
                                       component={SimulationContainer} />
                                <Route exact path="/simulatie/resultaat/:calculationHash"
                                       component={SimulationContainer} />
                                <Route exact path="/admin" component={Admin} />
                                <Route exact path="/:language/" component={LandingContainer} />
                                <Route exact path="/"
                                       render={() => (
                                           <Redirect
                                               to={`/${langCode || 'nl'}`} />
                                       )}
                                />
                                <Route component={FourOFour} />
                            </Switch>
                        </Col>
                    </Row>
                </Layout.Content>
                <Layout.Footer>
                    <Footer />
                </Layout.Footer>
            </Layout>
            <CookiePolicy />
        </>
    )
}

export default App
import gql from 'graphql-tag';

export const VEHICLE = gql`
    query Vehicle($calculationHash: String!, $vehicleId: String!) {
        vehicle(calculationHash: $calculationHash, vehicleId: $vehicleId) {
            id
            vehicleType
            vehicleFinancingType
            purchase {
                purchaseAmount
                maintenance
                tax
                milagePrivate
                milageProfessional
                fuelConsumption
                fuelPricePerLiter
                insurance
                otherCosts
            }
            leasing {
                leaseAmount
                otherCosts
                milagePrivate
                milageProfessional
            }
            includeInCalculation
            calculatedCashFlowValue
            calculateNetValue
            total
        }
    }
`;

export const UPDATE_VEHICLE = gql`
    mutation UpdateVehicle($calculationHash: String!, $vehicleInput: VehicleInput!) {
        updateVehicle(calculationHash: $calculationHash, vehicleInput: $vehicleInput) {
            id
            vehicleType
            vehicleFinancingType
            purchase {
                purchaseAmount
                maintenance
                tax
                milagePrivate
                milageProfessional
                fuelConsumption
                fuelPricePerLiter
                insurance
                otherCosts
            }
            leasing {
                leaseAmount
                otherCosts
                milagePrivate
                milageProfessional
            }
            includeInCalculation
            calculatedCashFlowValue
            calculateNetValue
            total
        }
    }
`;
import React, {useContext} from "react";
import ResultView from "./result-view";
import {steps, useSetCorrectSimulationStep} from "../simulation-view";
import {RouteComponentProps} from "react-router";
import {FintoolContext} from "../../../context/fintool-context";
import {useCalculationInfoQuery, useUpdateInitStateMutation} from "../../../generated/graphql";
import {message, Spin} from "antd";
import {handle} from "../../app/app";
import {RESULT} from "../common/result-chart/queries";
import {CALCULATION_INFO} from "../queries";


interface Props extends RouteComponentProps {
}

const ResultContainer: React.FC<Props> = ({match}) => {

    useSetCorrectSimulationStep(match, steps.RESULT)

    const [context, setContext] = useContext(FintoolContext)

    const [updateInitState] = useUpdateInitStateMutation({
            refetchQueries: [
                {query: RESULT, variables: {calculationHash: context.calculationHash}},
                {query: CALCULATION_INFO, variables: {calculationHash: context.calculationHash}}
            ]
        }
    )

    const onUpdate = () => {
        updateInitState({
            variables: {
                calculationHash: context.calculationHash!,
                newState: false
            }
        }).then((result: any) => {
            setContext({...context, initState: result.data.updateInitState.initState})
        }).catch(error => {
            message.error(error.message)
        })
    }

    const {data, error, loading, networkStatus} = useCalculationInfoQuery({
        variables: {calculationHash: context.calculationHash!},
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "cache-and-network"
    });

    if (loading || networkStatus === 4) {
        return <div><Spin size="small" /></div>;
    }

    error && handle(error);

    if (!data) {
        return <div>no data</div>;
    }

    return (
        <ResultView calculationInfo={data.calculationInfo} updateInitState={onUpdate} />
    )
}

export default ResultContainer
import React, {useContext} from 'react'
import ExpenseView from "./expense-view";
import {
    useExpenseQuery, useUpdateExpenseSeenStateMutation,
    useUpdateInitStateMutation
} from "../../../generated/graphql";
import {message, Spin} from "antd";
import {handle} from "../../app/app";
import {FintoolContext} from "../../../context/fintool-context";
import {RESULT} from "../common/result-chart/queries";
import {CALCULATION_INFO} from "../queries";

interface Props {
    calculationHash: string
}

const ExpenseContainer: React.FC<Props> = ({calculationHash}) => {

    const [context, setContext] = useContext(FintoolContext)

    const [updateInitState] = useUpdateInitStateMutation({
            refetchQueries: [
                {query: RESULT, variables: {calculationHash: context.calculationHash}},
                {query: CALCULATION_INFO, variables: {calculationHash: context.calculationHash}}
            ]
        }
    )

    const [updateExpenseSeenState] = useUpdateExpenseSeenStateMutation({
            refetchQueries: [
                {query: RESULT, variables: {calculationHash: context.calculationHash}},
                {query: CALCULATION_INFO, variables: {calculationHash: context.calculationHash}}
            ]
        }
    )

    const onUpdateExpenseSeen = () => {
        updateExpenseSeenState({
            variables: {
                calculationHash: context.calculationHash!,
                newState: true
            }
        }).then((result: any) => {
            setContext({...context, expenseSeenState: result.data.updateExpenseSeenState.expenseSeenState})
        }).catch((error: any) => {
            message.error(error.message)
        })
    }

    const onUpdate = () => {
        updateInitState({
            variables: {
                calculationHash: context.calculationHash!,
                newState: false
            }
        }).then((result: any) => {
            setContext({...context, initState: result.data.updateInitState.initState})
        }).catch(error => {
            message.error(error.message)
        })
    }

    const {data, error, loading, networkStatus} = useExpenseQuery({
        variables: {calculationHash: calculationHash},
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "network-only"
    });

    if (loading || networkStatus === 4) {
        return <div><Spin size="small" /></div>;
    }

    error && handle(error)

    if (!data) {
        return null;
    }

    if (!!data.expense) {
        return (
            <ExpenseView data={data.expense} updateInitState={onUpdate} updateExpenseSeenState={onUpdateExpenseSeen} />
        )
    }
    return null
}

export default ExpenseContainer
import React, {useContext, useState} from 'react'
import {Button, Col, Drawer, Icon, Row} from "antd";
import LanguageSelect from "../../../i18n/language-select";
import Calculations from "../../../devonly/Calculations";
import {FintoolContext} from "../../../context/fintool-context";
import logo from "../../../assets/images/acerta-logo.svg";
import {Link, RouteComponentProps, withRouter} from "react-router-dom";
import {Default, isMobileQuery, LargeDesktop, Mobile, TabletOrSmallDesktop} from "../../../utils/media-queries";
import {I18nContext} from "../../../i18n";
import LoadSimulationContainer from "./simulation-code/load-simulation-container";
import SaveSimulationContainer from "./simulation-code/save-simulation-container";
import DownloadContainer from "../download/download-container";
import {useMediaQuery} from "react-responsive";

interface Props extends RouteComponentProps {
    networkError?: boolean
}

const Header: React.FC<Props> = ({location, networkError}) => {

    const [context] = useContext(FintoolContext)
    const {translate} = useContext(I18nContext)
    const [showMenuDrawer, setShowMenuDrawer] = useState(false)
    const isMobile = useMediaQuery(isMobileQuery)

    const downloadButton = <Button type={"link"}
                                   style={{
                                       color: "#3F7CBF",
                                   }}
    >
        <Icon type="download" style={{fontSize: 18}} /> {isMobile && translate("common.pdf.download")}
    </Button>

    return (
        <div id="header">
            <Row>
                <Col xs={18} md={12}  offset={1}>
                    <Row>
                        <Col xs={23}>
                            {networkError ?
                                <a href="/" id={translate("header.logo.link.id")}><img src={logo} alt="Acerta Logo" className="header-logo" /></a> :
                                <Link to="/" id={translate("header.logo.link.id")}><img src={logo} alt="Acerta Logo" className="header-logo" /></Link>}
                            <h1 id="header-title">{translate("header.main.title")}</h1>
                        </Col>
                    </Row>
                </Col>
                {!networkError &&
                <Col xs={5} md={11}>
                    <Row>
                        <Col xs={21} style={{textAlign: 'right'}}>
                            <TabletOrSmallDesktop>
                                <div style={{marginRight: 10, display: "inline-block"}}><LoadSimulationContainer />
                                </div>
                                {!!context.calculationHash &&
                                <>
                                    <div style={{marginRight: 10, display: "inline-block"}}><SaveSimulationContainer
                                        calculationHash={context.calculationHash} /></div>
                                    <div style={{marginRight: 10, display: "inline-block"}}><DownloadContainer
                                        component={downloadButton} /></div>
                                </>}
                            </TabletOrSmallDesktop>
                            <Default>
                                {context.admin ?
                                    <>
                                        <Calculations />
                                    </> : <>&nbsp;</>}
                                <LargeDesktop>
                                    {!context.calculationHash &&
                                    <div style={{marginRight: 10, display: "inline-block"}}><LoadSimulationContainer />
                                    </div>}
                                </LargeDesktop>
                                <div style={{marginLeft: 20, display: "inline-block"}}><LanguageSelect /></div>
                            </Default>
                            <Mobile>
                                <Button type={"link"} onClick={() => setShowMenuDrawer(true)}>
                                    <Icon type="menu" style={{fontSize: 26, marginRight: 10}} />
                                </Button>
                                <Drawer
                                    width={350}
                                    placement="right"
                                    closable={true}
                                    onClose={() => setShowMenuDrawer(false)}
                                    visible={showMenuDrawer}
                                >
                                    <div style={{marginTop: 20}}>
                                        <div style={{marginRight: 10, marginBottom: 10}}>
                                            <LoadSimulationContainer />
                                        </div>
                                        {!!context.calculationHash &&
                                        <>
                                            <div style={{marginRight: 10, marginBottom: 10}}><SaveSimulationContainer
                                                calculationHash={context.calculationHash} /></div>
                                            <div style={{marginRight: 10, marginBottom: 20}}><DownloadContainer
                                                component={downloadButton} /></div>
                                        </>
                                        }
                                        <div style={{display: "inline-block"}}><LanguageSelect
                                            onSelect={() => setShowMenuDrawer(false)} /></div>
                                    </div>
                                </Drawer>
                            </Mobile>
                        </Col>
                        <Col xs={3}></Col>
                    </Row>
                </Col>}
            </Row>
        </div>
    )
}

export default withRouter(Header)
import React, {useState} from 'react'
import CalculationsContainer from "./CalculationsContainer";
import {Button, Drawer, Icon} from 'antd'

const Calculations = () => {

    const [show, setShow] = useState(false)

    return (
        <>
            <Button style={{verticalAlign: '-0.2em', paddingRight: 40}} type={"link"} onClick={() => setShow(true)}>
                <Icon style={{color: '#004B79',fontSize: 20}} type="info-circle" />
            </Button>
            <Drawer
                width={500}
                title="Bestaande simulaties"
                placement="right"
                closable={true}
                onClose={() => setShow(false)}
                visible={show}
            >
                <CalculationsContainer setShow={setShow} />
            </Drawer>
        </>
    )
}

export default Calculations
import React, {useContext, useEffect, useState} from 'react'
import {FinancingItemType, FinancingQuery} from "../../../generated/graphql";
import ShadowBox, {BoxTitle} from "../../common/shadow-box/shadow-box";
import {I18nContext} from "../../../i18n";
import FinancingItemSingleRowFormContainer, {FinancingItemProps} from "./financing-item-single-row-form-container";
import loanIcon from "../../../assets/images/loan.svg";
import Header from "../common/header";
import {Modal} from "antd";
import LoanCalculationModuleFetchLoanContainer
    from "./loan-calculation-module/loan-calculation-module-fetch-container";
import {FintoolContext} from "../../../context/fintool-context";

interface Props {
    data: FinancingQuery,
    onAdd: any,
    newLoanId?: string,
    updateInitState: any
    updateFinanceSeenState: any
}

const FinancingView: React.FC<Props> = ({data, onAdd, newLoanId, updateInitState, updateFinanceSeenState}) => {

    const [loanBoxOpen, setLoanBoxOpen] = useState(true)
    const [boxOpen, setBoxOpen] = useState(true)
    const [loanModuleOpen, setLoanModuleOpen] = useState(false)
    const {translate} = useContext(I18nContext)

    const [context] = useContext(FintoolContext)

    useEffect(() => {
        if (context.initState) {
            updateInitState()
        }
        if (!context.financeSeenState) {
            updateFinanceSeenState()
        }
        // eslint-disable-next-line
    },[])

    const onBeforeAdd = (financingItemProps: FinancingItemProps) => {
        onAdd(financingItemProps)
        if (financingItemProps.option === FinancingItemType.Loan) {
            setLoanModuleOpen(true)
        }
    }

    if (!data) return null
    return (
        <>
            <Header stepName={"financing"} iconSource={loanIcon} defaultNumberOfTexts={1} />
            <ShadowBox title={
                <BoxTitle iconClass="icon-bank-24"
                          text={translate("financing.inbreng.title")}
                          addText={translate("financing.inbreng.add.text")}
                          explanation={translate("financing.inbreng.long.subtitle", true)}
                          setBoxOpen={setBoxOpen} boxOpen={boxOpen}
                          onAdd={onAdd}
                          toAdd={{
                              name: "",
                              option: FinancingItemType.OwnResource,
                              amount: 0,
                          }}
                          noArrow={false}
                          trackOpenCloseClass={translate("financing.open.close.class")}
                          trackAddClass={translate("financing.inbreng.add.class")}
                />
            }
                       open={boxOpen}
                       noArrow={false}>
                {data.financing && data.financing.ownResources && data.financing.ownResources.map((ownResource, index) =>
                    <div className={index === 0 ? 'first' : ''} key={'div_'+ ownResource.id}>
                    <FinancingItemSingleRowFormContainer key={ownResource.id}
                                                         financingItem={{
                                                             id: ownResource.id,
                                                             name: ownResource.name!,
                                                             amount: ownResource.amount,
                                                             financingItemType: FinancingItemType.OwnResource,
                                                             includeInCalculation: ownResource.includeInCalculation
                                                         }} /></div>
                )}
            </ShadowBox>
            <ShadowBox title={
                <BoxTitle iconClass="icon-partner-24"
                          text={translate("financing.leningen.title")}
                          addText={translate("financing.leningen.add.text")}
                          explanation={translate("financing.leningen.long.subtitle", true)}
                          setBoxOpen={setLoanBoxOpen} boxOpen={loanBoxOpen}
                          onAdd={onBeforeAdd}
                          noArrow={false}
                          addOptions={[{
                              value: FinancingItemType.Loan,
                              text: translate("financing.toevoegen." + FinancingItemType.Loan),
                              trackAddClass: translate("financing.toevoegen." + FinancingItemType.Loan + ".add.class")
                          },
                              {
                                  value: FinancingItemType.Subsidy,
                                  text: translate("financing.toevoegen." + FinancingItemType.Subsidy),
                                  trackAddClass: translate("financing.toevoegen." + FinancingItemType.Subsidy + ".add.class")
                              }]}
                          toAdd={{
                              name: "",
                              amount: 0,
                          }}
                          trackOpenCloseClass={translate("financing.open.close.class")}
                          trackAddClass=""
                />
            }
                       open={loanBoxOpen}
                       noArrow={false}>
                {data.financing && data.financing.loans && data.financing.loans.map((loan, index) =>
                    <div className={index === 0 ? 'first' : ''} key={'div_' + loan.id}>
                    <FinancingItemSingleRowFormContainer key={loan.id}
                                                         financingItem={{
                                                             id: loan.id,
                                                             name: loan.name!,
                                                             amount: loan.amount,
                                                             financing: loan.financing,
                                                             financingItemType: FinancingItemType.Loan,
                                                             includeInCalculation: loan.includeInCalculation
                                                         }} /></div>
                )}
                {data.financing && data.financing.subsidies && data.financing.subsidies.map((subsidy, index) =>
                    <div className={(index === 0 && data.financing!.loans!.length <= 0) ? 'first' : ''} key={'div_' + subsidy.id}>
                    <FinancingItemSingleRowFormContainer key={subsidy.id}
                                                         financingItem={{
                                                             id: subsidy.id,
                                                             name: subsidy.name!,
                                                             amount: subsidy.amount,
                                                             financingItemType: FinancingItemType.Subsidy,
                                                             includeInCalculation: subsidy.includeInCalculation
                                                         }} /></div>
                )}
            </ShadowBox>
            {newLoanId &&
            <Modal
                visible={loanModuleOpen}
                onOk={() => {
                    setLoanModuleOpen(false)
                }}
                onCancel={() => setLoanModuleOpen(false)}
                okText={"Lening opslaan"}
                cancelText={"Annuleren"}
                okButtonProps={{disabled: true}}
                footer={null}
            >
                <LoanCalculationModuleFetchLoanContainer selectedLoanId={newLoanId}
                                                         onClose={() => setLoanModuleOpen(false)} />
            </Modal>}
        </>
    )
};

export default FinancingView
import React, {useContext, useState} from 'react'
import {currency, I18nContext} from "../../../i18n";
import {CalculationInfo, ResultQuery} from "../../../generated/graphql";
import {Button, Col, Collapse, Icon, message, Row} from "antd";
import {useMediaQuery} from "react-responsive";
import {Default, isMobileQuery, Mobile} from "../../../utils/media-queries";
import {EmptyShadowBox} from "../../common/shadow-box/shadow-box";
import CopyToClipboard from 'react-copy-to-clipboard'
import ListItem from '../../common/list-item'

const {Panel} = Collapse;

interface Props {
    data: ResultQuery
    calculationInfo: CalculationInfo
}

const ResultDetailsView: React.FC<Props> = ({data, calculationInfo}) => {

    const {translate} = useContext(I18nContext);
    const isMobile = useMediaQuery(isMobileQuery)
    const [detailsActive, setDetailsActive] = useState(false)

    interface HeaderDivProps {
        description: string
        value: number
    }

    interface FooterDivProps {
        description: string
        value: number
        color: string
        negative?: boolean
    }

    const FooterDiv: React.FC<FooterDivProps> = ({description, value, color, negative}) => {
        return (
            <div
                className="rounded-font"
                style={{
                    width: '100%', justifyContent: 'space-between',
                    padding: 16,
                    display: 'flex',
                    color: color,
                    borderTop: '1px solid #d8d8d8',
                    backgroundColor: isMobile ? '#fff' : '#F3F1F1'
                }}
            >
                <div>{description}</div>
                <div>€ {currency(value * (negative ? -1 : 1), true)}</div>
            </div>
        )
    }

    interface DetailDivProps {
        hideOperation?: boolean
        subtract: boolean
        color: string
        description: string
        value: number
        noWrap?: boolean
    }

    const DetailDiv: React.FC<DetailDivProps> = ({subtract, color, description, value, hideOperation, noWrap}) => {
        return (
            <div
                style={{
                    padding: 0,
                    borderBottom: '1px solid #d8d8d8',
                }}
            >
                <div style={{display: 'flex'}}>
                    <div
                        style={{
                            paddingTop: 16,
                            paddingBottom: 16,
                            height: '100%',
                            width: 8,
                            backgroundColor: color
                        }}
                    >&nbsp;</div>
                    {hideOperation ?
                        <div style={{padding: 16, paddingLeft: 34}}></div> :
                        <div><Icon style={{fontSize: 18, padding: 16}} type={subtract ? "minus" : "plus"} /></div>
                    }
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        justifyContent: 'space-between',
                        paddingTop: 16
                    }}>
                        <div>{description}</div>
                        <div className="rounded-font"
                             style={{paddingRight: 16, whiteSpace: noWrap ? "nowrap" : "inherit"}}>{hideOperation ?
                            <span>&nbsp;&nbsp;</span> : (subtract ? '- ' : '+ ')}{currency(value)}</div>
                    </div>
                </div>
            </div>
        )
    }

    const customPanelStyle = {
        background: '#E2E2E2',
        borderRadius: 4,
        border: 0,
        overflow: 'hidden',
    };

    const leftColumnHeader = <Col xs={24} md={12} style={{display: 'flex'}}>
        <div>
            <h3 className="acerta-dark-blue">{translate("result.subtitle.netto.inkomen")}</h3>
        </div>
        <div
            style={{flexGrow: 1, textAlign: 'right', fontSize: 18, paddingRight: isMobile ? 0 : 38}}
            className={"rounded-font"}>
            <span style={{whiteSpace: 'nowrap'}}><span className="acerta-light-blue-1">€ </span>
            <span style={{whiteSpace: 'nowrap'}} className="acerta-dark-blue">
                {currency(data.result.calculatedYearlyNetIncome, true, false)}
            </span></span>
        </div>
    </Col>;
    const rightColHeader = <Col xs={24} md={12} style={{display: 'flex'}}>
        <div style={{paddingLeft: isMobile ? 0 : 40}}>
            <h3 className="acerta-dark-blue">{translate("result.subtitle.beroepsrekening")}</h3>
        </div>
        <div style={{flexGrow: 1, textAlign: 'right', fontSize: 18}} className={"rounded-font"}>
            <span style={{whiteSpace: 'nowrap'}}><span className="acerta-light-blue-1">€ </span>
            <span style={{whiteSpace: 'nowrap'}} className="acerta-dark-blue">
                {data.result.shortage > 0 && <span>-</span>}
                {currency(data.result.surplus > 0 ? data.result.surplus : data.result.shortage, true, false)}
            </span></span>
        </div>
    </Col>;
    const lefColumn = <Col xs={24} md={12}>
        <DetailDiv description={translate("result.label.totaal.inkomsten")}
                   value={data.result.yearlyRevenue}
                   color="#4CB4E7"
                   subtract={false}
                   hideOperation={true}
        />
        <DetailDiv
            description={translate("result.yearlyCost")}
            value={data.result.yearlyCost}
            subtract={true} color="#E8318A" />
        <DetailDiv
            description={translate("result.socialContributions")}
            value={data.result.socialContributionsWithPensionSaving}
            subtract={true} color="#EE7556" />
        <DetailDiv
            description={translate("result.personalIncomeTax")}
            value={data.result.personalIncomeTax}
            subtract={true} color="#FFD500" />
        <FooterDiv
            description={translate("result.calculatedYearlyNetIncome")}
            negative={false}
            value={data.result.calculatedYearlyNetIncome}
            color="#3F7CBF" />
    </Col>;
    const rightColumn = <Col xs={24} md={12}>
        <DetailDiv description={translate("result.label.totaal.inkomsten")}
                   value={data.result.yearlyRevenue}
                   color="#4CB4E7"
                   subtract={false}
                   hideOperation={true}
                   noWrap={true}
        />
        <DetailDiv description={translate("result.yearlyExpense")}
                   value={data.result.yearlyExpense}
                   subtract={true}
                   color="#E8318A"
                   noWrap={true}
        />
        <DetailDiv description={translate("result.contributionResources")}
                   value={data.result.financed}
                   subtract={false}
                   color="#004B79"
                   noWrap={true}
        />
        <FooterDiv
            description={data.result.surplus > 0 ? translate("result.surplus") : translate("result.shortage")}
            value={data.result.surplus > 0 ? data.result.surplus : data.result.shortage}
            negative={data.result.shortage > 0}
            color={data.result.surplus > 0 ? "#68C382" : "#ff4545"} />
    </Col>;
    return (
        <>
            <Row gutter={24}>
                <Col xs={24}>
                    <p>
                        {translate("result.summary.part1", true)}
                        <span
                            style={{whiteSpace: 'nowrap'}}>
                            <strong>€ {currency(data.result.calculatedYearlyNetIncome / 12, true, true)}</strong>
                        </span>
                        {translate("result.summary.part2", true)}
                        {data.result.desiredYearlyNetIncome > data.result.calculatedYearlyNetIncome ?
                            <>
                                <strong
                                    style={{whiteSpace: 'nowrap'}}>{currency(Math.round(data.result.desiredYearlyNetIncome / 12 - data.result.calculatedYearlyNetIncome / 12), false, true)}</strong>
                                {translate("result.summary.part3_minder", true)}
                            </> :
                            <>
                                <strong
                                    style={{whiteSpace: 'nowrap'}}>{currency(Math.round(data.result.calculatedYearlyNetIncome / 12 - data.result.desiredYearlyNetIncome / 12), false, true)}</strong>
                                {translate("result.summary.part3_meer", true)}
                            </>
                        }
                    </p>
                    <p>
                        {data.result.shortage > 0 ?
                            <>
                                <span>{translate("result.summary.jaarbasis.tekort.part1", true)}</span>
                                <span
                                    style={{whiteSpace: 'nowrap'}}><strong>€ {currency(data.result.calculatedYearlyNetIncome, true, true)}</strong></span>
                                <span>{translate("result.summary.jaarbasis.tekort.part2", true)}</span>
                            </>
                            :
                            <>
                                <span>{translate("result.summary.jaarbasis.overschot.part1", true)}</span>
                                <span
                                    style={{whiteSpace: 'nowrap'}}><strong>{currency(data.result.calculatedYearlyNetIncome, false, true)}</strong></span>
                                <span>{translate("result.summary.jaarbasis.overschot.part2", true)}</span>
                            </>
                        }
                    </p>
                    <p style={{marginBottom: 20}}>
                        {data.result.shortage > 0 ?
                            <span
                                style={{whiteSpace: 'nowrap'}}>{translate("result.summary.part4_tekort", true)}</span> :
                            <span
                            >{translate("result.summary.part4_overschot", true)}</span>
                        }
                        {translate("result.summary.part5", true)}
                        <strong
                            style={{marginBottom: 20}}
                        >{data.result.shortage > 0 ? currency(data.result.shortage, false, true) : currency(data.result.surplus, false, true)}</strong>
                        {translate("result.summary.part6", true)}
                    </p>
                </Col>
            </Row>
            <Default>
                <Collapse
                    bordered={false}
                    defaultActiveKey={['0']}
                    expandIconPosition={'left'}
                    style={{marginBottom: 15}}
                    onChange={(key => setDetailsActive(!detailsActive))}
                    className={detailsActive ? translate('result.details.close.class') : translate('result.details.open.class')}
                >
                    <Panel header={
                        <Row>
                            {leftColumnHeader}
                            {rightColHeader}
                        </Row>
                    } key="1" style={customPanelStyle}>
                        <Row gutter={24} style={{backgroundColor: "white"}}>
                            {lefColumn}
                            {rightColumn}
                        </Row>
                    </Panel>
                </Collapse>
            </Default>
            <Mobile>
                <Row>
                    <Col>
                        <Collapse
                            bordered={false}
                            defaultActiveKey={['1']}
                            expandIconPosition={'left'}
                            style={{marginBottom: 15}}
                        >
                            <Panel header={
                                <Row>
                                    {leftColumnHeader}
                                </Row>
                            } key="1" style={customPanelStyle}>
                                <Row gutter={24} style={{backgroundColor: "white"}}>
                                    {lefColumn}
                                </Row>
                            </Panel>
                        </Collapse>
                    </Col>
                    <Col>
                        <Collapse
                            bordered={false}
                            defaultActiveKey={['1']}
                            expandIconPosition={'left'}
                            style={{marginBottom: 15}}
                        >
                            <Panel header={
                                <Row>
                                    {rightColHeader}
                                </Row>
                            } key="1" style={customPanelStyle}>
                                <Row gutter={24} style={{backgroundColor: "white"}}>
                                    {rightColumn}
                                </Row>
                            </Panel>
                        </Collapse>
                    </Col>
                </Row>
            </Mobile>
            <Row>
                <Col xs={24}>
                    <EmptyShadowBox style={{marginTop: 20, paddingTop: 26, paddingBottom: 30}}>
                        <Row>
                            <Col xs={24} md={12}>
                                <div style={{
                                    marginRight: isMobile ? 0 : 10,
                                    paddingRight: isMobile ? 0 : 10,
                                    paddingLeft: isMobile ? 0 : 18,
                                }}>
                                    <h3 style={{marginBottom: 10}}>{translate("result.before.you.start.title")}</h3>
                                    <ListItem style={{marginBottom: 10}}
                                              text={translate("result.before.you.start.item1", true)} />
                                    <ListItem style={{marginBottom: 10}}
                                              text={translate("result.before.you.start.item2", true)} />
                                </div>
                            </Col>
                            <Col xs={24} md={12}>
                                <div style={{
                                    marginRight: isMobile ? 0 : 10,
                                    marginTop: isMobile ? 10 : 0,
                                    marginLeft: isMobile ? 0 : 10,
                                    paddingLeft: isMobile ? 0 : 38,
                                    borderLeft: isMobile ? 'none' : '1px solid #D8D8D8'
                                }}>
                                    <h3 style={{marginBottom: 10}}>{translate("result.ready.title")}</h3>
                                    <ListItem style={{marginBottom: 10}} text={
                                        <span>{translate("result.ready.text1")} {translate("result.ready.text2")}</span>} />
                                    <div style={{textAlign: 'left', marginLeft: 26, marginTop: 10, marginBottom: 20}}>
                                        <Button
                                            style={{
                                                backgroundColor: "#4CB4E7", color: "#fff",
                                                paddingLeft: 15,
                                                paddingRight: 15,
                                                fontSize: 14,
                                            }}
                                            id={translate("result.ready.button.id")}
                                            href={translate("result.ready.button.link")}
                                            target="_blank"
                                            className="rounded-font" type={"link"}>
                                            {translate("result.ready.button")}
                                        </Button>
                                    </div>
                                    <ListItem text={
                                        <span>{translate("result.not.ready.title")} {translate("result.not.ready.text")}</span>
                                    } />
                                    <div style={{paddingLeft: 24, marginTop: 10}}>
                                        <div style={{textAlign: 'right', fontStyle: "italic"}}>
                                            <CopyToClipboard
                                                text={calculationInfo.code!}
                                                onCopy={() => message.success(translate("common.code.copied"))}
                                            >
                                                <sub style={{cursor: "pointer"}}><Icon
                                                    type="copy" /> {translate("common.clipboard.click")}</sub>
                                            </CopyToClipboard>
                                        </div>
                                        <CopyToClipboard
                                            text={calculationInfo.code!}
                                            onCopy={() => message.success(translate("common.code.copied"))}
                                        >
                                            <div style={{
                                                borderRadius: 4,
                                                padding: 6,
                                                textAlign: 'center',
                                                cursor: "pointer"
                                            }}
                                                 className="acerta-grey-light-background">

                                            <span style={{fontSize: 16, cursor: 'pointer'}}
                                                  className="rounded-font acerta-dark-blue">{calculationInfo.code}</span>
                                            </div>
                                        </CopyToClipboard>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </EmptyShadowBox>
                </Col>
            </Row>
        </>
    )
}

export default ResultDetailsView
import React, {useContext} from 'react'
import {message, Spin} from "antd";
import SocialContributionSingleRowFormView from "./social-contribution-single-row-form-view";
import {
    CalculationOptionsInput,
    useResultQuery,
    useUpdateCalculationOptionsMutation
} from "../../../../../generated/graphql";
import {handle} from "../../../../app/app";
import {FintoolContext} from "../../../../../context/fintool-context";
import SvapzSingleRowFormView from "./svapz-single-row-form-view";
import {RESULT} from "../../../common/result-chart/queries";
import {MESSAGE_DURATION} from "../../../../../utils/constants";
import {I18nContext} from "../../../../../i18n";

interface Props {
}

const SocialExpenseContainer: React.FC<Props> = () => {

    const [context] = useContext(FintoolContext);
    const {translate} = useContext(I18nContext);

    const [updateCalculationOptions] = useUpdateCalculationOptionsMutation({
            refetchQueries: [
                {query: RESULT, variables: {calculationHash: context.calculationHash}}
            ]
        }
    );

    const {data, error, loading, networkStatus} = useResultQuery({
        variables: {
            calculationHash: context.calculationHash as string
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "network-only"
    });
    if (loading || networkStatus === 4) {
        return <div><Spin size="small"/></div>;
    }
    error && handle(error);
    if (!data) {
        return <div>no data</div>;
    }

    const updatePensionType = (pensionType: string) => {
        updateCalculationOptions({
            variables: {
                calculationHash: context.calculationHash!,
                calculationOptions: {pensionType: pensionType} as CalculationOptionsInput
            }
        }).then((result: any) => {
            message.success(translate("common.save.success"), MESSAGE_DURATION)
        }).catch((error: any) => {
            message.error(error.message);
            console.log("updatePensionType error: " + error.message)
        })
    };

    return (
        <>
            <SocialContributionSingleRowFormView result={data.result}/>
            <SvapzSingleRowFormView result={data.result} updatePensionType={updatePensionType}/>
        </>
    )
};

export default SocialExpenseContainer
import React, {useContext, useState} from 'react'
import {currency, I18nContext} from "../../../../i18n";
import './result-chart-view.css'
import {isMobileQuery, isTabletOrMobileOrSmallDesktopQuery, LargeDesktop} from "../../../../utils/media-queries";
import {useMediaQuery} from "react-responsive";
import {Button, Col, Icon, Popover, Row} from "antd";
import BarChartUtil from "./bar-chart-util";
import Explanation from "../../../common/explanation";
import {ResultQuery} from "../../../../generated/graphql";
import {BankSaldoChartPart, Legend, NettoInkomenChartPart} from "./chart-parts";
import accoladeTop from '../../../../assets/images/accolade_top_invert.png'
import accoladeLeg from '../../../../assets/images/accolade_leg_invert.png'
import accoladeMiddle from '../../../../assets/images/accolade_middle_invert.png'
import accoladeBottom from '../../../../assets/images/accolade_bottom_invert.png'
import LoadSimulationContainer from "../../../common/header/simulation-code/load-simulation-container";
import SaveSimulationContainer from "../../../common/header/simulation-code/save-simulation-container";
import DownloadContainer from "../../../common/download/download-container";
import {FintoolContext} from "../../../../context/fintool-context";
import cashflowPlaceholder_nl from '../../../../assets/images/cashflow_placeholder_nl.png'
import cashflowPlaceholder_fr from '../../../../assets/images/cashflow_placeholder_fr.png'
import cashflowPlaceholder_en from '../../../../assets/images/cashflow_placeholder_en.png'
import AcertaIcon from "../../../common/iconfont";

interface Props {
    data: ResultQuery
}

const ResultChartView: React.FC<Props> = ({data}) => {

    const [context] = useContext(FintoolContext)
    const {translate, langCode} = useContext(I18nContext)

    const [withAccolade, setWithAccolade] = useState(false)

    const [aftrekbaarHover, setAftrekbaarHover] = useState(false)
    const [sociaalHover, setSociaalHover] = useState(false)
    const [personenHover, setPersonenHover] = useState(false)
    const [nettoHover, setNettoHover] = useState(false)
    const [uitgavenHover, setUitgavenHover] = useState(false)
    const [inkomstenHover, setInkomstenHover] = useState(false)
    const [eigenHover, setEigenHover] = useState(false)
    const [tekortHover, setTekortHover] = useState(false)
    const [surplusHover, setSurplusHover] = useState(false)

    const isTabletOrMobileOrSmallDesktop = useMediaQuery(isTabletOrMobileOrSmallDesktopQuery)
    const isMobile = useMediaQuery(isMobileQuery)

    const barChartUtil = new BarChartUtil(data.result);

    const accoladeHeight = barChartUtil.netIncomeDenominator > barChartUtil.result.yearlyProceeds ? 0
        : barChartUtil.result.calculatedYearlyNetIncome / barChartUtil.result.yearlyProceeds * 180;


    let accoladePartSize = 10
    if (accoladeHeight < 30) {
        accoladePartSize = Math.round(accoladeHeight / 3)
    }
    const accoladeLegHeight = Math.round((accoladeHeight - 30) / 2)

    const downloadButton = <Button
        type={"link"}
        style={{
            color: "#3F7CBF",
        }}
    >
        <Icon type="download" style={{fontSize: 18}} /> {isMobile && translate("common.pdf.download")}
    </Button>

    return (
        <div style={{
            paddingRight: 30,
            paddingTop: 10,
            paddingLeft: 20,
            padding: isTabletOrMobileOrSmallDesktop ? 0 : '10 30 10 20',
            backgroundColor: '#fff',
            borderLeft: isTabletOrMobileOrSmallDesktop ? '' : '1px solid #eee',
            borderBottom: isTabletOrMobileOrSmallDesktop ? '' : '1px solid #eee',
            width: '100%',
            marginTop: isTabletOrMobileOrSmallDesktop ? -30 : 'inherit',

        }}>
            <LargeDesktop>
                <div style={{marginRight: 10, display: "inline-block"}}><LoadSimulationContainer /></div>
                {!!context.calculationHash &&
                <>
                    <div style={{marginRight: 10, display: "inline-block"}}><SaveSimulationContainer
                        calculationHash={context.calculationHash} /></div>
                    <div style={{marginRight: 10, display: "inline-block"}}><DownloadContainer
                        component={downloadButton} /></div>
                    <div style={{marginTop: 5, marginBottom: 10, borderBottom: '1px solid #eee'}}></div>
                </>
                }
            </LargeDesktop>
            <h3 style={{
                display: 'inline-block',
                marginBottom: isTabletOrMobileOrSmallDesktop ? 10 : 20
            }}>{translate("result.title.jaarlijks.inkomen")}</h3>
            <Explanation onClick={() => setWithAccolade(!withAccolade)} title={translate("chart.net.help.title")}
                         explanation={translate("chart.net.help.text")} placement="bottom" />
            <div style={{display: 'flex', flexDirection: "row", justifyContent: "space-between"}}>
                <div style={{width: 110}}>
                    <div style={{
                        display: 'flex',
                        flexDirection: "column",
                        flexGrow: 1,
                        justifyContent: "flex-end",
                        height: 180
                    }}>
                        {!context.initState ?
                            <div className={"bar bar-inkomsten" + (accoladeHeight <= 0 ? ' less' : '')}
                                 style={{
                                     display: 'flex', flexDirection: "column", justifyContent: 'center',
                                     height: barChartUtil.netIncomeDenominator > barChartUtil.result.yearlyProceeds ?
                                         Math.round(barChartUtil.result.yearlyProceeds / barChartUtil.netIncomeDenominator * 180)
                                         : Math.round(180 - (barChartUtil.result.calculatedYearlyNetIncome / barChartUtil.result.yearlyProceeds * 180)),
                                     // height: 102
                                 }}
                            >
                                <div style={{fontSize: 12}}>{translate('chart.yearlyRevenue')}</div>
                                <div className="rounded-font"
                                     style={{
                                         fontSize: 14,
                                         whiteSpace: 'nowrap'
                                     }}>{currency(barChartUtil.result.yearlyProceeds, false, true)}</div>
                            </div>
                            :
                            <div className="bar bar-inkomsten less"
                                 style={{
                                     display: 'flex', flexDirection: "column", justifyContent: 'center',
                                     height: 0,
                                 }}
                            >
                                <div style={{fontSize: 12}}>{translate('chart.yearlyRevenue')}</div>
                                <div className="rounded-font"
                                     style={{fontSize: 14, whiteSpace: 'nowrap'}}>{currency(0, false, true)}</div>
                            </div>
                        }
                        {(!context.initState && accoladeHeight > 0) &&
                        <div className={"bar-inkomsten-netto" + (withAccolade ? "-accolade" : "") + " bar"}
                             style={{
                                 display: 'flex', flexDirection: "column", justifyContent: 'center',
                                 height: accoladeHeight
                             }}
                        >
                            {accoladeHeight > 40 &&
                            <div style={{fontSize: 12}}>{translate('chart.calculatedYearlyNetIncome')}</div>}
                            {accoladeHeight > 20 &&
                            <div className="rounded-font"
                                 style={{
                                     fontSize: 14,
                                     whiteSpace: 'nowrap'
                                 }}>{currency(barChartUtil.result.calculatedYearlyNetIncome, false, true)}</div>}
                        </div>}
                    </div>
                </div>
                <div style={{
                    display: withAccolade ? 'flex' : 'none',
                    height: 180,
                    width: accoladePartSize,
                    lineHeight: 0,
                    flexDirection: "column"
                }}>
                    {accoladeHeight !== 0 &&
                    <>
                        <div style={{flexGrow: 1}}></div>
                        <div id="accolade">
                            <div>
                                <img src={accoladeTop} style={{width: accoladePartSize, height: accoladePartSize}}
                                     alt="" />
                            </div>
                            {accoladeHeight > 33 &&
                            <div>
                                <img src={accoladeLeg}
                                     style={{height: accoladeLegHeight, width: 10, transition: '0.3s'}} alt="" />
                            </div>
                            }
                            <div>
                                <img src={accoladeMiddle} style={{width: accoladePartSize, height: accoladePartSize}}
                                     alt="" />
                            </div>
                            {accoladeHeight > 33 &&
                            <div>
                                <img src={accoladeLeg}
                                     style={{height: accoladeLegHeight, width: 10, transition: '0.3s'}} alt="" />
                            </div>
                            }
                            <div>
                                <img src={accoladeBottom} style={{width: accoladePartSize, height: accoladePartSize}}
                                     alt="" />
                            </div>
                        </div>
                    </>}
                </div>
                <div style={{width: 60}}>
                    {!context.initState ?
                        <div className="bar-uitgaven">
                            <NettoInkomenChartPart
                                hoverState={aftrekbaarHover}
                                setHoverState={setAftrekbaarHover}
                                valueName="yearlyCost"
                                height={barChartUtil.result.yearlyCost > barChartUtil.netIncomeDenominator ? 180 : barChartUtil.result.yearlyCost / barChartUtil.netIncomeDenominator * 180}
                                first={true}
                                last={
                                    barChartUtil.result.socialContributionsWithPensionSaving / barChartUtil.netIncomeDenominator * 180 < 2 ? true
                                        : false}
                            />
                            <NettoInkomenChartPart
                                hoverState={sociaalHover}
                                setHoverState={setSociaalHover}
                                valueName="socialContributions"
                                height={barChartUtil.result.socialContributionsWithPensionSaving <= 0 ? 0 : barChartUtil.result.socialContributionsWithPensionSaving / barChartUtil.netIncomeDenominator * 180}
                                last={barChartUtil.result.personalIncomeTax / barChartUtil.netIncomeDenominator * 180 < 2
                                && barChartUtil.result.calculatedYearlyNetIncome <= 0 ? true : false}
                            />
                            <NettoInkomenChartPart
                                hoverState={personenHover}
                                setHoverState={setPersonenHover}
                                valueName="personalIncomeTax"
                                height={barChartUtil.result.personalIncomeTax / barChartUtil.netIncomeDenominator * 180}
                                last={barChartUtil.result.calculatedYearlyNetIncome <= 0 ? true : false}
                            />
                            <NettoInkomenChartPart
                                hoverState={nettoHover}
                                setHoverState={setNettoHover}
                                valueName="calculatedYearlyNetIncome"
                                height={barChartUtil.result.calculatedYearlyNetIncome > 0 ?
                                    barChartUtil.result.calculatedYearlyNetIncome / barChartUtil.netIncomeDenominator * 180
                                    : 0
                                }
                                last={true}
                            />
                        </div> :
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: "column",
                                flexGrow: 1,
                                justifyContent: "flex-end",
                                height: 180,
                            }}>
                            <div className="bar-uitgaven">
                                <NettoInkomenChartPart
                                    hoverState={aftrekbaarHover}
                                    setHoverState={setAftrekbaarHover}
                                    valueName="yearlyCost"
                                    height={13}
                                    first={true}
                                />
                                <NettoInkomenChartPart
                                    hoverState={sociaalHover}
                                    setHoverState={setSociaalHover}
                                    valueName="socialContributions"
                                    height={13}
                                />
                                <NettoInkomenChartPart
                                    hoverState={personenHover}
                                    setHoverState={setPersonenHover}
                                    valueName="personalIncomeTax"
                                    height={13}
                                />
                                <NettoInkomenChartPart
                                    hoverState={nettoHover}
                                    setHoverState={setNettoHover}
                                    valueName="calculatedYearlyNetIncome"
                                    height={13}
                                    last={true}
                                />
                            </div>
                        </div>
                    }
                </div>
                <div style={{width: 120}}>
                    <Legend hoverState={aftrekbaarHover} setHoverState={setAftrekbaarHover} valueName="yearlyCost"
                            value={context.initState ? 0 : data.result.yearlyCost} colorClass="acerta-grey-less-dark" />
                    <Legend hoverState={sociaalHover} setHoverState={setSociaalHover} valueName="socialContributions"
                            value={context.initState ? 0 : barChartUtil.result.socialContributionsWithPensionSaving <= 0 ? 0 : barChartUtil.result.socialContributionsWithPensionSaving}
                            colorClass="acerta-grey-less-dark" />
                    <Legend hoverState={personenHover} setHoverState={setPersonenHover} valueName="personalIncomeTax"
                            value={context.initState ? 0 : data.result.personalIncomeTax}
                            colorClass="acerta-grey-less-dark" />
                    <Legend hoverState={nettoHover} setHoverState={setNettoHover} valueName="calculatedYearlyNetIncome"
                            value={context.initState ? 0 : data.result.calculatedYearlyNetIncome < 0 ? 0 : data.result.calculatedYearlyNetIncome}
                            colorClass="acerta-dark-blue" bolder={true} />
                </div>
            </div>
            <div style={{
                marginTop: isMobile ? 10 : 20,
                marginBottom: isMobile ? 10 : 20,
                borderBottom: '1px solid #eee'
            }}></div>
            <h3 style={{marginBottom: 10, display: 'inline-block'}}>{translate("result.title.maandelijks.inkomen")}</h3>
            <Explanation title={translate("chart.monthly.help.title")}
                         explanation={translate("chart.monthly.help.text")} />
            <Row gutter={5} type="flex" align="bottom" className="comparison" style={{marginTop: 0}}>
                <Col xs={24} style={{paddingBottom: isMobile ? 0 : 10}}>
                    <div className="desired">
                        <div
                            className="net"
                            style={{whiteSpace: 'nowrap'}}>€&nbsp;{currency(data.result.desiredYearlyNetIncome / 12, true, true).split(',').map(
                            (part, index) => {
                                return index === 0 ? <span key={index}>{part}</span> :
                                    <span key={index} style={{fontSize: 15}}>{part}</span>
                            })}</div>
                        <div>
                            <sup><em>{translate("result.desired.netto")}</em></sup>
                        </div>
                    </div>
                    <div className="calculated">
                        <div
                            className="net">€&nbsp;
                            <span
                                className={(!context.initState && data.result.calculatedYearlyNetIncome < 0) ? "negative-red" : "acerta-dark-blue"}
                                style={{whiteSpace: 'nowrap'}}>
                            {currency(context.initState ? 0 : data.result.calculatedYearlyNetIncome / 12, true, true).split(',').map(
                                (part, index) => {
                                    return index === 0 ? <span key={index}>{part}</span> :
                                        <span key={index} style={{fontSize: 18}}>{part}</span>
                                })
                            }</span>
                            {(!context.initState && data.result.calculatedYearlyNetIncome < 0) &&
                            <Popover
                                title={translate("chart.result.negative.popup.text", true)}
                                placement={"topLeft"}
                            >
                                <Icon type="exclamation-circle"
                                      className="negative-red"
                                      style={{
                                          fontSize: 13,
                                          marginBottom: 3,
                                          marginLeft: 5,
                                          verticalAlign: '0.6em'
                                      }}
                                /></Popover>}
                        </div>
                        <div>
                            <sup><em>{translate("result.calculated.netto")}</em></sup></div>
                    </div>
                </Col>
            </Row>
            {!context.initState && context.financeSeenState ?
                <>
                    <div style={{
                        marginTop: isMobile ? 0 : 10,
                        marginBottom: isMobile ? 10 : 20,
                        borderBottom: '1px solid #eee'
                    }}></div>
                    <h3 style={{
                        display: 'inline-block',
                        marginBottom: isTabletOrMobileOrSmallDesktop ? 10 : 15
                    }}>{translate("chart.title.beroepsrekening")}</h3>
                    <Explanation title={translate("chart.professionalAccount.help.title")}
                                 explanation={translate("chart.professionalAccount.help.text")} />
                    <div style={{display: 'flex', justifyContent: 'space-between', paddingRight: 20}}>
                        <Legend hoverState={uitgavenHover} setHoverState={setUitgavenHover} valueName="yearlyExpense"
                                value={data.result.yearlyExpense} colorClass="acerta-pink" />
                        {!!barChartUtil.surplus() &&
                        <Legend hoverState={surplusHover} setHoverState={setSurplusHover} valueName="surplus"
                                value={barChartUtil.surplus()}
                                colorClass="acerta-medium-blue-1" striped={true} />}
                    </div>
                    <div>
                        <div style={{lineHeight: 0}}>
                            <BankSaldoChartPart
                                hoverState={uitgavenHover}
                                setHoverState={setUitgavenHover}
                                colorClass="acerta-pink-background bar"
                                percentage={barChartUtil.expensePercentage()}
                                firstTop={true}
                                lastTop={100 - barChartUtil.expensePercentage() <= 0 ? true : false}
                            >
                                <div style={{
                                    display: "flex",
                                    alignItems: 'center',
                                    height: '100%',
                                    justifyContent: 'space-between',
                                    color: '#fff'
                                }}>
                                </div>
                            </BankSaldoChartPart>
                            <BankSaldoChartPart
                                hoverState={surplusHover}
                                setHoverState={setSurplusHover}
                                colorClass="chart-color-surplus"
                                percentage={barChartUtil.surplus() <= 0 ? 0 : 100 - barChartUtil.expensePercentage()}
                                lastTop={true}
                            />
                        </div>
                        <div>
                            <BankSaldoChartPart
                                hoverState={inkomstenHover}
                                setHoverState={setInkomstenHover}
                                colorClass="acerta-light-blue-1-background"
                                percentage={barChartUtil.revenuePercentage()}
                                firstBottom={true}
                                lastBottom={(barChartUtil.toFinancePercentage() <= 0 && barChartUtil.financingPercentage() <= 0) ? true : false}
                            />
                            <BankSaldoChartPart
                                hoverState={eigenHover}
                                setHoverState={setEigenHover}
                                colorClass="acerta-dark-blue-background"
                                percentage={barChartUtil.financingPercentage() <= 0 ? 0 : barChartUtil.financingPercentage()}
                                lastBottom={barChartUtil.toFinancePercentage() <= 0 ? true : false}
                                firstBottom={barChartUtil.revenuePercentage() * 0.995 <= 1 ? true : false}
                            />
                            <BankSaldoChartPart
                                hoverState={tekortHover}
                                setHoverState={setTekortHover}
                                colorClass="striped-background"
                                percentage={barChartUtil.toFinancePercentage() <= 0 ? 0 : barChartUtil.toFinancePercentage()}
                                firstBottom={(barChartUtil.financingPercentage() <= 0 && barChartUtil.revenuePercentage() <= 0) ? true : false}
                                lastBottom={true}
                            >
                            </BankSaldoChartPart>
                        </div>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'space-between', paddingRight: 20}}>
                        <Legend hoverState={inkomstenHover} setHoverState={setInkomstenHover} valueName="yearlyRevenue"
                                value={data.result.yearlyRevenue} colorClass="acerta-medium-blue-1" />
                        {!(barChartUtil.financingPercentage() <= 0) &&
                        <Legend hoverState={eigenHover} setHoverState={setEigenHover} valueName="contributionResources"
                                value={data.result.financed} colorClass="acerta-medium-blue-1" />}
                        {!(barChartUtil.toFinancePercentage() <= 0) &&
                        <Legend hoverState={tekortHover} setHoverState={setTekortHover} valueName="shortage"
                                value={barChartUtil.toFinance()} colorClass="acerta-medium-blue-1" striped={true} />
                        }
                        {(!context.initState && barChartUtil.toFinance() > 0) &&
                        <Popover
                            title={translate("chart.result.negative.popup.text2", true)}
                            placement={"topLeft"}
                        >
                            <Icon type="exclamation-circle"
                                  className="negative-red"
                                  style={{
                                      fontSize: 13,
                                  }}
                            /></Popover>}
                    </div>
                    <div style={{marginTop: 20, marginBottom: 20, borderBottom: '0px solid #eee'}}></div>
                </> :
                ((!context.initState && context.expenseSeenState && !context.financeSeenState) ? <div>
                        <div
                            className="light-shadow"
                            style={{
                                marginTop: 10,
                                marginBottom: 30,
                                borderRadius: 4,
                                width: 310
                            }}>
                            <div style={{border: '1px solid #ddd', borderRadius: 4, padding: 10}}>
                                <div style={{marginBottom: 20}}>
                                    <AcertaIcon icon={"icon-info-circle-16"} style={{marginRight: 5}} />
                                    {translate("chart.expense.help.text", true)}
                                </div>
                                <img alt="cashflowPlaceholder" src={
                                    langCode === 'nl' ?
                                        cashflowPlaceholder_nl :
                                        (langCode === 'fr' ? cashflowPlaceholder_fr : cashflowPlaceholder_en)}
                                     style={{
                                         width: 280
                                     }}
                                />
                            </div>
                        </div>
                    </div> :
                    (!context.initState && !context.expenseSeenState && !context.financeSeenState) &&
                    <div>
                        <div
                            className="light-shadow"
                            style={{
                                marginTop: 10,
                                marginBottom: 30,
                                borderRadius: 4,
                                width: 310
                            }}>
                            <div style={{border: '1px solid #ddd', borderRadius: 4, padding: 10}}>
                                <div style={{marginBottom: 20}}>
                                    <AcertaIcon icon={"icon-info-circle-16"} style={{marginRight: 5}} />
                                    {translate("chart.initial.help.text")}
                                </div>
                            </div>
                        </div>
                    </div>)}
        </div>

    )
};

export default ResultChartView
import React, {useState} from 'react';

export interface StateProps {
    currentPage: string
    currentStep: string
    currentTopStep: number
    calculationHash: string | null
    admin: boolean
    initState: boolean
    financeSeenState: boolean
    expenseSeenState: boolean
    incomeVisualSeen: boolean
    expenseVisualSeen: boolean
    validationErrors: string[]
}

const FintoolContext = React.createContext<[StateProps, any]>([{
    currentPage: '/',
    currentStep: '/simulatie/profiel',
    currentTopStep: 0,
    calculationHash: null,
    admin: false,
    initState: true,
    financeSeenState: false,
    expenseSeenState: false,
    incomeVisualSeen: false,
    expenseVisualSeen: false,
    validationErrors: [],
}, () => {
}]);

const FintoolProvider = (props: any) => {

    const [context, setContext] = useState({
        currentPage: '/',
        currentStep: '/simulatie/profiel',
        currentTopStep: 0,
        calculationHash: null,
        admin: false,
        initState: true,
        financeSeenState: false,
        expenseSeenState: false,
        incomeVisualSeen: false,
        expenseVisualSeen: false,
        validationErrors: [],
    });
    return (
        <FintoolContext.Provider value={[context, setContext]}>
            {props.children}
        </FintoolContext.Provider>
    );
}

export {FintoolContext, FintoolProvider};
import React, {useContext, useState} from 'react'
import {Col, Form, InputNumber, Row, Switch, Tooltip} from "antd";
import {FormComponentProps, ValidateCallback} from "antd/es/form";
import {Default, isMobileQuery, Mobile} from "../../../../../utils/media-queries";
import {I18nContext} from "../../../../../i18n";
import Explanation from "../../../../common/explanation";
import {useMediaQuery} from "react-responsive";
import {Result} from "../../../../../generated/graphql";


interface FormProps extends FormComponentProps {
    result: Result
    updatePensionType: any
}

const SvapzSingleRowForm: React.FC<FormProps> = ({form, result, updatePensionType}) => {

    const {translate} = useContext(I18nContext);
    const {getFieldDecorator} = form;
    const isMobile = useMediaQuery(isMobileQuery)
    const [priceSelected, setPriceSelected] = useState(false)

    const toggleSVAPZ = (checked: boolean) => {
        form.validateFields((err: ValidateCallback<any>, values: any) => {
            if (!err) {
                const pensionType: string = checked ? 'SVAPZ' : 'NONE';
                updatePensionType(pensionType);
            }
        })
    };

    const isSvapzSelected = () => {
        return result.calculationOptions.pensionType === 'SVAPZ';
    };

    const toggleField = getFieldDecorator('svapz', {})(
        <Switch size="small"
                onChange={toggleSVAPZ}
                checked={isSvapzSelected()}  />
    );

    const unitPriceField = getFieldDecorator('unitPrice', {
        initialValue: result.pensionSaving,
    })(
        <InputNumber style={{width: isMobile ? 90 : 90}}
                     disabled={true}
                     decimalSeparator={','}
                     precision={2}
                     className={"input-currency-disabled" + (isSvapzSelected() ? '' : ' item-excluded')}
                     onFocus={() => setPriceSelected(true)}
        />
    );

    return (
        <Form id={"form_svapz"}>
            <Default>
                <Row className="single-row-form-row" type="flex" align="middle" justify="space-between" gutter={16}>
                    <Col xs={2}>
                        {toggleField}
                    </Col>
                    <Col xs={12}>
                        <span className={isSvapzSelected() ? '' : 'item-excluded'}>
                        {translate("expense.type.SVAPZ")}
                            <Explanation
                                title={translate("expense.type.help.title.SVAPZ")}
                                explanation={translate("expense.type.help.text.SVAPZ", true)}
                                placement={"right"}/>
                        </span>
                    </Col>
                    <Col xs={8} style={{textAlign: 'right'}}>
                        <span style={{color: '#999', marginRight: 8}}>Prijs</span>
                        <Tooltip title={translate("common.price.SVAPZ")}>{unitPriceField}</Tooltip>
                    </Col>
                    <Col xs={2} style={{textAlign: 'center'}}> </Col>
                </Row>
            </Default>
            <Mobile>
                <Row style={{marginTop: 0}} gutter={0}>
                    <Col xs={3}>
                        <span>{toggleField}</span>
                    </Col>
                    <Col xs={18}>
                        <span className={isSvapzSelected() ? '' : 'item-excluded'}>
                            {translate("expense.type.SVAPZ")}
                            <Explanation
                                title={translate("expense.type.help.title.SVAPZ")}
                                explanation={translate("expense.type.help.text.SVAPZ", true)}
                                placement={"right"}/>
                        </span>
                    </Col>
                    <Col xs={3} style={{textAlign: 'right'}}>
                    </Col>
                </Row>
                <Row type="flex" align="middle"
                     style={{paddingBottom: 15, marginTop: 0, marginBottom: 12, borderBottom: '1px solid #eee'}}>
                    <Col xs={3}></Col>
                    <Col xs={18} style={{textAlign: 'right'}}>
                        {translate("common.bedrag")}&nbsp;
                        <span style={{position: "relative"}}>
                            {unitPriceField}
                            {priceSelected && isMobile &&
                            <span style={{
                                position: 'absolute',
                                top: 25, right: 3,
                                fontSize: 11,
                                fontStyle: "italic",
                                color: '#888'
                            }}>{translate("common.ex.btw")}</span>}
                        </span>
                    </Col>
                    <Col xs={3}>
                    </Col>
                </Row>
            </Mobile>
        </Form>
    )
}

const SvapzSingleRowFormView = Form.create<FormProps>({
    name: 'generalExpense',
})(SvapzSingleRowForm);

export default SvapzSingleRowFormView
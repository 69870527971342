import React, {useContext} from 'react'
import {Button, Col, Icon, Row} from "antd";
import {FintoolContext} from "../../../../context/fintool-context";
import {steps} from "../../simulation-view";
import {RouteComponentProps, withRouter} from "react-router";
import AcertaIcon from "../../../common/iconfont";
import {I18nContext} from "../../../../i18n";
import {openNotificationWithIcon} from "../../../app/app";

interface Props extends RouteComponentProps {
}

const VorigeVolgende: React.FC<Props> = ({history}) => {

    const [context, setContext] = useContext(FintoolContext)
    const {translate} = useContext(I18nContext)

    const onStepChange = (newStep: number) => {
        if (context.validationErrors && context.validationErrors.length > 0) {
            openNotificationWithIcon("warning",
                translate("common.validation.error.title"),
                <ul>{context.validationErrors.map((error) => <li>{translate(error, true)}</li>)}</ul>)
            return
        }
        const key = Object.keys(steps)[newStep]
        setContext({
            ...context,
            currentStep: steps[key].path,
            currentTopStep: newStep,
        })
        const goto = steps[key].path + (context.calculationHash ? "/" + context.calculationHash : "")
        console.log(goto)
        history.push(steps[key].path + (context.calculationHash ? "/" + context.calculationHash : ""))
    }

    return (
        <>
            <Row className="vorige-volgende">
                <Col xs={8}>
                    {context.currentTopStep > 0 &&
                    <span style={{whiteSpace: 'nowrap'}}><Icon type="arrow-left" />
                        <span style={{marginLeft: 15}}>
                    <span style={{cursor: 'pointer', whiteSpace: 'nowrap'}} className="rounded-font"
                          onClick={() => onStepChange(context.currentTopStep - 1)}>
                        {translate("common.previous.step")}
                    </span>
                </span></span>
                    }
                </Col>
                <Col xs={16} style={{textAlign: 'right'}}>
                    {context.currentTopStep < (Object.keys(steps).length - 1) &&
                    <>
                    <Button
                        size={"large"}
                        id={context.currentTopStep !== (Object.keys(steps).length - 2) ? translate("common.next.step.id") : translate("button.view.result.id")}
                        type={"primary"}
                          onClick={() => onStepChange(context.currentTopStep + 1)}>
                        {context.currentTopStep !== (Object.keys(steps).length - 2) ? translate("common.next.step") : translate("button.view.result")}
                        <AcertaIcon icon={"icon-arrow-left-24"}
                                    style={{
                                        transform: 'rotate(180deg)',
                                        color: "#FFF",
                                        verticalAlign: '-0.2em',
                                        marginLeft: 5}} />
                    </Button>
                    </>
                    }
                </Col>
            </Row>
        </>
    )
}

export default withRouter(VorigeVolgende)
import React, {useContext, useState} from 'react'
import {Button, Col, Icon, Modal, Row} from "antd";
import {Default, Desktop, isMobileQuery} from "../../utils/media-queries";
import {I18nContext} from "../../i18n";
import EnterCodeContainer from "../common/code-enter/enter-code-container";
import ReactPlayer from 'react-player'
import ListItem from "../common/list-item";
import {useMediaQuery} from "react-responsive";
import {LANDING_MOVIE_LOCATION} from "../../utils/constants";

interface Props {
    createCalculation: any
}

interface LandingItemProps {
    text: string
}

const LandingView: React.FC<Props> = ({createCalculation}) => {

    const {translate, langCode} = useContext(I18nContext)
    const [showLoadPopup, setShowLoadPopup] = useState(false)
    const isMobile = useMediaQuery(isMobileQuery)

    return (
        <div style={{backgroundColor: '#fff'}}>
            <Row>
                <Col>
                    <div id="landing-page-header" className={"landing-page-header-" + langCode}>
                        <Row>
                            <Col xs={21} md={12} offset={2}>
                                <Desktop>
                                    <p style={{paddingTop: 20}}>{translate("landing.banner.title")}</p>
                                </Desktop>
                                <h1>{translate("landing.banner.subtitle")}</h1>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            <Row style={{paddingTop: isMobile ? 0 : 20}}>
                <Desktop>
                    <Col xs={12} lg={14} xl={12}>
                        <div id="landing-page-visuals" className={"landing-page-visuals-" + langCode}>
                            <div style={{height: 218}}>&nbsp;</div>
                            {langCode === 'nll' &&
                            <ReactPlayer url={LANDING_MOVIE_LOCATION}
                                         playing
                                         volume={0}
                                         width='130px' height='230px' style={{marginLeft: 421}} loop={true}
                            />}
                        </div>
                    </Col>
                </Desktop>
                <Col xs={{span: 20, offset: 2}} lg={{span: 8, offset: 0, pull: 0}} style={{paddingTop: isMobile ? 0 : 20}}>
                    <Default><h2>{translate("landing.summary.header")}</h2></Default>
                    <div style={{marginTop: isMobile ? 10 : 20}}>
                        <ListItem style={{marginBottom: 10}} text={translate("landing.summary.point1", true)} />
                        <ListItem style={{marginBottom: 10}}
                            text={translate("landing.summary.point2", true)} />
                        <ListItem style={{marginBottom: 10}} text={translate("landing.summary.point3", true)} />
                    </div>
                    <div style={{marginTop: 30}}>
                        <Button id={translate("landing.button.start.id")} type={"primary"} style={{width: '100%', fontSize: 26, height: 45}}
                                onClick={() => createCalculation()}>{translate("landing.button.start")}</Button>
                    </div>
                    <div style={{marginTop: 30, padding: 20, paddingTop: 20, borderRadius: 4}}
                         className="acerta-grey-light-background">
                        <h3 style={{marginBottom: 5}}>{translate("landing.gegevens.title")}</h3>
                        <p>{translate("landing.gegevens.tekst")}</p>
                        <>
                            <Button id={translate("landing.gegevens.link.id")} type={"link"} onClick={() => setShowLoadPopup(true)} className="light-link">
                                <Icon type="arrow-right" />
                                <span> {translate("landing.gegevens.link")}</span>
                            </Button>
                            <Modal
                                visible={showLoadPopup}
                                onCancel={() => setShowLoadPopup(false)}
                                okButtonProps={{disabled: true}}
                                footer={null}
                            >
                                <EnterCodeContainer inModal={true} onModalAction={() => setShowLoadPopup(false)} />
                            </Modal>
                        </>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default LandingView
import React, {useContext, useState} from 'react'
import {
    GroupedExpense, Housing, HousingType,
    useAddExpenseItemMutation, useAddHousingMutation,
    useAddVehicleMutation,
    useExpenseCategoriesQuery,
    Vehicle,
    VehicleType
} from "../../../generated/graphql";
import GeneralExpenseView from "./general";
import {FintoolContext} from "../../../context/fintool-context";
import {EXPENSE} from "./queries";
import {RESULT} from "../common/result-chart/queries";
import {message} from "antd";
import {I18nContext} from "../../../i18n";
import {MESSAGE_DURATION} from "../../../utils/constants";
import {handle} from "../../app/app";
import VehicleView from "./vehicle";
import HousingView from "./housing/housing-view";

interface Props {
    groupedExpenses: GroupedExpense[]
    vehicles: Vehicle[] | null | undefined
    housings: Housing[] | null | undefined
}

const ExpenseListContainer: React.FC<Props> = ({groupedExpenses, vehicles, housings}) => {

    const [context] = useContext(FintoolContext);
    const {translate} = useContext(I18nContext);
    const [newVehicleId, setNewVehicleId] = useState<string | undefined>(undefined)
    const [newHousingId, setNewHousingId] = useState<string | undefined>(undefined)

    const {data, error, loading, networkStatus} = useExpenseCategoriesQuery({
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "cache-first"
    });

    const [addExpenseItem] = useAddExpenseItemMutation({
            refetchQueries: [
                {query: EXPENSE, variables: {calculationHash: context.calculationHash}},
                {query: RESULT, variables: {calculationHash: context.calculationHash}}
            ]
        }
    );

    const [addVehicle] = useAddVehicleMutation({
            refetchQueries: [
                {query: EXPENSE, variables: {calculationHash: context.calculationHash}},
                {query: RESULT, variables: {calculationHash: context.calculationHash}}
            ]
        }
    );

    const [addHousing] = useAddHousingMutation({
            refetchQueries: [
                {query: EXPENSE, variables: {calculationHash: context.calculationHash}},
                {query: RESULT, variables: {calculationHash: context.calculationHash}}
            ]
        }
    );

    const onAdd = (expenseType: string, expenseCategory: string) => {
        if (!!context.calculationHash) {
            addExpenseItem({
                variables: {
                    calculationHash: context.calculationHash,
                    expenseType: expenseType,
                    expenseCategory: expenseCategory
                }
            }).then((result: any) => {
                message.success(translate("common.add.succes"), MESSAGE_DURATION)
            }).catch((error: any) => {
                message.error(error.message)
            })
        }
    }

    const onAddVehicle = (vehicleType: VehicleType) => {
        if (!!context.calculationHash) {
            addVehicle({
                variables: {
                    calculationHash: context.calculationHash,
                    vehicleType: vehicleType
                }
            }).then((result: any) => {
                message.success(translate("common.add.succes"), MESSAGE_DURATION)
                setNewVehicleId(result.data.addVehicle.id)
            }).catch((error: any) => {
                message.error(error.message)
            })
        }
    }

    const onAddHousing = (housingType: HousingType) => {
        if (!!context.calculationHash) {
            addHousing({
                variables: {
                    calculationHash: context.calculationHash,
                    housingType: housingType
                }
            }).then((result: any) => {
                message.success(translate("common.add.succes"), MESSAGE_DURATION)
                setNewHousingId(result.data.addHousing.id)
            }).catch((error: any) => {
                message.error(error.message)
            })
        }
    }

    if (loading || networkStatus === 4 || !data) {
        return null;
    }

    error && handle(error)

    let views: any = []

    data.expenseCategories.forEach((expenseCategory) => {
        const existingExpense = groupedExpenses.find(value => value.key === expenseCategory.code)
        if (expenseCategory.code === 'VOERTUIGEN') {
            views.push(<VehicleView key={expenseCategory.id} onAdd={onAddVehicle} data={vehicles}
                                    newVehicleId={newVehicleId} />)
        } else if (expenseCategory.code === 'HUISVESTING') {
            views.push(<HousingView key={expenseCategory.id} onAdd={onAddHousing} data={housings}
                                    newHousingId={newHousingId} />)
        } else {
            views.push(<GeneralExpenseView key={expenseCategory.id} onAdd={onAdd}
                                           data={existingExpense ? existingExpense.values : []}
                                           expenseCategory={expenseCategory} />)
        }
    })

    return (
        <>
            {views}
        </>
    )
}

export default ExpenseListContainer
import React, {useContext} from 'react'
import {Col, Row, Steps} from 'antd'
import {FintoolContext} from "../../../../context/fintool-context";
import {steps} from "../../simulation-view";
import {RouteComponentProps, withRouter} from "react-router";
import {I18nContext} from "../../../../i18n";
import {openNotificationWithIcon} from "../../../app/app";

const TopSteps: React.FC<RouteComponentProps> = ({history}) => {
    const [context, setContext] = useContext(FintoolContext)
    const {translate} = useContext(I18nContext)

    const onStepChange = (newStep: number) => {
        if (context.validationErrors && context.validationErrors.length > 0) {
            openNotificationWithIcon("warning",
                translate("common.validation.error.title"),
                <ul>{context.validationErrors.map((error) => <li>{translate(error, true)}</li>)}</ul>)
            return
        }
        const key = Object.keys(steps)[newStep]
        setContext({
            ...context,
            currentStep: steps[key].path,
            currentTopStep: newStep,
        })
        const goto = steps[key].path + (context.calculationHash ? "/" + context.calculationHash : "")
        history.push(goto)
    }

    const stepNumberStyle = (step: number) => {
        return {
            display: 'inline-block',
            width: 22,
            height: 22,
            border: '2px solid ' + (context.currentTopStep === step ?
                '#E8318A' : (context.currentTopStep > step ? '#49A5DD' : '#bbb')),
            marginRight: 4,
            borderRadius: '50%',
            fontSize: 14,
            paddingTop: 2,
        }
    }

    const customDot = (dot: any, {status, index}: any) => (
        <span className="step-number rounded-font" style={stepNumberStyle(index)}>{index + 1}</span>
    );

    return (
        <Row>
            <Col>
                <Steps className="simulation-steps" progressDot={customDot} current={context.currentTopStep}
                       onChange={onStepChange}>
                    <Steps.Step className={translate("step.profile.class")} title={translate("step.profile")} />
                    <Steps.Step className={translate("step.revenues.class")} title={translate("step.revenues")} disabled={!context.calculationHash} />
                    <Steps.Step className={translate("step.expenses.class")} title={translate("step.expenses")} disabled={!context.calculationHash} />
                    <Steps.Step className={translate("step.financing.class")} title={translate("step.financing")} disabled={!context.calculationHash} />
                    <Steps.Step className={translate("step.result.class")} title={translate("step.result")} disabled={!context.calculationHash} />
                </Steps>
            </Col>
        </Row>
    )
}

export default withRouter(TopSteps)
import React, {useContext, useEffect, useRef} from 'react'
import {Button, Col, Form, Icon, Input, Row} from "antd";
import {FormComponentProps, ValidateCallback} from "antd/es/form";
import {I18nContext} from "../../../i18n";

interface Props extends FormComponentProps {
    enterCode: any
    inModal?: boolean
    onModalAction?: any
}

const EnterCodeForm: React.FC<Props> = ({form, enterCode, inModal, onModalAction}) => {
    const {translate} = useContext(I18nContext);
    const {getFieldDecorator} = form;

    const codeField = useRef<Input>(null);

    useEffect(() => {
        if (codeField.current) {
            codeField.current.focus();
        }
    }, []);

    const onSubmit = () => {
        form.validateFields((err: ValidateCallback<any>, values: any) => {
            if (!err) {
                enterCode(values.code);
                form.resetFields();
                onModalAction && onModalAction()
            }
        })
    };

    return (
        <>
            <h2>{translate("simulation.load.title")}</h2>
            <Form className="enter-code-form">
                <Form.Item>
                    {getFieldDecorator('code', {
                        rules: [{required: true, message: 'code is verplicht'}],
                    })(
                        <Input ref={codeField} placeholder="code" />,
                    )}
                </Form.Item>
                <Form.Item style={{marginBottom: 0}}>
                    <Row>
                        <Col xs={24} style={{textAlign: inModal ? 'right' : 'inherit'}}>
                            {inModal &&
                            <Button
                                style={{marginRight: 10}}
                                type="default" onClick={onModalAction}>
                                {translate("common.cancel")}
                            </Button>
                            }
                            <Button
                                key="submit"
                                type="primary"
                                htmlType="submit"
                                onClick={onSubmit}
                                id={translate("simulation.load.button.id")}>
                                    <Icon type="folder-open" />
                                    {translate("simulation.load.button")}
                            </Button>
                        </Col>
                    </Row>
                </Form.Item>
            </Form>
        </>
    )
};
const EnterCodeView = Form.create<Props>({name: 'EnterCode',})(EnterCodeForm);

export default EnterCodeView
import React, {useContext} from 'react'
import {FormComponentProps, ValidateCallback} from "antd/es/form";
import {Vehicle, VehicleFinancingType, VehicleInput} from "../../../../../generated/graphql";
import {Button, Col, Form, Radio, Row, Slider} from "antd";
import {currency, I18nContext, noCurrency} from "../../../../../i18n";
import _ from 'lodash'
import AcertaIcon from "../../../../common/iconfont";
import Explanation from "../../../../common/explanation";
import {useMediaQuery} from "react-responsive";
import {isMobileQuery} from "../../../../../utils/media-queries";

interface FormProps extends FormComponentProps {
    onSave: any
    onClose: any
    vehicle: Vehicle
}

interface Item {
    name: string
    min: number
    max: number
    step: number
    isCurrency: Boolean
}

const VehicleCalculationModuleForm: React.FC<FormProps> = ({form, onSave, onClose, vehicle}) => {
    const {getFieldDecorator} = form;
    const {translate} = useContext(I18nContext)
    const isMobile = useMediaQuery(isMobileQuery)

    const defaultPurchase = {
        purchaseAmount: 22000,
        maintenance: 300,
        tax: 100,
        milagePrivate: 10000,
        milageProfessional: 15000,
        fuelConsumption: 6,
        fuelPricePerLiter: 1.4,
        insurance: 1700,
        otherCosts: 500
    }
    const defaultLeasing = {
        leaseAmount: 9000,
        milagePrivate: 10000,
        milageProfessional: 15000,
        otherCosts: 250
    }

    const onChangeValue = (vehicleFinancingType: VehicleFinancingType | undefined) => {
        form.validateFields((err: ValidateCallback<any>, values: VehicleInput) => {
            let type = !vehicleFinancingType ? values.vehicleFinancingType : (
                vehicleFinancingType === VehicleFinancingType.OwnContribution.toString() ?
                    VehicleFinancingType.OwnContribution :
                    (vehicleFinancingType === VehicleFinancingType.Purchase.toString() ?
                        VehicleFinancingType.Purchase : VehicleFinancingType.Leasing))
            onSave({
                ...values,
                leasing: type === VehicleFinancingType.Leasing ? (values.leasing ? values.leasing : defaultLeasing) : null,
                purchase: type !== VehicleFinancingType.Leasing ? (values.purchase ? values.purchase : defaultPurchase) : null,
                vehicleType: vehicle.vehicleType,
                vehicleFinancingType: type,
                includeInCalculation: vehicle.includeInCalculation
            }, form)
        })
    }

    const purchaseItems: Item[] = [
        {name: 'purchase.purchaseAmount', min: 1000, max: 100000, step: 1000, isCurrency: true},
        {name: 'purchase.maintenance', min: 100, max: 2000, step: 100, isCurrency: true},
        {name: 'purchase.tax', min: 0, max: 5000, step: 100, isCurrency: true},
        {name: 'purchase.insurance', min: 100, max: 2500, step: 100, isCurrency: true},
        {name: 'purchase.otherCosts', min: 0, max: 5000, step: 100, isCurrency: true},
        {name: 'purchase.fuelPricePerLiter', min: 1, max: 3, step: 0.1, isCurrency: true},
        {name: 'purchase.fuelConsumption', min: 1, max: 20, step: 1, isCurrency: false},
        {name: 'purchase.milagePrivate', min: 1000, max: 100000, step: 1000, isCurrency: false},
        {name: 'purchase.milageProfessional', min: 1000, max: 100000, step: 1000, isCurrency: false},
    ]

    const leasingItems: Item[] = [
        {name: 'leasing.leaseAmount', min: 2500, max: 15000, step: 100, isCurrency: true},
        {name: 'leasing.otherCosts', min: 0, max: 5000, step: 100, isCurrency: true},
        {name: 'leasing.milagePrivate', min: 1000, max: 100000, step: 1000, isCurrency: false},
        {name: 'leasing.milageProfessional', min: 1000, max: 100000, step: 1000, isCurrency: false},
    ]

    return (
        <>
            <h3 style={{marginBottom: 5}}>
                <AcertaIcon icon={"icon-calculator-24"} style={{marginRight: 8, verticalAlign: '-0.1em'}} />
                {translate("expenses.vehicle.type." + vehicle.vehicleType)}</h3>
            <p>{translate("expenses.vehicle.calculator.subtitle")}</p>
            <Form id="vehicleCalculationModuleForm">
                <Row style={{marginBottom: 10}} type="flex" align="middle">
                    <Col xs={24} style={{marginTop: 10, marginBottom: 10}}>
                        {getFieldDecorator('vehicleFinancingType', {
                            initialValue: vehicle.vehicleFinancingType
                        })(
                            <Radio.Group onChange={(e) => {
                                onChangeValue(e.target.value)
                            }}>
                                <Radio
                                    value={VehicleFinancingType.OwnContribution}>{translate("expenses.vehicle.financing.type." + VehicleFinancingType.OwnContribution)}</Radio>
                                <Radio
                                    value={VehicleFinancingType.Purchase}>{translate("expenses.vehicle.financing.type." + VehicleFinancingType.Purchase)}</Radio>
                                <Radio
                                    value={VehicleFinancingType.Leasing}>{translate("expenses.vehicle.financing.type." + VehicleFinancingType.Leasing)}</Radio>
                            </Radio.Group>
                        )}
                    </Col>
                </Row>
                {
                    (vehicle.vehicleFinancingType === VehicleFinancingType.Leasing ?
                        leasingItems :
                        purchaseItems).map((item: Item) =>
                        <Row style={{marginBottom: 0}} type="flex" align="middle" key={item.name}>
                            {isMobile ?
                                <>
                                <Col xs={10} md={13} style={{marginBottom: 10}}>
                                    <div>
                                        {translate("expenses.vehicle.item.name." + item.name)}
                                        {translate("expenses.vehicle.item.name." + item.name + ".help") !== '' &&
                                        <Explanation title={translate("expenses.vehicle.item.name." + item.name)}
                                                     explanation={translate("expenses.vehicle.item.name." + item.name + ".help")} />}
                                    </div>
                                    <div className={"rounded-font" + (item.isCurrency ? " acerta-medium-blue-1" : "")}>
                                        {form.getFieldValue(item.name) ?
                                            (item.isCurrency ? currency(form.getFieldValue(item.name), false, item.name.indexOf('fuelPricePerLiter') > 0 ? false : true) : noCurrency(form.getFieldValue(item.name))) :
                                            (item.isCurrency ? currency(_.get(vehicle, item.name), false, item.name.indexOf('fuelPricePerLiter') > 0 ? false : true) : noCurrency(_.get(vehicle, item.name)))}
                                    </div>
                                </Col>
                                </>
                                : <>
                                    <Col xs={8}>
                                        {translate("expenses.vehicle.item.name." + item.name)}
                                        {translate("expenses.vehicle.item.name." + item.name + ".help") !== '' &&
                                        <Explanation title={translate("expenses.vehicle.item.name." + item.name)}
                                                     explanation={translate("expenses.vehicle.item.name." + item.name + ".help")} />}
                                    </Col>
                                    <Col xs={4} style={{textAlign: 'right'}}
                                         className={"rounded-font" + (item.isCurrency ? " acerta-medium-blue-1" : "")}>
                                        {form.getFieldValue(item.name) ?
                                            (item.isCurrency ? currency(form.getFieldValue(item.name), false, item.name.indexOf('fuelPricePerLiter') > 0 ? false : true) : noCurrency(form.getFieldValue(item.name))) :
                                            (item.isCurrency ? currency(_.get(vehicle, item.name), false, item.name.indexOf('fuelPricePerLiter') > 0 ? false : true) : noCurrency(_.get(vehicle, item.name)))}
                                    </Col>
                                </>}
                            <Col xs={0} md={1}>
                            </Col>
                            <Col xs={14} md={11}>
                                {getFieldDecorator(item.name, {
                                    initialValue: _.get(vehicle, item.name)
                                })(
                                    <Slider
                                        step={item.step}
                                        min={item.min} max={item.max}
                                        // getTooltipPopupContainer={tooltipContainer}
                                        onAfterChange={() => onChangeValue(undefined)}
                                        tipFormatter={(number) => {
                                            return <span
                                                style={{zIndex: 10000}}>{number} {translate("common.slider." + item.name)}</span>
                                        }}
                                    />
                                )}
                            </Col>
                        </Row>)
                }
            </Form>
            <div style={{textAlign: 'right', marginTop: 10}}>
                <Button
                    type={"primary"} onClick={() => {
                    onClose()
                }}>{translate("common.button.toepassen")}</Button>
            </div>
        </>
    )
}

const VehicleCalculationModuleView = Form.create<FormProps>({
    name: 'vehicleCalculationModule',
})(VehicleCalculationModuleForm);

export default VehicleCalculationModuleView
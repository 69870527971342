import gql from "graphql-tag";

export const LOAN = gql`
    query Loan($calculationHash: String!, $loanId: String!) {
        loan(calculationHash: $calculationHash, loanId: $loanId) {
            id
            name
            amount
            interest
            duration
            toPay
            financing
            includeInCalculation
            annuity
        }
    }
`;

export const SAVE_LOAN = gql`
    mutation SaveLoan($calculationHash: String!, $loanInput: LoanInput) {
        saveLoan(calculationHash: $calculationHash, loanInput: $loanInput) {
            id
            name
            amount
            interest
            duration
            toPay
            financing
            includeInCalculation
            annuity
        }
    }
`;
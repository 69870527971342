import React, {useContext} from 'react'
import {FintoolContext} from "../../../context/fintool-context";
import {message} from "antd";
import {useEnterCodeMutation} from "../../../generated/graphql";
import EnterCodeView from "./enter-code-view";
import {handle} from "../../app/app";
import {CALCULATION_INFO} from "../../simulatie/queries";
import Cookies from "js-cookie";
import {RouteComponentProps, withRouter} from "react-router";

interface Props extends RouteComponentProps {
    inModal?: boolean
    onModalAction?: any
}

const EnterCodeContainer: React.FC<Props> = ({history, inModal, onModalAction}) => {
    const [context, setContext] = useContext(FintoolContext);

    const [enterCode] = useEnterCodeMutation({
        refetchQueries: [
            {query: CALCULATION_INFO, variables: {calculationHash: context.calculationHash}},
        ]
    });
    const doEnterCode = (code: string,) => {
        enterCode(
            {
                variables: {
                    calculationCode: code
                },
            }
        ).then((result: any) => {
            message.success("Simulatie opgehaald");
            const calculationHash = result.data.enterCode
            setContext({
                ...context,
                calculationHash: calculationHash
            });
            Cookies.set('calculationHash', calculationHash)
            history.push("/simulatie/profiel/" + calculationHash)

        }).catch(error => {
            console.log(error.message);
            error && handle(error)
        })
    };

    return (
        <EnterCodeView enterCode={doEnterCode} inModal={inModal} onModalAction={onModalAction} />
    )
};

export default withRouter(EnterCodeContainer)
import React, {useContext} from 'react'
import {message} from "antd";
import {FintoolContext} from "../../../../../context/fintool-context";
import {useUpdateVehicleMutation, Vehicle, VehicleInput} from "../../../../../generated/graphql";
import {I18nContext} from "../../../../../i18n";
import {FINANCING} from "../../../financing/queries";
import {RESULT} from "../../../common/result-chart/queries";
import {MESSAGE_DURATION} from "../../../../../utils/constants";
import {VEHICLE} from "./queries";
import VehicleCalculationModuleView from "./vehicle-calculation-module-view";

interface Props {
    onClose: any
    vehicle: Vehicle
}

const VehicleCalculationModuleSaveContainer: React.FC<Props> = ({onClose, vehicle}) => {

    const [context] = useContext(FintoolContext)
    const {translate} = useContext(I18nContext)

    const [saveVehicle] = useUpdateVehicleMutation({
            refetchQueries: [
                {query: FINANCING, variables: {calculationHash: context.calculationHash}},
                {query: VEHICLE, variables: {calculationHash: context.calculationHash, vehicleId: vehicle.id}},
                {query: RESULT, variables: {calculationHash: context.calculationHash}}
            ]
        }
    );

    const onSave = (vehicleInput: VehicleInput) => {
        if (!!context.calculationHash) {
            saveVehicle({
                variables: {
                    calculationHash: context.calculationHash,
                    vehicleInput: {...vehicleInput, id: vehicle ? vehicle.id : null}
                }
            }).then((result: any) => {
                message.success(translate("common.save.success"), MESSAGE_DURATION)
            }).catch((error: any) => {
                message.error(error.message)
            })
        }
    }
    return (
        <VehicleCalculationModuleView onSave={onSave} onClose={onClose} vehicle={vehicle} />
    )
}

export default VehicleCalculationModuleSaveContainer
import React, {useContext} from 'react'
import {Spin} from "antd";
import {FintoolContext} from "../../../../../context/fintool-context";
import {handle} from "../../../../app/app";
import {useVehicleQuery} from "../../../../../generated/graphql";
import VehicleCalculationModuleSaveContainer from "./vehicle-calculation-module-save-container";

interface Props {
    onClose: any
    selectedVehicleId: string
}

const VehicleCalculationModuleFetchContainer: React.FC<Props> = ({onClose, selectedVehicleId}) => {

    const [context] = useContext(FintoolContext)

    const {data, error, loading, networkStatus} = useVehicleQuery({
        variables: {
            calculationHash: context.calculationHash!,
            vehicleId: selectedVehicleId
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "network-only"
    })

    if (loading || networkStatus === 4) {
        return <div><Spin size="small" /></div>;
    }

    error && handle(error)

    if (!data) {
        return null;
    }

    return (
        <VehicleCalculationModuleSaveContainer onClose={onClose} vehicle={data.vehicle} />
    )
}

export default VehicleCalculationModuleFetchContainer
import React, {useContext} from 'react'
import {Button, Col, Row} from "antd";
import preval from 'preval.macro'
import {useMediaQuery} from "react-responsive";
import {isMobileQuery} from "../../../utils/media-queries";
import {withRouter} from "react-router-dom";
import {RouteComponentProps} from "react-router";
import {I18nContext} from "../../../i18n";
import Disclaimer from "../disclaimer/disclaimer";

const Footer: React.FC<RouteComponentProps> = ({location}) => {

    const isMobile = useMediaQuery(isMobileQuery)
    const {translate} = useContext(I18nContext)
    return (
        <div className="footer"
             style={{
                 paddingTop: 60,
                 backgroundColor: location.pathname === "/" ? "#fff" : (isMobile ? "#F0F2F5" : "#f9f9f9")
             }}>
            <div style={{paddingBottom: 20, display: 'flex', backgroundColor: '#fff'}}>
                <div style={{height: 4, flexGrow: 1}} className="acerta-dark-blue-background"></div>
                <div style={{height: 4, flexGrow: 1}} className="acerta-medium-blue-2-background"></div>
                <div style={{height: 4, flexGrow: 1}} className="acerta-light-blue-2-background"></div>
                <div style={{height: 4, flexGrow: 1}} className="acerta-light-blue-1-background"></div>
                <div style={{height: 4, flexGrow: 1}} className="acerta-pink-background"></div>
                <div style={{height: 4, flexGrow: 1}} className="acerta-orange-background"></div>
                <div style={{height: 4, flexGrow: 1}} className="acerta-yellow-background"></div>
            </div>
            <div style={{backgroundColor: '#fff'}}>
                <Row>
                    <Col xs={11} offset={1}>
                        <div
                            className="acerta-grey-less-dark"
                            style={{
                                fontSize: 11,
                            }}>
                            <div>
                                {translate("common.disclaimer.text")}
                            </div>
                            <div style={{display: 'flex'}}>
                                <div style={{
                                    height: 18,
                                    overflow: 'hidden',
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap"
                                }}>
                                    {translate("common.disclaimer.text2")}
                                </div>
                                <div>
                                    <Disclaimer buttonStyle={{fontSize: 11, height: 'auto'}} linkText={translate("common.meer")} />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={1}>
                        <div style={{textAlign: 'right', display: 'none'}}>
                            Build Date: {preval`module.exports = new Date().toLocaleString();`}.
                        </div>
                    </Col>
                    <Col xs={8} offset={1}>
                        <div style={{textAlign: 'right'}}>
                        <Button
                            style={{color: "#004B79"}}
                            type={'link'}
                            className="rounded-font acerta-dark-blue"
                            target="_blank"
                            id={translate('footer.privacy.and.cookies.id')}
                            href={translate('footer.privacy.and.cookies.link')} >
                            {translate("footer.privacy.and.cookies")}
                        </Button>
                        <Button
                            style={{marginLeft: 20, color: "#004B79"}}
                            type={'link'}
                            className="rounded-font"
                            target="_blank"
                            id={translate('footer.disclaimer.id')}
                            href={translate('footer.disclaimer.link')}
                        >
                            {translate("footer.disclaimer")}
                        </Button>
                        </div>
                    </Col>
                    <Col xs={2}></Col>
                </Row>
                <Row>
                    <Col>
                        <div style={{height: 20, backgroundColor: '#fff'}}></div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default withRouter(Footer)
import gql from 'graphql-tag';

export const REVENUE = gql`
    query Revenue($calculationHash: String!) {
        revenue(calculationHash: $calculationHash) {
            serviceRevenues {
                revenues {
                    id
                    description
                    quantity
                    unitPrice
                    dateCreated
                    includeInCalculation
                    calculatedCashFlowValue
                }
                totalPrice
            }
            products {
                revenues {
                    id
                    description
                    quantity
                    unitPrice
                    dateCreated
                    includeInCalculation
                    calculatedCashFlowValue
                }
                totalPrice
            }
            otherRevenues {
                revenues {
                    id
                    description
                    price
                    dateCreated
                    includeInCalculation
                }
                totalPrice
            }
        }
    }
`;
import React, {useContext, useEffect, useRef, useState} from 'react'
import {Button, Checkbox, Col, Form, Icon, Input, message, Row} from "antd";
import {FormComponentProps, ValidateCallback} from "antd/es/form";
import {I18nContext} from "../../../i18n";
import AcertaIcon from "../iconfont";
import {useMediaQuery} from "react-responsive";
import {isMobileQuery} from "../../../utils/media-queries";

interface Props extends FormComponentProps {
    sendEmail: any
    inModal?: boolean
    onModalAction?: any
    title: string
    downloadPDF?: boolean
    busy: boolean
    newCode: string | undefined
}

const MailCaptureForm: React.FC<Props> = ({form, sendEmail, inModal, onModalAction, title, downloadPDF, busy, newCode}) => {
    const [gdprAgreed, setGdprAgreed] = useState(true)
    const {translate} = useContext(I18nContext);
    const {getFieldDecorator} = form;
    const isMobile = useMediaQuery(isMobileQuery)

    const emailField = useRef<Input>(null);

    useEffect(() => {
        if (inModal && emailField.current) {
            emailField.current.focus();
        }
        // eslint-disable-next-line
    }, []);

    const onSubmit = () => {
        form.validateFields((err: ValidateCallback<any>, values: any) => {
            if (!err) {
                sendEmail(values.email);
                form.resetFields();
            }
            else {
                message.error(translate("common.error.email.valid.required"))
            }
        })
    };

    return (
        <>
            <h2>{title}</h2>
            <div>{downloadPDF ? translate("simulation.download.subtitle", true) :
                translate("simulation.save.subtitle", true)}</div>
            <Form className="mail-capture-form">
                <div style={{width: inModal ? 'inherit' : isMobile ? '100%' : 400}}>
                    <Form.Item>
                        {getFieldDecorator('email', {
                            rules: [{
                                type: 'email',
                                required: true,
                                message: 'e-mail is verplicht'}],
                        })(
                            <Input ref={emailField} disabled={busy} placeholder="email@outlook.com" />,
                        )}
                    </Form.Item>
                </div>
                <div>
                    <Checkbox style={{marginBottom: 10}} checked={gdprAgreed}
                              onChange={() => setGdprAgreed(!gdprAgreed)}>
                        <span style={{fontSize: 12, fontStyle: 'italic'}}>{translate("common.gdpr.agreement")}</span>
                    </Checkbox>
                </div>
                <Form.Item style={{marginBottom: 0}}>
                    <Row>
                        <Col xs={24} style={{textAlign: inModal ? 'right' : 'inherit'}}>
                            {inModal && !newCode &&
                            <Button
                                style={{marginRight: 10}}
                                type="default"
                                onClick={onModalAction}
                                disabled={busy}
                            >
                                {translate("common.cancel")}
                            </Button>
                            }
                            {inModal && newCode &&
                            <Button
                                style={{marginRight: 10}}
                                type="default"
                                onClick={onModalAction}
                                disabled={busy}
                            >
                                {translate("common.cancel")}
                            </Button>
                            }
                            {!newCode &&
                            <Button
                                id={downloadPDF ? translate('simulation.download.button.id') : translate('simulation.save.button.id')}
                                key="submit"
                                type="primary"
                                htmlType="submit"
                                onClick={onSubmit}
                                disabled={busy || !gdprAgreed}>
                                {busy ? <Icon type="sync" spin={true} /> :
                                    <AcertaIcon
                                        icon={"icon-save-24"}
                                        size={'16px'}
                                        style={{marginRight: 6, verticalAlign: '-0.1em', color: "#fff"}} />}
                                {downloadPDF ? translate('simulation.download.button') : translate('simulation.save.button')}
                            </Button>
                            }
                        </Col>
                    </Row>
                </Form.Item>
            </Form>
        </>
    )
};
const MailCaptureView = Form.create<Props>({name: 'MailCapture',})(MailCaptureForm);

export default MailCaptureView
import React, {useContext} from 'react'
import {Button} from "antd";
import {I18nContext} from "../i18n";
import {FintoolContext} from "../context/fintool-context";

interface Props {
    onSelect?: any
}

const LanguageSelect:React.FC<Props> = ({onSelect}) => {

    const {dispatch, langCode} = useContext(I18nContext);
    const [context] = useContext(FintoolContext)

    const onLanguageSelect = (language: string) => {
        dispatch({type: "setLanguage", payload: language});
        onSelect && onSelect()
    }

    return (
        <>
            {!context.calculationHash ?
                <Button type={"link"} className={langCode === 'nl' ? 'active-lang underline' : ''} href="/nl">NL</Button>
                :
                <Button type={"link"} className={langCode === 'nl' ? 'active-lang underline' : ''} onClick={() => onLanguageSelect('nl')}>NL</Button>
            }
            <span className="acerta-grey-medium">&nbsp;|&nbsp;</span>
            {!context.calculationHash ?
                <Button type={"link"} className={langCode === 'fr' ? 'active-lang underline' : ''} disabled={false} href="/fr">FR</Button>
                :
                <Button type={"link"} className={langCode === 'fr' ? 'active-lang underline' : ''} disabled={false}
                        onClick={() => onLanguageSelect('fr')}>FR</Button>
            }
            <span className="acerta-grey-medium">&nbsp;|&nbsp;</span>
            {!context.calculationHash ?
                <Button type={"link"} className={langCode === 'en' ? 'active-lang underline' : ''} disabled={false} href="/en">EN</Button>
                :
                <Button type={"link"} className={langCode === 'en' ? 'active-lang underline' : ''} disabled={false}
                        onClick={() => onLanguageSelect('en')}>EN</Button>
            }
        </>
    )
}

export default LanguageSelect
import React, {useContext} from 'react'
import ResultDetailsView from "./result-details-view";
import {CalculationInfo, useResultQuery} from "../../../generated/graphql";
import {Spin} from "antd";
import {FintoolContext} from "../../../context/fintool-context";
import {handle} from "../../app/app";

interface Props {
    calculationInfo: CalculationInfo
}

const ResultDetailsContainer:React.FC<Props> = ({calculationInfo}) => {
    const [context] = useContext(FintoolContext)

    const {data, error, loading, networkStatus} = useResultQuery({
        variables: {
            calculationHash: context.calculationHash as string
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "network-only"
    });

    if (loading || networkStatus === 4) {
        return <div><Spin size="small" /></div>;
    }

    error && handle(error);

    if (!data) {
        return <div>no data</div>;
    }

    return (
        <ResultDetailsView calculationInfo={calculationInfo} data={data} />
    )
}

export default ResultDetailsContainer
import React from 'react'

interface Props {
    icon:
        "icon-alert-triangle-16" |
        "icon-arrow-down-16" |
        "icon-arrow-up-16" |
        "icon-check-16" |
        "icon-check-circle-fill-16" |
        "icon-chevron-down-16" |
        "icon-chevron-left-16" |
        "icon-chevron-right-16" |
        "icon-chevron-up-16" |
        "icon-drag-16" |
        "icon-external-link-16" |
        "icon-help-circle-16" |
        "icon-help-circle-fill-16" |
        "icon-info-circle-16" |
        "icon-loading-16" |
        "icon-minus-16" |
        "icon-minus-circle-16" |
        "icon-plus-16" |
        "icon-plus-circle-16" |
        "icon-x-16" |
        "icon-zap-16" |
        "icon-arrow-left-24" |
        "icon-arrow-right-24" |
        "icon-briefcase-24" |
        "icon-close-24" |
        "icon-download-cloud-24" |
        "icon-edit-3-24" |
        "icon-edit-24" |
        "icon-eye-24" |
        "icon-eye-off-24" |
        "icon-home-24" |
        "icon-info-circle-24" |
        "icon-info-circle-fill-24" |
        "icon-lock-24" |
        "icon-mail-24" |
        "icon-message-circle-24" |
        "icon-monitor-24" |
        "icon-package-24" |
        "icon-pie-chart-24" |
        "icon-printer-24" |
        "icon-save-24" |
        "icon-settings-24" |
        "icon-tool-24" |
        "icon-trash-24" |
        "icon-truck-24" |
        "icon-upload-cloud-24" |
        "icon-users-24" |
        "icon-calculator-24" |
        "icon-plus-circle-24" |
        "icon-download-24"
    size?: string
    style?: any
    trackOpenCloseClass?: string
}

const AcertaIcon:React.FC<Props> = ({icon, size, style, trackOpenCloseClass}) => {
    let defaultStyle = {fontSize: size || (icon.slice(-2) + "px"), marginTop: 0}
    return(
        <span style={{...defaultStyle, ...style}} className={icon + " acerta-icon " + (trackOpenCloseClass || "")}></span>
    )
}

export default AcertaIcon
import {Result} from "../../../../generated/graphql";

export default class BarChartUtil {
    result: Result;
    netIncomeDenominator: number

    constructor(result: Result) {
        this.result = result;
        this.netIncomeDenominator =
            Math.round(this.result.yearlyCost +
            this.result.socialContributionsWithPensionSaving +
            this.result.personalIncomeTax +
            (this.result.calculatedYearlyNetIncome > 0 ? this.result.calculatedYearlyNetIncome : 0))

    }

    getLargestValueOnScale = () => {
        const cashInput = this.getCashInput();
        if (this.result.yearlyExpense > cashInput) {
            return this.result.yearlyExpense;
        }
        return cashInput;
    };

    getCashInput = () => {
        return this.result.yearlyRevenue + this.result.financed;
    };

    expensePercentage = () => {
        return (this.result.yearlyExpense / this.getLargestValueOnScale()) * 100;
    };

    revenuePercentage = () => {
        return (this.result.yearlyRevenue / this.getLargestValueOnScale()) * 100;
    };

    personalIncomeTaxPercentage = () => {
        return (this.result.personalIncomeTax / this.getLargestValueOnScale()) * 100;
    };

    socialPercentage = () => {
        return (this.result.socialContributions / this.getLargestValueOnScale()) * 100;
    }

    financingPercentage = () => {
        return (this.result.financed / this.getLargestValueOnScale()) * 100;
    };

    toFinancePercentage = () => {
        const toFinance = this.toFinance();
        return (toFinance / this.getLargestValueOnScale()) * 100;
    };

    toFinance = () => {
        return this.result.shortage;
    };

    surplus = () => {
        return this.result.surplus;
    };

}

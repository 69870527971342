import React, {useContext, useState} from 'react'
import {Button, Col, Row} from 'antd'
import Cookies from 'js-cookie';
import {I18nContext} from "../../../i18n";
import AcertaIcon from "../iconfont";

const CookiePolicy = () => {
    const cookieComplianceCookieName = "cookie_compliance"
    const [showCookiePolicy, setShowCookiePolicy] = useState(Cookies.get(cookieComplianceCookieName) ? false : true)
    const {translate} = useContext(I18nContext)

    const setAccepted = () => {
        Cookies.set(cookieComplianceCookieName, "1", { expires: 30 })
        setShowCookiePolicy(false)
    }

    if (!showCookiePolicy) return null

    return (
        <div style={{
            paddingTop: 20,
            left: 0,
            right: 0,
            bottom: 0,
            position: 'fixed',
            zIndex: 2243060,
        }}>
            <div id="cookie-policy"
                 style={{
                     backgroundColor: '#fff',
                     paddingTop: 30,
                     paddingBottom: 20,
                 }}
            >
                <Row>
                    <Col xs={23} md={15} lg={16} offset={1} style={{marginBottom: 5, paddingRight: 30}}>
                            <span style={{fontSize: 16}}>
                            {translate("common.cookies.text")}&nbsp;
                                <Button
                                    style={{fontSize: 16, padding: 0, lineHeight: 0}}
                                    type={'link'}
                                    target="_blank"
                                    href={translate("footer.privacy.and.cookies.link")}>
                                {translate("common.cookies.acerta.link.text")}
                            </Button>
                            </span>
                    </Col>
                    <Col xs={{offset: 1, span: 22}} md={6} lg={5}>
                        <Button type={"primary"} style={{width: '100%', marginBottom: 10}}
                                onClick={setAccepted}>
                            <AcertaIcon icon={"icon-arrow-right-24"} size={"16px"}
                                        style={{verticalAlign: '-0.1em', color: '#fff', marginRight: 10}} />
                            {translate("common.cookies.accept.button")}
                        </Button>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default CookiePolicy
import React, {useContext} from 'react'
import {ExpenseCategoriesWithSelectedQuery} from "../../../../generated/graphql";
import {I18nContext} from "../../../../i18n";
import {Col, Row} from "antd";
import AcertaIcon from "../../../common/iconfont";

interface Props {
    data: ExpenseCategoriesWithSelectedQuery
}

const ExpenseCategoriesView: React.FC<Props> = ({data}) => {

    const {translate} = useContext(I18nContext)

    const rows = [...Array(Math.ceil(data.expenseCategories.length / 3))];
    const catRows = rows.map((row, idx) => data.expenseCategories.slice(idx * 3, idx * 3 + 3));
    const content = catRows.map((row, idx) => (
        <Row key={idx}>
            {row.map(category => {
                const iconClass: any = "icon-expense-category-" + category.code
                return (<Col xs={8}>
                    <>
                        <h3 style={{
                            marginBottom: 10,
                            marginTop: 20
                        }}>
                            <AcertaIcon icon={iconClass} size={"24px"}
                                        style={{marginRight: 5, verticalAlign: '-0.1em'}} />
                            {translate("expense.category." + category.code)}
                        </h3>
                        {category.expenseTypes && category.expenseTypes.map((expenseType) =>
                            <div style={{marginBottom: 5}}>
                                <span className="acerta-light-blue-1">
                                <AcertaIcon
                                    style={{
                                        verticalAlign: '-0.1em',
                                        marginRight: 10,
                                        color: expenseType.selected ? 'inherit' : 'transparent'
                                    }}
                                    icon={"icon-check-16"} />
                                </span>
                                <span className={expenseType.selected ? "acerta-light-blue-1" : ""}>
                                    {translate("expense.type." + expenseType.code)}
                                </span>
                            </div>
                        )}
                    </>
                </Col>)
            })}
        </Row>)
    );

    return (
        <Row>
            <h1>Uitgaven</h1>
            {content}
        </Row>
    )
}

export default ExpenseCategoriesView
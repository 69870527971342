import React, {useContext} from 'react'
import {RouteComponentProps} from "react-router";
import {steps, useSetCorrectSimulationStep} from "../simulation-view";
import ExpenseContainer from "./expense-container";
import {FintoolContext} from "../../../context/fintool-context";

interface Props extends RouteComponentProps {
}

const Expense: React.FC<Props> = ({match}) => {

    const [context] = useContext(FintoolContext)

    useSetCorrectSimulationStep(match, steps.EXPENSES)

    if (!context.calculationHash) return null

    return (
        <ExpenseContainer calculationHash={context.calculationHash} />
    )
}

export default Expense
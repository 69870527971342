import React, {useContext, useState} from 'react'
import {Button, Modal} from "antd";
import {I18nContext} from "../../../i18n";

interface Props {
    buttonStyle: any
    linkText: string
}

const Disclaimer:React.FC<Props> = ({buttonStyle, linkText}) => {

    const {translate} = useContext(I18nContext)
    const [show, setShow] = useState(false)

    return (
        <>
            <Button style={buttonStyle} type={"link"} onClick={() => setShow(true)}>{linkText}</Button>
            <Modal
                visible={show}
                closable={true}
                okText={translate("common.button.sluiten")}
                onCancel={() => setShow(false)}
                footer={[
                    <Button key="submit" type="primary" onClick={() => setShow(false)}>
                        {translate("common.button.sluiten")}
                    </Button>,
                ]}
                onOk={() => setShow(false)}
            >
                <h2>{translate("common.disclaimer.popup.title")}</h2>
                <p>{translate("common.disclaimer.text")}</p>
                <p>{translate("common.disclaimer.text2")}</p>
            </Modal>
        </>
    )
}

export default Disclaimer
import React, {useContext, useState} from 'react'
import ShadowBox, {BoxTitle} from "../../../common/shadow-box/shadow-box";
import {Housing, HousingType} from "../../../../generated/graphql";
import {I18nContext} from "../../../../i18n";
import HousingSingleRowFormContainer from "./housing-single-row-form-container";
import {Modal} from "antd";
import HousingCalculationModuleFetchContainer from "./housing-calculation-module";

interface Props {
    onAdd: any
    data?: Housing[] | null
    newHousingId?: string
}

const HousingView: React.FC<Props> = ({data, onAdd, newHousingId}) => {

    const [boxOpen, setBoxOpen] = useState(true)
    const [housingModuleOpen, setHousingModuleOpen] = useState(false)
    const {translate} = useContext(I18nContext)

    const onBeforeAdd = (values: any) => {
        onAdd(values.option)
        if (values.option === HousingType.EigenWoning || values.option === HousingType.Beroepsruimte) {
            setHousingModuleOpen(true)
        }
    }

    return (
        <>
            <ShadowBox title={
                <BoxTitle iconClass="icon-home-24"
                          text={translate("expense.category.HUISVESTING")}
                          addText={translate("expense.category.add.text.HUISVESTING")}
                          setBoxOpen={setBoxOpen} boxOpen={boxOpen}
                          noArrow={false}
                          onAdd={onBeforeAdd}
                          addOptions={[{
                              disabled: (data!.some((housing: Housing) => housing.housingType === HousingType.EigenWoning)),
                              value: HousingType.EigenWoning,
                              text: translate("expenses.housing.type." + HousingType.EigenWoning)
                          },
                              {
                                  disabled: (data!.some((housing: Housing) => housing.housingType === HousingType.Beroepsruimte)),
                                  value: HousingType.Beroepsruimte,
                                  text: translate("expenses.housing.type." + HousingType.Beroepsruimte)
                              },
                              {
                                  disabled: (data!.some((housing: Housing) => housing.housingType === HousingType.Inrichting)),
                                  value: HousingType.Inrichting,
                                  text: translate("expenses.housing.type." + HousingType.Inrichting)
                              },
                              ]}
                          toAdd={{
                              purchaseAmount: 0,
                              maintenance: 0,
                          }}
                          trackOpenCloseClass={translate("expense.housing.open.close.class")}
                          trackAddClass={translate("expense.category.add.HUISVESTING.class")}
                />
            } open={boxOpen}
                       noArrow={false}>
                {data ? data.map((housing: Housing, index: number) =>
                    <div className={index === 0 ? 'first' : ''} key={'div_'+ housing.id}>
                        <HousingSingleRowFormContainer key={housing.id}
                                                       housing={housing}
                        /></div>
                ) : null}
            </ShadowBox>
            {newHousingId &&
            <Modal
                visible={housingModuleOpen}
                onOk={() => {
                    setHousingModuleOpen(false)
                }}
                onCancel={() => setHousingModuleOpen(false)}
                okText={"Lening opslaan"}
                cancelText={"Annuleren"}
                okButtonProps={{disabled: true}}
                footer={null}
                style={{ top: 0 }}
            >
                <HousingCalculationModuleFetchContainer selectedHousingId={newHousingId}
                                                        onClose={() => setHousingModuleOpen(false)} />
            </Modal>
            }
            <div id="sliders-tooltip-housing-container"></div>
        </>
    )
}

export default HousingView
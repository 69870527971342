import React, {useContext, useState} from 'react'
import {ServiceRevenuesSection} from "../../../../generated/graphql";
import ServiceRevenueSingleRowFormContainer from "./service-revenue-single-row-form-container";
import ShadowBox, {BoxTitle} from "../../../common/shadow-box/shadow-box";
import {I18nContext} from "../../../../i18n";

interface Props {
    data: ServiceRevenuesSection
    onAdd: any
}

const ServiceRevenuesView: React.FC<Props> = ({data, onAdd}) => {

    const [boxOpen, setBoxOpen] = useState(true)
    const {translate} = useContext(I18nContext)

    return (
        <>
            <ShadowBox title={
                <BoxTitle iconClass="icon-employer-24"
                          text={translate("revenue.diensten.title")}
                          addText={translate("revenue.diensten.add.text")}
                          explanation={translate("revenue.diensten.long.subtitle")}
                          setBoxOpen={setBoxOpen}
                          boxOpen={boxOpen}
                          noArrow={false}
                          onAdd={() => onAdd({
                              description: "",
                              unitPrice: 0,
                              quantity: 0,
                          })}
                          trackOpenCloseClass={translate("revenue.service.open.close.class")}
                          trackAddClass={translate("revenue.service.add.class")}
                />}
                       open={boxOpen}
                       noArrow={false}
            >
                {data.revenues.map((serviceRevenue, index) =>
                    !!serviceRevenue ?
                        <div className={index === 0 ? 'first' : ''} key={'div_'+ serviceRevenue.id}>
                            <ServiceRevenueSingleRowFormContainer key={serviceRevenue.id}
                                                              serviceRevenue={serviceRevenue} />
                        </div>: null
                )}
            </ShadowBox>
        </>
    )
}

export default ServiceRevenuesView
import React, {useContext, useEffect, useRef, useState} from 'react'
import sofieAvatar from '../../../../assets/images/sofie_avatar.png'
import {Affix} from "antd";
import './tabs.css'
import Sofie from "./sofie";
import {FintoolContext} from "../../../../context/fintool-context";
import _ from 'lodash'
import {I18nContext} from "../../../../i18n";

const Tabs = () => {
    const {translate, langCode} = useContext(I18nContext)
    const [context] = useContext(FintoolContext)
    const ref = useRef(null)
    const [drawerHeight, setDrawerHeight] = useState(0)
    const [showSofie, setShowSofie] = useState(false)
    const [sofieSeen, setSofieSeen] = useState(false)
    useEffect(() => {
            if (showSofie || (!sofieSeen && context.currentTopStep === 0)) {
                const newDrawerHeight = _.get(ref.current, "clientHeight")
                if (!!newDrawerHeight && (newDrawerHeight !== drawerHeight)) {
                    setDrawerHeight(14 + newDrawerHeight)
                    setSofieSeen(true)
                    setShowSofie(true)
                } else {
                    setDrawerHeight(0)
                }
            } else {
                setDrawerHeight(0)
            }
            // eslint-disable-next-line
        }, [context.currentTopStep, langCode, showSofie]
    )

    const pageWithExample: boolean = [0, 1, 2, 3].includes(context.currentTopStep)

    return (
        <>
            <Affix
                className="tabs-menu"
                offsetTop={90}>
                <>
                    <div
                        className="tab-drawer"
                        style={{
                            height: pageWithExample ? drawerHeight : 0,
                            overflow: 'hidden',
                            paddingLeft: 0,
                            paddingRight: 10,
                        }}
                    >
                        {pageWithExample &&
                        <div ref={ref}><Sofie close={() => {
                            setShowSofie(false)
                        }} /></div>}
                    </div>
                    <div style={{
                        float: 'right',
                        position: 'relative',
                        zIndex: 1000,
                        paddingRight: 60,
                        marginBottom: pageWithExample ? -60 : 0
                    }}>
                        {pageWithExample &&
                        <div
                            className={"tab" + (drawerHeight > 0 ? " tab-no-image" : "")}
                            style={{
                                display: 'flex',
                                backgroundColor: '#fff',
                                zIndex: 11,
                            }}
                            onClick={() => {
                                setShowSofie(!showSofie)
                            }}
                        >
                            <div>
                                <img
                                    alt="sofie"
                                    style={{width: 40}}
                                    src={sofieAvatar} />
                            </div>
                            <div style={{marginLeft: 10}}>
                                {translate("common.example.sofie")}
                            </div>
                        </div>}
                    </div>
                </>
            </Affix>
        </>
    )
}

export default Tabs
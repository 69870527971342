import React, {useContext} from 'react'
import {ProductInput, ProductsRevenuesSection, useAddProductMutation} from "../../../../generated/graphql";
import {REVENUE} from "../queries";
import {RESULT} from "../../common/result-chart/queries";
import {message} from "antd";
import {FintoolContext} from "../../../../context/fintool-context";
import ProductsView from "./products-view";
import {MESSAGE_DURATION} from "../../../../utils/constants";
import {I18nContext} from "../../../../i18n";

interface Props {
    data: ProductsRevenuesSection
}

const ProductsContainer: React.FC<Props> = ({data}) => {

    const [context] = useContext(FintoolContext)
    const {translate} = useContext(I18nContext);

    const [addProduct] = useAddProductMutation({
            refetchQueries: [
                {query: REVENUE, variables: {calculationHash: context.calculationHash}},
                {query: RESULT, variables: {calculationHash: context.calculationHash}}
            ]
        }
    )

    const onAdd = (values: ProductInput) => {
        if (!!context.calculationHash) {
            addProduct({
                variables: {
                    calculationHash: context.calculationHash,
                    productInput: values
                }
            }).then((result: any) => {
                message.success(translate("common.add.succes"), MESSAGE_DURATION)
            }).catch(error => {
                message.error(error.message)
            })
        }
    }

    return (
        <ProductsView onAdd={onAdd} data={data} />
    )
}

export default ProductsContainer
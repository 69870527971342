import React, {useContext, useEffect} from 'react'
import {Affix, Col, Row} from "antd";
import {Route} from "react-router";
import ResultChartContainer from "./common/result-chart";
import {FintoolContext} from "../../context/fintool-context";
import _ from "lodash";
import TopSteps from "./common/top-steps";
import {isMobileQuery, isTabletOrMobileQuery, isTabletQuery, LargeDesktop, TabletOrMobileOrSmallDesktop} from "../../utils/media-queries";
import Revenue from "./revenue";
import Expense from "./expense";
import Financing from "./financing";
import Profile from "./profile";
import ResultContainer from "./result"
import {useMediaQuery} from "react-responsive";
import {CalculationInfo} from "../../generated/graphql";
import Tabs from "./common/tabs";
import TabsMobile from "./common/tabs/tabs-mobile";

export const steps: any = {
    PROFILE: {path: "/simulatie/profiel", stepNumber: 0},
    REVENUES: {path: "/simulatie/ontvangsten", stepNumber: 1},
    EXPENSES: {path: "/simulatie/uitgaven", stepNumber: 2},
    FINANCING: {path: "/simulatie/financiering", stepNumber: 3},
    RESULT: {path: "/simulatie/resultaat", stepNumber: 4},
}

export const useSetCorrectSimulationStep = (match: any, step: any) => {

    const [context, setContext] = useContext(FintoolContext)

    useEffect(() => {
        const calculationHash = _.get(match, "params.calculationHash")
        if (context.calculationHash !== calculationHash ||
            context.currentPage !== "/simulatie" ||
            context.currentStep !== step.path) {
            setContext({
                ...context,
                calculationHash: calculationHash,
                currentPage: "/simulatie",
                currentStep: step.path,
                currentTopStep: step.stepNumber
            })
        }
        // eslint-disable-next-line
    }, [match])

    return null
}

interface Props {
    calculationInfo: CalculationInfo | null
}

const SimulationView: React.FC<Props> = ({calculationInfo}) => {
    const [context] = useContext(FintoolContext)
    const isMobile = useMediaQuery(isMobileQuery)
    const isTabletOrMobile = useMediaQuery(isTabletOrMobileQuery)
    const isTablet = useMediaQuery(isTabletQuery)

    return (
        <div style={{display: "flex", flexDirection: "row"}}>
            <div style={{width: '100%'}}>
                <Affix offsetTop={0}>
                    <Row className="simulation-steps-row"
                         style={{
                             backgroundColor: "#fff",
                             zIndex: 9,
                             height: isMobile ? 70 : 90
                         }}
                    >
                        <Col xs={24}>
                            <div style={{marginTop: isMobile ? 40 : 55, paddingRight: 50}} />
                            <Row>
                                <Col xs={isMobile ? 21 : 22} offset={isMobile ? 0 : (isTablet ? 1 : 2)}>
                                    <TopSteps />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Affix>
                <LargeDesktop>
                    <Tabs />
                </LargeDesktop>
                <TabletOrMobileOrSmallDesktop>
                    <TabsMobile/>
                </TabletOrMobileOrSmallDesktop>
                <Row style={{paddingTop: isMobile ? 12 : 24, marginBottom: 50}}>
                    <Col span={isTabletOrMobile ? 22 : 21} offset={isTabletOrMobile ? 1 : 2}>
                        <div>
                            <Route exact path="/simulatie/profiel" component={Profile} />
                            <Route path="/simulatie/profiel/:calculationHash" component={Profile} />
                            <Route path="/simulatie/ontvangsten/:calculationHash" component={Revenue} />
                            <Route path="/simulatie/uitgaven/:calculationHash" component={Expense} />
                            <Route path="/simulatie/financiering/:calculationHash" component={Financing} />
                            <Route path="/simulatie/resultaat/:calculationHash" component={ResultContainer} />
                        </div>
                    </Col>
                </Row>
            </div>

            <LargeDesktop>
                <div style={{minWidth: 362}}>
                    {!!context.calculationHash &&
                    <Affix offsetTop={0}>
                        <ResultChartContainer calculationHash={context.calculationHash} />
                    </Affix>
                    }
                </div>
            </LargeDesktop>
        </div>
    )
}

export default SimulationView
import React, {useContext, useState} from 'react'
import {Modal, Tooltip} from "antd";
import {FintoolContext} from "../../../context/fintool-context";
import MailCaptureContainer from "../../common/mail-capture/mail-capture-container";
import {getBackendPrefix} from "../../app/app";
import {I18nContext} from "../../../i18n";
import {useMediaQuery} from "react-responsive";
import {isDesktopQuery} from "../../../utils/media-queries";

interface Props {
    askEmail: boolean
    component: any
    noTooltip?: boolean
}

const DownloadView: React.FC<Props> = ({askEmail, component, noTooltip}) => {
    const {translate} = useContext(I18nContext);
    const [context] = useContext(FintoolContext)
    const [showEmailPopup, setShowEmailPopup] = useState(false)
    const isDesktop = useMediaQuery(isDesktopQuery)

    const download = () => {
        if (askEmail) {
            setShowEmailPopup(true)
        } else {
            const downloadUrl = getBackendPrefix() + '/backend/simulatie/pdf/' + context.calculationHash;
            window.open(downloadUrl, '_self');
        }
    }

    return (
        <>
            {isDesktop && !noTooltip ?
                <Tooltip title={translate("common.pdf.download")} placement={"bottomLeft"}>
                    <span onClick={() => download()}>{component}</span>
                </Tooltip> : <><span onClick={() => download()}>{component}</span></>}
            <Modal
                visible={showEmailPopup}
                onCancel={() => setShowEmailPopup(false)}
                okButtonProps={{disabled: true}}
                footer={null}
            >
                <MailCaptureContainer
                    title={translate("simulation.download.title")}
                    inModal={true}
                    downloadPDF={true}
                    onModalAction={() => setShowEmailPopup(false)} />
            </Modal>
        </>
    )
}

export default DownloadView
import React, {useContext, useEffect} from 'react'
import LandingView from "./landing-view";
import {useCreateCalculationMutation} from "../../generated/graphql";
import {FintoolContext} from "../../context/fintool-context";
import {RouteComponentProps} from "react-router";
import Cookies from "js-cookie";
import {CALCULATION_INFO} from "../simulatie/queries";
import {useParams} from "react-router-dom";
import {I18nContext} from "../../i18n";

interface Props extends RouteComponentProps {
}

const LandingContainer: React.FC<Props> = ({match, history}) => {
    const [context, setContext] = useContext(FintoolContext)
    const {dispatch, langCode} = useContext(I18nContext);
    let {language} = useParams();
    if (language && (language !== langCode)) {
        dispatch({type: "setLanguage", payload: language});
    }

    useEffect(() => {
        setContext({
            ...context,
            calculationHash: null,
            incomeVisualSeen: false,
            expenseVisualSeen: false,
        })
        // eslint-disable-next-line
    }, [])

    const [createCalculation] = useCreateCalculationMutation({
        refetchQueries: [
            {query: CALCULATION_INFO, variables: {calculationHash: context.calculationHash}},
        ]
    });

    const create = () => {
        createCalculation({
                variables: {
                    lang: langCode
                }
            }
        ).then((result: any) => {
            let calculationHash = result.data.createCalculation;
            setContext({
                ...context,
                calculationHash: calculationHash
            });
            Cookies.set('calculationHash', calculationHash)
            history.push("/simulatie/profiel/" + calculationHash)
        }).catch(error => {
            console.log(error.message);
        })
    };

    return (
        <LandingView createCalculation={create}/>
    )
}

export default LandingContainer
import gql from 'graphql-tag';

export const EXPENSE_CATEGORIES_WITH_SELECTED = gql`
    query ExpenseCategoriesWithSelected($calculationHash: String) {
        expenseCategories(calculationHash: $calculationHash){
            id
            code
            expenseTypes{
                id
                code
                selected
            }
        }
    }
`;
import React, {useContext, useState} from 'react'
import {FintoolContext} from "../../../context/fintool-context";
import {message} from "antd";
import {useSendEmailMutation} from "../../../generated/graphql";
import MailCaptureView from "./mail-capture-view";
import {getBackendPrefix, handle} from "../../app/app";
import {CALCULATION_INFO} from "../../simulatie/queries";
import {EmptyShadowBox} from "../shadow-box/shadow-box";
import {I18nContext} from "../../../i18n";

interface Props {
    inModal?: boolean
    onModalAction?: any
    downloadPDF?: boolean
    title: string
}

const MailCaptureContainer: React.FC<Props> = ({inModal, onModalAction, downloadPDF, title}) => {
    const [context] = useContext(FintoolContext);
    const {translate} = useContext(I18nContext);
    const [busy, setBusy] = useState(false)
    const [newCode, setNewCode] = useState<string | undefined>(undefined)

    const [sendEmail] = useSendEmailMutation({
        refetchQueries: [
            {query: CALCULATION_INFO, variables: {calculationHash: context.calculationHash}},
        ]
    });
    const sendEmailAndHandleResponse = (email: string,) => {
        const calculationHash = context.calculationHash as string;
        setBusy(true)
        sendEmail(
            {
                variables: {
                    calculationHash: calculationHash,
                    email: email
                },
            }
        ).then((result: any) => {
            setBusy(false)
            if(inModal) {
                onModalAction()
            }
            setNewCode(result)
            message.success(translate("common.email.send"));
            if (downloadPDF) {
                const downloadUrl = getBackendPrefix() + '/backend/simulatie/pdf/' + context.calculationHash;
                window.open(downloadUrl, '_self');
            }
        }).catch(error => {
            setBusy(false)
            console.log(error.message);
            error && handle(error)
        })
    };
    return (
        inModal ?
            <MailCaptureView
                sendEmail={sendEmailAndHandleResponse}
                inModal={inModal}
                onModalAction={onModalAction}
                title={title}
                downloadPDF={downloadPDF}
                busy={busy}
                newCode={newCode}
            />
            :
            <EmptyShadowBox>
                <MailCaptureView
                    sendEmail={sendEmailAndHandleResponse}
                    inModal={inModal}
                    onModalAction={onModalAction}
                    title={title}
                    downloadPDF={downloadPDF}
                    busy={busy}
                    newCode={newCode}
                />
            </EmptyShadowBox>
    )
};

export default MailCaptureContainer
import React, {useContext} from 'react'
import {Spin} from "antd";
import {FintoolContext} from "../../../../../context/fintool-context";
import {handle} from "../../../../app/app";
import {useHousingQuery} from "../../../../../generated/graphql";
import HousingCalculationModuleSaveContainer from "./housing-calculation-module-save-container";

interface Props {
    onClose: any
    selectedHousingId: string
}

const HousingCalculationModuleFetchContainer: React.FC<Props> = ({onClose, selectedHousingId}) => {

    const [context] = useContext(FintoolContext)

    const {data, error, loading, networkStatus} = useHousingQuery({
        variables: {
            calculationHash: context.calculationHash!,
            housingId: selectedHousingId
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "network-only"
    })

    if (loading || networkStatus === 4) {
        return <div><Spin size="small" /></div>;
    }

    error && handle(error)

    if (!data) {
        return null;
    }

    return (
        <HousingCalculationModuleSaveContainer onClose={onClose} housing={data.housing} />
    )
}

export default HousingCalculationModuleFetchContainer